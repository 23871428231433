import { Checkbox, CheckboxGroup } from '@cb/apricot-react-forms'
export const CheckboxgroupComponent = (props: any) => {
  const { vertical, parentArray, dataArray, setArray, classNameprop, checkboxId, customcheck, setdisable, legend } = props
  return (
    <CheckboxGroup fieldsetId={`${checkboxId}-options`} legend={legend} legendClassName='sr-only' vertical={vertical}>
      {parentArray.map((option: { value: any; label: string }, index: number) => {
        const checked = dataArray.includes(option.value) || customcheck || false
        return (
          <Checkbox
            key={index}
            disabled={setdisable}
            id={`${checkboxId}-${index}-option`}
            label={option.label}
            className={classNameprop}
            data-testid={`${checkboxId}-${index}-option`}
            checked={checked}
            onChange={(checked: boolean) => setArray(checked, option.value)}
          />
        )
      })}
    </CheckboxGroup>
  )
}
export const CheckboxComponent = (props: any) => {
  const { parentArray, dataArray, setArray, label, checkboxId, classNameprop, setdisable } = props
  return (
    <Checkbox
      className={classNameprop}
      label={label}
      disabled={setdisable}
      id={checkboxId}
      data-testid={checkboxId}
      checked={parentArray.map(({ value }: any) => value).every((val: any) => dataArray.includes(val))}
      onChange={(checked: boolean) => setArray(checked)}
    />
  )
}
