import { Checkbox, CheckboxGroup, RadioButton, RadioButtonGroup, Spacer } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { Tooltip, TooltipHeader } from '@msss/ui-components'
import { useCallback, useEffect, useState } from 'react'
import MyCriteriaList from '../../../../my-account/saved-criteria/MyCriteriaList'
import { AvailableMySavedCriteriaTypes } from '../../../../my-account/saved-criteria/options'
import { ICriteria } from '../../ICriteria'
import { IUserData } from '../../IUserData'
import { ISavedCriteriaData, SavedCriteriaSectionProps } from '../../Search'
import { Section } from '../../summary/Section'
import { Summary } from '../../summary/Summary'
import { ALSO_INCLUDE_STUDENTS, alsoIncludeStudentsMap, INCLUDE_STUDENTS, includeStudentsMap } from './options'
import SAGrid from './SAGrid'

const SegmentAnalysis = ({
  selectedCriteria,
  selectedUserData,
  handleCriteria,
  handleUserData,
  myaccount
}: SavedCriteriaSectionProps) => {
  const hasChanged = (): boolean => {
    if (alsoIncludeStudents.length === 0 && clusters.length === 0) return false
    else return true
  }

  const [alsoIncludeStudents, setAlsoIncludeStudents] = useState<Array<string>>([])
  const [clusters, setClusters] = useState<Array<string>>([])
  const [includeStudents, setIncludeStudents] = useState<string>(INCLUDE_STUDENTS.all)

  // Select saved criteria set
  const [savedCriteriaData, setSavedCriteriaData] = useState<ISavedCriteriaData>()

  const [changed, setChanged] = useState<boolean>(hasChanged)

  const setup = async (criteria?: ICriteria, userData?: IUserData) => {
    if (criteria?.hsCluster2020) {
      setAlsoIncludeStudents(() => {
        const list: Array<string> = []
        userData?.unknownHsCluster === 'Y' && list.push(ALSO_INCLUDE_STUDENTS.noHSC)
        userData?.unknownNhCluster === 'Y' && list.push(ALSO_INCLUDE_STUDENTS.noNHC)
        return list
      })
      setClusters(criteria.hsCluster2020)
      setIncludeStudents(INCLUDE_STUDENTS.clusters)
    }
  }

  useEffect(() => {
    setup(selectedCriteria, selectedUserData)
  }, [selectedCriteria])

  useEffect(() => {
    // uncheck alsoIncludeStudents if no full row / column is selected anymore
    const hasColSelected = clusters.some((cluster: string) => cluster.startsWith('00'))
    const hasRowSelected = clusters.some((cluster: string) => cluster.endsWith('00'))
    const needsUpdate =
      (!hasColSelected && alsoIncludeStudents.includes(ALSO_INCLUDE_STUDENTS.noNHC)) ||
      (!hasRowSelected && alsoIncludeStudents.includes(ALSO_INCLUDE_STUDENTS.noHSC))
    if (needsUpdate) {
      setAlsoIncludeStudents(previous =>
        previous.filter((value: string) => (value === ALSO_INCLUDE_STUDENTS.noNHC ? hasColSelected : hasRowSelected))
      )
    }
  }, [clusters])

  useEffect(() => {
    const criteria: ICriteria = { hsCluster2020: clusters }
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())

    const userDataData: IUserData = {
      unknownHsCluster: alsoIncludeStudents.includes(ALSO_INCLUDE_STUDENTS.noHSC) ? 'Y' : 'N',
      unknownNhCluster: alsoIncludeStudents.includes(ALSO_INCLUDE_STUDENTS.noNHC) ? 'Y' : 'N'
    }

    if (handleUserData) {
      handleUserData(userDataData)
      setSavedUserData(userDataData)
      setChanged(hasChanged())
    }
  }, [alsoIncludeStudents, clusters])

  useEffect(() => {
    if (savedCriteriaData && savedCriteriaData.criteriaId !== -1) {
      setup(savedCriteriaData?.criteria, savedCriteriaData?.userData)
      setSavedCriteriaData({ ...savedCriteriaData, criteriaId: -1 })
    }
  }, [savedCriteriaData])

  const handleIncludeStudents = useCallback((value: string) => {
    setIncludeStudents(value)
    if (value === INCLUDE_STUDENTS.all) {
      setAlsoIncludeStudents([])
      setClusters([])
    }
  }, [])

  useEffect(() => {
    if (myaccount) setIncludeStudents(INCLUDE_STUDENTS.clusters)
  }, [myaccount])

  // these are for creating my saved critera set in sfs
  const setSavedCriteria = useCallback((criteriaData: ICriteria): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: ICriteria = { ...prev.criteria, ...criteriaData }
        return { ...prev, criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0)) }
      }
      // prev undefined
      const x: ICriteria = criteriaData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.SegmentAnalysis,
        userData: {},
        criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0))
      }
    })
  }, [])

  const setSavedUserData = (userDataData: IUserData): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: IUserData = { ...prev.userData, ...userDataData }
        return {
          ...prev,
          userData: Object.fromEntries(
            Object.entries(x).filter(([key, value]) =>
              value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
            )
          )
        }
      }
      // prev undefinded
      const x: IUserData = userDataData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.SegmentAnalysis,
        userData: Object.fromEntries(
          Object.entries(x).filter(([key, value]) =>
            value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
          )
        ),
        criteria: {}
      }
    })
  }

  const handleAlsoIncludeStudents = useCallback((checked: boolean, value: string) => {
    if (checked) {
      // add value
      setAlsoIncludeStudents(previous => [...previous, value])
    } else {
      // remove value
      setAlsoIncludeStudents(previous => previous.filter(item => item !== value))
    }
  }, [])

  const clearSelections = useCallback((event: any) => {
    event.preventDefault()
    setAlsoIncludeStudents([])
    setClusters([])
  }, [])

  const removeClusterOption = useCallback((label: string) => {
    setAlsoIncludeStudents(previousOptions => {
      const removingItem = Array.from(alsoIncludeStudentsMap.values()).find((option: any) => option.label === label)
      return previousOptions.filter((option: string) => option !== ensure(removingItem).value)
    })
  }, [])

  const removeClusterSelection = useCallback((label: string) => {
    setClusters(previousClusters => {
      let [, nhCluster, , hsCluster] = label.split(' ') // ['EN:', `${nhCluster},`, 'HS:', hsCluster]
      nhCluster = nhCluster.split(',')[0] // [nhCluster]
      nhCluster = nhCluster === 'ALL' ? '00' : Number(nhCluster) < 10 ? `0${nhCluster}` : nhCluster
      hsCluster = hsCluster === 'ALL' ? '00' : Number(hsCluster) < 10 ? `0${hsCluster}` : hsCluster
      return previousClusters.filter((cluster: string) => cluster !== `${nhCluster}${hsCluster}`)
    })
  }, [])

  const toSummary = useCallback(() => {
    const dataContent = [
      ...(alsoIncludeStudents.length
        ? [
            {
              name: 'Cluster Options',
              values: Array.from(alsoIncludeStudentsMap.values())
                .filter((option: any) => alsoIncludeStudents.includes(option.value))
                .map((option: any) => option.label),
              remove: removeClusterOption
            }
          ]
        : []),
      ...(clusters.length
        ? [
            {
              name: 'Cluster Selections',
              values: clusters.map((cluster: string) => {
                const nhCluster = cluster.substring(0, 2)
                const hsCluster = cluster.substring(2)
                return `EN: ${nhCluster === '00' ? 'ALL' : Number(nhCluster)}, HS: ${
                  hsCluster === '00' ? 'ALL' : Number(hsCluster)
                }`
              }),
              remove: removeClusterSelection,
              scroll: true
            }
          ]
        : [])
    ]

    return {
      section: Section.SA,
      data: dataContent
    }
  }, [alsoIncludeStudents, clusters, removeClusterOption, removeClusterSelection])

  return (
    <>
      <div className='cb-margin-left-8'>
        <p>
          Clusters are an integral part of College Board Search; they are included in Student Search Service® output, can be used
          to select students in Student Search, and can be used in research queries and interactive reports in the Enrollment
          Planning Service™.
        </p>
        <Spacer size='48' />
        <div className='row'>
          <div className='col-sm-4' style={myaccount ? { visibility: 'hidden' } : undefined}>
            <TooltipHeader
              header='Saved Criteria'
              id='sa-saved-clusters'
              // tooltipMsg='select previously saved criteria'
            />
            <MyCriteriaList
              type={AvailableMySavedCriteriaTypes.SegmentAnalysis}
              setSavedCriteriaData={ensure(setSavedCriteriaData)}
              myaccount={ensure(myaccount)}
              changed={changed}
            />
          </div>
          <div className='col-sm-3 offset-sm-5'>
            <div
              className='cb-gray5-bg cb-padding-left-24 cb-padding-right-32 cb-padding-top-8 cb-padding-bottom-16'
              role='region'
              aria-labelledby='sa-need-help'
            >
              <h4 className='cb-h6' id='sa-need-help'>
                Need Help<span className='sr-only'> with segment analysis</span>?
              </h4>
              <Spacer size='16' />
              <a
                href='https://secure-media.collegeboard.org/mSSS/media/pdf/segment-analysis-service-cluster-description-guide.pdf'
                rel='noopener noreferrer'
                target='_blank'
              >
                Download the Segment Analysis Service Cluster Guide
              </a>
              {/* <Spacer size='16' />
            <a
              href=''
              rel='noopener noreferrer'
              target='_blank'
            >
              Historic and Periodic Tagging Tips
            </a> */}
            </div>
          </div>
          <div className='col-xs-12'>
            <Spacer size='32' />
            {!myaccount ? (
              <>
                <RadioButtonGroup
                  fieldsetId='sa-include-students-options'
                  legend='Include students who'
                  legendClassName='sr-only'
                  onChange={handleIncludeStudents}
                  name='include-students'
                  value={includeStudents}
                  vertical
                >
                  {Array.from(includeStudentsMap.values()).map(option => (
                    <RadioButton
                      id={`sa-include-students-${option.value}-option`}
                      key={option.value}
                      label={option.label}
                      value={option.value as string}
                    />
                  ))}
                </RadioButtonGroup>
                <Spacer size='32' />
              </>
            ) : null}
          </div>
          {includeStudents !== INCLUDE_STUDENTS.all && (
            <div className='col-xs-12'>
              <h4 className='cb-h6'>Cluster Selection Tips</h4>
              <p className='cb-margin-top-16'>
                Activate the grid by selecting a square. Click either a row header or a column header to select all the cells for
                either the Educational Neighborhood Clusters or High School Clusters. Cells that intersect will create a cluster
                combination. You can deselect cells you wish to exclude.
              </p>
              <p className='cb-margin-top-16'>
                Limit your volume to less than 225,000 students if you wish to have the Segment Analysis Supplemental output file
                generated. The file includes factor scores. These scores account for all the considerations used when determining
                Educational Neighborhood Clusters or High School Clusters.
              </p>
              <div className='display-flex align-items-center cb-margin-top-24'>
                <h4 className='cb-h6'>Choose one of the following options for your student clusters</h4>
                <Tooltip
                  ariaLabel='student clusters'
                  id='sa-student-cluster-options'
                  message='While most students will have data for both the Educational Neighborhood Clusters and High School Clusters, some students will not. Selecting students who are missing data ensures that these students appear on your output file when you have cluster combinations.'
                />
              </div>
              <Spacer size='8' />
              <CheckboxGroup
                fieldsetId='sa-also-include-students-options'
                legend='Also include students who'
                legendClassName='sr-only'
                vertical
              >
                {Array.from(alsoIncludeStudentsMap.values()).map(option => (
                  <Checkbox
                    checked={alsoIncludeStudents.includes(option.value as string)}
                    disabled={
                      includeStudents === INCLUDE_STUDENTS.all ||
                      !clusters.length ||
                      !clusters.some((cluster: string) =>
                        option.value === ALSO_INCLUDE_STUDENTS.noNHC ? cluster.startsWith('00') : cluster.endsWith('00')
                      )
                    }
                    id={`sa-also-include-students-${option.value}-option`}
                    key={option.value}
                    label={option.label}
                    onChange={(checked: boolean) => handleAlsoIncludeStudents(checked, option.value as string)}
                  />
                ))}
              </CheckboxGroup>
            </div>
          )}
        </div>
        {includeStudents !== INCLUDE_STUDENTS.all && (
          <>
            <Spacer size='48' />
            <SAGrid clusters={clusters} setClusters={setClusters} />
          </>
        )}
        <Spacer size='48' />
        <Summary summaryId='sa' {...toSummary()} savedCriteria={savedCriteriaData} clear={clearSelections} />
        <Spacer />
      </div>
    </>
  )
}

export default SegmentAnalysis
