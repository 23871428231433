import { useSearchUserContext } from '@msss/ui-components'
import React from 'react'

const LoggedInAs = () => {
  const { user } = useSearchUserContext()
  return (
    <div aria-label='Organization Selection' className='cb-white-bg' role='region'>
      <div className='container display-flex justify-content-end cb-margin-top-8'>
        Logged in as:{' '}
        <span className='cb-font-weight-bold cb-padding-left-8'>
          {user.parentOrgName ? user.parentOrgName : 'Organization Name Missing'},{' '}
          {user.orgName ? user.orgName : 'Department Name Missing'}
        </span>
      </div>
    </div>
  )
}

export default LoggedInAs
