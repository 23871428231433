import React from 'react'
import { MsssInfo } from './MsssDialog'

function NoAuthorizedOrg() {
  return (
    <MsssInfo
      title="We're Sorry"
      message='Sorry, The account you are trying to login with is not authorized to access this page. You need access to an
              organization with a valid role. If you have another account, please try logging in with that account.'
    />
  )
}

export default NoAuthorizedOrg
