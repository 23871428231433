import { Spinner } from '@cb/apricot-react'
import cn from 'classnames'
import { useCallback, useEffect } from 'react'
import './styles/TypeaheadItem.scss'

interface TypeAheadItemProps {
  cityState?: string
  id: string
  isDisabled?: boolean
  isSelected?: boolean
  itemClass?: string
  iterator: number
  loader?: boolean
  keyBoardNavigation: boolean
  keyBoardNavigationPosition: number
  onClick?: (e: any) => void
  onFocus?: (e: any) => void
  onKeyBoardNavigationFocus?: (id: string) => void
  orgName?: string
}

const TypeAheadItem = ({
  cityState,
  id,
  isDisabled,
  isSelected,
  itemClass,
  iterator,
  loader,
  keyBoardNavigation,
  keyBoardNavigationPosition,
  onClick = () => {},
  onFocus = () => {},
  onKeyBoardNavigationFocus = () => {},
  orgName
}: TypeAheadItemProps) => {
  useEffect(() => {
    if (iterator === keyBoardNavigationPosition) {
      onKeyBoardNavigationFocus(id)
    }
  }, [keyBoardNavigationPosition, iterator])

  const onClickItem = useCallback(
    (e: any) => {
      e.preventDefault()
      onClick(e)
    },
    [onClick]
  )

  const containerClasses = cn('typeahead-item-container', itemClass, isDisabled && 'thi-disabled')
  const linkClasses = cn(
    'cb-menu-link',
    iterator === keyBoardNavigationPosition && 'typeahead-item-focus',
    keyBoardNavigation && 'typeahead-item-cb-hover-override'
  )

  return (
    <li
      aria-checked={isSelected}
      aria-disabled={isDisabled}
      aria-selected={iterator === keyBoardNavigationPosition}
      className={containerClasses}
      data-typeahead-item
      id={id}
      role='option'
    >
      <a
        className={linkClasses}
        data-list-item-option={iterator}
        id={`${id}-item`}
        onClick={!loader ? onClickItem : null}
        onFocus={onFocus}
        onMouseOver={onFocus}
        role='presentation'
        style={{ display: 'flex' }}
        tabIndex='-1'
      >
        {loader && (
          <div className='display-flex justify-content-center'>
            <Spinner ariaLabel='loading more options' />
          </div>
        )}
        {!loader && (
          <div className='typeahead-item-content'>
            <div className='display-flex justify-content-between align-items-center cb-margin-bottom-8 cb-font-size-regular'>
              <p className='cb-font-weight-bold'>{orgName}</p>
              <span aria-hidden className={`cb-icon cb-${isSelected ? 'checkbox' : 'box'}`} />
            </div>
            <p className='cb-font-weight-light cb-font-size-small' style={{ marginTop: '-8px' }}>
              {cityState}
            </p>
          </div>
        )}
      </a>
    </li>
  )
}

export default TypeAheadItem
