import { Spacer, Table, Column } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useEffect, useState } from 'react'
import { ICriteriaExcluded } from '../search-form/ICriteriaExcluded'
import { ReferenceService } from '../../../services/rest/ReferenceService'
import refPath from '../../../assets/ref_path.json'
import { PARENTEMAILONLABEL } from '../search-form/criteria/ParentEmailPref'

interface CriteriaExcludedProps {
  criteriaExcluded: ICriteriaExcluded | undefined
}

interface ISummaryTable {
  name: string
  values: JSX.Element
}
interface ICriteriaLabelValues {
  label?: string
  key: string
  values?: string[]
  data?: string[]
}

export const CriteriaExcludedSummary = (props: CriteriaExcludedProps): JSX.Element => {
  const { criteriaExcluded } = props

  const [data, setData] = useState<any>([])

  useEffect(() => {
    const setup = async () => {
      const criteriaLabelValues = await convertCriteriatoTableData(criteriaExcluded!)

      const realItems: ISummaryTable[] = await criteriaLabelValues.map((item: any) => {
        const tableItem: ISummaryTable = {
          name: item.label,
          values: <div> {item.values.join(item.label.startsWith('Segment Analysis') ? '; ' : ', ')} </div>
        }
        return tableItem
      })

      setData(realItems)
    }
    if (criteriaExcluded && Object.keys(criteriaExcluded).length > 0) {
      setup()
    }
  }, [])

  return (
    <div>
      <b>NOTE</b>: Choosing <b>Continue</b> removes the criteria selections that the College Board no longer supports and/or your
      organization no longer has access to.
      <Spacer />
      <span className='cb-font-weight-bold'>Items that will be removed:</span>
      <Spacer />
      <Table
        a11yHighlightText={undefined}
        a11yHighlightType='append'
        className='ms-table'
        stickyColumn={true}
        data={data}
        tableId='criteriaExcludedTableId'
        headless={true}
      >
        <Column field='name' rowHeader title='Criteria' />
        <Column field='values' title='Selections' />
      </Table>
    </div>
  )
}

const convertCriteriatoTableData = async (criteriaExcluded: ICriteriaExcluded) => {
  const { hsEnglishHnrs, hsScienceHnrs, hsHistoryHnrs, hsMathHnrs, hsLanguageHnrs, ...exCriteria } = criteriaExcluded
  const dsf = [...(exCriteria.hsEnglishYrs ?? []), ...(hsEnglishHnrs ?? [])]
  if ([...(exCriteria.hsEnglishYrs ?? []), ...(hsEnglishHnrs ?? [])].length > 0) {
    exCriteria.hsEnglishYrs = [...(exCriteria.hsEnglishYrs ?? []), ...(hsEnglishHnrs ?? [])]
  }
  if ([...(exCriteria.hsScienceYrs ?? []), ...(hsScienceHnrs ?? [])].length > 0) {
    exCriteria.hsScienceYrs = [...(exCriteria.hsScienceYrs ?? []), ...(hsScienceHnrs ?? [])]
  }
  if ([...(exCriteria.hsHistoryYrs ?? []), ...(hsHistoryHnrs ?? [])].length > 0) {
    exCriteria.hsHistoryYrs = [...(exCriteria.hsHistoryYrs ?? []), ...(hsHistoryHnrs ?? [])]
  }
  if ([...(exCriteria.hsMathYrs ?? []), ...(hsMathHnrs ?? [])].length > 0) {
    exCriteria.hsMathYrs = [...(exCriteria.hsMathYrs ?? []), ...(hsMathHnrs ?? [])]
  }
  if ([...(exCriteria.hsLanguageYrs ?? []), ...(hsLanguageHnrs ?? [])].length > 0) {
    exCriteria.hsLanguageYrs = [...(exCriteria.hsLanguageYrs ?? []), ...(hsLanguageHnrs ?? [])]
  }
  if (exCriteria.hsCluster?.length) {
    exCriteria.hsCluster = exCriteria.hsCluster.map(data => {
      const en = data?.substring(0, 2) || ''
      const hs = data?.substring(2, 4) || ''
      const cluster = 'EN: ' + en + ', HS: ' + hs
      return cluster.replaceAll('00', 'ALL')
    })
  }

  if (exCriteria.hsCluster2020?.length) {
    exCriteria.hsCluster2020 = exCriteria.hsCluster2020.map(data => {
      let en = data?.substring(0, 2) || ''
      en = en === '00' ? 'ALL' : Number(en) < 10 ? `${Number(en)}` : en
      let hs = data?.substring(2, 4) || ''
      hs = hs === '00' ? 'ALL' : Number(hs) < 10 ? `${Number(hs)}` : hs
      return 'EN: ' + en + ', HS: ' + hs
    })
  }

  type criteriaExcludedType = typeof exCriteria
  const hsActivityYrsKeys: string[] = Object.keys(exCriteria).filter(item => item.startsWith('hsActivityYrs'))
  if (hsActivityYrsKeys && hsActivityYrsKeys.length > 0) {
    await Promise.all(
      hsActivityYrsKeys.map((item: string) => {
        const awdNum = item.split('Yrs')[1]
        if (exCriteria[`hsActivityAwd${awdNum}`]) {
          exCriteria[item] = [...exCriteria[item], ...exCriteria[`hsActivityAwd${awdNum}`]]
          delete exCriteria[`hsActivityAwd${awdNum}` as keyof criteriaExcludedType]
        }
        exCriteria[item] = exCriteria[item].map((yrs: string) => {
          if (yrs.includes(',')) {
            // handle edge case where multiple years were selected in legacy
            // keep least specific year
            const yrsSplit = yrs.split(',')
            let min = Number(yrsSplit[0].trim())
            yrsSplit.forEach((actNum: string) => {
              const num = Number(actNum.trim())
              if (min > num) min = num
            })
            return `${min}`
          }
          return yrs
        })
      })
    )
  }

  const satSubKeys: string[] = Object.keys(exCriteria).filter(item => {
    return item.startsWith('satSub')
  })
  if (satSubKeys && satSubKeys.length > 0) {
    await Promise.all(
      satSubKeys.map((item: string) => {
        delete exCriteria[item as keyof criteriaExcludedType]
      })
    )
    exCriteria.satSubject = satSubKeys
  }

  const peerGroupEntries = Object.entries(criteriaExcluded)
    .filter(([key]) => key.startsWith('favPeers'))
    .map(([key]) => {
      delete exCriteria[key as keyof criteriaExcludedType]
      return key
    })
  if (peerGroupEntries.length > 0) {
    exCriteria.favPeers = peerGroupEntries
  }

  const criteriaKeys = Object.keys(exCriteria)
  const criteriaLabelValues = await Promise.all(
    criteriaKeys.map(async criteriaKey => {
      const labels = []

      let criteriaObject = ensure(exCriteria[criteriaKey as keyof criteriaExcludedType])
      criteriaObject = Array.isArray(criteriaObject) ? criteriaObject : [criteriaObject]
      const row: ICriteriaLabelValues = { key: criteriaKey }
      if (refMap.has(criteriaKey)) {
        // if it is a known mapped property
        const config = refMap.get(criteriaKey)
        row.label = config.label
        type refpathtype = typeof refPath
        const refPathItem = refPath[criteriaKey as keyof refpathtype]
        const lookupData = config.ref
          ? config.ref
          : refPath[criteriaKey as keyof refpathtype]
          ? await ReferenceService.getReferenceData(refPath[criteriaKey as keyof refpathtype].objects)
          : undefined
        row.values = lookupData
          ? await Promise.all(
              criteriaObject!.map(async (value: string) => {
                try {
                  type lookupType = typeof lookupData
                  const reference = lookupData[value as keyof lookupType]
                  if (!reference) {
                    // eslint-disable-next-line no-throw-literal
                    throw `No Entry found with ${criteriaKey} ${value}`
                  } else {
                    type refType = typeof reference
                    const description =
                      reference && reference['description' as keyof refType] ? reference['description' as keyof refType] : value
                    return description
                  }
                } catch (e) {
                  return value
                }
              })
            )
          : criteriaObject
        // row.values
      } else {
        row.label = criteriaKey
        row.values = criteriaObject
      }
      return row
    })
  )
  //console.log(`Converted Returned ${JSON.stringify(criteriaLabelValues)}`)
  return criteriaLabelValues
}

const subYears = {
  '0.5': {
    code: '0.5',
    description: 'Less than 1 year of study'
  },
  '1': {
    code: '1',
    description: '1 year of study'
  },
  '2': {
    code: '2',
    description: '2 years of study'
  },
  '3': {
    code: '3',
    description: '3 years of study'
  },
  '4': {
    code: '4',
    description: '4 years of study'
  },
  '4.5': {
    code: '4.5',
    description: '4 or more years of study'
  },
  Y: {
    code: 'Y',
    description: 'Honors'
  }
}

const refMap: Map<string, any> = new Map([
  ['degree', { label: 'Educational Aspirations' }],
  [
    'finAidNeed',
    {
      label: 'Financial Aid Plans',
      ref: {
        Y: {
          code: 'Y',
          description: 'Planning to apply for financial aid'
        },
        N: {
          code: 'N',
          description: 'Not planning to apply for financial aid'
        },
        X: {
          code: 'X',
          description: 'Undecided about financial aid'
        },
        U: {
          code: 'U',
          description: 'No response'
        }
      }
    }
  ],
  ['collegeSize', { label: 'College Size' }],
  ['collegeLocation', { label: 'College Location' }],
  ['collegeSetting', { label: 'College Setting' }],
  ['collegeAffiliation', { label: 'College Affiliation' }],
  ['collegeHousingPlans', { label: 'College Housing Plans' }],
  ['collegeGender', { label: 'College Gender' }],
  ['collegeType', { label: 'College Type' }],
  [
    'rotcCollege',
    {
      label: 'ROTC Plans',
      ref: {
        Y: {
          code: 'Y',
          description: 'Planning to participate in ROTC during college'
        }
      }
    }
  ],
  [
    'religionChoice',
    {
      label: 'Religion Choice',
      ref: {
        S: {
          code: 'S',
          description: 'Include only students who indicated a student religion'
        },
        C: {
          code: 'C',
          description: 'Include only students who indicated a student religious interest'
        },
        B: {
          code: 'B',
          description: 'Include students who indicated a student religion or religious interest'
        }
      }
    }
  ],
  ['religion', { label: 'Religious Affiliation' }],

  ['classRank', { label: 'Class Rank' }],
  ['collegeGender', { label: 'College Gender' }],
  ['hsSports', { label: 'High School Sports' }],
  ['collegeGender', { label: 'College Gender' }],
  ['hsArtMusicHnrs', { label: 'High School Art Music Honors', ref: subYears }],

  ['hsActivityYrs', { label: 'High School Activities', ref: subYears }],
  ['hsActivityYrs1', { label: 'Honors program or independent study', ref: subYears }],
  ['hsActivityYrs2', { label: 'Art', ref: subYears }],
  ['hsActivityYrs3', { label: 'Athletics: Intramural, Junior Varsity', ref: subYears }],
  ['hsActivityYrs4', { label: 'Athletics: Varsity', ref: subYears }],
  ['hsActivityYrs5', { label: 'Career-oriented Activity', ref: subYears }],
  ['hsActivityYrs6', { label: 'Community/Service Activity', ref: subYears }],
  ['hsActivityYrs7', { label: 'Computer Activity', ref: subYears }],
  ['hsActivityYrs8', { label: 'Dance Activity', ref: subYears }],
  ['hsActivityYrs9', { label: 'Debate/Public speaking', ref: subYears }],
  ['hsActivityYrs10', { label: 'Ethnic/Cross-cultural Activity', ref: subYears }],
  ['hsActivityYrs11', { label: 'Foreign Exchange', ref: subYears }],
  ['hsActivityYrs12', { label: 'Foreign Language Activity', ref: subYears }],
  ['hsActivityYrs13', { label: 'Government/Political Activity', ref: subYears }],
  ['hsActivityYrs14', { label: 'Journalism/Literary Activity', ref: subYears }],
  ['hsActivityYrs15', { label: 'Reserve Officers Training Corps (Army ROTC, Air Force ROTC, or Navy ROTC)', ref: subYears }],
  ['hsActivityYrs16', { label: 'Music: Instrumental performance', ref: subYears }],
  ['hsActivityYrs17', { label: 'Music: Vocal performance', ref: subYears }],
  ['hsActivityYrs18', { label: 'Religious Activity', ref: subYears }],
  ['hsActivityYrs19', { label: 'Science/Math Activity', ref: subYears }],
  ['hsActivityYrs20', { label: 'Environmental or ecology activity', ref: subYears }],
  ['hsActivityYrs21', { label: 'School-spirit Activity', ref: subYears }],
  ['hsActivityYrs22', { label: 'Drama or Theater', ref: subYears }],
  ['hsActivityYrs23', { label: 'Cooperative work or internship', ref: subYears }],
  ['hsActivityYrs24', { label: 'Work: Part-time', ref: subYears }],
  ['hsActivityYrs25', { label: 'Other activity not listed', ref: subYears }],
  ['hsActivityYrs26', { label: 'Departmental organization (club within my major)', ref: subYears }],
  ['hsActivityYrs27', { label: 'Fraternity, sorority, or social club', ref: subYears }],
  ['hsActivityAwd1', { label: 'Honors program or independent study', ref: subYears }],
  ['hsActivityAwd2', { label: 'Art Honors', ref: subYears }],
  ['hsActivityAwd3', { label: 'Athletics: Intramural, Junior Varsity Honors', ref: subYears }],
  ['hsActivityAwd4', { label: 'Athletics: Varsity Honors', ref: subYears }],
  ['hsActivityAwd5', { label: 'Career-oriented Activity Honors', ref: subYears }],
  ['hsActivityAwd6', { label: 'Community/Service Activity Honors', ref: subYears }],
  ['hsActivityAwd7', { label: 'Computer Activity Honors', ref: subYears }],
  ['hsActivityAwd8', { label: 'Dance Activity Honors', ref: subYears }],
  ['hsActivityAwd9', { label: 'Debate/Public speaking Honors', ref: subYears }],
  ['hsActivityAwd10', { label: 'Ethnic/Cross-cultural Activity Honors', ref: subYears }],
  ['hsActivityAwd11', { label: 'Foreign Exchange Honors', ref: subYears }],
  ['hsActivityAwd12', { label: 'Foreign Language Activity Honors', ref: subYears }],
  ['hsActivityAwd13', { label: 'Government/Political Activity Honors', ref: subYears }],
  ['hsActivityAwd14', { label: 'Journalism/Literary Activity Honors', ref: subYears }],
  [
    'hsActivityAwd15',
    { label: 'Reserve Officers Training Corps (Army ROTC, Air Force ROTC, or Navy ROTC) Honors', ref: subYears }
  ],
  ['hsActivityAwd16', { label: 'Music: Instrumental performance Honors', ref: subYears }],
  ['hsActivityAwd17', { label: 'Music: Vocal performance Honors', ref: subYears }],
  ['hsActivityAwd18', { label: 'Religious Activity Honors', ref: subYears }],
  ['hsActivityAwd19', { label: 'Science/Math Activity Honors', ref: subYears }],
  ['hsActivityAwd20', { label: 'Environmental or ecology Activity Honors', ref: subYears }],
  ['hsActivityAwd21', { label: 'School-spirit Activity Honors', ref: subYears }],
  ['hsActivityAwd22', { label: 'Drama or Theater Honors', ref: subYears }],
  ['hsActivityAwd23', { label: 'Cooperative work or internship Honors', ref: subYears }],
  ['hsActivityAwd24', { label: 'Work: Part-time Honors', ref: subYears }],
  ['hsActivityAwd25', { label: 'Other activity not listed Honors', ref: subYears }],
  ['hsActivityAwd26', { label: 'Departmental organization (club within my major) Honors', ref: subYears }],
  ['hsActivityAwd27', { label: 'Fraternity, sorority, or social club Honors', ref: subYears }],
  ['hsEnglishYrs', { label: 'High School English', ref: subYears }],
  ['hsEnglishHnrs', { label: 'High School English Honors', ref: subYears }],
  ['hsLanguageYrs', { label: 'High School Language', ref: subYears }],
  ['hsLanguageHnrs', { label: 'High School Language Honors', ref: subYears }],
  ['hsMathYrs', { label: 'High School Math', ref: subYears }],
  ['hsMathHnrs', { label: 'High School Math Honors', ref: subYears }],
  ['hsScienceYrs', { label: 'High School Science', ref: subYears }],
  ['hsScienceHnrs', { label: 'High School Science Honors', ref: subYears }],
  ['hsHistoryYrs', { label: 'High School History', ref: subYears }],
  ['hsHistoryHnrs', { label: 'High School History Honors', ref: subYears }],
  [
    'rotcHs',
    {
      label: 'High School ROTC',
      ref: {
        Y: {
          code: 'Y',
          description: 'Participated in JROTC during high school.'
        }
      }
    }
  ],

  ['hsArtMusicYrs', { label: 'High School Music Years', ref: subYears }],
  [
    'hsArtMusic',
    {
      label: 'High School Art & Music',
      ref: {
        '1': {
          code: 1,
          description: 'Acting or the production of a play'
        },
        '2': {
          code: 2,
          description: 'Art history or art appreciation'
        },
        '3': {
          code: 3,
          description: 'Dance'
        },
        '4': {
          code: 4,
          description: 'Drama or theater for appreciation'
        },
        '5': {
          code: 5,
          description: 'Music history, theory, or appreciation'
        },
        '6': {
          code: 6,
          description: 'Music, instrumental or vocal performance'
        },
        '7': {
          code: 7,
          description: 'Photography or filmmaking'
        },
        '8': {
          code: 8,
          description: 'Studio art and design'
        },
        '52': {
          code: 52,
          description: 'No course work or experience in this area'
        }
      }
    }
  ],
  ['citizenship', { label: 'Citizenship Status' }],
  ['educationLevel', { label: 'Education Level' }],
  ['firstGeneration', { label: 'First Generation' }],
  ['satSubject', { label: 'SAT Subject Test' }],
  [
    'testTaken',
    {
      label: 'Exam Test Takers',
      ref: {
        AP: {
          code: 'AP',
          desription: 'AP'
        },
        SAT: {
          code: 'SAT',
          description: 'SAT'
        },
        SATSubj: {
          code: 'SATSubj',
          description: 'SAT Subject'
        },
        PSAT: {
          code: 'PSAT',
          description: 'PSAT'
        }
      }
    }
  ],

  ['hsCluster', { label: 'Segment Analysis: 2010 Cluster Selections' }],
  ['hsCluster2020', { label: 'Segment Analysis: 2020 Cluster Selections' }],
  [
    'unknownHsCluster',
    {
      label: 'Segment Analysis: High School Clusters',
      ref: {
        Y: {
          code: 'Y',
          description: 'Include students with no High School Clusters'
        },
        N: {
          code: 'N',
          description: 'Only include students with High School Clusters'
        }
      }
    }
  ],
  [
    'unknownNhCluster',
    {
      label: 'Segment Analysis: Educational Neighborhood Clusters',
      ref: {
        Y: {
          code: 'Y',
          description: 'Include students with no Educational Neighborhood Clusters'
        },
        N: {
          code: 'N',
          description: 'Only include students with Educational Neighborhood Clusters'
        }
      }
    }
  ],

  [
    'landNhChallenge',
    {
      label: 'Landscape Context: Neighborhood challenge',
      ref: {
        M: {
          code: 'M',
          description: 'Above Average Neighborhood Challenge (neighborhoods at the 50th percentile and higher challenge level)'
        },
        H: {
          code: 'H',
          description: 'High Neighborhood Challenge (neighborhoods at the 75th percentile and higher challenge level)'
        }
      }
    }
  ],
  [
    'landSatChallenge',
    {
      label: 'Landscape Context: SAT Scores in context',
      ref: {
        Y: {
          code: 'Y',
          description: 'Only include students with a SAT score in the top 25th percentile of SAT takers in their high school'
        }
      }
    }
  ],

  ['zip5', { label: 'Zip Code Upload' }],
  [
    'parentEmail',
    {
      label: 'Parent Contacts',
      ref: {
        Y: {
          code: 'Y',
          description: PARENTEMAILONLABEL
        }
      }
    }
  ],

  [
    'favPeers',
    {
      label: 'Interest In My Peers',
      ref: {
        favPeers1: {
          key: 'favPeers1',
          description: 'Peer Group 1'
        },
        favPeers2: {
          key: 'favPeers2',
          description: 'Peer Group 2'
        },
        favPeers3: {
          key: 'favPeers3',
          description: 'Peer Group 3'
        },
        favPeers4: {
          key: 'favPeers4',
          description: 'Peer Group 4'
        },
        favPeers5: {
          key: 'favPeers5',
          description: 'Peer Group 5'
        }
      }
    }
  ]
])
