import { useQuery } from '@apollo/client'
import { ErrorNotification } from '@cb/apricot-react'
import { numberFormatter, sanitizeHtml, useCMSContent } from '@msss/ui-common'
import { Loader, PageContainer, useSearchUserContext } from '@msss/ui-components'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import refPath from '../../assets/ref_path.json'
import { siteName } from '../../constants/app'
import { LILP_MENUS, LILP_PAGE } from '../../constants/content'
import { GET_PORTFOLIO_QUERY } from '../../services/graphql/queries'
import { ReferenceService } from '../../services/rest/ReferenceService'
import { getPortfolioData } from '../msof/searches-orders/SearchesOrdersHome'
import UsefulLinks from '../UsefulLinks'
import CtaCards from './CtaCards'
import Menus from './Menus'
import NeedHelp from './NeedHelp'
import Resources from './Resources'

interface IAddedCount {
  count: number
  date: string
}

const Landing = () => {
  const cb = (window as any).cb

  const { user } = useSearchUserContext()
  const [addedCount, setAddedCount] = useState<IAddedCount>()

  const {
    data: initCMSMenuData,
    loading: isCMSMenuLoading,
    error: isCMSMenuError
  } = useCMSContent(LILP_MENUS.name, LILP_MENUS.params)
  const hasCMSMenuData: boolean = initCMSMenuData && initCMSMenuData.length > 0

  const {
    data: initCMSPageData,
    loading: isCMSPageLoading,
    error: isCMSPageError
  } = useCMSContent(LILP_PAGE.name, LILP_PAGE.params)
  const hasCMSPageData: boolean = initCMSPageData && initCMSPageData.length > 0
  const cmsPageData = hasCMSPageData ? initCMSPageData[0] : {}

  const {
    data: initPortfolioData,
    loading: isPortfolioDataLoading,
    error: isPortfolioDataError
  } = useQuery(GET_PORTFOLIO_QUERY, {
    variables: { orgId: user.orgId }
  })
  const hasPortfolioData: boolean = initPortfolioData?.getPortfolio?.items && initPortfolioData.getPortfolio.items.length > 0
  const portfolioData = hasPortfolioData
    ? getPortfolioData(initPortfolioData.getPortfolio.items)
        .sort((itemA, itemB) => {
          const aDate: any = new Date(itemA.lastModifiedDate)
          const bDate: any = new Date(itemB.lastModifiedDate)
          return bDate - aDate
        })
        .slice(0, 4)
    : []

  useEffect(() => {
    const setup = async () => {
      const data: IAddedCount = await ReferenceService.getReferenceData(refPath['monthlyAddedCount'].xref)
      const date = new Date(data.date)
      data.date = `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
      setAddedCount(data)
    }
    setup()
  }, [])

  return (
    <PageContainer
      noGFMargin
      pageLoading={isPortfolioDataLoading || isCMSMenuLoading || isCMSPageLoading}
      pageLoaded={hasPortfolioData && hasCMSMenuData && hasCMSPageData}
      pageError={
        // has error AND no longer loading
        (isPortfolioDataError || isCMSMenuError || isCMSPageError) &&
        !(isPortfolioDataLoading || isCMSMenuLoading || isCMSPageLoading)
          ? '500'
          : undefined
      }
      siteName={siteName}
      title='Home'
    >
      <UsefulLinks tab='landing' />
      {(isCMSMenuLoading || isCMSPageLoading) && <Loader />}
      {hasCMSMenuData && hasCMSPageData && (
        <>
          <div className='cb-blue5-tint-2'>
            <div className='container cb-padding-top-48 cb-padding-bottom-48'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <h1 className='cb-padding-bottom-16' style={{ lineHeight: '2.5rem' }}>
                    <span className='cb-h4'>Hi {cb.core.iam.getAuthSession().basicProfile.firstName},</span>
                    <br />
                    <span className='cb-h3'>{sanitizeHtml(cmsPageData.title)}</span>
                  </h1>
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(cmsPageData.body?.processed) }} />
                </div>
                <div className='col-xs-12 col-sm-6 col-lg-4 offset-lg-2 col-2xl-3 offset-2xl-3'>
                  <div className='cb-card cb-card-event'>
                    <div className='cb-card-content'>
                      <div className='display-flex'>
                        <h2 className='cb-card-title '>
                          <span>New Students added as of {addedCount?.date ?? 'N/A'} </span>
                        </h2>
                        <span className='cb-h4'>{numberFormatter(addedCount?.count ?? 0)}</span>
                      </div>
                      <div className='cb-card-action'>
                        <Link className='cb-btn cb-btn-primary' id='to-msof-link' style={{ marginLeft: '0' }} to='/my-searches'>
                          Go to My Searches, Orders & Files
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container cb-padding-top-72 cb-padding-bottom-72'>
            <div className='row'>
              <CtaCards content={cmsPageData} />
            </div>
          </div>
          <div className='cb-blue5-tint-2'>
            <div className='container cb-padding-top-72 cb-padding-bottom-72'>
              <div className='row'>
                <Menus
                  hasPortfolioData={hasPortfolioData}
                  isPortfolioDataError={Boolean(isPortfolioDataError)}
                  menuContent={initCMSMenuData}
                  portfolioData={portfolioData}
                />
              </div>
            </div>
          </div>
          <div className='container cb-padding-top-72 cb-padding-bottom-72'>
            <div className='row'>
              <Resources content={cmsPageData} />
            </div>
          </div>
          <div className='cb-blue5-tint-1 cb-box-shadow-top-light'>
            <div className='container cb-padding-top-32 cb-padding-bottom-32'>
              <div className='row'>
                <NeedHelp content={cmsPageData} />
              </div>
            </div>
          </div>
        </>
      )}
      {(isCMSMenuError || isCMSPageError) && (
        <div className='container cb-margin-bottom-72'>
          <div className='row'>
            <div className='col-xs-12'>
              <ErrorNotification title='Error'>There was an error loading the page content. Please try again.</ErrorNotification>
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  )
}

export default Landing

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
