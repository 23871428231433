import { ErrorNotification, InfoNotification, SuccessNotification } from '@cb/apricot-react'
import { focus, sanitizeHtml, useCMSContent } from '@msss/ui-common'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { SYSTEM_ALERT } from '../constants/content'
import { useSystemAlertContext } from '../context/SystemAlertContext'

const SystemAlert = () => {
  const { count } = useSystemAlertContext()
  const [data, setData] = useState<Array<any>>([])
  const [dismissed, setDismissed] = useState<Array<boolean>>([])

  const { data: dataInit } = useCMSContent(SYSTEM_ALERT.name, SYSTEM_ALERT.params)
  const hasData = dataInit && dataInit.length > 0

  const classes = 'cb-box-shadow-light cb-margin-top-16 cb-margin-bottom-16'

  useEffect(() => {
    if (hasData) {
      setData(dataInit)
      setDismissed(() => {
        const init: boolean[] = []
        dataInit.forEach(() => init.push(false))
        return init
      })
    }
  }, [hasData])

  useEffect(() => {
    // needed to trigger a manual refresh of data to force alerts
    // to rerender when pathname doesn't trigger the update
    setData(prev => [...prev])
  }, [count])

  const shouldAlertShow = useCallback(
    (index: number, visibility: string) => {
      if (dismissed[index]) {
        // already been seen & dismissed
        return false
      }

      const pathname = window.location.pathname
      if (visibility === '*') {
        // show on all pages except purchase flow
        return !pathname.includes('/purchase')
      }

      // visibility could contain multiple paths separated by a comma
      const visiblePaths = visibility.split(',')
      return visiblePaths.some(path => (path === '/' && pathname === '/') || (path !== '/' && pathname.includes(path)))
    },
    [dismissed]
  )

  const onNotificationDismiss = useCallback(
    (index: number) => {
      setDismissed((prev: boolean[]) => {
        const updated: boolean[] = [...prev]
        updated.splice(index, 1, true)

        // set focus on next visible alert or on local nav bar
        const nextAvailableAlert = updated.findIndex(
          (hasDismissed: boolean, i: number) => !hasDismissed && shouldAlertShow(i, data[i].field_alert_visibility_settings)
        )
        focus(null, nextAvailableAlert === -1 ? 'local-nav-bar' : `msss-alert-${nextAvailableAlert}`)

        return updated
      })
    },
    [data, shouldAlertShow]
  )

  return (
    <>
      {hasData &&
        data.map((alert: any, i: number) => {
          const id = `msss-alert-${i}`
          const show = shouldAlertShow(i, alert.field_alert_visibility_settings)
          return (
            <Fragment key={id}>
              {show && (!alert.field_alert_type || alert.field_alert_type === 'lv-alert') && (
                <InfoNotification
                  className={classes}
                  dismissible
                  notificationId={id}
                  onDismiss={() => onNotificationDismiss(i)}
                  title={sanitizeHtml(alert.title)}
                >
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(alert.field_body_content?.processed) }} />
                </InfoNotification>
              )}
              {show && alert.field_alert_type === 'lv-success' && (
                <SuccessNotification
                  className={classes}
                  dismissible
                  notificationId={id}
                  onDismiss={() => onNotificationDismiss(i)}
                  title={sanitizeHtml(alert.title)}
                >
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(alert.field_body_content?.processed) }} />
                </SuccessNotification>
              )}
              {show && alert.field_alert_type === 'lv-emergency' && (
                <ErrorNotification
                  className={classes}
                  dismissible
                  notificationId={id}
                  onDismiss={() => onNotificationDismiss(i)}
                  title={sanitizeHtml(alert.title)}
                >
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(alert.field_body_content?.processed) }} />
                </ErrorNotification>
              )}
            </Fragment>
          )
        })}
    </>
  )
}

export default SystemAlert
