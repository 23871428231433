import { ISearchUser } from '@msss/ui-common'

const _ = require('lodash')

//kb
export const chunkedArray = (arr: any, arraychunksize: number) => {
  const chunked = []
  for (const elem of arr) {
    const last = chunked[chunked.length - 1]
    if (!last || last.length === arraychunksize) {
      chunked.push([elem])
    } else {
      last.push(elem)
    }
  }
  return chunked
}

export const getRoles = (orgId: number, user: ISearchUser) => {
  const allRoles = user.allRoles
  console.log(allRoles)
  if (allRoles) {
    const parentOrgId = user.associations?.find(e => e.value === orgId)?.parentOrgId
    console.log(
      'allRoles: ',
      allRoles.filter(role => role.orgId === orgId || role.orgId === parentOrgId)
    )
    return allRoles.filter(role => role.orgId === orgId || role.orgId === parentOrgId)
  }
  return []
}

export const removeEmptyElements = (obj: any) => {
  for (const prop in obj) {
    if (typeof obj[prop] === 'object') {
      if (_.isNull(obj[prop])) {
        delete obj[prop]
      } else {
        removeEmptyElements(obj[prop])
      }
    } else if (obj[prop] === '' || _.isNull(obj[prop])) {
      delete obj[prop]
    }
  }
  return obj
}

export const getYearEndDate = (year: string) => new Date(Number(year), 7, 31, 23, 59, 59)
