import { useLazyQuery } from '@apollo/client'
import { ErrorNotification, ILabeledValue, Spinner } from '@cb/apricot-react'
import React, { useEffect, useState } from 'react'
import { GET_DEPARTMENT_QUERY } from '../../../../../services/graphql/queries'
import { IContact } from '../../../../../interfaces/IContact'
import { ReferenceService } from '../../../../../services/rest/ReferenceService'
import { pathCountry } from '../../../../search/search-form/criteria/geography/Options'

const OrderContact = ({ orgId, isBilling }: { orgId: number; isBilling: boolean }) => {
  const [contact, setContact] = useState<IContact>()

  const [countryList, setCountryList] = useState<ILabeledValue[]>([])
  const [getDepartment, { loading, error, data }] = useLazyQuery(GET_DEPARTMENT_QUERY)
  // used to fetch the
  useEffect(() => {
    if (orgId && orgId !== -1) {
      getDepartment({
        variables: { orgId }
      })
    }
  }, [orgId])

  useEffect(() => {
    const setup = async () => {
      const list = await ReferenceService.getReferenceData(pathCountry)
      setCountryList(list.map((i: any): ILabeledValue => ({ label: i.description, value: i.code })))
      let bcontact: IContact = {}
      if (data) {
        if (isBilling) bcontact = data.getDepartment.billingContactInfo
        else {
          bcontact = data.getDepartment.contactInfo
        }
        setContact(bcontact)
      }
    }
    setup()
  }, [data])

  if (loading) {
    return <Spinner />
  }
  if (error) {
    console.log('error', error)
    return <ErrorNotification title='Error'>There was an error fetching department data for ID={orgId}.</ErrorNotification>
  }
  if (data) {
    return (
      <>
        <div>
          <div>
            {contact?.firstName} {contact?.lastName}
          </div>
          <div>{contact?.departmentName}</div>
          <div>{contact?.streetAddress1}</div>
          <div>{contact?.streetAddress2}</div>
          <div>{contact?.streetAddress3}</div>
          <div>
            {contact?.city} , {contact?.state} {contact?.zip5}
          </div>
          <div>{countryList.find(cntry => cntry.value === contact?.country)?.label}</div>
        </div>
      </>
    )
  }

  return null
}

export default OrderContact
