import './geography.scss'
import { Checkbox, RadioButton, RadioButtonGroup, Spacer } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { TooltipHeader } from '@msss/ui-components'
import React, { useCallback, useEffect, useState } from 'react'

import { ICheck, ICriteria, IZipUpload } from '../../ICriteria'
import { ISavedCriteriaData, SavedCriteriaSectionProps } from '../../Search'
import { Summary } from '../../summary/Summary'
import { Section } from '../../summary/Section'
import ZipCodes from './Zipcodes'
import { IBits } from '../../summary/IBits'
import GeographyTabs from './GeographyTabs'
import { IUserData } from '../../IUserData'
import { ReferenceService } from '../../../../../services/rest/ReferenceService'
import refPath from '../../../../../assets/ref_path.json'
import { GEOGRAPHY } from './Enums'
import { HOME_SCHOOLED } from '../../SearchFormConstants'
import MyCriteriaList from '../../../../my-account/saved-criteria/MyCriteriaList'
import { AvailableMySavedCriteriaTypes } from '../../../../my-account/saved-criteria/options'

enum STUDENT_LOCATION {
  state = 'S',
  hsState = 'H'
}

export interface IGeoSection {
  sectionid?: string
  setAddressState?: (address: string) => void
  updateData: (checked: boolean, criteria: ICheck) => void
  data: ICheck[]
  address?: string
  setHomeSchooled?: (checked: boolean) => void
  homeSchooled?: boolean
}

interface IAddressNSavedCriteria extends IGeoSection {
  setSavedCriteriaData: (value: ISavedCriteriaData) => void
  myaccount: boolean
  changed: boolean
}
export interface IGeoZipSection extends IGeoSection {
  updateZipUpload: (zipUpload: IZipUpload) => void
  zipUploadData?: IZipUpload
}

const Geography = ({
  selectedCriteria,
  selectedUserData,
  handleUserData,
  handleCriteria,
  myaccount
}: SavedCriteriaSectionProps) => {
  const hasChanged = (): boolean => {
    if (data.length === 0 && studentLocation === STUDENT_LOCATION.state && zipUploadData === undefined) {
      return false
    } else {
      return true
    }
  }

  // selected criteria for each go tabs
  const [data, setData] = useState<ICheck[]>([])
  // zipcode file
  const [zipUploadData, setZipUploadData] = useState<IZipUpload>()
  // - for state | hsState radio
  const [studentLocation, setStudentLocation] = useState<string>(STUDENT_LOCATION.state)
  const [homeSchooled, setHomeSchooled] = useState<boolean>(false)
  const [savedCriteriaData, setSavedCriteriaData] = useState<ISavedCriteriaData>()
  const [loadingMySavedCriteria, setLoadingMySavedCriteria] = useState<boolean>(false)
  const [changed, setChanged] = useState<boolean>(hasChanged)

  interface ISelectedData {
    section: GEOGRAPHY
    label?: Array<string>
    refpath?: string
    refoptional?: string
    tabData: string[] | undefined
    currOption?: string
    currOptionInt?: string
    currOptionStatic?: string
  }

  const getFormatConfig = (criteria?: ICriteria): ISelectedData[] => {
    return [
      {
        section: GEOGRAPHY.state,
        label: ['description'],
        refpath: refPath[GEOGRAPHY.state].objects,
        refoptional: refPath['territoriesArmed'].objects,
        tabData: criteria?.state,
        currOptionStatic: 'state'
      },
      {
        section: GEOGRAPHY.county,
        label: ['state', 'description'],
        refpath: refPath[GEOGRAPHY.county].objects,
        tabData: criteria?.county,
        currOption: 'state'
      },
      {
        section: GEOGRAPHY.market,
        label: ['code', 'description'],
        refpath: refPath[GEOGRAPHY.market].objects,
        tabData: criteria?.epsCode,
        currOption: 'state'
      },
      {
        section: GEOGRAPHY.msa,
        label: ['state', 'description'],
        refpath: refPath[GEOGRAPHY.msa].objects,
        tabData: criteria?.msaCode,
        currOption: 'state'
      },
      {
        section: GEOGRAPHY.international,
        label: ['description'],
        refpath: refPath[GEOGRAPHY.international].objects,
        tabData: criteria?.country,
        currOptionInt: 'intlRegionCode'
      },
      {
        section: GEOGRAPHY.international,
        label: ['description'],
        refpath: refPath['regionFips'].objects,
        tabData: criteria?.regionFips
      },
      {
        section: GEOGRAPHY.zip2,
        tabData: criteria?.zip2
      },
      {
        section: GEOGRAPHY.zip3,
        tabData: criteria?.zip3
      },
      {
        section: GEOGRAPHY.zip5,
        tabData: criteria?.zip5
      }
    ]
  }

  const selectedData2ICheck = async ({
    section,
    label,
    refpath,
    refoptional,
    tabData,
    currOption,
    currOptionInt,
    currOptionStatic
  }: ISelectedData): Promise<ICheck[]> => {
    if (tabData) {
      if (refpath) {
        let ref = await ReferenceService.getReferenceData(refpath)
        if (refoptional) {
          const ref2 = await ReferenceService.getReferenceData(refoptional)
          ref = { ...ref, ...ref2 }
        }
        type reftype = typeof ref
        const checks = tabData.map((e): ICheck => {
          const r = ref[e as keyof reftype]
          let description = ''
          if (label) {
            const d = label.map(l => r[l])
            description = d.join('-')
          }
          let currOptionLabel = currOptionStatic || ''
          if (currOption) {
            currOptionLabel = r[currOption]
          } else if (currOptionInt) {
            currOptionLabel = `${parseInt(r[currOptionInt])}`
          }
          const check = { section: section, currOption: currOptionLabel, label: description, value: e }
          return check
        })
        return checks
      } else {
        const checks = tabData.map((e): ICheck => ({ section: section, label: e, value: e }))
        return checks
      }
    }
    return []
  }

  const setup = async (criteria?: ICriteria, userData?: IUserData) => {
    clearAll()
    let formattedData: ICheck[] = []
    if (criteria) {
      formattedData = (await Promise.all(getFormatConfig(criteria).map(e => selectedData2ICheck(e)))).reduce(
        (a, c) => a.concat(c),
        []
      )
      setData(formattedData)
      if (criteria.homeSchooled && criteria.homeSchooled[0]) {
        setHomeSchooled(true)
      }
    }

    if (userData) {
      userData.studentLocation ? setStudentLocation(userData.studentLocation) : null
      // zipcode: when it gets here, data should be OK
      if (formattedData.length > 0) {
        if (userData.zipFileCount && userData.zipFilename) {
          updateZipUpload({
            zip: formattedData.filter(e => e.section?.startsWith('zip')),
            fileName: userData.zipFilename,
            successCount: userData.zipFileCount
          })
        }
      }
    }
  }

  useEffect(() => {
    setup(selectedCriteria, selectedUserData)
  }, [])

  useEffect(() => {
    handleCriteria(addEmptyCritiera(toCriteria()), toSummary())
    setSavedCriteria(addEmptyCritiera(toCriteria()))
    setChanged(hasChanged())
  }, [data, studentLocation])

  useEffect(() => {
    let userData: IUserData
    if (zipUploadData) {
      userData = { zipFileCount: zipUploadData.zip.length.toString(), zipFilename: zipUploadData.fileName }
    } else {
      userData = {
        zipFilename: '',
        zipFileCount: ''
      }
    }

    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
      setChanged(hasChanged())
    }
  }, [zipUploadData])

  useEffect(() => {
    const userData: IUserData = {
      studentLocation
    }

    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
      setChanged(hasChanged())
    }
  }, [studentLocation])

  useEffect(() => {
    const criteria: ICriteria = { homeSchooled: homeSchooled ? ['Y'] : [] }
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
    // call update my saved data
  }, [homeSchooled])

  useEffect(() => {
    //console.log('geography onchange: get saved criteria:', savedCriteriaData)
    if (savedCriteriaData && savedCriteriaData.criteriaId !== -1) {
      setLoadingMySavedCriteria(true)
      setup(savedCriteriaData.criteria, savedCriteriaData.userData)
      setSavedCriteriaData({ ...savedCriteriaData, criteriaId: -1 })
      // update criteria id to -1 so we can reuse the obj for
      // saved criteria set on sfs.  this is because we don't want to
      // redo this load my saved
      setLoadingMySavedCriteria(false)
    }
  }, [savedCriteriaData])

  const removeHomeSchooled = (label: string): void => {
    setHomeSchooled(false)
  }

  // these are for creating my saved critera set in sfs
  const setSavedCriteria = useCallback((criteriaData: ICriteria): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: ICriteria = { ...prev.criteria, ...criteriaData }
        return { ...prev, criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0)) }
      }
      // prev undefined
      const x: ICriteria = criteriaData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.Geography,
        userData: {},
        criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0))
      }
    })
  }, [])

  const setSavedUserData = (userDataData: IUserData): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: IUserData = { ...prev.userData, ...userDataData }
        return {
          ...prev,
          userData: Object.fromEntries(
            Object.entries(x).filter(([key, value]) =>
              value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
            )
          )
        }
      }
      // prev undefinded
      const x: IUserData = userDataData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.Geography,
        userData: Object.fromEntries(
          Object.entries(x).filter(([key, value]) =>
            value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
          )
        ),
        criteria: {}
      }
    })
  }

  const updateData = (checked: boolean, picked: ICheck): void => {
    // console.log('udpate criteria called by child', checked, picked)
    if (checked) {
      // unfortunately need to dedupe because of zip code and select all
      // - same zip as previously typed...
      // - select all sends the whole list so need to
      // check to see if it is already in the list before add
      if (
        data.filter(e => {
          if (e.section?.startsWith(ensure(picked.section))) {
            return e.label === picked.label
          }
          return false
        }).length === 0
      ) {
        setData(prev => [...prev, picked])
      }
    } else {
      // for remove, this is speciall because we only remove based
      // on the data obj it self.  data has the label now as part of the
      // data structure.  this is so we don't need to keep ref data
      // when the dropdown list switches
      setData(prev => [
        ...prev.filter(e => {
          if (e.section?.startsWith(ensure(picked.section))) {
            //if (e.label == picked.label) console.log(e.label, e.section, picked.label)
            return e.label != picked.label
          }
          return true
        })
      ])
    }
  }

  // constructs an ICheck
  const removeOne = (label: string, valueType?: string): void => {
    // console.log('removeone called', label, valueType)
    // the remove is by label.  the value isn't needed.  we don't get value from
    // summary section anyhow.
    valueType ? updateData(false, { section: valueType, label: label, value: '' }) : null

    // handle case of zip file
    // the above will remove the file, but because it is a file, we have to remove all the
    // zipcodes from the file
    if (zipUploadData && valueType === GEOGRAPHY.zip) {
      setData(data.filter(e => !e.section?.startsWith(GEOGRAPHY.zip)))
      // need to clean up userdata too
      setZipUploadData(undefined)
      // need to manually remove file from hidden remove link so same file can be re-uploaded
      const rmFile: HTMLElement | null = document.querySelector('.ms-upload-zip-height a.cb-file-element-rm')
      rmFile?.click()
    }
  }

  const toSummary = () => {
    const dataContent = [
      ...getSummary(GEOGRAPHY.state),
      ...getSummary(GEOGRAPHY.zip),
      ...getSummary(GEOGRAPHY.market),
      ...getSummary(GEOGRAPHY.county),
      ...getSummary(GEOGRAPHY.msa),
      ...getSummary(GEOGRAPHY.international)
    ]
    if (homeSchooled) {
      dataContent.push({
        remove: removeHomeSchooled,
        name: 'Home Schooled',
        values: [HOME_SCHOOLED]
      })
    }

    return {
      section: Section.GEOGRAPHY,
      data: dataContent
    }
  }

  // helper to filter out varuious section variations
  const toCriteria = (): ICriteria => {
    return data.reduce((all, d) => {
      // d.section is the solr field name
      if (d.section) {
        const key = d.section
        if (!Object.prototype.hasOwnProperty.call(all, key)) {
          all[key as keyof ICriteria] = Array<string>()
        }
        all[key as keyof ICriteria]?.push(d.value)
      }
      return all
    }, {} as ICriteria)
  }

  // comb through what is passed in, if any of the criteria within
  // geography section is empty, we add [] for that criterion
  // so handlecriteria can filter out previous value
  const addEmptyCritiera = (all: ICriteria): ICriteria => {
    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.state)) {
      all = { ...all, ...{ [GEOGRAPHY.state]: [] } }
    }

    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.msa)) {
      all = { ...all, ...{ [GEOGRAPHY.msa]: [] } }
    }

    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.county)) {
      all = { ...all, ...{ [GEOGRAPHY.county]: [] } }
    }

    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.market)) {
      all = { ...all, ...{ [GEOGRAPHY.market]: [] } }
    }

    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.international)) {
      all = { ...all, ...{ [GEOGRAPHY.international]: [] } }
    }

    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.zip2)) {
      all = { ...all, ...{ [GEOGRAPHY.zip2]: [] } }
    }

    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.zip3)) {
      all = { ...all, ...{ [GEOGRAPHY.zip3]: [] } }
    }

    if (!Object.prototype.hasOwnProperty.call(all, GEOGRAPHY.zip5)) {
      all = { ...all, ...{ [GEOGRAPHY.zip5]: [] } }
    }

    return all
  }

  const getSummary = (section: string): IBits[] => {
    let name: string = ''
    let summaryParts: IBits[] = []

    const base: IBits = {
      remove: removeOne,
      valueType: section,
      name: name,
      values: []
    }

    switch (section) {
      case GEOGRAPHY.state:
        name = 'U.S. States or Territories'
        break
      case GEOGRAPHY.market:
        name = 'Geomarkets'
        break
      case GEOGRAPHY.county:
        name = 'Counties'
        break
      case GEOGRAPHY.msa:
        name = 'MSAs'
        break
      case GEOGRAPHY.zip:
        name = 'Zip Codes'
        break
      case GEOGRAPHY.international:
        name = 'International'
        break
    }

    let part: ICheck[] = data.filter(e => e.section?.startsWith(section))
    // check if zip is a file
    // if file, then remove all the zip criteria in the summary leave just the filename
    // test: if /^\d$/ is true then remove
    if (zipUploadData && section === GEOGRAPHY.zip) {
      part = part.filter(e => !/^\d+$/.test(e.label))
    } else if (section === GEOGRAPHY.zip) {
      // this is just zip codes.  we need to sort this as numbers
      part.sort((a, b) => parseInt(a.label) - parseInt(b.label))
    } else {
      // general sort
      part.sort((a, b) => (a.label < b.label ? -1 : Number(a.label > b.label)))
    }

    if (part && part.length > 0) {
      summaryParts = [
        ...summaryParts,
        {
          ...base,
          ...{ name },
          //...{ values: part.map(e => e.label).sort() }
          ...{ values: part.map(e => e.label) }
        }
      ]
    }

    return summaryParts
  }

  const clearSelections = (event: React.FormEvent): void => {
    event.preventDefault()
    clearAll()
  }

  const clearAll = () => {
    setData([])
    setStudentLocation(STUDENT_LOCATION.state)
    setZipUploadData(undefined)
    // need to manually remove file from hidden remove link so same file can be re-uploaded
    const rmFile: HTMLElement | null = document.querySelector('.ms-upload-zip-height a.cb-file-element-rm')
    rmFile?.click()
  }

  const updateZipUpload = (zipfile: IZipUpload): void => {
    if (zipfile.zip && zipfile.zip.length >= 0) {
      setZipUploadData(zipfile)
      setData(prev => [
        ...prev.filter(e => !e.section?.startsWith(GEOGRAPHY.zip)),
        ...zipfile.zip,
        ...(zipfile.zip.length >= 0 ? [{ section: GEOGRAPHY.zip, label: zipfile.fileName, value: '' }] : [])
      ])
    } else {
      setZipUploadData(undefined)
      setData(prev => prev.filter(e => !e.section?.startsWith(GEOGRAPHY.zip)))
    }
  }

  return (
    <>
      <p>
        Click the tabs to customize a search by the students' geographic origin. This is defined by the location of the home or
        school.
      </p>
      <Spacer />
      <AddressNSavedCriteria
        setAddressState={setStudentLocation}
        address={studentLocation}
        homeSchooled={homeSchooled}
        setHomeSchooled={setHomeSchooled}
        setSavedCriteriaData={setSavedCriteriaData}
        myaccount={myaccount}
        changed={changed}
      />
      <fieldset disabled={loadingMySavedCriteria}>
        <ZipCodes
          updateData={updateData}
          data={data}
          sectionid={GEOGRAPHY.zip}
          zipUploadData={zipUploadData}
          updateZipUpload={updateZipUpload}
        />
        <GeographyTabs updateData={updateData} data={data} />
      </fieldset>
      <Spacer />
      <Summary summaryId='geography-summary-id' {...toSummary()} savedCriteria={savedCriteriaData} clear={clearSelections} />
    </>
  )
}

export default Geography

const AddressNSavedCriteria = ({
  setAddressState,
  address,
  homeSchooled,
  setHomeSchooled,
  setSavedCriteriaData,
  myaccount,
  changed
}: Partial<IAddressNSavedCriteria>) => {
  const addressOptions = [
    { label: 'Home address', value: STUDENT_LOCATION.state },
    { label: 'High school address', value: STUDENT_LOCATION.hsState }
  ]

  // used to async load actual saved criteria for the selected
  const handleHomeSchool = (value: boolean) => {
    //console.log(`dfsfklsdafjlkfdjlsafjlasdf ${value}`)
    if (setHomeSchooled) {
      setHomeSchooled(value)
    }
    if (setAddressState) {
      setAddressState(STUDENT_LOCATION.state)
    }
  }

  return (
    <div className='row'>
      <div className='col-xs-4' style={myaccount ? { visibility: 'hidden' } : undefined}>
        <TooltipHeader
          header='Saved Criteria'
          id='geo-saved-criteria'
          tooltipMsg='We will save your selections for future searches.'
        />
        <div className='row'>
          <div className='col-xs-6'>
            <MyCriteriaList
              type={AvailableMySavedCriteriaTypes.Geography}
              setSavedCriteriaData={ensure(setSavedCriteriaData)}
              myaccount={ensure(myaccount)}
              changed={ensure(changed)}
            />
          </div>
        </div>
      </div>
      <div className='col-xs-4'>
        <TooltipHeader
          header='Address'
          id='geo-address'
          tooltipMsg="Set the address option to the home address default to find students by their place of residence. Select the high school address to select students based on the location of their school. An international student's school address may not be available."
        />
        <div className='row'>
          <div className='col-xs-12'>
            <RadioButtonGroup
              ariaLabelledBy='geo-address'
              fieldsetId='geo-address-options'
              name='address'
              onChange={setAddressState}
              required
              value={address}
              vertical
            >
              {addressOptions.map((option, i) => {
                return (
                  <RadioButton
                    id={`geo-address-option-${i}`}
                    key={i}
                    label={option.label}
                    value={option.value}
                    disabled={option.value === STUDENT_LOCATION.hsState && homeSchooled === true}
                  />
                )
              })}
            </RadioButtonGroup>
          </div>
        </div>
      </div>
      <div className='col-xs-4'>
        <TooltipHeader header='Home School' id='home-schooled-tooltip-id' />
        <div className='row'>
          <div className='col-xs-12'>
            <Checkbox
              checked={homeSchooled}
              className='cb-padding-bottom-8'
              disabled={address === STUDENT_LOCATION.hsState}
              id={`home-schooled-checkbox`}
              key='homeSchooled-key'
              label={HOME_SCHOOLED}
              onChange={handleHomeSchool}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
