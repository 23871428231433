import { scrollFocus } from '@msss/ui-common'
import { Loader } from '@msss/ui-components'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FeatureAccessContext } from '../../../context/FeatureAccessProvider'
import PlansFeaturesSubscription from './PlansFeaturesSubscription'
import PlansFeatuesTransactional from './PlansFeaturesTransactional'
import { SuccessNotification } from '@cb/apricot-react'

interface PlansFeatuesProps {
  isActiveTab: boolean
}

const PlansFeatues = ({ isActiveTab }: PlansFeatuesProps) => {
  const { isTransactional, loadingFeatures, getFeatureInfo } = useContext(FeatureAccessContext)
  const location: any = useLocation()
  // cache state locally
  const [quoteSuccess, setQuoteSuccess] = useState(location.state || {})

  // reset the success alert
  const resetAlert = () => {
    setQuoteSuccess({})
  }

  useEffect(() => {
    if (isActiveTab) {
      getFeatureInfo(true)
    }
  }, [isActiveTab])

  // when location changes we need to set our state again
  useEffect(() => {
    setQuoteSuccess(location.state || {})
    window.history.replaceState({}, document.title)
  }, [location])

  const QuoteSavedSuccessMsg = () => {
    return (
      <SuccessNotification title='Success!' dismissible>
        Your Quote {quoteSuccess?.quoteName} for {Number(quoteSuccess?.selectedYear) - 1} - {Number(quoteSuccess?.selectedYear)}{' '}
        Subscription has been saved
      </SuccessNotification>
    )
  }

  if (isActiveTab) {
    if (loadingFeatures) {
      return <Loader ariaLabel='fetching plans and features' />
    } else if (isTransactional()) {
      return (
        <div>
          {quoteSuccess?.quoteStatus && quoteSuccess?.quoteStatus.indexOf('success') > -1 && <QuoteSavedSuccessMsg />}
          <PlansFeatuesTransactional />
        </div>
      )
    } else {
      return (
        <div>
          {quoteSuccess?.quoteStatus && quoteSuccess?.quoteStatus.indexOf('success') > -1 && <QuoteSavedSuccessMsg />}
          <PlansFeaturesSubscription />
        </div>
      )
    }
  } else {
    return null
  }
}

export default PlansFeatues
