export enum ExamSummaryEnums {
  EXAM_TAKING_OPTIONS,
  AP_ANY,
  AP,
  PSAT_ANY,
  PSAT,
  SAT_ANY,
  SAT,
  SCORE_SENDS,
  SCORE_LOGIC
}
