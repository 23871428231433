import { gql, useMutation, useQuery } from '@apollo/client'
import { Input, Modal, NakedButton, PrimaryButton, Spacer, Spinner } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { MouseEvent, useEffect, useState } from 'react'
import { SAVE_CRITERIA_PREF } from '../../../services/graphql/mutations'
import { GET_CRITERIA_PREFS } from '../../../services/graphql/queries'
import { AvailableMySavedCriteriaTypes } from '../../my-account/saved-criteria/options'
import { ISavedCriteriaData } from '../search-form/Search'
import { CategoryGroupCode } from '../search-form/summary/Section'
// this is a style that a child of this comp is expecting already.  so we import it here
// as opposed to the child comp (also, the child can be dynamic so we do it at the parent for clarity)
//import '../../../components/my-account/saved-criteria/SavedCriteriaDetail.scss'

interface ISavePref {
  userId: number
  orgId?: number
  name: string
  categoryGroupCd: number
  criteriaId?: number
  criteria: string
  userData?: string
}

interface IInput {
  name: string
  validationMsg: string | undefined
  validationType: 'success' | 'error' | 'general' | undefined
}

const SFSSavedCriteria = ({ savedCriteria }: { savedCriteria: ISavedCriteriaData }) => {
  const { user } = useSearchUserContext()

  const [open, setOpen] = useState<boolean>(false)
  const [prefListNames, setPrefListNames] = useState<Array<string>>()

  const prefListResult = useQuery(GET_CRITERIA_PREFS, {
    variables: { orgId: user.orgId }
  })

  useEffect(() => {
    if (prefListResult?.data) setPrefListNames(prefListResult.data.getCriteriaPreferences.map(p => p.name))
  }, [prefListResult.data])

  const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setOpen(true)
  }

  return (
    <>
      <NakedButton noPadding onClick={handleSave} style={{ color: 'white' }} className='cb-pallette-blue3 cb-margin-right-8'>
        Save To Search Preferences
      </NakedButton>

      {open ? (
        <SavedCriteriaDetailModal savedCriteria={savedCriteria} prefNames={prefListNames} open={open} setOpen={setOpen} />
      ) : null}
    </>
  )
}

export default SFSSavedCriteria

const SavedCriteriaDetailModal = ({
  savedCriteria,
  prefNames,
  open,
  setOpen
}: {
  savedCriteria: ISavedCriteriaData
  prefNames?: Array<string>
  open: boolean
  setOpen: (value: boolean) => void
}) => {
  const initPrefName = (): IInput => {
    const errorMessage = inputStringValidation('')
    return {
      name: '',
      validationMsg: errorMessage ? errorMessage : '',
      validationType: errorMessage ? 'error' : undefined
    }
  }

  const { user } = useSearchUserContext()
  const [prefName, setPrefName] = useState<IInput>(initPrefName())

  // for cache update on a mutation, if you look at the params, you can print out
  // cache and see all the 'watched' queries as well as a list of cache.  cache is mapped by
  // __typename key which is automatically inserted as part of the result.  the value of the typename is
  // determined by the return type in schema.graphql for the get calls; mutation will look for typename AND
  // default key id / __id to find the correct entry in cache. for more complex obj, you will do the cache
  // update for CRUD your self (need more reading.  don't understand fully)
  // for every watched query, under fields: you can specify cache to update
  // in my case I want to update the pref list (in case of add new) refresh cache (in case of pref name change)
  // note the return on add new... you have to add your new entry to existing and return the result
  const [saveCriteria, mutateResult] = useMutation(SAVE_CRITERIA_PREF, {
    update(cache, { data }) {
      // console.log('cache ... data is ', data)
      // console.log('cache ... savecriteria mutation cache', cache)
      cache.modify({
        // will enter all the watched queries mutations it seems
        fields: {
          getCriteriaPreferences(existing = [], func) {
            // console.log('existing:', existing)
            // console.log('all func:', func)
            const addme = cache.writeFragment({
              data: data.saveCriteriaPreference,
              fragment: gql`
                fragment NewCriteriaPreference on CriteriaPreference {
                  categoryGroupCd
                  createdBy
                  createdDate
                  criteriaId
                  lastModifiedBy
                  lastModifiedDate
                  name
                  uploadFileCount
                  uploadFileName
                }
              `
            })
            // console.log('......curr:', existing)
            // console.log('.......addme', addme)
            return [...existing, addme]
          }
        }
      })
    }
  })

  // we check for result of the save mutation.  if we saved OK, we then close the
  // table row if we don't do this we will get render table while updating comp error
  useEffect(() => {
    if (mutateResult.data) {
      const criteriaId = mutateResult.data.saveCriteriaPreference
      if (criteriaId !== -1) {
        // close the accordion
      }
    }
  }, [mutateResult.data])

  const getCatGroupCdByType = (type: AvailableMySavedCriteriaTypes): CategoryGroupCode => {
    switch (type) {
      case AvailableMySavedCriteriaTypes.Geography:
        return CategoryGroupCode.GEOGRAPHY
      case AvailableMySavedCriteriaTypes.CBExams:
        return CategoryGroupCode.EXAMS
      case AvailableMySavedCriteriaTypes.IntendedMajor:
        return CategoryGroupCode.IM
      case AvailableMySavedCriteriaTypes.SegmentAnalysis:
        return CategoryGroupCode.SA
    }
  }

  const validateInputString = (name: string) => {
    const errorMessage = inputStringValidation(name)
    if (errorMessage) {
      setPrefName({ name, validationMsg: errorMessage, validationType: 'error' })
    } else if (checkDupPrefName(name, prefNames)) {
      setPrefName({ name, validationMsg: 'The preference name you entered already exists', validationType: 'error' })
    } else {
      setPrefName({ name, validationMsg: '', validationType: 'general' })
    }
  }

  const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
    // console.log('saving pref to db')
    // console.log('...... criteria', savedCriteria.criteria)
    // console.log('...... userdata', savedCriteria.userData)
    const pref: ISavePref = {
      userId: ensure(user.userId),
      orgId: ensure(user.orgId),
      categoryGroupCd: getCatGroupCdByType(savedCriteria.type),
      name: prefName.name,
      criteria: JSON.stringify(savedCriteria.criteria),
      userData: JSON.stringify(savedCriteria.userData)
    }

    saveCriteria({
      variables: { saveCriteria: pref }
    })
  }

  if (mutateResult.loading) {
    return <Spinner />
  }

  if (mutateResult.error) {
    return <p title='Preferences Error'>There was an error working with your preferences. {mutateResult.error}</p>
  }

  return open ? (
    <Modal
      actions={[
        <NakedButton key='cancel' onClick={() => setOpen(false)} disabled={mutateResult.called}>
          Cancel
        </NakedButton>,
        <PrimaryButton
          key='save'
          onClick={handleSave}
          loading={mutateResult.called}
          disabled={mutateResult.called || Boolean(prefName.validationMsg)}
        >
          Save
        </PrimaryButton>
      ]}
      analytics
      analyticsTitle='mysavedcriteria-on-sfs-saved-criteria'
      onClose={() => {
        mutateResult.reset()
        setOpen(false)
      }}
      closeButton={false}
      title='Save New Criteria Set'
      open={open}
      withFooter
      withHeader
    >
      {/* need this outer div for input to make sure in error condition, we have enough Spacer
      for all the erro markings */}
      <div className='cb-padding-top-8' style={{ minWidth: '50%' }}>
        <Input
          ariaLabel='criteria preference name'
          clearable
          floating
          condensed
          label='Enter preference name'
          value={prefName.name}
          maxLength={50}
          onChange={e => {
            validateInputString(e.target.value)
          }}
          validation={prefName.validationType}
          validationMsg={prefName.validationMsg}
        />
      </div>
      <Spacer />

      {mutateResult.error ? (
        !mutateResult.data.deleteCriteriaPreference ? (
          <p className='cb-margin-top-8'>Delete failed please try again later</p>
        ) : null
      ) : null}
    </Modal>
  ) : null
}

const inputStringValidation = (name: string | undefined): string => {
  let errorMessage = ''
  if (name) {
    name.trim().length > 50 ? (errorMessage = 'The name/title must be limited to 50 alphanumeric characters') : null
    name.trim().length === 0 ? (errorMessage = 'The name/title is required before the save continues.') : null
  } else {
    errorMessage = 'The name/title is required before the save continues.'
  }
  return errorMessage
}

const checkDupPrefName = (name: string, allPrefNames?: Array<string>): boolean => {
  if (!allPrefNames) return false
  return Boolean(allPrefNames.find(n => n.trim().toLocaleLowerCase() === name.trim().toLocaleLowerCase()))
}
