import { IOrgRolePair } from '@msss/ui-common'
import jwt_decode from 'jwt-decode'

const { cb } = window as any

export const allowedLoginAsRoles = ['2051', '2052', '2053']

export const getTokens = () => {
  const jwt = cb.core.iam.getJWTToken()
  const authenToken = cb.core.iam.getAuthenticationToken()
  const authorToken = cb.core.iam.getAuthorizationToken()

  const payload = {
    jwt,
    authenToken,
    authorToken
  }

  return payload
}

export const getRoleOrgPairs = () => {
  const jwt = getJWTPayload()
  if (!jwt) {
    return null
  }
  const x: any = jwt
  // console.log(JSON.stringify(x.cb))
  return x.cb.ro
}

export const getEmail = () => {
  const jwt = getJWTPayload()
  if (!jwt) {
    return null
  }
  const x: any = jwt
  return x.cb.em
}

export const getJWTPayload = () => {
  const jwt = cb.core.iam.getJWTToken()
  if (!jwt) {
    return null
  }

  return jwt_decode(jwt)
}

export const getSessionId = () => {
  const session = cb.core.iam.getAuthSession()
  if (session.isLoggedIn) {
    return session.sessionId
  }
}

export const getAnyValidOrg = () => {
  // valid search epl roles
  const allowedRoles = ['460', '461', '462', '463', '464', '465', '466', '467', '468', '469', '470']

  return getAnyValidOrgFromAllowedRoles(allowedRoles)
}

// only org
export const getAnyValidAdminOrg = () => {
  // valid search admin epl roles
  return getAnyValidOrgFromAllowedRoles(allowedLoginAsRoles)
}

// org and role
export const getAnyValidAdminOrgRole = () => {
  // valid search admin epl roles
  return getAnyValidOrgRoleFromAllowedRoles(allowedLoginAsRoles)
}

const getAnyValidOrgFromAllowedRoles = (allowedRoles: string[]) => {
  let validOrg = undefined

  const jwt = getJWTPayload()
  if (!jwt) {
    return undefined
  }
  const x: any = jwt

  // any valid orgId
  if (x.cb.ro) {
    const validOrgRoles = x.cb.ro.filter((orgRole: { rid: string }) => allowedRoles.includes(orgRole.rid))
    // console.log(x.cb.ro)

    if (validOrgRoles.length > 0) {
      validOrg = validOrgRoles[0].oid
      // // console.log('valid orgId: ' + validOrg)
    } else {
      // console.log('did not find any org-role combo')
    }
  }

  return validOrg
}

const getAnyValidOrgRoleFromAllowedRoles = (allowedRoles: string[]): IOrgRolePair | undefined => {
  let validOrgRole

  const jwt = getJWTPayload()
  if (!jwt) {
    return undefined
  }
  const x: any = jwt

  // any valid orgId
  if (x.cb.ro) {
    const validOrgRoles = x.cb.ro.filter((orgRole: { rid: string }) => allowedRoles.includes(orgRole.rid))

    if (validOrgRoles.length > 0) {
      validOrgRole = { orgId: parseInt(validOrgRoles[0].oid), role: parseInt(validOrgRoles[0].rid) }
    } else {
      validOrgRole = undefined
    }
  }

  return validOrgRole
}
