import './assets/ms_app.scss'
import { hydrate } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import AppEntry from './AppEntry'

/*
if (process.env.ENV !== 'production') {
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000)
}
*/

/**
 * element in HTML document for React SPA
 * @type {ReactElement}
 */
const rootElement = document.getElementById('root')

/**
 * React SPA
 * @type {ReactElement}
 */
const rootComponent = (
  <Router>
    <AppEntry />
  </Router>
)

/**
 * hydrate makes component load faster - it will keep anything inside the "root"
 * and build from things changed. render will rebuild everything
 */
if (rootElement && document.cookie.includes('cb_login')) {
  hydrate(rootComponent as any, rootElement)
} else {
  console.log('setting redirect from index...')
  const c = window.location.href
  window.location.href = `https://account.collegeboard.org/login/login?appId=451&idp=EPL&DURL=${c}`
  //render(rootComponent, rootElement);
}
