import { ILabeledValue, Select } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useCallback, useEffect, useState } from 'react'
import { AvailableTypeValues } from './options'

interface AddNewProps {
  addingNew: ILabeledValue | undefined
  setAddingNew: (value: ILabeledValue | undefined) => void
  disabled: boolean
}

// the disabled is used in case of an edit existing...
const AddNewCriteria = ({ addingNew, setAddingNew, disabled }: AddNewProps) => {
  const [value, setValue] = useState<string>('')
  const [values] = useState<Array<ILabeledValue>>(() => Array.from(AvailableTypeValues.values()))

  useEffect(() => {
    if (!addingNew) {
      // reset after done adding new
      setValue('')
    }
  }, [addingNew])

  const onChange = useCallback(
    (value: string) => {
      const selection: ILabeledValue = ensure(values.find((item: ILabeledValue) => item.value === parseInt(value)))
      setAddingNew(selection)
      setValue(value)
    },
    [values, setAddingNew]
  )

  return (
    <Select
      ariaLabel='Select new criteria to add'
      css=''
      disabled={Boolean(addingNew || disabled)}
      labelNoValue='Add Criteria Type'
      name='addCriteriaType'
      onChange={onChange}
      selectId='sc-add-criteria-type-options'
      value={value}
      values={values}
    />
  )
}

export default AddNewCriteria
