import { DatePicker, ICBDatePicker, NakedButton } from '@cb/apricot-react'
import { FilterWithCheckbox } from '@msss/ui-components'
import { useRef, useState } from 'react'
import { filterOrderStatusOptions, filterTypeOptions } from '../options'
import OtherFilters from './OtherFilters'
import { IFilterOptions } from '../../common/IFilterOptions'

export enum FilterColumn {
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  ITEM_TYPE = 'itemType',
  ORDER_STATUS = 'orderStatus',
  TOP_SEARCH = 'topSearch',
  OWNER_USER_ID = 'ownerUserId'
}

const FilterOptions = ({ setMsofGlobalFilter, setFilter }: IFilterOptions) => {
  const [startDateFilter, setStartDateFilter] = useState<any>()
  const [endDateFilter, setEndDateFilter] = useState<any>()
  const [itemTypeFilter, setItemTypeFilter] = useState<Array<string>>([])
  const [orderStatusFilter, setOrderStatusFilter] = useState<Array<string>>([])
  const [topSearchFilter, setTopSearchFilter] = useState<boolean>(false)
  const [ownedByMeFilter, setOwnedByMeFilter] = useState<boolean>(false)

  const CBDatePickerRef = useRef<ICBDatePicker>()

  // Clear all filters
  const clearAllFilters = () => {
    setFilter(FilterColumn.LAST_MODIFIED_DATE, [])
    setMsofGlobalFilter('')

    CBDatePickerRef && CBDatePickerRef.current ? CBDatePickerRef.current.reset() : null
    setStartDateFilter('')
    setEndDateFilter('')
    setItemTypeFilter([])
    setOrderStatusFilter([])
    setTopSearchFilter(false)
    setOwnedByMeFilter(false)
  }

  const handleItemTypeFilter = (event: boolean, value: string | number) => {
    if (event) {
      setItemTypeFilter(prevItemTypeFilter => [...prevItemTypeFilter, value as string])
    } else {
      setItemTypeFilter(prevItemTypeFilter => prevItemTypeFilter.filter(item => item !== value))
    }
  }

  const handleOrderStatusFilter = (event: boolean, value: string | number) => {
    if (event) {
      setOrderStatusFilter(prevOrderStatusFilter => [...prevOrderStatusFilter, value as string])
    } else {
      setOrderStatusFilter(prevOrderStatusFilter => prevOrderStatusFilter.filter(item => item !== value))
    }
  }

  const handleTopSearchFilter = (event: boolean) => {
    if (event) {
      setTopSearchFilter(true)
    } else {
      setTopSearchFilter(false)
    }
  }

  const handleOwnedByMeFilter = (event: boolean) => {
    if (event) {
      setOwnedByMeFilter(true)
    } else {
      setOwnedByMeFilter(false)
    }
  }

  const clearItemTypeFilter = () => {
    setItemTypeFilter([])
  }

  const clearOrderStatusFilter = () => {
    setOrderStatusFilter([])
  }

  const clearOtherFilters = () => {
    setTopSearchFilter(false)
    setOwnedByMeFilter(false)
  }

  return (
    <>
      <DatePicker
        cbRef={CBDatePickerRef}
        buttonId='filter-last-modified-date-range-btn'
        datePickerId='filter-last-modified-date-range-picker'
        endDate={endDateFilter}
        onReset={event => {
          setFilter(FilterColumn.LAST_MODIFIED_DATE, [])
        }}
        onUpdate={selectedDate => {
          const startDate = new Date(selectedDate.dateObj.start)
          startDate.setHours(0, 0, 0)
          const endDate = new Date(selectedDate.dateObj.end)
          endDate.setHours(23, 59, 59)
          setFilter(FilterColumn.LAST_MODIFIED_DATE, [startDate, endDate])
        }}
        palette='blue2'
        startDate={startDateFilter}
      />

      {/* Item Type filter */}
      <FilterWithCheckbox
        name='Type'
        id='filter-type'
        options={filterTypeOptions}
        setFilter={setFilter}
        column={FilterColumn.ITEM_TYPE}
        filter={itemTypeFilter}
        handleFilter={handleItemTypeFilter}
        clearFilter={clearItemTypeFilter}
      />

      {/* Order Status filter */}
      <FilterWithCheckbox
        name='Status'
        id='filter-order-status'
        options={filterOrderStatusOptions}
        setFilter={setFilter}
        column={FilterColumn.ORDER_STATUS}
        filter={orderStatusFilter}
        handleFilter={handleOrderStatusFilter}
        clearFilter={clearOrderStatusFilter}
      />

      {/* Other filters */}
      <OtherFilters
        name='Other Filters'
        setFilter={setFilter}
        topSearchFilter={topSearchFilter}
        ownedByMeFilter={ownedByMeFilter}
        handleTopSearchFilter={handleTopSearchFilter}
        handleOwnedByMeFilter={handleOwnedByMeFilter}
        clearFilter={clearOtherFilters}
      />

      <NakedButton id='clear-all-filter-selections' onClick={clearAllFilters}>
        Clear All
      </NakedButton>
    </>
  )
}

export default FilterOptions
