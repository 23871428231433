import { ILabeledValue, Spacer } from '@cb/apricot-react'
import { numberFormatter } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { useContext, useEffect, useState } from 'react'
import { FeatureAccessContext } from '../../../../context/FeatureAccessProvider'
import { ReferenceService } from '../../../../services/rest/ReferenceService'
import { pathDataloadSchedule, pathNotificationFrequency } from '../../../search/search-form/criteria/geography/Options'
import { OrderStatus } from '../../common/options'
import EditSaveSearchDescription from '../order-details/EditSaveSearchDescription'
import EditSaveSearchName from '../order-details/EditSaveSearchName'
import { OrderOverView } from '../order-details/SearchOrderDetails'

const NOT_AVAILABLE = 'N/A'

export interface OrderOverViewProps {
  searchOrder: OrderOverView
  savedSearchId: number
}

const OverViewTab = ({ searchOrder, savedSearchId }: OrderOverViewProps) => {
  const { user } = useSearchUserContext()
  //   const [updatedDesc, setUpdatedDesc] = useState<string | undefined>()

  const [notificationFrequencyList, setNotificationFrequencyList] = useState<ILabeledValue[]>([])
  const { isTransactional } = useContext(FeatureAccessContext)
  const [dataloadList, setDataloadList] = useState<ILabeledValue[]>([])

  const [updatedDesc, setUpdatedDesc] = useState<string | undefined>()
  const [searchName, setSearchName] = useState<string | undefined>()
  const [theName, setTheName] = useState<string>(searchOrder.name ? searchOrder.name : NOT_AVAILABLE)
  const [theDescription, setTheDescription] = useState<string>(searchOrder.description ? searchOrder.description : NOT_AVAILABLE)

  const updateAfterDescChange = (id: number, desc: string) => {
    setUpdatedDesc(desc)
  }

  const updateAfterNameChange = (savedSearchId: number, updatedName: string) => {
    setSearchName(updatedName)
  }

  useEffect(() => {
    const setup = async () => {
      const list = await ReferenceService.getReferenceData(pathDataloadSchedule)
      setDataloadList(list.map((i: any): ILabeledValue => ({ label: '(' + i.description + ')', value: i.code })))
      const ntificationlist = await ReferenceService.getReferenceData(pathNotificationFrequency)
      setNotificationFrequencyList(ntificationlist.map((i: any): ILabeledValue => ({ label: i.description, value: i.code })))
    }
    setup()
  }, [])

  let orderStatus = searchOrder.status ? searchOrder.status : NOT_AVAILABLE

  const canEditName: boolean =
    orderStatus !== OrderStatus.submitted &&
    orderStatus !== OrderStatus.fulfilled &&
    orderStatus !== OrderStatus.partiallyFulfilled

  if (orderStatus === 'NotSubmitted') {
    orderStatus = 'Not Submitted'
  }

  if (orderStatus === 'PartiallyFulfilled') {
    orderStatus = 'Partially Fulfilled'
  }
  useEffect(() => {
    setTheName(searchName ? searchName : searchOrder.name ? searchOrder.name : NOT_AVAILABLE)
  }, [searchName])

  useEffect(() => {
    setTheDescription(updatedDesc ? updatedDesc : searchOrder.description ? searchOrder.description : NOT_AVAILABLE)
  }, [updatedDesc])
  // const theName = searchName? searchName : searchOrder.name ? searchOrder.name : NOT_AVAILABLE
  //const theDescription = updatedDesc ? updatedDesc : searchOrder.description ? searchOrder.description : NOT_AVAILABLE

  return (
    <>
      <div className='row'>
        {canEditName && (
          <div className='col-sm-2 cb-font-weight-bold cb-margin-top-14' id={`name-title-${savedSearchId}`}>
            Name{'  '}
            <EditSaveSearchName
              savedSearchId={savedSearchId}
              searchName={theName}
              namesAvailable={searchOrder.namesAvailable}
              description={theDescription}
              uploadFileCount={searchOrder?.uploadFileCount}
              uploadFileName={searchOrder?.uploadFileName}
              updateAfterNameChange={updateAfterNameChange}
              buttonDescribedBy={`name-title-${savedSearchId} name-${savedSearchId}`}
              buttonLabel='Edit'
            />
          </div>
        )}
        {!canEditName && (
          <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16' id={`name-title-${savedSearchId}`}>
            Name{'  '}
          </div>
        )}
        <div className='col-sm-4 cb-margin-top-16' id={`name-${savedSearchId}`}>
          {theName}
        </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Status </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.status ? orderStatus : NOT_AVAILABLE}</div>
      </div>
      <div className='row'>
        {canEditName && (
          <div className='col-sm-2 cb-font-weight-bold cb-margin-top-14' id={`desc-title-${savedSearchId}`}>
            Description{'  '}
            <EditSaveSearchDescription
              savedSearchId={savedSearchId}
              searchName={theName}
              searchDesc={theDescription}
              uploadFileCount={searchOrder?.uploadFileCount}
              uploadFileName={searchOrder?.uploadFileName}
              namesAvailable={searchOrder.namesAvailable}
              updateAfterDescChange={updateAfterDescChange} // using the name change as trigger to refresh the section
              buttonDescribedBy={`desc-title-${savedSearchId} desc-${savedSearchId}`}
              buttonLabel='Edit'
            />
          </div>
        )}
        {!canEditName && (
          <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16' id={`desc-title-${savedSearchId}`}>
            Description{'  '}
          </div>
        )}
        <div className='col-sm-4 cb-margin-top-16' id={`desc-${savedSearchId}`} style={{ wordBreak: 'break-word' }}>
          {theDescription}
        </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Order Number </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.orderId ? searchOrder.orderId : NOT_AVAILABLE}</div>
      </div>
      <div className='row cb-border-bottom cb-margin-bottom-16'>
        <Spacer size='48' />
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Type </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.itemTypeDescr ? searchOrder.itemTypeDescr : NOT_AVAILABLE} </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Volume Estimate </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.namesAvailable ? numberFormatter(searchOrder.namesAvailable) : NOT_AVAILABLE}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Source </div>
        {/* Hard coded as it will be always be Research and License */}
        <div className='col-sm-4 cb-margin-top-16'>SFS: Research & License </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Names Ordered </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.namesDesired ? numberFormatter(searchOrder.namesDesired) : '0'}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Created By </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.owner ? searchOrder.owner : NOT_AVAILABLE} </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Names Received </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.namesDelivered ? numberFormatter(searchOrder.namesDelivered) : '0'}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Created Date </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.createDateFormatted ? searchOrder.createDateFormatted : NOT_AVAILABLE}{' '}
        </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Total </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.formattedTotalAmount ? searchOrder.formattedTotalAmount : NOT_AVAILABLE}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Modified By </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.owner ? searchOrder.owner : NOT_AVAILABLE} </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Delivery Frequency </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.notificationFrequency && notificationFrequencyList.length > 0
            ? notificationFrequencyList.filter(nf => nf.value === searchOrder.notificationFrequency)[0].label
            : NOT_AVAILABLE}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Modified Date</div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.lastModifiedDateFormatted ? searchOrder.lastModifiedDateFormatted : NOT_AVAILABLE}
        </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Start Date </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.startDateFormated ? searchOrder.startDateFormated : 'Immediately'}{' '}
          {searchOrder.startSchedule && dataloadList.length > 0
            ? dataloadList.filter(dl => dl.value === searchOrder.startSchedule)[0]?.label
            : null}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Submitted By </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.orderOwner && searchOrder.submitDateFormatted ? searchOrder.orderOwner : NOT_AVAILABLE}
        </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>End Date </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.endDateFormatted ? searchOrder.endDateFormatted : NOT_AVAILABLE}{' '}
          {searchOrder.endSchedule && dataloadList.length > 0
            ? dataloadList.filter(dl => dl.value === searchOrder.endSchedule)[0]?.label
            : null}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Submitted Date </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.submitDateFormatted ? searchOrder.submitDateFormatted : NOT_AVAILABLE}
        </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Folder </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.folderName ? searchOrder.folderName : NOT_AVAILABLE}</div>
      </div>
    </>
  )
}

export default OverViewTab
