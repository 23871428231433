import { ILabeledValue } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import { useContext, useEffect, useState } from 'react'
import { FeatureAccessContext } from '../../../../context/FeatureAccessProvider'
import { ReferenceService } from '../../../../services/rest/ReferenceService'
import { pathBillingType } from '../../../search/search-form/criteria/geography/Options'
import { OrderOverView } from '../order-details/SearchOrderDetails'
import OrderContact from '../order-details/view-order-detail/OrderContact'

export interface BillingViewProps {
  searchOrder: OrderOverView
  savedSearchId: number
}
const BillingTab = ({ searchOrder }: BillingViewProps) => {
  const { user } = useSearchUserContext()
  const [billingTypeList, setBillingTypeList] = useState<ILabeledValue[]>([])
  const { isTransactional } = useContext(FeatureAccessContext)
  const orgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
  const NOT_AVAILABLE = 'N/A'

  useEffect(() => {
    const setup = async () => {
      const billingTypeList = await ReferenceService.getReferenceData(pathBillingType)
      setBillingTypeList(billingTypeList.map((i: any): ILabeledValue => ({ label: i.description, value: i.code })))
    }
    setup()
  }, [])
  return (
    <>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Payment Type </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.billingType && isTransactional() === true && billingTypeList.length > 0
            ? billingTypeList.filter(nf => nf.value === searchOrder.billingType)[0].label
            : isTransactional() === true
            ? NOT_AVAILABLE
            : 'Subscription'}
        </div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Purchase Order Number </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.poNumber ? searchOrder.poNumber : NOT_AVAILABLE}</div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Billing Address </div>
        <div className='col-sm-4 cb-margin-top-16'>{<OrderContact orgId={orgId ? orgId : 0} isBilling={true} />}</div>
      </div>
    </>
  )
}

export default BillingTab
