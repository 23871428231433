import { Input, Modal, NakedButton, PrimaryButton, Select, Spacer, SuccessNotification } from '@cb/apricot-react'
import { useMutation } from '@apollo/client'
import {
  CHANGE_FILE_FORMAT,
  DELETE_SAVED_SEARCH,
  MOVE_SAVED_SEARCH,
  SAVE_UPDATE_SEARCH,
  STOP_ORDER
} from '../../../services/graphql/mutations'
import { ISavedSearchInput } from '../../search/search-form/ISearchFaces'
import { removeEmptyElements } from '../../../utils/generalHelpers'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ICriteriaExcluded } from '../../search/search-form/ICriteriaExcluded'
import { CriteriaExcludedSummary } from '../../search/modals/CriteriaExcludedSummary'
import { SaveSearchValidation } from '../../search/search-form/saveSearchValidation'
import { focus } from '../../../services/location/LocationService'
const _ = require('lodash')

interface IDeleteModal {
  savedSearchId: number
  userId: number
  name: string
  modalTrigger?: string
}

interface IChangeFormatModal {
  btnId: string
  orderId: number | undefined
  orderRun: number | undefined
  fileId: number
  fileType: string
  format: string
  open: boolean
  onClose: Function
}

interface ICopyModal {
  savedSearch: ISavedSearchInput
  excludedCriteria?: any
  userId?: number
  open: boolean
  onCancel: Function
}

interface IStopModal {
  orderId: number
  userId?: number
  status?: string
  namesDesired?: number
  namesDelivered?: number
  isFuture: boolean
  open: boolean
  onClose: Function
}

interface ICopyModalValidationState {
  name: string
  validationMsg: string
  validationType: 'success' | 'error' | 'general' | undefined
}

// no orderId & orderRun is a living record request
export const ChangeFormatModal = ({
  btnId,
  orderId,
  orderRun,
  fileId,
  fileType,
  format: currentFormat,
  open: shouldBeOpen,
  onClose
}: IChangeFormatModal) => {
  const [format, setFormat] = useState(currentFormat)
  const [open, setOpen] = useState<boolean>(shouldBeOpen)
  const [changed, setChanged] = useState(false)

  const [changeFormat] = useMutation(CHANGE_FILE_FORMAT, { onCompleted: data => setChanged(true) })

  const handleChangeFormat = () => {
    const changeRequest = { orderId, orderRun, fileId, newFormat: format, fileType }
    changeFormat({ variables: { changeRequest } })
  }

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => focus(null, btnId), 500)
  }

  useEffect(() => {
    if (open !== shouldBeOpen) {
      setOpen(shouldBeOpen)
    }
  }, [open, shouldBeOpen])

  const options = [
    { label: 'Select Open', value: '' },
    { label: 'Fixed Width - .txt', value: 'FixedWidth' },
    { label: 'MS Excel 2007 - .xlsx', value: 'Excel' },
    { label: 'Comma Delimited - .csv', value: 'CommaDelimited' },
    { label: 'Tab Delimited - .tsv', value: 'TabDelimited' }
  ].filter(option => option.value !== currentFormat)

  const button = changed
    ? [
        <PrimaryButton key='ok' onClick={() => handleClose()}>
          OK
        </PrimaryButton>
      ]
    : [
        <NakedButton key='cancel' onClick={() => handleClose()}>
          Cancel
        </NakedButton>,
        <PrimaryButton key='update' disabled={format === currentFormat} onClick={() => handleChangeFormat()}>
          Update
        </PrimaryButton>
      ]

  return (
    <Modal
      actions={button}
      analytics
      analyticsTitle='msof-change-format'
      closeButton={false}
      title={<span className='cb-font-weight-bold ms-modal-header-fontsize'>Edit File Format</span>}
      withFooter
      withHeader
      showHeaderCloseGlyph={false}
      open={open}
      onClose={() => onClose()}
    >
      {changed ? (
        <div>
          You have requested this file in a new format. Your new file will be available shortly. Please refresh the My Searches,
          Orders & Files page to download the file in the new format.
        </div>
      ) : (
        <div>
          {`Note: This file format will be used for all future runs of ${
            orderId !== undefined ? 'this order' : 'Living Record'
          }.`}
          <Spacer />
          <Select label='Change format to:' css={undefined} values={options} onChange={(value: any) => setFormat(value)} />
        </div>
      )}
    </Modal>
  )
}

export const CopyModal = ({ savedSearch, excludedCriteria, userId, open: shouldBeOpen, onCancel }: ICopyModal) => {
  const navigate = useNavigate()
  const [saveSearch, { loading, error, data, reset }] = useMutation(SAVE_UPDATE_SEARCH, {
    onCompleted: data => handleSaveComplete(data)
  })
  const [name, setName] = useState(savedSearch.name ?? '')
  const [open, setOpen] = useState<boolean>(shouldBeOpen)

  const [state, setState] = useState<ICopyModalValidationState>({ name: name, validationMsg: '', validationType: undefined })

  useEffect(() => {
    if (!open && shouldBeOpen) {
      onCancel()
    }
  }, [open, shouldBeOpen, onCancel])

  const handleSaveComplete = (data: any) => {
    const error = data.saveSavedSearch.error
    if (error) {
      let validationMsg
      switch (error) {
        case 'DuplicateSearchName':
          validationMsg = 'The Search name you entered already exists'
          break
        // TODO: What other validation errors would we have?  Add handlers for future
        // error codes as needed....
        default:
          validationMsg = 'There was an error. Please try again later'
      }
      // we only have duplicate search name for now...
      setState({ ...state, validationMsg, validationType: 'error' })
    } else {
      reset()
      setOpen(false)
      navigate(`/search-students?savedSearchId=${data.saveSavedSearch.savedSearchId}`)
    }
  }

  const handleSaveSearch = () => {
    const { errorMessage } = SaveSearchValidation(name.trim())
    if (errorMessage) {
      setState({ ...state, validationMsg: errorMessage, validationType: 'error' })
      return
    }

    // ---> TODO: This is a hack!  Removing __typename b/c it messes up the mutation. We need to figure out
    //  how to get results back from AppSync w/o the __typename field!
    const newSavedSearch = _.omit(savedSearch, '__typename')
    if (newSavedSearch.order) {
      delete newSavedSearch.order.__typename
    }
    // END HACK SECTION

    newSavedSearch.name = name.trim()
    newSavedSearch.createdBy = userId
    saveSearch({ variables: { savedSearch: removeEmptyElements(newSavedSearch) } })
  }

  return (
    <Modal
      actions={[
        <NakedButton key='cancel' onClick={() => onCancel()}>
          Cancel
        </NakedButton>,
        <PrimaryButton key='continue' onClick={() => handleSaveSearch()}>
          Continue
        </PrimaryButton>
      ]}
      analytics
      analyticsTitle='msof-copy-order'
      open={open}
      onClose={() => setOpen(false)}
      closeButton={false}
      title={<span className='cb-font-weight-bold ms-modal-header-fontsize'>Save or Rename Copy</span>}
      withFooter
      withHeader
    >
      <div>
        <Input
          clearable
          value={name}
          floating={false}
          id='copy-order-search-name'
          label='Name'
          labelClassName='cb-margin-top-12'
          onChange={(e: any) => setName(e.target.value)}
          validation={state.validationType}
          validationMsg={state.validationMsg}
        />
      </div>
      <div>
        <Spacer />
        <CriteriaExcludedSummary criteriaExcluded={JSON.parse(excludedCriteria) as ICriteriaExcluded} />
      </div>
    </Modal>
  )
}

export const StopModal = ({
  orderId,
  userId,
  namesDesired,
  namesDelivered,
  isFuture,
  open: shouldBeOpen,
  onClose
}: IStopModal) => {
  const navigate = useNavigate()
  const [stopOrder] = useMutation(STOP_ORDER, { onCompleted: data => handleStopComplete() })
  const [open, setOpen] = useState<boolean>(shouldBeOpen)

  useEffect(() => {
    if (open !== shouldBeOpen) {
      setOpen(shouldBeOpen)
    }
  }, [open, shouldBeOpen, onClose])

  useEffect(() => {
    if (!open) {
      onClose()
    }
  }, [open])

  const handleStopOrder = () => {
    stopOrder({ variables: { orderId, userId } })
  }

  const handleStopComplete = () => navigate('/my-searches')

  return (
    <Modal
      actions={[
        <NakedButton key='cancel' onClick={() => onClose()}>
          Cancel
        </NakedButton>,
        <PrimaryButton key='continue' onClick={() => handleStopOrder()}>
          Continue
        </PrimaryButton>
      ]}
      analytics
      analyticsTitle='msof-stop-order'
      open={open}
      onClose={() => setOpen(false)}
      closeButton={false}
      title={<span className='cb-font-weight-bold ms-modal-header-fontsize'>Stop Order {orderId}?</span>}
      withFooter
      withHeader
    >
      <div>
        Are you sure you would like to stop this order? You will not receive any new names for this order, and this action cannot
        be undone.
        <Spacer />
        Names Ordered: {namesDesired}
        {!isFuture ? (
          <>
            <br />
            Names Delivered: {namesDelivered}
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  )
}

export const DeleteModal = ({ savedSearchId, userId, name, modalTrigger }: IDeleteModal) => {
  interface IDeleted {
    deletePortfolioItem: boolean
  }

  // reset is for reset in modal should you close and hit
  // apply again, the previous error/loading would be reset and thus correct messages
  const [deleteSearch, { loading, error, data, reset }] = useMutation(DELETE_SAVED_SEARCH, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getPortfolio(curr = {}) {
            if (error || data) {
              if (error || !data.deletePortfolioItem) {
                console.log('false cache', curr.items.length, savedSearchId, error, data?.deletePortfolioItem)
                return curr
              } else {
                console.log('removing cache', curr.items.length, savedSearchId)
                return { ...curr, items: curr.items.filter((e: any) => e.savedSearchId !== savedSearchId) }
              }
            } else {
              console.log('no erro and no data', error, data)
            }
          }
        }
      })
    }
  })

  const handleDelete = () => {
    deleteSearch({ variables: { savedSearchId: savedSearchId, deletedBy: userId } })
  }

  // only render if the trigger exists.  when trigger exists, it has to
  // match apply button id, we only render when this happens because apricot
  // only does the matching on mount.  if we pre render with non matching trigger
  // like a trigger="default", then later update trigger via useeffect, even though
  // trigger is updated apricot will not re do the match part so the button still won't open the modal
  return modalTrigger ? (
    <Modal
      actions={
        <NakedButton loading={loading} disabled={loading} onClick={handleDelete}>
          Ok
        </NakedButton>
      }
      analytics
      analyticsTitle='msof-delete-saved-search'
      onClose={() => reset()}
      closeButton={false}
      title='Delete saved search'
      trigger={modalTrigger}
      withFooter
      withHeader
    >
      Are you sure you want to delete {name}?
      {data || error ? (
        !data.deletePortfolioItem ? (
          <p className='cb-margin-top-8'>Delete failed please try again later</p>
        ) : null
      ) : null}
    </Modal>
  ) : null
}
