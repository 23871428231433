import { ILabeledValue, Notification, Spacer } from '@cb/apricot-react'
import { AccordionTableData, PageSize, Pagination, Table, useTableContext } from '@msss/ui-components'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { Column } from 'react-table'
import { MSOF_VIEW } from './ViewToggle'

interface ListUnassignedSearchesProps {
  columns: Array<Column<object>>
  data: Array<object>
  hiddenColumns?: string[]
  id: string
  msofFilter: ILabeledValue[] | undefined
  msofGlobalFilter: string | undefined
  renderHeader: ({ row }: { row: any }) => ReactNode
  renderPanel: ({ row }: { row: any }) => ReactNode
  setSelectedRows: (rows: any[]) => void
  title: string
  view: string
}

const ListUnassignedView = ({
  columns,
  data,
  hiddenColumns,
  id,
  msofFilter,
  msofGlobalFilter,
  renderHeader,
  renderPanel,
  setSelectedRows,
  title,
  view
}: ListUnassignedSearchesProps) => {
  const initialTableState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'lastModifiedDate',
          desc: true
        }
      ]
    }),
    []
  )

  return (
    <div style={{ display: view === MSOF_VIEW.FOLDERS ? 'block' : 'none' }}>
      <Table columns={columns} data={data} hiddenColumns={hiddenColumns} initialTableState={initialTableState}>
        <ListUnassignedTable
          id={id}
          msofFilter={msofFilter}
          msofGlobalFilter={msofGlobalFilter}
          renderHeader={renderHeader}
          renderPanel={renderPanel}
          setSelectedRows={setSelectedRows}
          title={title}
        />
      </Table>
    </div>
  )
}

export default ListUnassignedView

interface ListUnassignedTableProps {
  id: string
  msofFilter: ILabeledValue[] | undefined
  msofGlobalFilter: string | undefined
  renderHeader: ({ row }: { row: any }) => ReactNode
  renderPanel: ({ row }: { row: any }) => ReactNode
  setSelectedRows: (rows: any[]) => void
  title: string
}

const ListUnassignedTable = ({
  id,
  msofFilter,
  msofGlobalFilter,
  renderHeader,
  renderPanel,
  setSelectedRows,
  title
}: ListUnassignedTableProps) => {
  const {
    data,
    gotoPage,
    page,
    pageCount,
    pageIndex,
    pageSize,
    rows,
    setFilter,
    setGlobalFilter,
    setPageSize,
    selectedFlatRows,
    selectedRowIds
  } = useTableContext()

  useEffect(() => {
    // apply the global filter
    if (msofGlobalFilter) {
      setGlobalFilter(msofGlobalFilter)
    } else {
      setGlobalFilter('')
    }
  }, [msofGlobalFilter])

  // apply the rest of the filters
  useEffect(() => {
    if (msofFilter) {
      msofFilter.forEach(item => setFilter(item.label, item.value))
    }
  }, [data, msofFilter])

  useEffect(() => {
    setSelectedRows?.(selectedFlatRows)
  }, [selectedRowIds])

  return (
    <>
      <div className='row'>
        <div className='col-sm-12 cb-font-weight-bold cb-margin-bottom-8 cb-margin-top-32'>{title}</div>
        <div className='col-sm-4' role='status'>
          {rows.length < data.length && (
            <>
              <span className='cb-font-weight-bold'>{rows.length}</span> {rows.length === 1 ? 'Result' : 'Results'}
            </>
          )}
        </div>
        <div className='col-sm-8 cb-align-right'>
          <PageSize pageSize={pageSize} handlePageSize={setPageSize} />
          <span className='cb-padding-8'>
            ({data.length} {data.length === 1 ? 'Record' : 'Records'})
          </span>
        </div>
      </div>

      <div className='row'>
        <div className='col-xs-12'>
          <AccordionTableData id={id} renderHeader={renderHeader} renderPanel={renderPanel} showSelectAll />
        </div>
      </div>

      {rows.length === 0 && (
        <div className='row'>
          <div className='col-xs-12'>
            <Notification className='cb-gray5-bg' color={undefined} icon='exclamation' title='No Results Found'>
              <p>To try again, remove any of the selected filters and choose from the others shown.</p>
            </Notification>
          </div>
        </div>
      )}

      {page.length < data.length && (
        <div className='row'>
          <div className='col-xs-12 cb-align-center cb-margin-top-24'>
            <PageSize pageSize={pageSize} handlePageSize={setPageSize} />
            <Spacer size='16' />
            <Pagination gotoPage={gotoPage} id={id} pageCount={pageCount} pageIndex={pageIndex} />
          </div>
        </div>
      )}
    </>
  )
}
