import 'regenerator-runtime'
import axios from 'axios'
import { ISearchUser } from '@msss/ui-common'

import { ICriteria } from '../../components/search/search-form/ICriteria'
import { ConfigService } from '../rest/ConfigService'
import { ServiceUtils } from '../ServiceUtils'
import { IUserData } from '../../components/search/search-form/IUserData'

const getSearchCount = async (user: ISearchUser, criteria: ICriteria, userData: IUserData, savedSearchId?: number) => {
  const headers = await ServiceUtils.getHeaders()

  try {
    // keep this return for local testing
    // return { searchCount: Math.floor(Math.random() * (8000000 - 5000000 + 1) + 5000000) }
    // Sample criteria
    // const solrCriteria = { docType: ['S'], cohortYear: criteria.cohortYear, customerOrgs: [criteria.customerOrgs] }

    // ENROLLMENT-27216 (if no cohortYear's selected, seed the criteria.cohortYear with the 5 search years)
    const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
    if (criteria.cohortYear == undefined || criteria.cohortYear.length === 0) {
      const { searchYears } = await ConfigService.getSearchYears(authOrgId!)
      criteria = { ...criteria, cohortYear: searchYears }
    }
    const username = user.userName
    console.log('userdata:', JSON.stringify(userData))
    console.log('criteria:', JSON.stringify(criteria))
    const response = await axios.post(
      `${process.env['SOLR_SERVICE_URL']}/solr?orgId=${authOrgId}`,
      {
        criteria,
        userData,
        orgId: authOrgId,
        username,
        savedSearchId,
        timestamp: new Date()
      },
      { headers }
    )
    console.log('solrQuery:\n', response.data.solrQuery)
    return { searchCount: response.data.numFound, queryString: response.data.solrQuery }
  } catch (e) {
    return { searchCountError: e }
  }
}

const getAvailableCountsForLicense = async (user: ISearchUser) => {
  const headers = await ServiceUtils.getHeaders()

  try {
    // keep this for local testing
    // return { countsData: [1000000, 800000, 700000, 500000, 100000] }
    const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
    const { searchYears } = await ConfigService.getSearchYears(authOrgId!)

    // default criteria
    const criteria = { docType: ['S'], cohortYear: searchYears!, sellable: ['B,E,A'] }
    const countsData = searchYears!.reduce((map, searchYear) => (map.set(searchYear, 0), map), new Map())

    const options = { facets: ['cohortYear'], skipAudit: true }
    const username = user.userName
    const response = await axios.post(
      `${process.env['SOLR_SERVICE_URL']}/solr?orgId=${authOrgId}`,
      { criteria, options },
      { headers }
    )
    for (const [key, value] of Object.entries(response.data.facets.cohortYear)) {
      countsData.set(key, value)
    }
    return { countsData: countsData }
  } catch (e) {
    return { countsError: e }
  }
}

export const SolrService = {
  getSearchCount,
  getAvailableCountsForLicense
}
