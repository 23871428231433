import { Checkbox, CheckboxGroup, ILabeledValue, RadioButton, RadioButtonGroup } from '@cb/apricot-react'
import { TooltipHeader } from '@msss/ui-components'
import { useCallback, useEffect } from 'react'
import { ICriteriaNAdditional } from './IntendedMajor'
import { additionalOptions, majorsChoiceOptions } from './Options'
import CBInputMethod from '../../../../../utils/CBInputMethode'
import MyCriteriaList from '../../../../my-account/saved-criteria/MyCriteriaList'
import { AvailableMySavedCriteriaTypes } from '../../../../my-account/saved-criteria/options'

const CriteriaNAdditional = (props: ICriteriaNAdditional): JSX.Element => {
  const {
    setSavedCriteriaData,
    myaccount,
    changed,
    data: { selectedAdditionalOptions, selectedMajorChoice },
    updateData
  } = props

  useEffect(() => {
    CBInputMethod()
  }, [])

  const handleAdditionalOptions = useCallback(
    (checked: boolean, value: string) => {
      let newSelections = [...selectedAdditionalOptions]
      if (checked) {
        if (!newSelections.includes(value)) {
          newSelections.push(value)
        }
      } else {
        newSelections = newSelections.filter((selection: string) => selection !== value)
      }
      updateData({ selectedAdditionalOptions: newSelections })
    },
    [selectedAdditionalOptions, updateData]
  )

  return (
    <div className='row'>
      <div className='col-sm-8'>
        <div className='row'>
          <div className='col-sm-6' style={myaccount ? { visibility: 'hidden' } : undefined}>
            <TooltipHeader
              header='Saved Criteria'
              id='im-saved-criteria'
              // tooltipMsg='select previously saved criteria'
            />
            <MyCriteriaList
              type={AvailableMySavedCriteriaTypes.IntendedMajor}
              setSavedCriteriaData={setSavedCriteriaData}
              myaccount={myaccount}
              changed={changed}
            />
          </div>
          <div className='col-sm-5 offset-sm-1'>
            <RadioButtonGroup
              legend='Intended major selection options'
              legendClassName='sr-only'
              // don't need this. the screen reader will just read the legend correctly
              fieldsetId='im-saved-criteria-college-choice-options'
              name='im-saved-criteria-college-choice'
              onChange={(value: string) => updateData({ selectedMajorChoice: value })}
              value={selectedMajorChoice}
              vertical
            >
              {majorsChoiceOptions.map(({ label, value }: ILabeledValue) => (
                <RadioButton
                  id={`im-saved-criteria-college-choice-option-${value}`}
                  key={value}
                  label={label}
                  value={`${value}`}
                />
              ))}
            </RadioButtonGroup>
          </div>
        </div>
      </div>
      <div className='col-sm-4'>
        <TooltipHeader
          header='Additional Options'
          id='im-additional-options'
          tooltipMsg='Search by additional options that are not related to Majors.'
        />
        <div className='row'>
          <div className='col-xs-12' style={{ marginTop: '-8px' }}>
            {' '}
            {/* margin offsets spacer after hidden legend */}
            <CheckboxGroup
              fieldsetId='im-additional-options-options'
              legend='Additional Options'
              legendClassName='sr-only'
              vertical
            >
              {additionalOptions.map(({ label, value }: ILabeledValue) => (
                <Checkbox
                  checked={selectedAdditionalOptions?.includes(`${value}`) || false}
                  id={`im-additional-options-option-${value}`}
                  key={value}
                  label={label}
                  onChange={(checked: boolean) => handleAdditionalOptions(checked, `${value}`)}
                />
              ))}
            </CheckboxGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CriteriaNAdditional
