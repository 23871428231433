/* db msss_lookup.cd_user_preference_group
13	Competitor 
14	Interested in My College
1	Graduating Class
2	College Board Exams
3	Geography
4	Email & Postal Address Preferences
5	Demographics
6	High School Academic Performance
7	Intended Major
8	High School Courses & Activities
9	College Plans & Preferences
10	Segment Analysis
11	High School Profile
12	High School Activity
15	Interested in Colleges Like Mine
16	National Recognition programs
17	Environmental Attributes
18	Landscape Context
19	Parent Email Preferences
*/

import { ILabeledValue } from '@cb/apricot-react'
import React from 'react'
import CbExams from '../../search/search-form/criteria/cb-exams/CbExams'
import Geography from '../../search/search-form/criteria/geography/Geography'
import IntendedMajor from '../../search/search-form/criteria/intended-major/IntendedMajor'
import SegmentAnalysis from '../../search/search-form/criteria/segment-analysis/SegmentAnalysis'
import { SavedCriteriaSectionProps, SectionProps } from '../../search/search-form/Search'
import { NotSupported } from './SavedCriteria'

// current support
export enum AvailableMySavedCriteriaTypes {
  Geography = 3,
  CBExams = 2,
  IntendedMajor = 7,
  SegmentAnalysis = 10
}

export const AvailableTypeValues = new Map<AvailableMySavedCriteriaTypes, ILabeledValue>([
  [AvailableMySavedCriteriaTypes.Geography, { label: 'Geography', value: AvailableMySavedCriteriaTypes.Geography }],
  [AvailableMySavedCriteriaTypes.CBExams, { label: 'College Board Exams', value: AvailableMySavedCriteriaTypes.CBExams }],
  [AvailableMySavedCriteriaTypes.IntendedMajor, { label: 'Intended Majors', value: AvailableMySavedCriteriaTypes.IntendedMajor }],
  [
    AvailableMySavedCriteriaTypes.SegmentAnalysis,
    { label: 'Segment Analysis', value: AvailableMySavedCriteriaTypes.SegmentAnalysis }
  ]
])

export const filterCriteriaTypeOptions = Array.from(AvailableTypeValues.values()).map(option => ({
  label: option.label,
  value: option.value.toString(),
  column: 'categoryGroupCd'
}))

export const getCritComp = (type: string | number | undefined): React.FC<SavedCriteriaSectionProps> => {
  if (type) {
    const x = parseInt(type as string)
    switch (x) {
      case AvailableMySavedCriteriaTypes.Geography:
        return Geography
      case AvailableMySavedCriteriaTypes.SegmentAnalysis:
        return SegmentAnalysis
      case AvailableMySavedCriteriaTypes.CBExams:
        return CbExams
      case AvailableMySavedCriteriaTypes.IntendedMajor:
        return IntendedMajor
      default:
        return NotSupported
    }
  } else return NotSupported
}
