import './MajorGroups.scss'
import { Checkbox, CheckboxGroup, Select } from '@cb/apricot-react-forms'
import { TooltipHeader } from '@msss/ui-components'
import { Fragment, useCallback, useEffect } from 'react'
import { Major, IMajorGroups } from './IntendedMajor'
import CBInputMethod from '../../../../../utils/CBInputMethode'

const MajorGroups = (props: IMajorGroups): JSX.Element => {
  const {
    data: { selectedMajors },
    selectedMajorGroup,
    setSelectedMajorGroup,
    updateData,
    majorGroups,
    majorsLists
  } = props

  useEffect(() => {
    CBInputMethod()
  }, [])

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      const newSelectedMajors: string[] = []
      if (checked) {
        majorGroups.forEach((majorGroup: Major) => {
          newSelectedMajors.push(majorGroup.value)
          if (majorsLists[majorGroup.value]) {
            newSelectedMajors.push(...majorsLists[majorGroup.value].map((major: Major) => major.value))
          }
          // remove most of the lag with checking so many check boxes at once, but don't update criteria until the final update below
          updateData({ selectedMajors: newSelectedMajors, sendUpdate: false })
        })
      }
      updateData({ selectedMajors: newSelectedMajors })
    },
    [majorGroups, majorsLists, updateData]
  )

  const handleSelectGroup = useCallback(
    (checked: boolean, groupCode: string) => {
      let newSelectedMajors: string[] = [...selectedMajors]
      if (checked) {
        if (!newSelectedMajors.includes(groupCode)) {
          newSelectedMajors.push(groupCode)
        }
        majorsLists[groupCode]?.forEach((major: Major) => {
          if (!newSelectedMajors.includes(major.value)) {
            newSelectedMajors.push(major.value)
          }
        })
      } else {
        newSelectedMajors = newSelectedMajors.filter(
          (majorCode: string) =>
            groupCode !== majorCode && !majorsLists[groupCode]?.find((major: Major) => majorCode === major.value)
        )
      }
      updateData({ selectedMajors: newSelectedMajors })
    },
    [majorsLists, selectedMajors, updateData]
  )

  const handleSelectIndividual = useCallback(
    (checked: boolean, major: Major) => {
      let newSelectedMajors: string[] = [...selectedMajors]
      if (checked) {
        if (!newSelectedMajors.includes(major.value)) {
          newSelectedMajors.push(major.value)
          if (
            !newSelectedMajors.includes(major.dataGroupCode) &&
            majorsLists[major.dataGroupCode].every(({ value }: Major) => newSelectedMajors.includes(value))
          ) {
            newSelectedMajors.push(major.dataGroupCode)
          }
        }
      } else {
        newSelectedMajors = newSelectedMajors.filter(
          (majorCode: string) => majorCode !== major.dataGroupCode && majorCode !== major.value
        )
      }
      updateData({ selectedMajors: newSelectedMajors })
    },
    [majorsLists, selectedMajors, updateData]
  )

  return (
    <div className='row'>
      <div className='col-xs-12'>
        <TooltipHeader header='Major Groups' id='im-major-groups' tooltipMsg='Search Majors by group.' />
        <div className='row'>
          <div className='col-sm-4'>
            <Select
              ariaLabel='select major group'
              onChange={setSelectedMajorGroup}
              selectId='im-major-group-options'
              value={selectedMajorGroup}
              values={[
                ...(selectedMajorGroup === '-2' ? [{ label: 'No Selection', value: '-2' }] : []),
                { label: 'View All', value: '-1' },
                ...majorGroups
              ]}
            />
          </div>
          <div className='col-sm-8 cb-border cb-border-1 cb-border-radius ms-im-majors-container'>
            <div className='cb-padding-16'>
              {selectedMajorGroup === '-1' && (
                <>
                  <Checkbox
                    checked={
                      Object.entries(majorsLists).every(([groupCode, majors]: any) =>
                        majors?.every(({ value }: { value: string }) => selectedMajors?.includes(value))
                      ) || false
                    }
                    className='cb-font-weight-medium cb-padding-bottom-8'
                    id='im-select-all-majors-option'
                    label='Select All'
                    onChange={handleSelectAll}
                  />
                  <div className='ms-im-majors-scrollable'>
                    <CheckboxGroup
                      fieldsetId='im-major-group-options'
                      legend='All Majors'
                      legendClassName='sr-only'
                      vertical={true}
                    >
                      {majorGroups.map(({ label, value: groupCode }: { label: string; value: string }, i: number) => (
                        <Fragment key={groupCode}>
                          <Checkbox
                            checked={selectedMajors?.includes(groupCode) || false}
                            className={`cb-font-weight-medium cb-font-size-small cb-padding-bottom-8${
                              i !== 0 ? ' cb-padding-top-48' : ''
                            }`}
                            id={`im-major-group-option-${groupCode}`}
                            label={label}
                            onChange={(checked: boolean) => handleSelectGroup(checked, groupCode)}
                          />
                          {majorsLists[groupCode].map((major: Major) => (
                            <Checkbox
                              checked={selectedMajors?.includes(major.value) || false}
                              className='cb-font-size-small cb-padding-bottom-8'
                              id={`im-major-option-${groupCode}-${major.value}`}
                              key={major.value}
                              label={major.label}
                              onChange={(checked: boolean) => handleSelectIndividual(checked, major)}
                            />
                          ))}
                        </Fragment>
                      ))}
                    </CheckboxGroup>
                  </div>
                </>
              )}
              {selectedMajorGroup !== '-1' && selectedMajorGroup !== '-2' && (
                <CheckboxGroup
                  fieldsetId={`im-major-group-options-${selectedMajorGroup}`}
                  legend='Major Group'
                  legendClassName='sr-only'
                  vertical={true}
                >
                  <Checkbox
                    checked={selectedMajors?.includes(selectedMajorGroup) || false}
                    className='cb-font-weight-medium cb-font-size-small'
                    id={`im-major-group-option-${selectedMajorGroup}`}
                    label={majorGroups.find(({ value: groupCode }: { value: string }) => groupCode === selectedMajorGroup)?.label}
                    onChange={(checked: boolean) => handleSelectGroup(checked, selectedMajorGroup)}
                  />
                  <div className='ms-im-majors-scrollable'>
                    {majorsLists[selectedMajorGroup].map((major: Major) => (
                      <Checkbox
                        checked={selectedMajors?.includes(major.value) || false}
                        className='cb-font-size-small cb-padding-bottom-8'
                        id={`im-major-option-${selectedMajorGroup}-${major.value}`}
                        key={major.value}
                        label={major.label}
                        onChange={(checked: boolean) => handleSelectIndividual(checked, major)}
                      />
                    ))}
                  </div>
                </CheckboxGroup>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MajorGroups
