import { format, compareAsc } from 'date-fns'
import { getConfigurationService } from '@msss/searchlight-client'
import { ServiceUtils } from '../ServiceUtils'

const SEARCHLIGHT_NAMESPACE = 'searchlight'

// Before and on the EOY day the search year is the year of current date, after that its the next year
// Before and on of 31-AUG-2021 search year is 2021, after that is 2022
const getSearchYear = async (authOrgId: number) => {
  const headers = await ServiceUtils.getHeaders()
  try {
    // get EOY day from config eg: 31-AUG
    const data = await getConfig(authOrgId, headers, 'msss.search-eoy')

    const now = new Date()
    const endOfSearchYear = new Date(`${data.configValue}-${now.getUTCFullYear()}`)

    // today without time part
    const today = new Date(format(now, 'MM/DD/YYYY'))
    // get year from today
    let year: number = today.getFullYear()

    // Main logic: Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
    if (compareAsc(today, endOfSearchYear) > 0) {
      year++
    }

    return { searchYear: year }
  } catch (e) {
    return { error: e }
  }
}

/*
  Based on the search year create an array of allowed search cohort years to search for students from
  eg: if search year is 2022, then the array will be ['2025', '2024', '2023', '2022', '2021']
*/
const getSearchYears = async (authOrgId: number) => {
  try {
    // return { searchYears: ['2025', '2024', '2023', '2022', '2021'] }
    const searchYears: string[] = []
    const { searchYear } = await getSearchYear(authOrgId)

    for (let i = searchYear! - 1; i < searchYear! + 4; i++) {
      searchYears.push(i.toString())
    }

    return { searchYears }
  } catch (e) {
    return { error: e }
  }
}

/* Config value fetch api */
const getConfig = async (authOrgId: number, headers: any, propertyName: string, noCache: boolean = false) => {
  console.log('... system nocach configl', noCache)
  try {
    const config = await getConfigurationService(process.env['ENV_NAME'], authOrgId, headers, SEARCHLIGHT_NAMESPACE)
    const configValue = await config.getProperty(propertyName, noCache)

    return { configValue: configValue }
  } catch (e) {
    return { error: e }
  }
}

export const ConfigService = {
  getSearchYears,
  getSearchYear,
  getConfig
}
