import { ErrorNotification, Modal, PrimaryButton, Spacer } from '@cb/apricot-react'
import { Dispatch, SetStateAction } from 'react'

interface SystemErrorModalProps {
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>> //updates openModal
}
const SystemErrorModal = (props: SystemErrorModalProps) => {
  const { openModal, setOpenModal } = props
  return (
    <Modal
      analytics
      analyticsTitle='display-system-error'
      closeButton={true}
      closeButtonLabel={'OK'}
      disableHeightAdjustment={true}
      headerId='system-error-modal-header'
      modalId='system-error-modal'
      open={openModal}
      title='A network error has occurred'
      withFooter
      withHeader
      clickOverlayToClose={false}
      escClose={false}
      showHeaderCloseGlyph={false}
      onClose={() => setOpenModal(false)}
    >
      <Spacer />
      <div>
        The Recruitment & Admission Services website is currently unavailable. Please try again later. If the issue persists
        please contact Customer Service at 1-866-499-5357 or send us an{' '}
        <a href='mailto:collegeboardsearch@collegeboard.org' target='_top' title='Email us'>
          email
        </a>
        .
      </div>
    </Modal>
  )
}

export default SystemErrorModal
