import axios, { AxiosError } from 'axios'

const referenceUrl = process.env['REFERENCE_URL']

type ServerError = { errorMessage: string }

interface ICache {
  [key: string]: object
}

const cache: ICache = {}

const getReferenceData = async (reference: string) => {
  try {
    if (cache[reference]) return cache[reference]

    const response = await axios.get(`${referenceUrl}/${reference}.json`)
    // console.log(`ReferenceService.getReferenceData ${referenceUrl}/${reference}.json`)
    // console.log(`ReferenceService.getReferenceData  ${response.data}`)

    cache[reference] = response.data
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>
      if (serverError && serverError.response) {
        return serverError.response.data
      }
    }
    return { errorMessage: 'Error getting reference data.' }
  }
}

export const ReferenceService = {
  getReferenceData
}
