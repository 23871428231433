import { Spacer } from '@cb/apricot-react'
import { PageContainer } from '@msss/ui-components'
import React from 'react'
import { siteName } from '../../constants/app'
import UsefulLinks from '../UsefulLinks'

import Prospects from './Prospects'

export const Prospecto = ({ campId }: { campId?: string }) => {
  return (
    <PageContainer pageLoaded showGray siteName={siteName} title='Prospect Identifier'>
      <UsefulLinks />
      <div className='cb-shade-blue2-50 cb-padding-32'>
        <div className='container cb-white-color'>
          <h2>New Prospects are Available in Search</h2>
          <Spacer />
          <p>
            New students who match your prior order criteria have joined Search in the past month. Click a match to re-run these
            orders in the Search tool and see new matches. This list will be refreshed every month.
          </p>
        </div>
      </div>
      <div className='cb-padding-32'>
        <Spacer />
        <Prospects campId={campId} />
      </div>
    </PageContainer>
  )
}
