import { useQuery } from '@apollo/client'
import { ILabeledValue, Notification } from '@cb/apricot-react'
import { GlobalFilter, useSearchUserContext } from '@msss/ui-components'
import React, { useEffect, useState } from 'react'
import { Column } from 'react-table'
import FilterOptions, { FilterColumn } from './filters/FilterOptions'
import ViewToggle, { MSOF_VIEW } from '../common/folders/ViewToggle'
import ListAllSearches from './ListAllSearches'
import FolderActions from '../common/folders/FolderActions'
import ListUnassignedSearches from './ListUnassignedSearches'
import { GET_FOLDERS_OF_ORG } from '../../../services/graphql/queries'
import { IFolder } from '../common/folders/IFolder'
import NoFoldersFound from '../common/folders/NoFoldersFound'
import FoldersView from '../common/folders/FoldersView'
import { IPortfolioItem } from '../common/IPortfolioItem'
import ListFolderSearches from './ListFolderSearches'
import { IPortfolioFileType } from '../../../interfaces/reference/IReference'

interface Props {
  columns: Array<Column<object>>
  data: IPortfolioItem[]
  hiddenColumns: string[]
}

const SearchesOrdersContainer: React.FC<Props> = ({ columns, data, hiddenColumns }) => {
  const params = new URLSearchParams(location.search)

  const { user } = useSearchUserContext()

  // view is either 'list' or 'folders'
  const [view, setView] = useState<string>(MSOF_VIEW.LIST)
  // the common global filer
  const [msofGlobalFilter, setMsofGlobalFilter] = useState<string>(() => (params.get('orderId') ? params.get('orderId')! : ''))
  // all unassigned searches list
  const [unassignedData, setUnassignedData] = useState<IPortfolioItem[]>()
  // this data has only searches/orders that have a folder name
  const [foldersData, setFoldersData] = useState<IPortfolioItem[] | undefined>(undefined)
  // list of all folders of this org
  const [allFolders, setAllFolders] = useState<IFolder[] | undefined>(undefined)
  // folder state when user drills down to see folder details
  const [folder, setFolder] = useState<IFolder | undefined>(undefined)
  // this data has only searches/orders of the selected folder
  const [folderData, setFolderData] = useState<IPortfolioItem[] | undefined>(undefined)
  // selected savedSearch ids that will be used to assign to a folder or remove from folder
  const [selectedSearches, setSelectedSearches] = useState<string[]>([])
  // state to show/hide assign to folder success msg
  const [assignFolderSuccess, setAssignFolderSuccess] = useState<IFolder | undefined>(undefined)

  // We have to keep track of all filters. When clear all is called, the setState was not clearing all the filters
  // Also when data is reloaded the filters were getting lost.
  const [msofFilter, setMsofFilter] = useState<ILabeledValue[]>([])

  const foldersQuery = useQuery(GET_FOLDERS_OF_ORG, {
    variables: { orgId: user.orgId }
  })

  // Appsync queries
  const handleMsofFilter = (column: FilterColumn, value: string) => {
    // We just apply whatever filter the use selects. its only used to update filter of the react-table
    setMsofFilter(prevMsofFilter => [
      ...prevMsofFilter.filter(item => item.label !== column),
      {
        label: column,
        value
      }
    ])
  }

  // once folders query runs set the IFolder array
  useEffect(() => {
    if (!foldersQuery.loading && !foldersQuery.error) {
      if (foldersQuery.data?.getFolders) {
        const folders: IFolder[] = foldersQuery.data?.getFolders.map(item => {
          return { folderId: item.folderId, folderName: item.name }
        })
        setAllFolders(folders.sort((a, b) => a.folderName.localeCompare(b.folderName)))
      } else {
        setAllFolders([])
      }
    }
  }, [foldersQuery.data, foldersQuery.loading, foldersQuery.error])

  // set the unassigned table data from the original table data
  useEffect(() => {
    // list of searches that DONT belong to a folder
    setUnassignedData(data.filter(item => item.folderName === null))

    // list of searches that DO belong to a folder
    setFoldersData(data.filter(item => item.folderName !== null))

    if (folder) {
      setFolderData(data.filter(item => item.folderId === folder.folderId))
    } else {
      setFolderData(undefined)
    }
  }, [data])

  useEffect(() => {
    setAssignFolderSuccess(undefined)
  }, [view])

  useEffect(() => {
    if (folder) {
      setFolderData(data.filter(item => item.folderId === folder.folderId))
    } else {
      setFolderData(undefined)
    }
    setAssignFolderSuccess(undefined)
  }, [folder])

  const hasFoldersDataOrFolders = foldersData && allFolders && (foldersData.length > 0 || allFolders.length > 0)

  // if folder is selected then show the folder view
  if (folder) {
    return (
      <>
        {/* VIEW TOGGLE */}
        <ViewToggle view={view} setView={setView} setFolder={setFolder} />

        {folderData && allFolders ? (
          <ListFolderSearches
            columns={columns}
            hiddenColumns={hiddenColumns}
            data={folderData}
            folder={folder}
            setView={setView}
            setFolder={setFolder}
            foldersData={foldersData}
            allFolders={allFolders}
            assignFolderSuccess={assignFolderSuccess}
            setAssignFolderSuccess={setAssignFolderSuccess}
            category={IPortfolioFileType.Search}
          />
        ) : (
          <Notification color={undefined} icon='exclamation' title='No Records Found'>
            <p>No searches or orders found.</p>
          </Notification>
        )}
      </>
    )
  }

  return (
    <>
      {/* SEARCH and FILTERS */}
      <div aria-label='filters' className='row' role='region'>
        <div className='col-xs-12 col-sm-3 cb-margin-bottom-48'>
          <GlobalFilter
            globalFilter={msofGlobalFilter}
            id='searches-orders'
            label='Search'
            placeholder='Search'
            setGlobalFilter={setMsofGlobalFilter}
          />
        </div>
        <div className='col-xs-12 col-sm-9 offset-lg-1 col-lg-8 offset-xl-2 col-xl-7 cb-margin-bottom-32 display-flex justify-content-between'>
          <FilterOptions setMsofGlobalFilter={setMsofGlobalFilter} setFilter={handleMsofFilter} />
        </div>
      </div>

      {/* VIEW TOGGLE */}
      <ViewToggle view={view} setView={setView} setFolder={setFolder} />

      {/* If its folders view show folder actions (pending) */}
      {view === MSOF_VIEW.FOLDERS && allFolders && (
        <FolderActions
          foldersData={foldersData}
          selectedSearches={selectedSearches}
          allFolders={allFolders}
          setAssignFolderSuccess={setAssignFolderSuccess}
          category={IPortfolioFileType.Search}
        />
      )}

      {/* Success message when searches are assigned to a folder  */}
      {assignFolderSuccess && (
        <div className='col-xs-12 display-flex justify-content-end cb-border-top'>
          <div role='alert' className='cb-toast cb-toast-success' aria-hidden='false'>
            <span className='cb-icon cb-check cb-margin-right-8'>
              <span className='sr-only'>success</span>
            </span>
            <span className='cb-toast-msg'>
              ({assignFolderSuccess.count}) Searches have been assigned to folder '{assignFolderSuccess.folderName}'
            </span>
            <button
              type='button'
              className='cb-btn cb-btn-square cb-btn-greyscale cb-btn-close cb-margin-left-8'
              onClick={() => setAssignFolderSuccess(undefined)}
            >
              <span className='cb-icon cb-x-mark' aria-hidden='false' />
              <span className='sr-only'>Close alert message</span>
            </button>
          </div>
        </div>
      )}

      {/* If its folders view show folders */}
      {view === MSOF_VIEW.FOLDERS && (
        <>
          {/* If we have foldersData != undefined and allFolders != undefined (this is key as this ensures the folders query ran) */}
          {hasFoldersDataOrFolders ? (
            <FoldersView
              allFolders={allFolders}
              columns={columns}
              data={foldersData}
              folderCountDesc='Searches and Orders'
              hiddenColumns={hiddenColumns}
              id='searches-orders-folders'
              msofFilter={msofFilter}
              msofGlobalFilter={msofGlobalFilter}
              setFolder={setFolder}
              view={view}
            />
          ) : (
            <NoFoldersFound />
          )}
        </>
      )}

      {/* If its List View show all the searches  */}
      <ListAllSearches
        columns={columns}
        hiddenColumns={hiddenColumns}
        data={data}
        msofGlobalFilter={msofGlobalFilter}
        msofFilter={msofFilter}
        view={view}
      />

      {/* If its Folders View show all the unassigned searches  */}
      {unassignedData && unassignedData.length > 0 ? (
        <ListUnassignedSearches
          columns={columns}
          hiddenColumns={hiddenColumns}
          data={unassignedData}
          msofGlobalFilter={msofGlobalFilter}
          msofFilter={msofFilter}
          view={view}
          setSelectedSearches={setSelectedSearches}
        />
      ) : (
        <p>No unassigned searches or orders found</p>
      )}
    </>
  )
}

export default SearchesOrdersContainer
