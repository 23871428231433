import CBInputMethod from '@cb/apricot/CBInputMethod'
import { ErrorNotification } from '@cb/apricot-react'
import { ISearchUser } from '@msss/ui-common'
import { CBWidgets, defaultSearchUser, Loader, PageContainer, SearchUserContextProvider } from '@msss/ui-components'

import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import AppRouting from './AppRouting'
import Mismatch from './components/error/Mismatch'
import NoAuthorizedOrg from './components/error/NoAuthorizedOrg'
import { appId, siteName } from './constants/app'
import { getAnyValidOrg } from './utils/tokens'

export interface IAuditToken {
  userName: string
  orgId: number
  campaignId: string
  userId: number
  source: string
  iat: number
}

const AppMain = () => {
  // const tokenData: IAuditToken = {
  //   userName: 'fcastle',
  //   orgId: 286675,
  //   campaignId: 'adfafdadsff',
  //   userId: 64349,
  //   source: 'email',
  //   iat: 123412
  // }

  const cb = (window as any).cb
  const iambus = cb.core.iam.getEventBus()
  // we use the default
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(Boolean(cb.core.iam.getTempAWSCredsSubject()))
  const [spinner, setSpinner] = useState<boolean>(true)
  const [decoded, setDecoded] = useState<IAuditToken>()
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [isAuthError, setIsAuthError] = useState<boolean>(false)
  const [user, setUser] = useState<ISearchUser>(defaultSearchUser)

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const paramOrgId = params.get('orgId')

  iambus.on(cb.core.iam.events.AWSLogin, () => {
    console.log('setting logIN from iam event')
    setIsLoggedIn(true)
  })
  iambus.on(cb.core.iam.events.Logout, () => {
    console.log('setting logOUT from iam event')
    setIsLoggedIn(false)
    setSpinner(false)
  })
  iambus.on(cb.core.iam.events.AWSLoginFailed, () => {
    console.log('setting logIN FAILED from iam event')
    setIsAuthError(true)
    setSpinner(false)
  })

  useEffect(() => {
    CBInputMethod()
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      setIsAuthorized(true)
      setSpinner(false)

      // set up apollo
      if (token) {
        const { TOKEN_URL } = process.env
        const client = axios.create({
          baseURL: `${TOKEN_URL}/`
        })

        const authHeaders = {
          'X-CB-Catapult-Authentication-Token': 'CBLogin ' + cb.core.iam.getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': cb.core.iam.getJWTToken()
        }

        const headers = {
          Accept: 'application/json',
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          ...authHeaders
        }

        client
          .get(`decodeToken?token=${token}&orgId=${getAnyValidOrg()}`, { headers })
          .then((response: any) => {
            setDecoded(response.data)
          })
          .catch(e => console.log('call fail to decode token', e))
      }
      //console.log('app - effect LoggedIn:' + isLoggedIn)
    }
  }, [cb.core.iam, isLoggedIn, location.pathname, token])

  // from here on the main app starts
  if (spinner) {
    return (
      <PageContainer pageLoading siteName={siteName}>
        <Loader />
      </PageContainer>
    )
  }

  if (isAuthError) {
    return (
      <PageContainer pageError='500' siteName={siteName}>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <ErrorNotification className='cb-margin-top-72' title='Error'>
                We encountered an error trying to log you in. Please try again later.
              </ErrorNotification>
            </div>
          </div>
        </div>
      </PageContainer>
    )
  }

  if (isLoggedIn && !isAuthorized) {
    //console.log('isLoggedIn && !isAuthorized')
    return (
      <CBWidgets appId={appId}>
        <NoAuthorizedOrg />
      </CBWidgets>
    )
  }

  // special PI handling
  if (token || paramOrgId) {
    if (!decoded && !paramOrgId) {
      //console.log('!decoded && !paramOrgId')
      return (
        <CBWidgets appId={appId}>
          <Mismatch />
        </CBWidgets>
      )
    }

    if (decoded && decoded.userName.toUpperCase() !== cb.core.iam.getAuthSession().basicProfile.userName) {
      //console.log('decoded && decoded.userName.toUpperCase() !== cb.core.iam.getAuthSession().basicProfile.userName')
      return (
        <CBWidgets appId={appId}>
          <Mismatch />
        </CBWidgets>
      )
    }
  }

  if (isLoggedIn) {
    return (
      <CBWidgets appId={appId}>
        <SearchUserContextProvider user={user} setUser={setUser}>
          <AppRouting tokenData={decoded ? decoded : undefined} />
        </SearchUserContextProvider>
      </CBWidgets>
    )
  } else {
    console.log('*** setting redirect from AppMain...')
    // if you are not logged in then the user was shown a blank screen. instead of that take them to account.collegeboard.org page
    const c = window.location.href
    window.location.href = `https://account.collegeboard.org/login/login?appId=451&idp=EPL&DURL=${c}`
    return null
  }
}

export default AppMain
