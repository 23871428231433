import { ILabeledValue } from '@cb/apricot-react'
import { INCLUDE_ALL_STUDENTS } from '../../SearchFormConstants'

// Common section options
export const assessmentOptions: ILabeledValue[] = [
  { label: 'Include all opt-in students', value: 'O' },
  { label: 'Include only students who have taken a College Board Exam', value: 'Y' },
  { label: 'Include only students that have not taken a College Board Exam', value: 'N' },
  { label: 'Include only students who scored within the ranges defined below', value: 'S' }
]

export const scoreSendsOptions: ILabeledValue[] = [
  {
    label: INCLUDE_ALL_STUDENTS,
    value: ''
  },
  {
    label: 'Include only students who have NOT sent SAT or SAT Subject Test scores to my institution',
    value: 'N'
  },
  {
    label: 'Include only students who have sent SAT or SAT Subject Test scores to my institution',
    value: 'Y'
  }
]

export const scoreLogicOptions: ILabeledValue[] = [
  {
    label: 'OR includes all students who fall within at least one defined score band',
    value: 'OR'
  },
  {
    label: 'AND includes only students who fall within every defined score band',
    value: 'AND'
  }
]
