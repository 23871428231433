export const paginationOptions = [
  {
    label: '50',
    value: '50'
  },
  {
    label: '100',
    value: '100'
  },
  {
    label: '200',
    value: '200'
  }
]

// status enum
export enum OrderStatus {
  processing = 'Processing',
  failed = 'Failed',
  saved = 'Saved',
  notSubmitted = 'NotSubmitted',
  submitted = 'Submitted',
  partiallyFulfilled = 'PartiallyFulfilled',
  fulfilled = 'Fulfilled',
  calceled = 'Cancelled',
  deleted = 'Deleted'
}

export enum OrderStatusCode {
  Saved = '1',
  NotSubmitted = '2',
  Submitted = '3',
  PartiallyFulfilled = '4',
  Fulfilled = '5',
  Cancelled = '6'
}
