import { Popover, Spacer } from '@cb/apricot-react'
import { numberFormatter, Roles, UNLIMITED_NAMES } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useRef, useState } from 'react'
import { FlagpolePublic } from '../../services/rest/FlagpolePublic'
import { FeatureAccessContext } from '../../context/FeatureAccessProvider'
import { IAccountSubscriptionType, IFeatureInfo } from '../../interfaces/reference/IReference'
import { TABS } from './MyAccount'
import { Permissions } from './../common/Permissions'
import { focus } from '../../services/location/LocationService'
import './MyAccountPopover.scss'
import { formatToTimeZone } from 'date-fns-timezone'

const MyAccountPopover = (): JSX.Element => {
  const { user } = useSearchUserContext()
  const { isTransactional, getFeatureInfo, loadingFeatures } = useContext(FeatureAccessContext)

  const [subscriptionBalance, setSubscriptionBalance] = useState<number>(-1)
  const [subscriptionTotalNames, setSubscriptionTotalNames] = useState<number>(-1)
  const [subscriptionNamesUsed, setSubscriptionNamesUsed] = useState<number>(-1)
  const [adjustmentVolume, setAdjustmentVolumed] = useState<number>(-1)
  const [featureInfo, setFeatureInfo] = useState<IFeatureInfo | undefined>()
  const [popoverExpanded, setPopoverExpanded] = useState<boolean>(false)

  const popoverRef = useRef<any>()
  const popoverTriggerRef = useRef<any>()

  const setup = async () => {
    // if its login-as use that orgId for auth else use the selected org by user.
    const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
    const subscriptionVolume = await FlagpolePublic.getSubscriptionVolume(authOrgId!, user.orgId!)
    if (subscriptionVolume) {
      setSubscriptionBalance(subscriptionVolume.balance ?? -1)
      setSubscriptionTotalNames(subscriptionVolume.annualVolume ?? -1)
      setSubscriptionNamesUsed(subscriptionVolume.licensedVolume ?? -1)
      setAdjustmentVolumed(subscriptionVolume.adjustmentVolume ?? -1)
    }
  }

  useEffect(() => {
    setup()
  }, [user])

  useEffect(() => {
    if (popoverTriggerRef.current) {
      // setup mutation observer to listen for changes in popover open status so icon can stay in sync
      const mutationHandler = mutationsList => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
            const isExpanded = mutation.target.getAttribute('aria-expanded') === 'true'
            setPopoverExpanded(isExpanded)
          }
        }
      }
      const mutationObserver = new MutationObserver(mutationHandler)
      mutationObserver.observe(popoverTriggerRef.current, { attributeFilter: ['aria-expanded'] })
      return () => mutationObserver.disconnect()
    }
  }, [popoverTriggerRef.current])

  const handleMenuClickCapture = () => {
    if (popoverRef.current) {
      popoverRef.current.deactivate()
      popoverRef.current.hide()
      focus(null, 'main-content')
    }
  }

  useEffect(() => {
    if (loadingFeatures === false) {
      const fi = getFeatureInfo()
      setFeatureInfo(fi)
    }
  }, [getFeatureInfo, loadingFeatures])

  const getTitle = () => {
    let title = ''
    if (featureInfo && featureInfo.subscriptionType === IAccountSubscriptionType.ACTIVE_SUBSCRIPTION) {
      title =
        'Records Remaining: ' +
        (subscriptionBalance === -1 //this covers requirement for transactional users. looking into featureinfo lifecycle makes code complicated
          ? ''
          : subscriptionTotalNames < UNLIMITED_NAMES //intentionally user totalnames to have consistent defintion of Unlimited in the app
          ? numberFormatter(subscriptionBalance)
          : 'Unlimited') +
        '*'
    } else if (featureInfo && featureInfo.subscriptionType === IAccountSubscriptionType.TRANSACTIONAL) {
      title = 'Records Remaining: 0*'
    } else if (loadingFeatures) {
      title = 'Retrieving Feature Info...'
    } else if (!loadingFeatures && !featureInfo) {
      title = 'Error Retrieving Feature Info'
    }
    return title
  }

  const getSubscriptionContent = () => {
    if (!isTransactional() && featureInfo && featureInfo.featureTierNames && featureInfo.featureTierNames.length > 0) {
      return featureInfo.featureTierNames.join(' + ')
    } else {
      return 'N/A'
    }
  }

  const getALaCarteContent = (): string => {
    if (featureInfo && featureInfo.alacarteFeatureNames && featureInfo.alacarteFeatureNames.length > 0) {
      return featureInfo.alacarteFeatureNames.join(', ')
    } else {
      return 'N/A'
    }
  }

  const getNetTotalNames = (): string => {
    if (isTransactional()) {
      //seperating transactional aspect from other things for clarity
      return 'N/A'
    } else {
      return subscriptionTotalNames === -1 // this covers the requirement for transactional users
        ? 'N/A'
        : subscriptionTotalNames < UNLIMITED_NAMES
        ? numberFormatter(subscriptionTotalNames)
        : 'Unlimited'
    }
  }

  const getNamesUsed = (): string => {
    if (isTransactional()) {
      //seperating transactional aspect from other things for clarity
      return 'N/A'
    } else {
      return subscriptionNamesUsed === -1 || subscriptionNamesUsed === 0 ? 'N/A' : numberFormatter(subscriptionNamesUsed)
    }
  }

  const getAccountAdjustment = (): string => {
    if (isTransactional()) {
      //seperating transactional aspect from other things for clarity
      return 'N/A'
    } else {
      return adjustmentVolume === -1 || adjustmentVolume === 0 ? 'N/A' : numberFormatter(adjustmentVolume)
    }
  }

  const isMyAccountAvailable = () => {
    if (window.location.pathname.lastIndexOf('/purchase') > -1 || user.orgId === user.parentOrgId) {
      return false
    }
    return true
  }

  return (
    <>
      <button
        aria-controls='my-account-popover'
        aria-expanded='false'
        className={isMyAccountAvailable() ? 'popover-trigger' : 'popover-trigger cb-hidden'}
        id='my-account-popover-trigger'
        ref={popoverTriggerRef}
        type='button'
      >
        {getTitle()}
        <span aria-hidden className={`cb-margin-left-8 cb-icon cb-${popoverExpanded ? 'up' : 'down'}`} />
      </button>

      <Popover popoverId='my-account-popover' trigger='my-account-popover-trigger' placement='bottom' cbRef={popoverRef}>
        <>
          {featureInfo && (
            <>
              <div className='cb-gray5-bg'>
                <div className='display-flex justify-content-between'>
                  <span className='cb-font-size-small'>
                    {featureInfo && featureInfo.subscriptionType === IAccountSubscriptionType.ACTIVE_SUBSCRIPTION && (
                      <>
                        Your Subscription through{' '}
                        {featureInfo.endDate
                          ? formatToTimeZone(new Date(featureInfo.endDate), 'MM-DD-YYYY', {
                              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                            })
                          : 'N/A'}
                      </>
                    )}
                  </span>
                </div>
                <div className='display-flex justify-content-between'>
                  <h4 className='cb-h6'>{getTitle()}</h4>
                </div>
              </div>
              <Spacer size='16' />
              <div>
                <span className='cb-font-weight-medium'>Subscription Plan: </span> {getSubscriptionContent()}
              </div>
              <Spacer size='8' />
              <div>
                <span className='cb-font-weight-medium'>A La Carte Features: </span> {getALaCarteContent()}
              </div>
              <Spacer size='8' />
              <div>
                <span className='cb-font-weight-medium'>Net Total Records: </span> {getNetTotalNames()}
              </div>
              <Spacer size='8' />
              <div>
                <span className='cb-font-weight-medium'>Records Used: </span> {getNamesUsed()}
              </div>
              <Spacer size='8' />
              <div>
                <span className='cb-font-weight-medium'>Account Adjustments: </span> {getAccountAdjustment()}
              </div>
              <Spacer size='16' />
              <div>
                <span className='cb-font-size-xsmall'>
                  * This number is an estimate and does not include orders that we are still processing, orders submitted for a
                  future date, nor future runs of Standing Orders.
                </span>
              </div>
              <Spacer size='16' />
            </>
          )}
          <div className='cb-border-top-1 cb-border-solid'>
            <nav
              aria-label='My Account navigation'
              className='cb-menu-list cb-menu-list-vertical cb-menu-list-glyph cb-border-top-2' // should stay glyph
            >
              <ul>
                <li>
                  <Link
                    id='myaccount-popover-plansFeatures'
                    className='cb-menu-link'
                    to={`/my-account/${TABS.PlansFeatures}`}
                    onClickCapture={() => {
                      handleMenuClickCapture()
                    }}
                  >
                    <span> Plans and Features Summary </span>
                    <span className='cb-icon cb-right' aria-hidden='true' />
                  </Link>
                </li>
                <li>
                  <Link
                    id='myaccount-popover-selfService'
                    className='cb-menu-link'
                    to={`/my-account/${TABS.SelfService}`}
                    onClickCapture={() => {
                      handleMenuClickCapture()
                    }}
                  >
                    <span> Add New Subscription </span>
                    <span className='cb-icon cb-right' aria-hidden='true' />
                  </Link>
                </li>
                <li>
                  <Link
                    id='myaccount-popover-savedCriteria'
                    className='cb-menu-link'
                    to={`/my-account/${TABS.SavedCriteria}`}
                    onClickCapture={() => {
                      handleMenuClickCapture()
                    }}
                  >
                    <span> Your Saved Criteria </span>
                    <span className='cb-icon cb-right' aria-hidden='true' />
                  </Link>
                </li>
                <li>
                  <Link
                    id='myaccount-popover-peepGroup'
                    className='cb-menu-link'
                    to={`/my-account/${TABS.PeerGroups}`}
                    onClickCapture={() => {
                      handleMenuClickCapture()
                    }}
                  >
                    <span> Peer Group Selection </span>
                    <span className='cb-icon cb-right' aria-hidden='true' />
                  </Link>
                </li>
                <Permissions roles={[Roles.EPL_SSS_ALPHA]}>
                  <li>
                    <a id='myaccount-popover-yourUsers' className='cb-menu-link' href='https://dat.collegeboard.org/dat/login'>
                      <span> Manage Your Users </span>
                      <span className='cb-icon cb-right' aria-hidden='true' />
                    </a>
                  </li>
                </Permissions>
              </ul>
            </nav>
          </div>
        </>
      </Popover>
    </>
  )
}

export default MyAccountPopover
