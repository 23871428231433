import { ILabeledValue, NakedButton, Notification, Spacer } from '@cb/apricot-react'
import { dateFormatter, FeatureKeys, Roles } from '@msss/ui-common'
import {
  ExpandableTableData,
  ITableColumn,
  PageSize,
  Pagination,
  useSearchUserContext,
  useTableContext
} from '@msss/ui-components'
import { useCallback, useState } from 'react'
import FeatureProvider from '../../../context/FeatureProvider'
import { Permissions } from '../../common/Permissions'
import AddNewCriteria from './AddNewCriteria'
import FilterOptions from './FilterOptions'
import SavedCriteriaDetail from './SavedCriteriaDetail'
import { getCritComp } from './options'
import DeleteCriteriaDetail from './DeleteCriteriaDetail'

const { LEGACY_BASE_URL } = process.env

interface TableProps {
  checkDupPrefName: (name: string) => boolean
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const SavedCriteriaTable = ({ checkDupPrefName, setSuccess }: TableProps) => {
  const [addingNew, setAddingNew] = useState<ILabeledValue | undefined>()
  const { user } = useSearchUserContext()
  const {
    data,
    expandedRow,
    handleExpandedRow,
    handleGotoPage,
    handlePageSize,
    page,
    pageCount,
    pageIndex,
    pageSize,
    rows,
    setFilter
  } = useTableContext()

  const getBtnAriaLabel = useCallback(({ row }) => row.original.name, [])

  const renderCustomFirstRow = useCallback(
    ({ getExpandableTrigger, id }) => (
      <>
        <tr id={`${id}-add-new`}>
          <td>New Save 1</td>
          <td>{addingNew?.label}</td>
          <td>{dateFormatter.format(new Date())}</td>
          <td>
            <NakedButton disabled noPadding>
              Edit
            </NakedButton>
            {' | '}
            <NakedButton disabled noPadding>
              Delete
            </NakedButton>
          </td>
          {getExpandableTrigger({ ariaControls: `${id}-add-new-expanded`, ariaLabel: 'add new saved criteria' })}
        </tr>
        <tr id={`${id}-add-new-expanded`}>
          <td colSpan={5}>
            <div aria-label='add new criteria details' role='region'>
              <SavedCriteriaDetail
                preference={{ categoryGroupCd: addingNew?.value as number }}
                comp={getCritComp(addingNew?.value)}
                setExpandedRow={handleExpandedRow}
                setAddingNew={setAddingNew}
                checkDupPrefName={checkDupPrefName}
                setSuccess={setSuccess}
              />
            </div>
          </td>
        </tr>
      </>
    ),
    [addingNew]
  )

  const renderExpandedRow = useCallback(
    ({ row }) => (
      <td colSpan={5}>
        <div aria-label='saved criteria details' role='region'>
          <SavedCriteriaDetail
            preference={row.original}
            comp={getCritComp(row.original.categoryGroupCd)}
            setExpandedRow={handleExpandedRow}
            setAddingNew={setAddingNew}
            checkDupPrefName={checkDupPrefName}
            setSuccess={setSuccess}
          />
        </div>
      </td>
    ),
    []
  )

  const renderRowCells = useCallback(
    ({ row, rowIndex }) => (
      <>
        <td>{row.original.name}</td>
        <td>{row.original.categoryGroup}</td>
        <td>{row.original.lastModifiedDate}</td>
        <td>
          <NakedButton
            disabled={isExpandedOrAdding(expandedRow, addingNew)}
            noPadding
            onClick={() => handleExpandedRow(rowIndex)}
          >
            Edit
          </NakedButton>
          {' | '}
          <DeleteCriteriaDetail
            criteriaId={row.original.criteriaId}
            name={row.original.name}
            type={row.original.categoryGroup}
            disabled={isExpandedOrAdding(expandedRow, addingNew)}
          />
        </td>
      </>
    ),
    [addingNew, expandedRow]
  )

  return (
    <>
      <div aria-label='add new criteria & filters' className='row' role='region'>
        <div className='col-sm-2 cb-margin-bottom-48'>
          <AddNewCriteria
            addingNew={addingNew}
            setAddingNew={setAddingNew}
            // if row is expanded, i assume it is being edit mode.  we want to disable the add new dropdown
            disabled={isExpandedOrAdding(expandedRow, addingNew)}
          />
        </div>
        <div className='col-sm-7 cb-margin-bottom-48'>
          <FilterOptions setFilter={setFilter} />
        </div>
        <div className='col-sm-3 cb-margin-bottom-48 cb-align-right'>
          <FeatureProvider featureKey={FeatureKeys.EPS.key}>
            <Permissions roles={Roles.EPS}>
              <a href={`${LEGACY_BASE_URL}/my-account.htm?tab=preferences&orgId=${user.orgId}`} rel='noopener noreferrer'>
                View Competitors on Classic Site
              </a>
            </Permissions>
          </FeatureProvider>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-4' role='status'>
          {rows.length < data.length && (
            <>
              <span className='cb-font-weight-bold'>{rows.length}</span> {rows.length === 1 ? 'Result' : 'Results'}
            </>
          )}
        </div>
        <div className='col-sm-8 cb-align-right'>
          <PageSize pageSize={pageSize} handlePageSize={handlePageSize} />
          <span className='cb-padding-8'>
            ({data.length} {data.length === 1 ? 'Record' : 'Records'})
          </span>
        </div>

        <div className='col-xs-12'>
          <ExpandableTableData
            disableIconOnExpanded
            getBtnAriaLabel={getBtnAriaLabel}
            id='msof-saved-criteria'
            renderCustomFirstRow={renderCustomFirstRow}
            renderExpandedRow={renderExpandedRow}
            renderRowCells={renderRowCells}
            showCustomFirstRow={Boolean(addingNew)}
          />

          {rows.length === 0 && data.length === 0 && !addingNew && (
            <Notification className='cb-gray5-bg' color={undefined} icon='exclamation' title='No Criteria Found'>
              <p>No saved criteria found. Start adding a saved criteria to get started.</p>
            </Notification>
          )}

          {rows.length === 0 && data.length !== 0 && (
            <Notification className='cb-gray5-bg' color={undefined} icon='exclamation' title='No Results Found'>
              <p>To try again, remove any of the selected filters.</p>
            </Notification>
          )}
        </div>
      </div>

      {page.length < data.length && (
        <div className='row'>
          <div className='col-xs-12 cb-align-center cb-margin-top-24'>
            <PageSize pageSize={pageSize} handlePageSize={handlePageSize} />
            <Spacer size='16' />
            <Pagination gotoPage={handleGotoPage} id='msof-saved-criteria' pageCount={pageCount} pageIndex={pageIndex} />
          </div>
        </div>
      )}
    </>
  )
}

export default SavedCriteriaTable

const isExpandedOrAdding = (expandedRow: number, addingNew: ILabeledValue | undefined) => expandedRow !== -1 || Boolean(addingNew)
