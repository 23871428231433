import React from 'react'

interface ViewScoreBandProps {
  handleEditScoreLink: (event: any) => void
  lowScore: number
  highScore: number
}

export const ViewScoreBand = ({ handleEditScoreLink, lowScore, highScore }: ViewScoreBandProps) => {
  const scoreBandLabel = 'Score band ' + lowScore + ' - ' + highScore
  return (
    <>
      <div className='display-flex'>
        <p className='cb-font-weight-bold cb-margin-bottom-8 cb-margin-right-8'>
          {scoreBandLabel}{' '}
          <a href='#' onClick={handleEditScoreLink}>
            Edit Score
          </a>
        </p>
      </div>
    </>
  )
}
