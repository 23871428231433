import '../../geography/geography.scss'
import { ensure } from '@msss/ui-common'
import { useEffect, useState } from 'react'
import { psatRadioOptions } from './CriteriaSelectionOptions'
import { IExamCriteria } from '../IExamCriteria'
import { Select, Slider } from '@cb/apricot-react'
import NoExamsRange from '../NoExamsRange'

type SatProps = {
  psatExamsCriteria: IExamCriteria[]
  addPsatExamCriteria: (lowScore: number, highScore: number, exam: string) => void
  psatOption: string
  handlePsatOption: (value: string) => void
}

const PSAT = (props: SatProps): JSX.Element => {
  const { psatExamsCriteria, addPsatExamCriteria, psatOption, handlePsatOption } = props

  const [psatScoreLow, setPsatScoreLow] = useState<number>(320)
  const [psatScoreHigh, setPsatScoreHigh] = useState<number>(1520)
  const [psatExam, setPsatExam] = useState<string>('none')
  const [applyDisabled, setApplyDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (psatOption === 'PSAT') {
      psatRadioOptions.map(option => {
        if (option.value !== psatOption) option.disabled = true
      })
    } else {
      psatRadioOptions.map(option => {
        option.disabled = false
        psatExamsCriteria.map(criteria => {
          if (criteria.exam === option.value) {
            option.disabled = true
          }
        })
      })
    }
    if (psatOption && psatOption !== 'none' && psatOption !== 'PSAT') {
      setApplyDisabled(false)
    } else {
      setApplyDisabled(true)
    }
    setPsatExam(psatExam)
    const psatRangeOptions = ensure(psatRadioOptions.find(item => item.value === psatOption)).rangeOptions
    setPsatScoreLow(prevPsatScoreLow => (prevPsatScoreLow = psatRangeOptions.min))
    setPsatScoreHigh(prevPsatScoreHigh => (prevPsatScoreHigh = psatRangeOptions.max))
  }, [psatOption, psatExamsCriteria])

  const handleScoreRange = (value: string[]) => {
    setPsatScoreLow(parseInt(value[0]))
    setPsatScoreHigh(parseInt(value[1]))
  }

  const handlePsatExam = (value: string) => {
    setPsatExam(value)
    handlePsatOption(value)
  }

  const reset = () => {
    handlePsatOption('none')
    const psatRangeOptions = ensure(psatRadioOptions.find(item => item.value === psatOption)).rangeOptions
    setPsatScoreLow(prevPsatScoreLow => (prevPsatScoreLow = psatRangeOptions.min))
    setPsatScoreHigh(prevPsatScoreHigh => (prevPsatScoreHigh = psatRangeOptions.max))
  }

  const add = () => {
    addPsatExamCriteria(psatScoreLow, psatScoreHigh, psatOption)
    reset()
  }

  const selectedPsatExams = () => {
    const newPsatExamsCriteria = [...psatExamsCriteria]
    return newPsatExamsCriteria.reduce((acc: string[], current) => acc.concat(current.exam), [])
  }
  //console.log("=====", {selectionValue})
  return (
    <>
      <div className='cb-margin-left-8'>
        {/* List of PSAT Exams Row */}
        <div className='row'>
          <div className='col-xs-4'>
            <Select
              ariaLabel='Select a PSAT exam'
              css=''
              name='psatOption'
              onChange={handlePsatOption}
              selectId='cb-exams-psat-options'
              value={psatOption}
              values={psatRadioOptions}
            />
            {/* <Dropdown closeOnClick className="ms-psat" title={selectionValue}>
              {psatRadioOptions.map((option, index) => {
                // if any PSAT test taker is selected, then other exams should be disabled
                // otherwise just disable any tests that have been added to criteria
                const disabled = psatOption === 'PSAT' ? true : selectedPsatExams().includes(option.value)
                return (
                  <DropdownItem
                    key={option.value}
                    disabled={disabled}
                    divider={undefined}
                    dropdownItemId={`id${index}psat`}
                    icon={disabled ? 'check' : undefined}
                    label={option.label}
                    onClick={() => {
                      setSelectionValue(option.label)
                      handlePsatExam(option.value)
                    }}
                  />
                )
              })}
            </Dropdown> */}
          </div>
          {psatOption == 'none' || psatOption == 'PSAT' ? (
            <NoExamsRange />
          ) : (
            <div className='col-xs-8'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='cb-margin-top-48 cb-margin-right-8 cb-margin-bottom-32 cb-margin-left-8'>
                    {psatOption === 'psatRM' && (
                      <Slider
                        format={{ to: (value: number) => value.toFixed(0), from: (value: string) => parseInt(value) }}
                        label={['lowest PSAT score', 'highest PSAT score']}
                        margin={50}
                        onChange={handleScoreRange}
                        pips={{
                          mode: 'positions',
                          values: [0, 100],
                          density: 4
                        }}
                        range={{ max: 1520, min: 320 }}
                        start={[psatScoreLow, psatScoreHigh]}
                        step={10}
                        tooltips={{ sticky: true }}
                      />
                    )}
                    {(psatOption === 'psatR' || psatOption === 'psatM') && (
                      <Slider
                        format={{ to: (value: number) => value.toFixed(0), from: (value: string) => parseInt(value) }}
                        label={['lowest PSAT score', 'highest PSAT score']}
                        margin={50}
                        onChange={handleScoreRange}
                        pips={{
                          mode: 'positions',
                          values: [0, 100],
                          density: 4
                        }}
                        range={{ max: 760, min: 160 }}
                        start={[psatScoreLow, psatScoreHigh]}
                        step={10}
                        tooltips={{ sticky: true }}
                      />
                    )}
                    {(psatOption === 'psatRT' || psatOption === 'psatMT' || psatOption === 'psatWT') && (
                      <Slider
                        format={{ to: (value: number) => value.toFixed(0), from: (value: string) => parseInt(value) }}
                        label={['lowest PSAT score', 'highest PSAT score']}
                        margin={5}
                        onChange={handleScoreRange}
                        pips={{
                          mode: 'positions',
                          values: [0, 100],
                          density: 4
                        }}
                        range={{ max: 38, min: 8 }}
                        start={[psatScoreLow, psatScoreHigh]}
                        step={1}
                        tooltips={{ sticky: true }}
                      />
                    )}
                  </div>
                </div>
                <div className='col-xs-12 cb-align-right'>
                  <button
                    className='cb-btn cb-btn-primary cb-padding-left-32 cb-padding-right-32 ms-applynow'
                    disabled={applyDisabled}
                    id='apply-psat-btn'
                    onClick={add}
                    type='button'
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PSAT
