import React, { useEffect, useState } from 'react'
import { EditScoreBand } from './EditScoreBand'
import { ViewScoreBand } from './ViewScoreBand'

interface ScoreBandProps {
  lowScore: number
  highScore: number
  editScore: (prevLow: number, prevHigh: number, newLow: number, newHigh: number, exam: string) => void
  minScore: number
  maxScore: number
  minRange: number
  step: number
  exam: string
}

export const ScoreBand = ({ lowScore, highScore, editScore, minScore, maxScore, minRange, step, exam }: ScoreBandProps) => {
  const [isEditOpen, setIsEditOpen] = useState(false)

  const handleEditScoreLink = (e: any) => {
    e.preventDefault()
    setIsEditOpen(true)
  }

  const handleEditScore = (newLowScore: number, newHighScore: number) => {
    editScore(lowScore, highScore, newLowScore, newHighScore, exam)
    setIsEditOpen(false)
  }

  const handleCloseEditScore = (e: any) => {
    e.preventDefault()
    setIsEditOpen(false)
  }

  useEffect(() => {}, [isEditOpen])

  const scoreBandLabel = 'Score band ' + lowScore + ' - ' + highScore
  return (
    <>
      {isEditOpen ? (
        <EditScoreBand
          handleEditScore={handleEditScore}
          handleCloseEditScore={handleCloseEditScore}
          lowScore={lowScore}
          highScore={highScore}
          minScore={minScore}
          maxScore={maxScore}
          minRange={minRange}
          step={step}
          exam={exam}
        />
      ) : (
        <ViewScoreBand handleEditScoreLink={handleEditScoreLink} lowScore={lowScore} highScore={highScore} />
      )}
    </>
  )
}
