export enum ACTIONS {
  FULL_RESET = 'FULL_RESET',
  RESET_EXCEPT_INPUT = 'RESET_EXCEPT_INPUT',
  SET_DESECENDENT = 'SET_DESECENDENT',
  SET_FOCUS_POSITION = 'SET_FOCUS_POSITION',
  ON_FOCUS = 'ON_FOCUS',
  CLEAR = 'CLEAR',
  CUSTOM = 'CUSTOM',
  SELECT_MULTISELECT_OPTION = 'SELECT_MULTISELECT_OPTION'
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FULL_RESET: {
      return {
        ...state,
        value: '',
        keyBoardNavigationPosition: -1,
        showMenu: false,
        activeDescendant: null
      }
    }
    case ACTIONS.RESET_EXCEPT_INPUT: {
      return {
        ...state,
        keyBoardNavigationPosition: -1,
        showMenu: action.showMenu ?? false,
        activeDescendant: null
      }
    }
    case ACTIONS.SET_DESECENDENT: {
      return {
        ...state,
        activeDescendant: action.activeDescendant
      }
    }
    case ACTIONS.SET_FOCUS_POSITION: {
      return {
        ...state,
        keyBoardNavigationPosition: action.keyBoardNavigationPosition,
        value: action.value
      }
    }
    case ACTIONS.ON_FOCUS: {
      return {
        ...state,
        keyBoardNavigationPosition: -1,
        showMenu: action.showMenu,
        activeDescendant: null
      }
    }
    case ACTIONS.SELECT_MULTISELECT_OPTION: {
      return {
        ...state,
        keyBoardNavigationPosition: -1,
        showMenu: true,
        activeDescendant: null,
        value: ''
      }
    }
    case ACTIONS.CUSTOM: {
      const AD = action.activeDescendant
      return {
        ...state,
        value: action.value ?? state.value,
        keyBoardNavigationPosition: action.keyBoardNavigationPosition ?? state.keyBoardNavigationPosition,
        showMenu: action.showMenu ?? state.showMenu,
        activeDescendant: AD === null || typeof AD === 'string' ? AD : state.activeDescendant
      }
    }
    default: {
      return state
    }
  }
}
