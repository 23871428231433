import { TooltipHeader } from '@msss/ui-components'
import MosfActions from './Actions'
import SearchOrderDetails from './SearchOrderDetails'

export interface ViewSavedSearchDetailsProps {
  savedSearchId: number
  accordionSavedSearchId: number
  orderId?: number
  status?: string
  ownerUserId?: number
  startDate?: string
  namesDesired?: number
  namesDelivered?: number
  folderId?: number
}

export const ViewSavedSearchDetails = ({
  savedSearchId,
  accordionSavedSearchId,
  orderId,
  status,
  ownerUserId,
  namesDesired,
  namesDelivered,
  startDate,
  folderId
}: ViewSavedSearchDetailsProps) => {
  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <TooltipHeader header='I would like to' id='msof-accordion' tooltipMsg='Action options listed here' />
        </div>
        <MosfActions
          savedSearchId={savedSearchId}
          accordionSavedSearchId={accordionSavedSearchId}
          orderId={orderId}
          status={status}
          ownerUserId={ownerUserId}
          namesDesired={namesDesired}
          namesDelivered={namesDelivered}
          startDate={startDate}
          folderId={folderId}
        />
      </div>
      <div className='row'>
        {/* View Order Details */}
        <div className='col-sm-12 cb-margin-top-8'>
          <SearchOrderDetails savedSearchId={savedSearchId} accordionSavedSearchId={accordionSavedSearchId} />
        </div>
      </div>
    </>
  )
}

export default ViewSavedSearchDetails
