import gql from 'graphql-tag'

export const ASSOCIATION_QUERY = gql`
  query ($userName: String!) {
    getUser(username: $userName) {
      userId
      email
      username
      firstName
      lastName
      associations {
        department {
          orgName
          orgId
        }
        organization {
          orgId
          orgName
          diCode
        }
        role
      }
    }
  }
`
export const GET_ORG_ADDITIONAL_RECIPEINTS_QUERY = gql`
  query ($orgId: Int!) {
    getAdditionalRecipients(orgId: $orgId) {
      username
      firstName
      lastName
      email
      userId
      username
    }
  }
`

export const GET_SAVED_SEARCH_QUERY = gql`
  query ($savedSearchId: Int!) {
    getSavedSearch(savedSearchId: $savedSearchId) {
      namesAvailable
      orgId
      name
      savedSearchId
      description
      uploadFileCount
      uploadFileName
      criteria {
        criteriaId
        userData
        criteria
        excludedCriteria
      }
      order {
        orderId
        savedSearchId
        additionalNamesProjected
        billingType
        endDate
        endSchedule
        fileFormat
        namesAvailable
        namesDesired
        notificationFrequency
        oneTimeRun
        orderStatus
        orderType
        poNumber
        printFormat
        sasOrder
        originalCost
        searchYear
        sortSequence
        startDate
        startSchedule
        submitDate
        recipients {
          email
          userId
          username
        }
      }
    }
  }
`

export const GET_SAVED_SEARCH_ORDER_QUERY = gql`
  query ($savedSearchId: Int!) {
    getSavedSearch(savedSearchId: $savedSearchId) {
      namesAvailable
      orgId
      name
      savedSearchId
      description
      uploadFileCount
      uploadFileName
      order {
        orderId
        savedSearchId
        additionalNamesProjected
        billingType
        endDate
        endSchedule
        fileFormat
        recipients {
          email
          userId
        }
        namesAvailable
        namesDesired
        notificationFrequency
        oneTimeRun
        orderStatus
        orderType
        poNumber
        printFormat
        sasOrder
        originalCost
        searchYear
        sortSequence
        startDate
        startSchedule
        submitDate
      }
    }
  }
`

export const GET_DEPARTMENT_QUERY = gql`
  query ($orgId: Int!) {
    getDepartment(orgId: $orgId) {
      billingContactInfo {
        city
        cityPrefix
        citySuffix
        contactId
        country
        departmentName
        ecommAddressId
        ecommContactId
        email
        faxNumber
        firstName
        jobTitle
        orgName
        lastName
        phoneExtension
        phoneNumber
        postalCode
        prefix
        region
        state
        streetAddress1
        streetAddress2
        streetAddress3
        suffix
        title
        zip4
        zip5
      }
      contactInfo {
        contactId
        departmentName
        city
        firstName
        lastName
        country
        state
        streetAddress1
        streetAddress2
        streetAddress3
        zip5
        zip4
      }
    }
  }
`

export const GET_PORTFOLIO_QUERY = gql`
  query ($orgId: Int!) {
    getPortfolio(orgId: $orgId) {
      orgId
      items {
        savedSearchId
        name
        lastModifiedDate
        itemType
        createDate
        owner
        ownerUserId
        topSearch
        namesAvailable
        namesDelivered
        namesDesired
        totalAmount
        oneTimeRun
        orderId
        orderStatus
        orderType
        redesign
        startDate
        submitDate
        downloadsPending
        folderId
        folderName
        outputFiles {
          fileId
        }
      }
    }
  }
`

export const GET_PORTFOLIO_BY_CATEGORY_QUERY = gql`
  query ($orgId: Int!, $category: PortfolioFileType) {
    getPortfolio(orgId: $orgId, category: $category) {
      orgId
      items {
        savedSearchId
        name
        description
        itemType
        namesAvailable
        namesDelivered
        totalAmount
        namesDesired
        owner
        ownerUserId
        orderId
        orderStatus
        orderType
        downloadsPending
        folderId
        folderName
        createDate
        submitDate
        submittedBy
        lastModifiedDate
        status
        redesign
      }
    }
  }
`

export const GET_SEARCH_ORDER_DETAILS_QUERY = gql`
  query ($savedSearchId: Int!) {
    getPortfolioItem(savedSearchId: $savedSearchId) {
      savedSearchId
      name
      description
      lastModifiedDate
      itemType
      createDate
      owner
      ownerUserId
      topSearch
      namesAvailable
      namesDelivered
      namesDesired
      uploadFileCount
      uploadFileName
      totalAmount
      oneTimeRun
      orderId
      status
      orderOwner
      orderType
      startDate
      startSchedule
      endDate
      endSchedule
      sortSequence
      printFormat
      recipients {
        email
        userId
      }
      fileFormat
      billingType
      poNumber
      notificationFrequency
      submitDate
      downloadsPending
      totalRuns
      folderId
      folderName
      orderRuns {
        orderRun
        fileId
        fileName
        processDate
        namesReported
        totalAmount
      }
      outputFiles {
        fileId
        fileName
        accessList {
          userId
          primaryUser
        }
        createDate
        releaseDate
        expirationDate
        downloads {
          username
          downloadDate
        }
      }
    }
  }
`

export const GET_CRITERIA_PREF = gql`
  query ($criteriaId: Int!, $orgId: Int!, $createdBy: Int!) {
    getCriteriaPreference(criteriaId: $criteriaId, orgId: $orgId, createdBy: $createdBy) {
      criteriaId
      criteria
      userData
      excludedCriteria
    }
  }
`

export const GET_CRITERIA_PREFS = gql`
  query ($orgId: Int!) {
    getCriteriaPreferences(orgId: $orgId) {
      criteriaId
      uploadFileName
      uploadFileCount
      createdDate
      createdBy
      lastModifiedDate
      lastModifiedBy
      name
      categoryGroup
      categoryGroupCd
    }
  }
`

export const GET_USER_UI_PREFERENCES = gql`
  query ($userId: Int!, $orgId: Int!) {
    getUserPreferences(userId: $userId, orgId: $orgId) {
      orgId
      preferences {
        createdDate
        description
        lastModifiedDate
        name
        preferenceId
        value
      }
    }
  }
`

export const GET_ORDER_QUERY = gql`
  query ($orderId: Int!) {
    getOrder(orderId: $orderId) {
      submittedBy {
        firstName
        lastName
        email
      }
      billingType
      fileFormat
      namesAvailable
      namesDesired
      notificationFrequency
      oneTimeRun
      orderId
      orderStatus
      orderType
      poNumber
      printFormat
      recipients {
        email
        firstName
        lastName
        userId
      }
      savedSearchId
      sortSequence
      startDate
    }
  }
`

export const GET_DOWNLOAD_URL_QUERY = gql`
  query ($fileId: Int!) {
    getDownloadUrl(fileId: $fileId)
  }
`

export const GET_LIVING_RECORD_FILES = gql`
  query ($orgId: Int!) {
    getLivingRecordFiles(orgId: $orgId) {
      createDate
      downloaded
      expirationDate
      fileId
      fileName
      numRecords
      releaseDate
      downloads {
        downloadDate
        username
      }
      accessList {
        primaryUser
        userId
      }
    }
  }
`

export const GET_OTHER_FILES = gql`
  query ($orgId: Int!, $category: PortfolioFileType, $userId: Int) {
    getPortfolio(orgId: $orgId, category: $category, userId: $userId) {
      orgId
      items {
        fileId
        outputFiles {
          releaseDate
          fileName
          fileId
          expirationDate
          downloads {
            downloadDate
            username
          }
          accessList {
            primaryUser
            userId
          }
          createDate
          downloaded
        }
      }
    }
  }
`

export const GET_BULK_DOWNLOAD_FILES = gql`
  query ($orgId: Int!, $category: PortfolioFileType, $userId: Int) {
    getPortfolio(orgId: $orgId, category: $category, userId: $userId) {
      orgId
      items {
        fileId
        name
        outputFiles {
          fileId
          fileName
          createDate
          downloaded
          orgName
          diCode
          numRecords
          downloads {
            downloadDate
            username
          }
        }
      }
    }
  }
`

export const GET_PEER_GROUPS = gql`
  query ($orgId: Int!) {
    getPeerGroups(orgId: $orgId) {
      peerGroupId
      sequence
      name
      peers {
        orgId
        orgName
      }
      searchYear
    }
  }
`

export const GET_DEPARTMENTS_OF_ORG_QUERY = gql`
  query ($orgId: Int!) {
    getDepartments(orgId: $orgId) {
      orgId
      orgName
      orgStatus
    }
  }
`

export const GET_FOLDERS_OF_ORG = gql`
  query ($orgId: Int!) {
    getFolders(orgId: $orgId) {
      folderId
      name
    }
  }
`

export const QUOTES_OF_ORG = gql`
  query ($orgId: Int!) {
    getQuotes(orgId: $orgId) {
      quoteId
      name
      price
      searchYear
      endDate
      startDate
      totalRecords
      taxAmount
      subscriptionPurchaseId
    }
  }
`

export const GET_QUOTE_QUERY = gql`
  query ($quoteId: Int!) {
    getQuote(quoteId: $quoteId) {
      quoteId
      name
      lastModifiedBy
      orgId
      price
      searchYear
      taxAmount
      featureTierItems {
        count
        endDate
        startDate
        featureTierId
      }
    }
  }
`

export const GET_SUBSCRIPTIONS_QUERY = gql`
  query ($subscriberId: Int!) {
    getSubscriptions(subscriberId: $subscriberId) {
      subscriptionId
      featureTier {
        featureTierId
        name
        annualVolume
        price
        stackable
        features {
          featureId
          variablePercent
          key
          name
        }
      }
      startDate
      endDate
      searchYear
      annualVolume
      status
      alacarte
    }
  }
`
