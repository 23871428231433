import axios, { AxiosError } from 'axios'
const cb = (window as any).cb

export const FlagpoleApi = axios.create({
  baseURL: process.env['FLAGPOLE_PUBLIC_API_ENDPOINT']
})

export const InternationApi = axios.create({
  baseURL: process.env['REFERENCE_URL']
})
//"https://reference-assets-dev.msss-nonprod.collegeboard.org/x-refs/InternationalRegion/1.json"
export const getHeaders = () => ({
  Accept: 'application/json',
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json',
  'X-CB-Catapult-Authentication-Token': 'CBLogin ' + cb.core.iam.getAuthSession().sessionId,
  'X-CB-Catapult-Authorization-Token': cb.core.iam.getJWTToken()
})
