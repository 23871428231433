import CBLocalNavigation from '@cb/apricot/CBLocalNavigation'
import { Icon } from '@cb/apricot-react'
import { FeatureKeys, Roles } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Permissions } from './common/Permissions'
import { FeatureAccessContext } from '../context/FeatureAccessProvider'
import FeatureProvider from '../context/FeatureProvider'

const { LEGACY_BASE_URL } = process.env

type NavBarProps = {
  selected: string
}

const NavBar = ({ selected }: NavBarProps): JSX.Element => {
  const { loadingFeatures, isLoaded } = useContext(FeatureAccessContext)
  const { user } = useSearchUserContext()

  useEffect(() => {
    if (!loadingFeatures && isLoaded()) {
      let destroy: () => void
      setTimeout(() => {
        destroy = CBLocalNavigation({
          elem: document.querySelector('.cb-local-navigation'),
          mobileInteractionClose: true,
          mobileMainLabel: 'College Board Search Features and Tools',
          sticky: false
        }).destroy
      }, 750)
      return () => {
        // for some reason, destroy crashes when in login as
        // it is mainly needed to reset the local nav in case there are changes in the
        // number of items displayed - this will never be the case with login as; so allow
        // it to gracefully fail
        try {
          destroy?.()
        } catch (err) {
          console.log('could not destroy local navigation - failing gracefully')
        }
      }
    }
  }, [loadingFeatures])

  return (
    <>
      <div className='cb-local-navigation cb-white-bg cb-box-shadow-bottom cb-margin-bottom-8' data-cb-apricot='local-navigation'>
        <div className='cb-desktop-navigation container'>
          <div className='row'>
            <div className='col-xs cb-nav-container'>
              <div className='cb-nav-items hidden-xs-only'>
                <nav
                  aria-label='College Board Search Features and Tools'
                  className='cb-menu-list cb-menu-list-horizontal'
                  id='local-nav-bar'
                >
                  <ul style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <li className='cb-nav-link'>
                      <Link
                        aria-current={selected === 'landing' ? 'page' : undefined}
                        className={`cb-menu-link${selected === 'landing' ? ' cb-selected' : ''}`}
                        data-cb-link='true'
                        id='landing-nav-link'
                        to='/'
                      >
                        <Icon
                          name='home'
                          decorative
                          className='cb-margin-right-8'
                          style={{ position: 'relative', top: '-3px' }}
                        />
                        <span>Home</span>
                      </Link>
                    </li>

                    <Permissions roles={Roles.SEARCH_AND_ADMIN}>
                      <li className='cb-nav-link'>
                        <Link
                          aria-current={selected === 'prospect-identifier' ? 'page' : undefined}
                          className={`cb-menu-link${selected === 'prospect-identifier' ? ' cb-selected' : ''}`}
                          data-cb-link='true'
                          id='pi-nav-link'
                          to='/pi'
                        >
                          <span>New Matches</span>
                        </Link>
                      </li>
                    </Permissions>

                    <li className='cb-nav-link'>
                      <Link
                        aria-current={selected === 'msof' ? 'page' : undefined}
                        className={`cb-menu-link${selected === 'msof' ? ' cb-selected' : ''}`}
                        data-cb-link='true'
                        id='msof-nav-link'
                        to='/my-searches'
                      >
                        <span>My Searches, Orders & Files</span>
                      </Link>
                    </li>

                    <Permissions roles={Roles.SEARCH_AND_ADMIN}>
                      <li className='cb-nav-link'>
                        <Link
                          aria-current={selected === 'search-students' ? 'page' : undefined}
                          className={`cb-menu-link${selected === 'search-students' ? ' cb-selected' : ''}`}
                          data-cb-link='true'
                          id='search-students-nav-link'
                          to='/search-students'
                        >
                          <span>Search for Students</span>
                        </Link>
                      </li>
                    </Permissions>

                    <FeatureProvider featureKey={FeatureKeys.EPS.key} showLoader={false}>
                      <Permissions roles={Roles.EPS}>
                        <li className='cb-nav-link'>
                          <div className='cb-dropdown'>
                            {/* cb-selected gets placed on this anchor when a nested page is selected */}
                            <a className='cb-dropdown-toggle' href='#' id='eps-nav-dropdown-toggle' aria-expanded='false'>
                              <span>Enrollment Planning Service</span>
                              <Icon name='down' decorative />
                              <Icon name='up' decorative className='cb-hidden' />
                            </a>
                            <div className='cb-dropdown-menu cb-menu-right-always cb-box-shadow'>
                              <ul aria-labelledby='eps-nav-dropdown-toggle'>
                                <li className='cb-nav-link-second'>
                                  <a
                                    rel='noopener noreferrer'
                                    id='plan-travel-nav-link'
                                    href={`${LEGACY_BASE_URL}/plan-travel/home.htm?activity=5&orgId=${user.orgId}`}
                                  >
                                    <span>Plan Travel</span>
                                  </a>
                                </li>

                                <li className='cb-nav-link-second'>
                                  <a
                                    rel='noopener noreferrer'
                                    id='research-hs-nav-link'
                                    href={`${LEGACY_BASE_URL}/rsrch-schools/home.htm?activity=3&orgId=${user.orgId}`}
                                  >
                                    <span>Research HS</span>
                                  </a>
                                </li>

                                <li className='cb-nav-link-second'>
                                  <a
                                    rel='noopener noreferrer'
                                    id='competitive-analysis-nav-link'
                                    href={`${LEGACY_BASE_URL}/comp-analysis/home.htm?activity=4&orgId=${user.orgId}`}
                                  >
                                    <span>Competitive Analysis</span>
                                  </a>
                                </li>

                                <li className='cb-nav-link-second'>
                                  <a
                                    rel='noopener noreferrer'
                                    id='summary-reports-nav-link'
                                    href={`${LEGACY_BASE_URL}/summary-report/summary-report-home.htm?activity=4&orgId=${user.orgId}`}
                                  >
                                    <span>Summary Reports</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </Permissions>
                    </FeatureProvider>

                    <FeatureProvider featureKey={FeatureKeys.SAS.key} showLoader={false}>
                      <li className='cb-nav-link'>
                        <Link
                          aria-current={selected === 'sas' ? 'page' : undefined}
                          className={`cb-menu-link${selected === 'sas' ? ' cb-selected' : ''}`}
                          data-cb-link='true'
                          id='sas-nav-link'
                          to='/segment-analysis'
                        >
                          <span>Segment Analysis Service</span>
                        </Link>
                      </li>
                    </FeatureProvider>
                  </ul>
                </nav>
              </div>

              <div className='cb-mobile-trigger hidden-sm-up'>
                <a
                  href='#'
                  className='cb-icon cb-menu cb-no-text-decoration cb-link-black'
                  aria-expanded='false'
                  tabIndex={-1}
                  aria-hidden='true'
                >
                  <span className='sr-only'>Display Site Navigation</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='cb-mobile-navigation' />
      </div>
    </>
  )
}

export default NavBar
