// ###
// Search form Constants
// ###

export const UNTITLED_SEARCH_NAME = 'Untitled Search'

export const INCLUDE_ALL_STUDENTS = 'Include all students'

export const HOME_SCHOOLED = 'Include only home schooled students'

export const ROTC_HISTORY_LABEL = 'Participated in JROTC during high school'

// ### Criteria
export const COHORT_YEAR = 'cohortYear'
export const CUSTOMER_ORGS = 'customerOrgs'
export const IMC = 'engageOption'
export const EMAIL_PREF = 'emailPref'
export const COLLEGE_PREF = 'degree'

// ### Section: Grad Year
export const INCLUDE_ONLY = 'includeOnly'
export const INCLUDE_ALL = 'includeAll'

export const IMC_INCLUDE = 'M'

// ### Saved Search Attributes
export const ACTIVE_STATUS = 1
export const PORTFOLIO_ITEM_PURCHASE = 1
export const PORTFOLIO_ACCESS_PUBLIC = 1
export const ACTIVITY_PURCHASE = 1
