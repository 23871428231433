import { useMutation, useQuery } from '@apollo/client'
import { Modal, PrimaryButton, NakedButton, Spacer, ILabeledValue, Spinner, InfoNotification, Input } from '@cb/apricot-react'
import React, { useCallback, useEffect, useState } from 'react'
import { CREATE_FOLDER, DELETE_FOLDERS } from '../../../../services/graphql/mutations'
import { GET_FOLDERS_OF_ORG, GET_PORTFOLIO_BY_CATEGORY_QUERY } from '../../../../services/graphql/queries'
import { ITextInput } from '../../../common/ITextInput'
import { IFolder } from './IFolder'
import { inputStringValidation } from './FolderNameInput'
import { FolderName } from './FolderCard'
import { useSearchUserContext } from '@msss/ui-components'
import { IPortfolioItem } from '../IPortfolioItem'
import { IPortfolioFileType } from '../../../../interfaces/reference/IReference'

interface IManageFoldersModal {
  open: boolean
  setOpen: (value: boolean) => void
  foldersData: IPortfolioItem[] | undefined
  category: IPortfolioFileType
}

// Modal used to manage folders
export const ManageFoldersModal = ({ open, setOpen, foldersData, category }: IManageFoldersModal) => {
  const { user } = useSearchUserContext()

  const initTextInput = (): ITextInput => {
    return {
      name: '',
      validationMsg: '',
      validationType: undefined
    }
  }

  const [folders, setFolders] = useState<ILabeledValue[]>([])
  // list of folders that have searches
  const [foldersWithCount, setFoldersWithCount] = useState<IFolder[] | undefined>(undefined)

  // on click of delete, we will show a confirmation message if this is populated
  const [deleteFolder, setDeleteFolder] = useState<IFolder | undefined>(undefined)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)

  // edit related states
  const [editFolder, setEditFolder] = useState<IFolder | undefined>(undefined)
  // the folder name
  const [folderName, setFolderName] = useState<ITextInput>(initTextInput)
  const [editSuccess, setEditSuccess] = useState<boolean>(false)

  // Appsync - mutation and query
  // We will use this to check for duplicates
  const { loading, error, data } = useQuery(GET_FOLDERS_OF_ORG, {
    variables: { orgId: user.orgId }
  })

  const [deleteFolders, deleteMutationResult] = useMutation(DELETE_FOLDERS, {
    refetchQueries: [
      { query: GET_FOLDERS_OF_ORG, variables: { orgId: user.orgId } },
      { query: GET_PORTFOLIO_BY_CATEGORY_QUERY, variables: { orgId: user.orgId, category: category } }
    ]
  })

  const [saveFolder, editMutationResult] = useMutation(CREATE_FOLDER, {
    refetchQueries: [
      { query: GET_FOLDERS_OF_ORG, variables: { orgId: user.orgId } },
      { query: GET_PORTFOLIO_BY_CATEGORY_QUERY, variables: { orgId: user.orgId, category: category } }
    ]
  })

  // use folders data to create an array for
  useEffect(() => {
    if (!loading && !error) {
      const folders: ILabeledValue[] = []
      if (data?.getFolders) {
        data.getFolders.map(folder => folders.push({ label: folder.name, value: folder.folderId }))
        setFolders(folders)
      } else {
        setFolders([])
      }
    }
  }, [data, loading, error])

  useEffect(() => {
    if (deleteMutationResult?.data) {
      deleteMutationResult.reset()
      setDeleteSuccess(true)
    }
  }, [deleteMutationResult.data])

  useEffect(() => {
    setDeleteSuccess(false)
    setEditSuccess(false)
  }, [deleteFolder, editFolder])

  useEffect(() => {
    if (editMutationResult?.data) {
      editMutationResult.reset()
      setEditSuccess(true)
    }
  }, [editMutationResult.data])

  // this populates the foldersWithCount state using the actuals searches and orders data
  useEffect(() => {
    // update the folder's searches/orders count
    if (foldersData) {
      const foldersMapWithCount = foldersData.reduce((acc, item) => {
        if (item.folderName !== null) {
          if (acc[item.folderId]) {
            acc[item.folderId].count++

            return { ...acc }
          } else {
            return {
              ...acc,
              [item.folderId]: { folderId: item.folderId, folderName: item.folderName, count: 1 }
            }
          }
        } else {
          return acc
        }
      }, {})

      // get the folders as an array
      const foldersWithCount: IFolder[] = Object.values(foldersMapWithCount)

      // set the state
      setFoldersWithCount(foldersWithCount)
    }
  }, [foldersData])

  const handleEdit = useCallback(
    (folderId, folderName) => {
      // show a input box and change edit link to be save link
      setEditFolder({ folderId, folderName, count: 0, downloadsPending: 0 })
      setFolderName({ name: folderName, validationMsg: '', validationType: undefined })
    },
    [setEditFolder, setFolderName]
  )

  const handleSaveFolder = useCallback(
    (folderId, folderName) => {
      saveFolder({ variables: { folder: { folderId: Number(folderId), name: folderName.trim(), orgId: user.orgId } } })
      setEditFolder(undefined)
    },
    [saveFolder, setEditFolder, user.orgId]
  )

  const handleDelete = useCallback(
    (folderId, folderName) => {
      const folderIds = [folderId]
      deleteFolders({ variables: { folderIds: folderIds } })
      // reset the deleteFolder state
      setDeleteFolder(undefined)
    },
    [deleteFolders, setDeleteFolder]
  )

  const checkDupFolderName = useCallback(
    name => {
      return folders.find(folder => folder.label === name)
    },
    [folders]
  )

  const validateInputString = useCallback(
    (name: string) => {
      const errorMessage = inputStringValidation(name)
      if (errorMessage) {
        setFolderName({ name, validationMsg: errorMessage, validationType: 'error' })
      } else if (checkDupFolderName(name.trim())) {
        setFolderName({ name, validationMsg: 'The folder name you entered already exists', validationType: 'error' })
      } else {
        setFolderName({ name, validationMsg: '', validationType: 'general' })
      }
    },
    [setFolderName, checkDupFolderName]
  )

  const getFolderCount = useCallback(
    (folderId: number) => {
      if (foldersWithCount && foldersWithCount.length > 0) {
        const folderMatch = foldersWithCount.find(item => item.folderId === folderId)
        return folderMatch ? folderMatch.count : 0
      }

      return 0
    },
    [foldersWithCount]
  )

  //   Only render the modal if the open state is true
  return open ? (
    <Modal
      actions={
        // If deleteFolder object is populated then show delete related buttons
        deleteFolder
          ? [
              <NakedButton key='cancel' onClick={() => setDeleteFolder(undefined)} disabled={deleteMutationResult.called}>
                Cancel
              </NakedButton>,
              <PrimaryButton
                key='move'
                onClick={() => handleDelete(deleteFolder.folderId, deleteFolder.folderName)}
                loading={deleteMutationResult.called}
                disabled={deleteMutationResult.called}
              >
                Delete
              </PrimaryButton>
            ]
          : [
              <PrimaryButton key='close' onClick={() => setOpen(false)}>
                Close
              </PrimaryButton>
            ]
      }
      analytics
      analyticsTitle='msof-manage-folder'
      onClose={() => {
        //mutationResult.reset()
        setOpen(false)
      }}
      closeButton={false}
      title='Manage Folders'
      open={open}
      withFooter
      withHeader
    >
      <div>
        <h2 className={'cb-h6'}> Current Folders</h2>
        <p>Delete and/or Edit your Folders</p>
        <Spacer />

        {/* Confirmation message before deleting the folder */}
        {deleteFolder ? (
          <InfoNotification title='Delete Folder?'>
            Are you sure you want to delete the folder '{deleteFolder.folderName}'.
            {getFolderCount(deleteFolder.folderId) > 0
              ? ' Found ' + getFolderCount(deleteFolder.folderId) + ' Searches/Orders that belong to this folder.'
              : ''}
          </InfoNotification>
        ) : (
          <>
            {loading ? (
              <Spinner ariaLabel='Getting folders list' />
            ) : (
              <div style={{ maxHeight: '230px', overflow: 'scroll' }}>
                {deleteSuccess && (
                  <div role='alert' className='cb-toast cb-toast-success' aria-hidden='false'>
                    <span className='cb-icon cb-check cb-margin-right-8'>
                      <span className='sr-only'>success</span>
                    </span>
                    <span className='cb-toast-msg'>Folder has been deleted successfully</span>
                    <button
                      type='button'
                      className='cb-btn cb-btn-square cb-btn-greyscale cb-btn-close cb-margin-left-8'
                      onClick={() => setDeleteSuccess(false)}
                    >
                      <span className='cb-icon cb-x-mark' aria-hidden='false' />
                      <span className='sr-only'>Close alert message</span>
                    </button>
                  </div>
                )}

                {editSuccess && (
                  <div role='alert' className='cb-toast cb-toast-success' aria-hidden='false'>
                    <span className='cb-icon cb-check cb-margin-right-8'>
                      <span className='sr-only'>success</span>
                    </span>
                    <span className='cb-toast-msg'>Folder name has been updated successfully</span>
                    <button
                      type='button'
                      className='cb-btn cb-btn-square cb-btn-greyscale cb-btn-close cb-margin-left-8'
                      onClick={() => setEditSuccess(false)}
                    >
                      <span className='cb-icon cb-x-mark' aria-hidden='false' />
                      <span className='sr-only'>Close alert message</span>
                    </button>
                  </div>
                )}
                {folders && folders.length > 0 ? (
                  <div>
                    {folders
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((folder, i) => (
                        <div
                          className='display-flex justify-content-between cb-margin-left-32
                          cb-margin-right-32 cb-border-bottom cb-blue5-tint-1-border'
                          key={folder.value}
                        >
                          {editFolder && editFolder.folderId === folder.value ? (
                            <div className='flex-grow-1'>
                              <Input
                                ariaLabel='folder name'
                                value={folderName.name}
                                maxLength={100}
                                onChange={e => {
                                  validateInputString(e.target.value)
                                }}
                                onClear={e => {
                                  validateInputString('')
                                }}
                                validation={folderName.validationType}
                                validationMsg={folderName.validationMsg}
                                autoFocus
                              />
                            </div>
                          ) : (
                            <span className='align-self-center'>
                              <FolderName folderName={folder.label} maxChars={75} />
                            </span>
                          )}

                          <div>
                            {editFolder && editFolder.folderId === folder.value ? (
                              <>
                                <NakedButton onClick={() => setEditFolder(undefined)}>Cancel</NakedButton>|
                                <NakedButton
                                  disabled={folderName.validationType === 'error'}
                                  onClick={() => handleSaveFolder(editFolder.folderId, folderName.name)}
                                >
                                  Save
                                </NakedButton>
                                |
                              </>
                            ) : (
                              <>
                                <NakedButton
                                  disabled={deleteMutationResult.called || editMutationResult.called}
                                  onClick={() => handleEdit(folder.value, folder.label)}
                                >
                                  Edit
                                </NakedButton>
                                |
                              </>
                            )}
                            <NakedButton
                              disabled={deleteMutationResult.called || editMutationResult.called}
                              onClick={() =>
                                setDeleteFolder({
                                  folderId: Number(folder.value),
                                  folderName: folder.label,
                                  count: 0,
                                  downloadsPending: 0
                                })
                              }
                            >
                              Delete
                            </NakedButton>
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <p className='cb-padding-left-8'>There are no folders</p>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  ) : null
}

export default ManageFoldersModal
