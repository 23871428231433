import { useMutation, useQuery } from '@apollo/client'
import { Modal, PrimaryButton, NakedButton, Spacer, ILabeledValue, Spinner, Input } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import React, { useEffect, useState } from 'react'
import { CREATE_FOLDER } from '../../../../services/graphql/mutations'
import { GET_FOLDERS_OF_ORG, GET_PORTFOLIO_QUERY } from '../../../../services/graphql/queries'
import { ITextInput } from '../../../common/ITextInput'
import { inputStringValidation } from './FolderNameInput'

interface ICreateFolderModal {
  setCreateFolderSuccess: (value: string) => void
  open: boolean
  setOpen: (value: boolean) => void
}

// Modal used to move a Search to a Folder
export const CreateFolderModal = ({ setCreateFolderSuccess, open, setOpen }: ICreateFolderModal) => {
  const { user } = useSearchUserContext()

  const initTextInput = (): ITextInput => {
    return {
      name: '',
      validationMsg: '',
      validationType: undefined
    }
  }
  // used to disable the move search button in case of an error
  const [hadError, setHadError] = useState(false)
  // the folder name
  const [folderName, setFolderName] = useState<ITextInput>(initTextInput())
  // the list of folders available
  const [folders, setFolders] = useState<ILabeledValue[]>([])

  // Appsync - mutation and query
  // We will use this to check for duplicates
  const { loading, error, data } = useQuery(GET_FOLDERS_OF_ORG, {
    variables: { orgId: user.orgId }
  })

  const [createFolder, mutationResult] = useMutation(CREATE_FOLDER, {
    refetchQueries: [
      { query: GET_FOLDERS_OF_ORG, variables: { orgId: user.orgId } },
      { query: GET_PORTFOLIO_QUERY, variables: { orgId: user.orgId } }
    ]
  })

  useEffect(() => {
    if (!loading && !error) {
      const folders: ILabeledValue[] = []
      if (data?.getFolders) {
        data.getFolders.map(folder => folders.push({ label: folder.name, value: folder.folderId }))
        setFolders(folders)
      }
    }
  }, [data, loading, error])

  // If error set the error as true. This will help make the Move Search button to be disabled. Cant rely on mutationResult.called alone.
  useEffect(() => {
    if (mutationResult?.error) {
      mutationResult.reset()
      setHadError(true)
    }
  }, [mutationResult.error])

  useEffect(() => {
    if (mutationResult?.data) {
      setCreateFolderSuccess(mutationResult.data.saveFolder.name)
      setOpen(false)
    }
  }, [mutationResult.data])

  const handleCreate = () => {
    // trim spaces in the folder name
    createFolder({ variables: { folder: { name: folderName.name.trim(), orgId: user.orgId } } })
  }

  const checkDupFolderName = name => {
    return folders.find(folder => folder.label === name)
  }

  const validateInputString = (name: string) => {
    const errorMessage = inputStringValidation(name)
    if (errorMessage) {
      setFolderName({ name, validationMsg: errorMessage, validationType: 'error' })
    } else if (checkDupFolderName(name.trim())) {
      setFolderName({ name, validationMsg: 'The folder name you entered already exists', validationType: 'error' })
    } else {
      setFolderName({ name, validationMsg: '', validationType: 'general' })
    }
  }

  //   Only render the modal if the open state is true
  //   There are two views for the modal
  //   - Shows a list of folder names and Move button
  //   - Once the user has moved the search it shows a success message
  return open ? (
    <Modal
      actions={[
        <NakedButton key='cancel' onClick={() => setOpen(false)} disabled={mutationResult.called}>
          Cancel
        </NakedButton>,
        <PrimaryButton
          key='move'
          onClick={handleCreate}
          loading={mutationResult.called}
          disabled={
            mutationResult.called ||
            hadError ||
            loading ||
            folderName === undefined ||
            folderName.name.length == 0 ||
            folderName.validationType === 'error'
          }
        >
          Save
        </PrimaryButton>
      ]}
      analytics
      analyticsTitle='msof-create-folder'
      onClose={() => {
        mutationResult.reset()
        setOpen(false)
      }}
      closeButton={false}
      title='Create Folder'
      open={open}
      withFooter
      withHeader
    >
      <div>
        <React.Fragment>
          <p className='cb-padding-8'>Please create a folder name</p>
          <Spacer />
        </React.Fragment>
        {loading ? (
          <Spinner ariaLabel='Getting folders list' />
        ) : (
          <div style={{ minWidth: '50%' }}>
            <Input
              ariaLabel='folder name'
              floating
              label='Enter folder name'
              value={folderName.name}
              maxLength={100}
              onChange={e => {
                validateInputString(e.target.value)
              }}
              onClear={e => {
                validateInputString('')
              }}
              validation={folderName.validationType}
              validationMsg={folderName.validationMsg}
            />
          </div>
        )}
      </div>
    </Modal>
  ) : null
}

export default CreateFolderModal
