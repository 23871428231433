import { useLazyQuery, useMutation } from '@apollo/client'
import { ErrorNotification, PrimaryButton, Select } from '@cb/apricot-react'
import { ensure, Roles } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { isFuture } from 'date-fns'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GET_FOLDERS_OF_ORG, GET_SEARCH_ORDER_DETAILS_QUERY } from '../../../../services/graphql/queries'
import { OrderStatus } from '../../common/options'
import { CopyModal, DeleteModal, StopModal } from '../../common/MsofModals'
import { COPY_SEARCH } from '../../../../services/graphql/mutations'
import { ISavedSearchInput } from '../../../search/search-form/ISearchFaces'
import { ViewSavedSearchDetailsProps } from './ViewSavedSearchDetails'
import MoveModal from '../../common/folders/MoveModal'
import { IPortfolioFileType } from '../../../../interfaces/reference/IReference'

interface ICopySearchResult {
  savedSearch: ISavedSearchInput
  excludedCriteria?: any
}

enum SearchActionOptionKeys {
  open = 'open',
  copy = 'copy',
  delete = 'delete',
  view = 'view',
  move = 'move',
  stop = 'stop'
}

const actions = [
  {
    label: 'Open Search',
    value: SearchActionOptionKeys.open,
    disabled: true
  },
  {
    label: 'Copy Search',
    value: SearchActionOptionKeys.copy,
    disabled: true
  },
  {
    label: 'Delete Search',
    value: SearchActionOptionKeys.delete,
    disabled: true
  },
  {
    label: 'View Order Detail',
    value: SearchActionOptionKeys.view,
    disabled: false
  },
  {
    label: 'Move To >',
    value: SearchActionOptionKeys.move,
    disabled: false
  },
  {
    label: 'Stop Order',
    value: SearchActionOptionKeys.stop,
    disabled: true
  }
]

interface IMosfActionState {
  searchAction: string
  modalTrigger?: string
  searchName: string
  actions: { label: string; value: SearchActionOptionKeys }[]
}

export const MosfActions = ({
  savedSearchId,
  accordionSavedSearchId,
  orderId,
  status,
  ownerUserId,
  startDate,
  namesDesired,
  namesDelivered,
  folderId
}: ViewSavedSearchDetailsProps) => {
  const navigate = useNavigate()

  // useState
  // This state is used to keep track of what MSOF action the user selected. eg: copy/view etc
  const [mosfActionState, setMosfActionState] = useState<IMosfActionState>({
    searchAction: '',
    searchName: '',
    actions: actions
  })

  const [copyResult, setCopyResult] = useState<ICopySearchResult | null>()
  const [stopping, setStopping] = useState(false)
  const [moveModal, setMoveModal] = useState(false)

  // appsync queries and mutations
  const [getPortfolioPartial, { loading, error, data }] = useLazyQuery(GET_SEARCH_ORDER_DETAILS_QUERY)
  // folders of the org/dept
  const [getFolders, getFoldersResult] = useLazyQuery(GET_FOLDERS_OF_ORG)
  const [copySearch, { error: copyError }] = useMutation(COPY_SEARCH, { onCompleted: data => handleCopyComplete(data) })

  const { user } = useSearchUserContext()
  const applyActionButtonId = `apply-saved-search-btn-${accordionSavedSearchId}`

  useEffect(() => {
    if (savedSearchId && savedSearchId !== -1 && savedSearchId === accordionSavedSearchId) {
      // get the saved search/order data
      getPortfolioPartial({
        variables: { savedSearchId }
      })

      // get the folders of the org/dept
      getFolders({
        variables: { orgId: user.orgId }
      })
    }
  }, [savedSearchId, accordionSavedSearchId])

  useEffect(() => {
    if (copyError) {
      throw new Error()
    }
  }, [copyError])

  // useEffect on the data from saved search/order data
  useEffect(() => {
    if (data) {
      // service should filter deleted so not pullback in get
      const owner: boolean = data.getPortfolioItem.ownerUserId === user.userId
      const sssAlphaRole: boolean = user.roles?.find(e => e.orgId === user.orgId)?.role === Roles.EPL_SSS_ALPHA
      const sssCustomerRole: boolean = user.roles?.find(e => e.orgId === user.orgId)?.role === Roles.EPL_SSS_CUSTOMER
      const sssConsultantVendorRole: boolean =
        user.roles?.find(e => e.orgId === user.orgId)?.role === Roles.EPL_SSS_CONSULTANT_VENDOR
      const loginAsUser: boolean = user.loginAsOrgId !== undefined

      console.log(
        'Roles:',
        Roles.EPL_SSS_ALPHA,
        sssAlphaRole,
        Roles.EPL_SSS_CUSTOMER,
        sssCustomerRole,
        Roles.EPL_SSS_CONSULTANT_VENDOR,
        sssConsultantVendorRole
      )

      // status of submitted - order status=submitted, partially fulfilled, fulfilled
      const canDeleteStatus: boolean =
        data.getPortfolioItem.status !== OrderStatus.submitted &&
        data.getPortfolioItem.status !== OrderStatus.fulfilled &&
        data.getPortfolioItem.status !== OrderStatus.partiallyFulfilled

      const statusSaved: boolean = data.getPortfolioItem.status === OrderStatus.saved
      const statusNotSubmitted: boolean = data.getPortfolioItem.status === OrderStatus.notSubmitted

      // # Logic for open
      // If SSS Alpha, SSS Customer, or SSS Consultant Vendor roles may open a search/order
      // Also the search/order is in saved or not submitted status
      const canOpen: boolean =
        (sssAlphaRole || sssCustomerRole || sssConsultantVendorRole || loginAsUser) && (statusSaved || statusNotSubmitted)
      if (canOpen) {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.open)).disabled = false
      } else {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.open)).disabled = true
      }

      if (data && (owner || sssAlphaRole) && canDeleteStatus) {
        // # Logic for delete
        // If able to delete pop up message, confirm and delete,
        // If we are able to delete add the delete in the select dropdown
        ensure(actions.find(option => option.value === SearchActionOptionKeys.delete)).disabled = false
      } else {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.delete)).disabled = true
      }

      // # Logic for copy
      // If SSS Alpha, SSS Customer, or SSS Consultant Vendor roles may copy a search/order
      // Also, the savedSearchId must exist
      const canCopy: boolean =
        (sssAlphaRole || sssCustomerRole || sssConsultantVendorRole || loginAsUser) &&
        data.getPortfolioItem.savedSearchId !== null &&
        data.getPortfolioItem.savedSearchId !== undefined
      if (canCopy) {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.copy)).disabled = false
      } else {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.copy)).disabled = true
      }

      // # Logic for stop
      const canStop: boolean =
        (sssAlphaRole || user.userId === ownerUserId) &&
        status !== undefined &&
        (status === '4' || (startDate !== undefined && isFuture(new Date(startDate)) && status === '3'))
      if (canStop) {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.stop)).disabled = false
      } else {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.stop)).disabled = true
      }

      setMosfActionState(prev => ({ ...prev, actions, searchName: data.getPortfolioItem.name }))
    }
  }, [data])

  // this useEffect is used to set the Move to option as disabled or enabled
  useEffect(() => {
    if (getFoldersResult.data) {
      // # Logic for move - There should be atleast one folder for the org
      const folders = getFoldersResult.data.getFolders
      const canMove: boolean = folders && folders.length > 0
      if (canMove) {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.move)).disabled = false
      } else {
        ensure(actions.find(option => option.value === SearchActionOptionKeys.move)).disabled = true
      }
      setMosfActionState(prev => ({ ...prev, actions }))
    }
  }, [getFoldersResult.data])

  const handleCopyCancel = () => setCopyResult(null)

  const handleCopyComplete = (data: any) => {
    const { savedSearch, excludedCriteria } = data.copySearch
    if (excludedCriteria && Object.keys(excludedCriteria).length > 0) {
      setCopyResult({ savedSearch, excludedCriteria })
    } else {
      navigate(`/search-students?savedSearchId=${data.copySearch.savedSearch.savedSearchId}`)
    }
  }

  const handleStopCancel = () => setStopping(false)

  const handleSearchAction = (action: string) => {
    let msofActionState: IMosfActionState = { ...mosfActionState, searchAction: action }

    // delete
    if (action === SearchActionOptionKeys.delete) {
      msofActionState = { ...msofActionState, modalTrigger: applyActionButtonId }
    } else {
      // un associate the delete modal with button id
      delete msofActionState.modalTrigger
    }
    setMosfActionState(msofActionState)
  }

  const applySearchAction = (savedSearchId: number) => {
    if (savedSearchId === accordionSavedSearchId) {
      switch (mosfActionState.searchAction) {
        case SearchActionOptionKeys.open:
          navigate(`/search-students?savedSearchId=${savedSearchId}`)
          break
        case SearchActionOptionKeys.copy:
          copySearch({ variables: { savedSearchId, userId: user.userId } })
          break
        case SearchActionOptionKeys.view:
          window.open(`/order-detail?savedSearchId=${savedSearchId}&orgId=${user.orgId}`)
          break
        case SearchActionOptionKeys.stop:
          setStopping(true)
          break
        case SearchActionOptionKeys.move:
          setMoveModal(true)
          break
        // future items in dropdown
      }
    }
  }

  if (error || getFoldersResult.error) {
    return (
      <ErrorNotification title='Error'>
        There was an error fetching Saved Search details for Saved Search ID={savedSearchId}.
      </ErrorNotification>
    )
  }
  return (
    <>
      <div className='col-sm-4'>
        <Select
          ariaLabel='My searches action options'
          labelClassName='cb-font-weight-bold'
          labelNoValue='Select Option'
          name='actionOptions'
          onChange={handleSearchAction}
          selectId={`action-options-${accordionSavedSearchId}`}
          value={mosfActionState.searchAction}
          values={mosfActionState.actions}
        />
      </div>
      <div className='col-sm-8'>
        <PrimaryButton
          className='cb-btn cb-btn-primary cb-padding-left-32 cb-padding-right-32'
          id={applyActionButtonId}
          type='button'
          onClick={() => {
            applySearchAction(savedSearchId)
          }}
          disabled={mosfActionState.searchAction.length === 0}
        >
          Apply
        </PrimaryButton>
      </div>
      {copyResult && copyResult.excludedCriteria && (
        <CopyModal
          savedSearch={copyResult.savedSearch}
          excludedCriteria={copyResult.excludedCriteria}
          userId={user.userId}
          open={copyResult !== null}
          onCancel={handleCopyCancel}
        />
      )}
      {mosfActionState.modalTrigger && mosfActionState.searchAction === SearchActionOptionKeys.delete ? (
        <DeleteModal
          name={mosfActionState.searchName}
          savedSearchId={savedSearchId}
          userId={user.userId ? user.userId : -1}
          modalTrigger={mosfActionState.modalTrigger}
        />
      ) : null}
      {moveModal ? (
        <MoveModal
          savedSearchId={savedSearchId}
          currentFolderId={folderId}
          open={moveModal}
          setOpen={setMoveModal}
          category={IPortfolioFileType.Search}
        />
      ) : null}
      {orderId && (
        <StopModal
          open={stopping}
          orderId={orderId}
          userId={user.userId}
          status={status}
          namesDesired={namesDesired}
          namesDelivered={namesDelivered}
          isFuture={!isNil(startDate) && isFuture(new Date(startDate))}
          onClose={handleStopCancel}
        />
      )}
    </>
  )
}

export default MosfActions
