import { useMutation } from '@apollo/client'
import CBDropdown from '@cb/apricot/CBDropdown'
import CBForm from '@cb/apricot/CBForm'
import { NakedButton } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import CreateFolderModal from './CreateFolderModal'
import { IFolder } from './IFolder'
import ManageFoldersModal from './ManageFoldersModal'
import { MOVE_SAVED_SEARCH } from '../../../../services/graphql/mutations'
import { GET_FOLDERS_OF_ORG, GET_PORTFOLIO_BY_CATEGORY_QUERY } from '../../../../services/graphql/queries'
import { IPortfolioItem } from '../IPortfolioItem'
import { IPortfolioFileType } from '../../../../interfaces/reference/IReference'

interface IFolderActionsProps {
  foldersData: IPortfolioItem[] | undefined
  selectedSearches: string[]
  allFolders: IFolder[]
  setAssignFolderSuccess: (value: IFolder | undefined) => void
  category: IPortfolioFileType
}
const FolderActions = ({ foldersData, selectedSearches, allFolders, setAssignFolderSuccess, category }: IFolderActionsProps) => {
  const { user } = useSearchUserContext()

  const [createFolderModal, setCreateFolderModal] = useState(false)
  const [manageFoldersModal, setManageFoldersModal] = useState(false)
  const [createFolderSuccess, setCreateFolderSuccess] = useState<string>('')

  // these useRef needed for dropdown (assign to folder)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputDropdownRef = useRef<HTMLInputElement>(null)

  const [moveSearch] = useMutation(MOVE_SAVED_SEARCH, {
    refetchQueries: [
      { query: GET_FOLDERS_OF_ORG, variables: { orgId: user.orgId } },
      { query: GET_PORTFOLIO_BY_CATEGORY_QUERY, variables: { orgId: user.orgId, category: category } }
    ]
  })

  // this is how we initialize the dropdown
  useEffect(() => {
    // check if dropdown element is loaded in the DOM first
    // (.current attribute does some magic before we can run the script )
    dropdownRef.current && CBDropdown({ elem: dropdownRef.current, filter: true, closeOnClick: true })

    // floating label (check if the filter input is loaded in the DOM first)
    inputDropdownRef.current && CBForm.floatingLabel(inputDropdownRef.current)
  }, [])

  const handleCreateFolder = useCallback(() => {
    setCreateFolderModal(true)
  }, [])

  const handleManageFolders = useCallback(() => {
    setManageFoldersModal(true)
  }, [])

  const handleAssignToFolder = useCallback(
    (e, folderId) => {
      e.preventDefault()
      moveSearch({ variables: { savedSearch: { folderId, savedSearchIds: selectedSearches } } })
      setAssignFolderSuccess({
        folderId,
        count: selectedSearches.length,
        folderName: allFolders.find(item => item.folderId == folderId)?.folderName || '',
        downloadsPending: allFolders.find(item => item.folderId === folderId)?.downloadsPending || 0
      })
    },
    [selectedSearches, allFolders, setAssignFolderSuccess, moveSearch]
  )

  return (
    <>
      <div aria-label='list of available folder management options' className='row' role='region'>
        <div className='col-sm-12 display-flex justify-content-end cb-border-top'>
          <div className='cb-padding-16'>
            <NakedButton icon='plus' iconDecorative iconLeft noOutline noPadding onClick={handleCreateFolder}>
              Create Folder
            </NakedButton>
          </div>
          <div className='cb-padding-16'>|</div>
          <div className='cb-padding-16'>
            <NakedButton icon='compose' iconDecorative iconLeft noOutline noPadding onClick={handleManageFolders}>
              Manage Folders
            </NakedButton>
          </div>
          <div className='cb-padding-left-16 cb-padding-top-16'>|</div>
          <div>
            <div
              className={`cb-dropdown cb-margin-top-4 ${selectedSearches && selectedSearches.length > 0 ? '' : 'cb-disabled'}`}
              ref={dropdownRef}
            >
              <a
                className='cb-dropdown-toggle'
                id='assignToFolderDropdown'
                href='#'
                onClick={e => e.preventDefault()}
                role='button'
                aria-expanded='false'
              >
                <i className='cb-icon cb-down' aria-hidden='true' />
                <i className='cb-icon cb-up cb-hidden' aria-hidden='true' />
                <span className='cb-margin-left-8 cb-font-size-regular'>Assign to Folder</span>
              </a>
              <div className='cb-dropdown-menu cb-box-shadow'>
                <div className='cb-input-container'>
                  <div className='cb-input cb-condensed cb-floating-label'>
                    <label htmlFor='filter1'>Filter</label>
                    <input
                      ref={inputDropdownRef}
                      type='text'
                      id='filter1'
                      aria-controls='dropdownMenu5'
                      role='combobox'
                      aria-expanded='true'
                    />
                  </div>
                </div>
                <ul
                  role='listbox'
                  aria-labelledby='assignToFolderDropdown'
                  id='dropdownMenu5'
                  style={{ maxHeight: '250px', overflowY: 'auto' }}
                >
                  {/* List of all folders  */}
                  {allFolders.map(item => {
                    return (
                      <li key={`dropdown-option-folder-${item.folderId}`} role='presentation'>
                        <a
                          role='option'
                          href='#'
                          className='cb-truncate display-block'
                          style={{ width: '250px' }}
                          onClick={e => handleAssignToFolder(e, item.folderId)}
                        >
                          {item.folderName}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {createFolderSuccess && createFolderSuccess.length > 0 ? (
          <div className='col-sm-12 display-flex justify-content-end cb-border-top'>
            <div role='alert' className='cb-toast cb-toast-success' aria-hidden='false'>
              <span className='cb-icon cb-check cb-margin-right-8'>
                <span className='sr-only'>success</span>
              </span>
              <span className='cb-toast-msg'>{createFolderSuccess} folder has been created</span>
              <button
                type='button'
                className='cb-btn cb-btn-square cb-btn-greyscale cb-btn-close cb-margin-left-8'
                onClick={() => setCreateFolderSuccess('')}
              >
                <span className='cb-icon cb-x-mark' aria-hidden='false' />
                <span className='sr-only'>Close alert message</span>
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {/* Modals */}
      {createFolderModal ? (
        <CreateFolderModal
          setCreateFolderSuccess={setCreateFolderSuccess}
          open={createFolderModal}
          setOpen={setCreateFolderModal}
        />
      ) : null}
      {manageFoldersModal ? (
        <ManageFoldersModal
          open={manageFoldersModal}
          setOpen={setManageFoldersModal}
          foldersData={foldersData}
          category={category}
        />
      ) : null}
    </>
  )
}

export default FolderActions
