import { Icon, IconName } from '@cb/apricot-react'
import { getCMSPanelByFieldId, getIcon, sanitizeHtml } from '@msss/ui-common'

interface NeedHelpProps {
  content: Record<string, any>
}

const NeedHelp = ({ content }: NeedHelpProps) => {
  const needHelp = getCMSPanelByFieldId(content.field_panel, 'SupportPanel')?.field_promotion_component[0]

  return (
    <div className='col-xs-12 display-flex justify-content-center align-items-center'>
      <Icon decorative name={getIcon(needHelp.field_icon) as IconName} size='32' />
      <p className='cb-paragraph1-res cb-margin-left-24'>{sanitizeHtml(needHelp.title)}</p>
      <a
        className='cb-btn cb-white-bg cb-margin-left-24'
        href={sanitizeHtml(needHelp.field_cta_link?.url)}
        rel='noopener noreferrer'
        target='_blank'
      >
        {sanitizeHtml(needHelp.field_cta_link?.title)}
      </a>
    </div>
  )
}

export default NeedHelp
