import { List } from '@cb/apricot-react'

interface IThreeColumnListProps {
  theList: string[]
}

const ThreeColumnList = ({ theList }) => {
  let firstColumn: string[] = []
  let secondColumn: string[] = []
  let thridColumn: string[] = []
  if (theList && theList.length > 0) {
    const list = Object.assign([], theList)
    const threePartIndex = Math.ceil(list.length / 3)
    firstColumn = list.splice(-threePartIndex)
    secondColumn = list.splice(-threePartIndex)
    thridColumn = list
  }
  return (
    <>
      <div className='row' role='list'>
        <div className='col-xs-4'>
          <ul className='cb-text-list' role='presentation'>
            {firstColumn.map((v, i) => (
              <li key={`three-col-list-1-li-${i}`} className='cb-margin-top-16' role='listitem'>
                {v}
              </li>
            ))}
          </ul>
        </div>
        <div className='col-xs-4'>
          <ul className='cb-text-list' role='presentation'>
            {secondColumn.map((v, i) => (
              <li key={`three-col-list-2-li-${i}`} className='cb-margin-top-16' role='listitem'>
                {v}
              </li>
            ))}
          </ul>
        </div>
        <div className='col-xs-4'>
          <ul className='cb-text-list' role='presentation'>
            {thridColumn.map((v, i) => (
              <li key={`three-col-list-3-li-${i}`} className='cb-margin-top-16' role='listitem'>
                {v}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}
export default ThreeColumnList
