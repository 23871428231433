interface IScrollOptions {
  behavior?: string
  block?: string
}

interface IScrollInfo {
  containerId?: string
  scrollOptions?: IScrollOptions
}

/**
 * scrolls to (if not fully visible) a specific element on a page
 * @param {object} e DOM generated event
 * @param {string} eleId id of desired element
 * @param {boolean} basedOnTop denotes whether desired element position reletavite to the bottom of the screen should be based on the top of the element or the bottom
 * @param {object} scrollInfo collection of scroll params including optional scrollIntoView options or a container id to scroll within instead of window
 * @return {boolean} flag denoting whether conditional scrolling was successful
 */
export const conditionalScroll = (e: any, eleId: string, basedOnTop?: boolean, scrollInfo?: IScrollInfo = {}) => {
  if (e) {
    e.preventDefault()
  }

  const ele = document.getElementById(eleId)
  if (ele) {
    const { scrollOptions, ...scrollInfoRest } = scrollInfo
    if (shouldScrollToElement(ele, basedOnTop, scrollInfoRest)) {
      scrollToElement(ele, scrollOptions)
    }
    return true
  } else {
    return false
  }
}

/**
 * scrolls to (if not fully visible) and places focus on a specific element on a page
 * @param {object} e DOM generated event
 * @param {string} eleId id of desired element
 * @param {boolean} basedOnTop denotes whether desired element position reletavite to the bottom of the screen should be based on the top of the element or the bottom
 * @param {object} scrollInfo collection of scroll params including optional scrollIntoView options or a container id to scroll within instead of window
 * @return {boolean} flag denoting whether focusing and conditional scrolling was successful
 */
export const conditionalScrollFocus = (e: any, eleId: string, basedOnTop?: boolean, scrollInfo?: IScrollInfo = {}) => {
  if (e) {
    e.preventDefault()
  }

  const ele = document.getElementById(eleId)
  if (ele) {
    const { scrollOptions, ...scrollInfoRest } = scrollInfo
    if (shouldScrollToElement(ele, basedOnTop, scrollInfo)) {
      scrollToElement(ele, scrollOptions)
    }
    focusOnElement(ele)
    return true
  } else {
    return false
  }
}

/**
 * scrolls to and places focus on a specific element on a page
 * @param {object} e DOM generated event
 * @param {string} eleId id of desired element
 * @return {boolean} flag denoting whether focusing was successful
 */
export const focus = (e: any, eleId: string) => {
  if (e) {
    e.preventDefault()
  }

  const ele = document.getElementById(eleId)
  if (ele) {
    focusOnElement(ele)
    return true
  } else {
    return false
  }
}

/**
 * scrolls to a specific element on a page
 * @param {object} e DOM generated event
 * @param {string} eleId id of desired element
 * @param {object} scrollInfo collection of scroll params including optional scrollIntoView options
 * @return {boolean} flag denoting whether scrolling was successful
 */
export const scroll = (e: any, eleId: string, scrollInfo?: IScrollInfo = {}) => {
  if (e) {
    e.preventDefault()
  }

  const ele = document.getElementById(eleId)
  if (ele) {
    const { scrollOptions } = scrollInfo
    scrollToElement(ele, scrollOptions)
    return true
  } else {
    return false
  }
}

/**
 * scrolls to and places focus on a specific element on a page
 * @param {object} e DOM generated event
 * @param {string} eleId id of desired element
 * @param {object} scrollInfo collection of scroll params including optional scrollIntoView options
 * @return {boolean} flag denoting whether scrolling & focusing was successful
 */
export const scrollFocus = (e: any, eleId: string, scrollInfo?: IScrollInfo = {}) => {
  if (e) {
    e.preventDefault()
  }

  const ele = document.getElementById(eleId)
  if (ele) {
    const { scrollOptions } = scrollInfo
    scrollToElement(ele, scrollOptions)
    focusOnElement(ele)
    return true
  } else {
    return false
  }
}

/**
 * helper function to place focus on desired element
 * @param {object} ele desired element
 */
const focusOnElement = (ele: any) => {
  setTimeout(() => {
    // timeout needed for Android
    ele.blur() // blur needed to reset focus in iOS
    ele.setAttribute('tabindex', -1)
    ele.addEventListener('blur', () => ele.removeAttribute('tabindex'))
    ele.addEventListener('focusout', () => ele.removeAttribute('tabindex'))
    ele.focus({ preventScroll: true })
  }, 100)
}

/**
 * helper function to scroll to desired element
 * @param {object} ele desired element
 * @param {object} scrollOptions scrollIntoView options
 */
const scrollToElement = (ele: any, scrollOptions: IScrollOptions = {}) =>
  ele.scrollIntoView({ behavior: 'smooth', ...scrollOptions }) // scroll options ignored by safari (all devices) & IE

/**
 * helper function to determine if element should be scrolled to
 * @param {object} ele desired element
 * @param {boolean} basedOnTop denotes whether desired element position reletavite to the bottom of the screen should be based on the top of the element or the bottom
 * @param {object} scrollInfo collection of scroll params including a container id to scroll within instead of window
 * @return {boolean} flag denoting whether should scroll to element based on current conditions
 */
const shouldScrollToElement = (ele: any, basedOnTop: boolean, { containerId }: IScrollInfo) => {
  const elePosition = ele.getBoundingClientRect?.()?.top ?? 0 // not supported by IE
  let viewMin = 0,
    viewMax = window.innerHeight

  const containerEle = containerId && document.getElementById(containerId)
  if (containerEle) {
    const containerPosition = containerEle.getBoundingClientRect?.()?.top ?? 0 // not supported by IE
    viewMin = containerPosition
    viewMax = containerPosition + containerEle.offsetHeight
  }

  return (
    elePosition < viewMin || // need to scroll down so entire element is in view
    (basedOnTop ? elePosition : elePosition + ele.offsetHeight) > viewMax // need to scroll up so entire element is in view
  )
}
