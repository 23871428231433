import { SecondaryButton, Spacer } from '@cb/apricot-react'
import { numberFormatter, UNLIMITED_NAMES } from '@msss/ui-common'
import { TooltipHeader, useSearchUserContext } from '@msss/ui-components'
import { useContext, useEffect, useState } from 'react'
import { FeatureAccessContext } from '../../../context/FeatureAccessProvider'
import { IFeatureInfo } from '../../../interfaces/reference/IReference'
import { FlagpolePublic } from '../../../services/rest/FlagpolePublic'
import SubscriptionTableTiers from './SubscriptionTableTiers'
import SubscriptionTableALaCartes from './SubscriptionTableALaCartes'
import ThreeColumnList from './ThreeColumnList'
import Quotes from './quotes/Quotes'
import { TABS } from '../MyAccount'
import { useNavigate } from 'react-router-dom'

const PlansFeaturesSubscription = () => {
  const { user } = useSearchUserContext()
  const { getFeatureInfo, loadingFeatures } = useContext(FeatureAccessContext)
  const navigate = useNavigate()

  const [subscriptionBalance, setSubscriptionBalance] = useState<number>(-1)
  const [subscriptionTotalNames, setSubscriptionTotalNames] = useState<number>(-1)
  const [subscriptionNamesUsed, setSubscriptionNamesUsed] = useState<number>(-1)
  const [adjustmentVolume, setAdjustmentVolumed] = useState<number>(-1)
  const [featureInfo, setFeatureInfo] = useState<IFeatureInfo | undefined>()

  useEffect(() => {
    const setup = async () => {
      // if its login-as use that orgId for auth else use the selected org by user.
      const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
      const subscriptionVolume = await FlagpolePublic.getSubscriptionVolume(authOrgId!, user.orgId!)

      // Subscription related info
      if (subscriptionVolume) {
        setSubscriptionBalance(subscriptionVolume.balance ?? -1)
        setSubscriptionTotalNames(subscriptionVolume.annualVolume ?? -1)
        setSubscriptionNamesUsed(subscriptionVolume.licensedVolume ?? -1)
        setAdjustmentVolumed(subscriptionVolume.adjustmentVolume ?? -1)
      }
    }
    setup()
  }, [user])

  useEffect(() => {
    if (loadingFeatures === false) {
      const fi = getFeatureInfo(false)
      setFeatureInfo(fi)
    }
  }, [getFeatureInfo, loadingFeatures])

  useEffect(() => {}, [featureInfo, subscriptionBalance, subscriptionTotalNames, subscriptionNamesUsed])

  return (
    <>
      {!featureInfo ||
        (featureInfo.featureTiers?.length === 0 && featureInfo.alacarteFeatureTiers?.length === 0 && (
          <>
            <div className='cb-box-shadow-bottom cb-margin-bottom-16 cb-blue5-bg cb-padding-32 cb-border-radius-8'>
              <div className='container cb-white-color'>
                <div className='row'>
                  <div className='col-xs-10'>
                    <h2 className='cb-h5'>DISCOVER A MORE FLEXIBLE, MORE POWERFUL SEARCH</h2>
                    <Spacer size='8' />
                    <p>
                      Our customizable subscription plans allow you to create a Search solution that’s tailored to your needs and
                      your budget. It's not too late to get started—explore now and start making the most of Search.
                    </p>
                  </div>
                  <div className='col-xs-2 cb-align-right'>
                    <SecondaryButton
                      className='cb-margin-right-16'
                      light
                      onClick={() => navigate(`/my-account/${TABS.SelfService}`)}
                      id='view-plans-btn'
                    >
                      View Plans
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            </div>
            <Spacer size='32' />
          </>
        ))}
      <div className='cb-border-radius-8'>
        <div className='cb-padding-32' style={{ backgroundColor: '#f5f7fc' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-9'>
                <h2 className='cb-h5'>OVERVIEW OF YOUR SUBSCRIPTIONS</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='cb-box-shadow-bottom cb-padding-left-16 cb-padding-bottom-16 cb-margin-top-16 cb-margin-bottom-16'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-4'>
                <div className='container'>
                  <div className='row'>
                    <TooltipHeader
                      header='Total Number of Records Remaining'
                      id='total-names-remaining-tip'
                      tooltipMsg='This count represents the number of records you have available to license, excluding records you have already licensed under your current subscription(s). This count also includes any adjustments that have been applied to your active subscription(s) for the current Search year.'
                    />
                  </div>
                  <div className='row'>
                    <span>
                      {' '}
                      {subscriptionBalance === -1 // this covers the requirement for transactional users
                        ? 'N/A'
                        : subscriptionBalance < UNLIMITED_NAMES
                        ? numberFormatter(subscriptionBalance)
                        : 'Unlimited'}
                    </span>
                  </div>
                  <Spacer size='16' />
                  <div className='row'>
                    <TooltipHeader
                      header='Account Adjustment'
                      id='account-adjustment-tip'
                      tooltipMsg='Account Adjustments are the total number of records that have been credited towards your current subscription(s).'
                    />
                  </div>
                  <div className='row'>
                    <span>{adjustmentVolume === -1 || adjustmentVolume === 0 ? 'N/A' : numberFormatter(adjustmentVolume)}</span>
                  </div>
                </div>
              </div>
              <div className='col-xs-4'>
                <div className='container'>
                  <div className='row'>
                    <TooltipHeader
                      header='Total Number of Records Included'
                      id='total-names-include-tip'
                      tooltipMsg='This count represents the total number of records included in your current subscription(s).'
                    />
                  </div>
                  <div className='row'>
                    <span>
                      {' '}
                      {subscriptionTotalNames === -1 // this covers the requirement for transactional users
                        ? 'N/A'
                        : subscriptionTotalNames < UNLIMITED_NAMES
                        ? numberFormatter(subscriptionTotalNames)
                        : 'Unlimited'}
                    </span>
                  </div>
                  <Spacer size='16' />
                  <div className='row'>
                    <TooltipHeader
                      header='Total Number of Records Licensed'
                      id='licensed-count-tip'
                      tooltipMsg='This count represents the number of records you have already licensed under your current subscription(s).'
                    />
                  </div>
                  <div className='row'>
                    <span>{subscriptionNamesUsed === -1 ? 'N/A' : numberFormatter(subscriptionNamesUsed)}</span>
                  </div>
                </div>
              </div>
              <div className='col-xs-4'>
                <div className='container'>
                  <div className='row'>
                    <TooltipHeader
                      header='Total Number of Connections Audiences'
                      id='total-connection-audience-tip'
                      tooltipMsg='Total Number of Connections Audiences.'
                    />
                  </div>
                  <div className='row'>
                    <span>{featureInfo?.totalConnectionsAudiences === 0 ? 'N/A' : featureInfo?.totalConnectionsAudiences}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='cb-box-shadow-bottom cb-padding-16 cb-margin-bottom-16'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12'>
                <TooltipHeader
                  header='Overview of all Features Included in Your Account'
                  id='overview-feature-tip'
                  tooltipMsg='This is a list of features that are included in your current subscription(s) plus any features you have purchased a la carte.'
                />
              </div>
            </div>
            {featureInfo && featureInfo.featureNames && featureInfo.featureNames.length > 0 && (
              <ThreeColumnList theList={featureInfo.featureNames} />
            )}
          </div>
        </div>
        {featureInfo && (featureInfo.featureTiers?.length > 0 || featureInfo.alacarteFeatureTiers?.length > 0) && (
          <>
            <div className='cb-padding-top-24 cb-padding-left-16 cb-padding-right-32'>
              <div className='container'>
                <div className='row'>
                  <div className='col-xs-9'>
                    <h2 className='cb-h5'>CURRENT SUBSCRIPTIONS</h2>
                  </div>
                </div>
              </div>
            </div>

            {featureInfo.featureTiers.length > 0 && (
              <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
                <SubscriptionTableTiers data={featureInfo.featureTiers} />
              </div>
            )}

            {featureInfo.alacarteFeatureTiers.length > 0 && (
              <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
                <SubscriptionTableALaCartes data={featureInfo.alacarteFeatureTiers} />
              </div>
            )}
          </>
        )}

        <div className='cb-padding-top-24 cb-padding-left-16 cb-padding-right-32'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-9'>
                <h2 className='cb-h5'>FUTURE SUBSCRIPTIONS</h2>
              </div>
            </div>
          </div>
        </div>
        {(featureInfo?.futureFeatureTiers == undefined || featureInfo?.futureFeatureTiers.length == 0) &&
          (featureInfo?.futureAlacarteFeatureTiers == undefined || featureInfo.futureAlacarteFeatureTiers.length == 0) && (
            <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
              <div className='row'>
                <div className='col-xs-12'>You have no future subscriptions or a la carte features created</div>
              </div>
            </div>
          )}

        {featureInfo && featureInfo.futureFeatureTiers && featureInfo.futureFeatureTiers.length > 0 && (
          <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
            <SubscriptionTableTiers data={featureInfo.futureFeatureTiers} />
          </div>
        )}

        {featureInfo && featureInfo.futureAlacarteFeatureTiers && featureInfo.futureAlacarteFeatureTiers.length > 0 && (
          <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
            <SubscriptionTableALaCartes data={featureInfo.futureAlacarteFeatureTiers} />
          </div>
        )}

        {/* Quotes Section */}
        <div className='cb-padding-top-24 cb-padding-left-16 cb-padding-right-32'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-9'>
                <h2 className='cb-h5'>SAVED PRICE QUOTES</h2>
              </div>
            </div>
          </div>
        </div>
        <Quotes />
      </div>
      <div className='cb-padding-top-24 cb-padding-left-16 cb-padding-right-32'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-9'>
              <h2 className='cb-h5'>PREVIOUS YEAR'S PLANS</h2>
            </div>
          </div>
        </div>
      </div>
      {(featureInfo?.priorFeatureTiers == undefined || featureInfo?.priorFeatureTiers.length == 0) &&
        (featureInfo?.priorAlacarteFeatureTiers == undefined || featureInfo.priorAlacarteFeatureTiers.length == 0) && (
          <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
            <div className='row'>
              <div className='col-xs-12'>You have no previous year subscriptions or a la carte features created</div>
            </div>
          </div>
        )}

      {featureInfo && featureInfo.priorFeatureTiers && featureInfo.priorFeatureTiers.length > 0 && (
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <SubscriptionTableTiers data={featureInfo.priorFeatureTiers} />
        </div>
      )}

      {featureInfo && featureInfo.priorAlacarteFeatureTiers && featureInfo.priorAlacarteFeatureTiers.length > 0 && (
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <SubscriptionTableALaCartes data={featureInfo.priorAlacarteFeatureTiers} />
        </div>
      )}
    </>
  )
}

export default PlansFeaturesSubscription
