import { NakedButton, Tab, Tabs } from '@cb/apricot-react'
import { Loader, PageContainer, useSearchUserContext } from '@msss/ui-components'
import React, { Suspense, useCallback, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PeerGroups from './peer-groups/PeerGroups'
import PlansFeatures from './plans-features/PlansFeatures'
import SavedCriteria from './saved-criteria/SavedCriteria'
import { siteName } from '../../constants/app'
import { useSystemAlertContext } from '../../context/SystemAlertContext'

// MFE containers must be lazy loaded
const SelfService = React.lazy(() => import('./self-service/SelfService'))

const { LEGACY_BASE_URL, SUPPORT_SITE_URL } = process.env

export enum TABS {
  PlansFeatures = 'plans-features',
  SelfService = 'self-service',
  SavedCriteria = 'saved-criteria',
  PeerGroups = 'peer-groups'
}

const MyAccount = () => {
  const navigate = useNavigate()

  const { selectedTab } = useParams()
  const goBackTimesRef = useRef<number>(-1)
  const { user } = useSearchUserContext()
  const { refreshAlerts } = useSystemAlertContext()

  const onTabChange = useCallback(
    (panelId: string) => {
      goBackTimesRef.current = goBackTimesRef.current - 1
      navigate(`/my-account/${panelId}`)
    },
    [navigate]
  )

  const onSubPageChange = useCallback(() => {
    goBackTimesRef.current = goBackTimesRef.current - 1
    refreshAlerts()
  }, [])

  return (
    <PageContainer pageLoaded siteName={siteName} title='My Account'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-3'>
            <NakedButton
              className='cb-margin-top-24'
              icon='left'
              iconLeft
              noPadding
              onClick={() => navigate(goBackTimesRef.current)}
            >
              Back
            </NakedButton>
          </div>
          <div className='col-xs-9 cb-align-right'>
            <a
              className='cb-margin-top-24 cb-btn cb-btn-naked cb-no-padding'
              href={`${LEGACY_BASE_URL}/my-account.htm?tab=preferences&orgId=${user.orgId}`}
              rel='noopener noreferrer'
            >
              Return to Classic Site
            </a>
            {' | '}
            <a
              className='cb-margin-top-24 cb-btn cb-btn-naked cb-no-padding'
              href={SUPPORT_SITE_URL}
              rel='noopener noreferrer'
              target='_blank'
            >
              Support
            </a>
          </div>
          <div className='col-xs-12'>
            <h1 className='cb-h3 cb-margin-top-32 cb-margin-bottom-72'>My Account</h1>
          </div>

          <div className='col-xs-12'>
            {/* using isActiveTab flags to tell Tab children when they are visible to users as Apricot does not
              unmount children when the tabs change */}
            <Tabs isControlled onTabChange={onTabChange}>
              <Tab
                label='Plans and Features Summary'
                panelId={TABS.PlansFeatures}
                selected={!selectedTab || selectedTab === TABS.PlansFeatures}
              >
                <PlansFeatures isActiveTab={!selectedTab || selectedTab === TABS.PlansFeatures} />
              </Tab>
              <Tab label='Add New Subscription' panelId={TABS.SelfService} selected={selectedTab === TABS.SelfService}>
                {selectedTab === TABS.SelfService ? (
                  <Suspense fallback={<Loader />}>
                    <SelfService isActiveTab={selectedTab === TABS.SelfService} onSubPageChange={onSubPageChange} />
                  </Suspense>
                ) : null}
              </Tab>
              <Tab label='Your Saved Criteria' panelId={TABS.SavedCriteria} selected={selectedTab === TABS.SavedCriteria}>
                <SavedCriteria />
              </Tab>
              <Tab label='Peer Groups' panelId={TABS.PeerGroups} selected={selectedTab === TABS.PeerGroups}>
                <PeerGroups />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default MyAccount
