import { PermissionProps } from '../../interfaces/reference/IReference'
import { useSearchUserContext } from '@msss/ui-components'

export const Permissions = ({ roles, children, altContent }: PermissionProps) => {
  const { user } = useSearchUserContext()
  const userRoles = user.roles
  // console.log('Permissions: ', user.roles, roles, user.loginAsOrgId)
  const hasAccess =
    userRoles &&
    userRoles.some(userRole => {
      if (roles.indexOf(userRole.role) > -1) {
        // if the user is login as then ignore the OrgId check
        return user.loginAsOrgId || userRole.orgId === user.orgId || userRole.orgId === user.parentOrgId
      }
      return false
    })
  return <>{hasAccess ? children : altContent || null}</>
}

export {}
