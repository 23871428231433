import { numberFormatter } from '@msss/ui-common'
import { Tooltip, useSearchUserContext } from '@msss/ui-components'
import { useEffect, useState } from 'react'
import { ConfigService } from '../../../services/rest/ConfigService'
import { SolrService } from '../../../services/solr/SolrService'
import { ICriteria } from '../../search/search-form/ICriteria'

const PeerVolume = ({ orgs }: { orgs: number[] }) => {
  const { user } = useSearchUserContext()

  const [criteria, setCriteria] = useState<ICriteria>({ sellable: ['B,E,A'] })
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    const getSearchYears = async () => {
      // Get search years from service
      const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
      const { searchYears } = await ConfigService.getSearchYears(authOrgId!)
      return searchYears
    }

    getSearchYears().then(searchYears => {
      if (searchYears) {
        // Don't need to remove Senior students
        setCriteria(previous => {
          return {
            ...previous,
            cohortYear: searchYears
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    if (criteria.cohortYear) {
      const getCount = async () => {
        const result = await SolrService.getSearchCount(
          user,
          {
            ...criteria,
            engaged: ['true'],
            ...(user.orgId ? { customerOrgs: [user.orgId?.toString()] } : null),
            favColleges: orgs.map(e => e.toString())
          },
          {}
        )
        if (result?.searchCount) {
          setCount(result.searchCount)
        } else {
          setCount(-1)
        }
      }
      getCount()
    }
  }, [orgs, criteria, user])

  return (
    <div className='display-flex align-items-center justify-content-center'>
      <div className='cb-font-weight-medium' role='status'>
        Students in Peer Group: {numberFormatter(count)}
      </div>
      <Tooltip
        ariaLabel='students in peer group count'
        id='students-in-pg-count'
        message={[
          'The Students in Peer Group reflects the number of high school students in Search that you have not yet licensed who have added one or more of your selected peer institutions to their college lists through BigFuture.',
          'Students are only counted once even if they have more than one peer institution on their college lists. The actual number of students included in your searches using this Peer Group will vary based on the criteria you select and the pool of students at the time of your order.'
        ]}
      />
    </div>
  )
}

export default PeerVolume
