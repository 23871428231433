import { Spacer } from '@cb/apricot-react'
import { AccordionTableData, GlobalFilter, PageSize, Pagination, useTableContext } from '@msss/ui-components'
import { useCallback } from 'react'
import { Subtitle } from './Subtitle'
import Departments from './Departments'

const OrgResultsTable = () => {
  const { data, globalFilter, gotoPage, page, pageCount, pageIndex, pageSize, rows, setGlobalFilter, setPageSize } =
    useTableContext()

  const renderHeader = useCallback(
    ({ row }) => (
      <div id={`login-as-result-${row.values.orgId}-header`} style={{ width: '100%' }}>
        <div className='row display-flex justify-content-between'>
          <h2 className='cb-h6'>{row.values.orgName?.replace(/&amp;/g, '&') || 'Untitled Institution'}</h2>
        </div>
        <p className='cb-accordion-heading-content' id={`login-as-result-${row.values.orgId}-header-sub-title`}>
          <Subtitle row={row} />
        </p>
      </div>
    ),
    []
  )

  const renderPanel = useCallback(
    ({ row }) => (
      <Departments
        orgId={row.values.orgId}
        accordionOrgId={row.values.orgId}
        orgName={row.values.orgName}
        diCode={row.values.diCode}
      />
    ),
    []
  )

  return (
    <>
      {/* SEARCH */}
      <GlobalFilter
        globalFilter={globalFilter}
        id='login-as-search-for-orgs'
        label='Search by keyword or DI Code'
        placeholder='Search by keyword or DI Code'
        setGlobalFilter={setGlobalFilter}
        useSearchBtn
      />

      {/* If not results found */}
      {rows.length === 0 && <p className='cb-font-weight-bold cb-red1-color'>Enter a valid keyword or DI Code.</p>}

      {/* If results found show the table */}
      {rows.length > 0 && rows.length < data.length && (
        <>
          <Spacer size='16' />
          <div className='row'>
            <div className='col-sm-12 cb-align-right'>
              <PageSize pageSize={pageSize} handlePageSize={setPageSize} />
              <span className='cb-padding-8'>
                ({data.length} {data.length === 1 ? 'Record' : 'Records'})
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <AccordionTableData id='login-as-results' renderHeader={renderHeader} renderPanel={renderPanel} />
            </div>
          </div>
        </>
      )}

      {rows.length > 0 && rows.length < data.length && page.length < data.length && (
        <div className='row'>
          <div className='col-xs-12 cb-align-center cb-margin-top-24'>
            <PageSize pageSize={pageSize} handlePageSize={setPageSize} />
            <Spacer size='16' />
            <Pagination gotoPage={gotoPage} id='' pageCount={pageCount} pageIndex={pageIndex} />
          </div>
        </div>
      )}
    </>
  )
}

export default OrgResultsTable
