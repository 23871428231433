import { dateFormatter } from '@msss/ui-common'
import { SimpleTableData, Table } from '@msss/ui-components'
import { useMemo } from 'react'

interface TableProps {
  data: Array<object>
}

const SubscriptionTableALaCartes = ({ data }: TableProps) => {
  const columns = useMemo(
    () => [
      { Header: 'A La Carte', accessor: 'name' },
      {
        Header: 'Date',
        accessor: 'startDate',
        sortType: 'dateRange',
        dateRangeEndAccessor: 'endDate',
        Cell: ({ row }) => (
          <>
            {dateFormatter.format(new Date(row.original.startDate))} - {dateFormatter.format(new Date(row.original.endDate))}
          </>
        )
      },
      {
        Header: 'Quantity',
        accessor: 'quantity'
      }
    ],
    []
  )

  return (
    <>
      <div className='row'>
        <div className='col-xs-12'>
          <Table columns={columns} data={data}>
            <ALaCartesTable />
          </Table>
        </div>
      </div>
    </>
  )
}

export default SubscriptionTableALaCartes

const ALaCartesTable = () => {
  return <SimpleTableData id='pf-ala-cart' showAllRows />
}
