import { dateFormatter } from '@msss/ui-common'
import React from 'react'
import { QuoteInfo } from './Interfaces'

const QuoteBasicInfo = ({ quoteInfo }: QuoteInfo) => {
  return (
    <div className='row cb-margin-top-24'>
      <div className='col-sm-4'>
        <p className='cb-font-weight-bold'>
          Quote for {quoteInfo.searchYear - 1} - {quoteInfo.searchYear}:
        </p>
        <p className='cb-margin-top-8'>{quoteInfo.name}</p>
      </div>
      <div className='col-sm-4'>
        <p className='cb-font-weight-bold'>Start/End Date of Subscription:</p>
        <p className='cb-margin-top-8'>
          {dateFormatter.format(new Date(quoteInfo.startDate))} - {dateFormatter.format(new Date(quoteInfo.endDate))}
        </p>
      </div>
    </div>
  )
}

export default QuoteBasicInfo
