import { useMutation, useQuery } from '@apollo/client'
import { Modal, PrimaryButton, NakedButton, Spacer, Select, ILabeledValue, Spinner } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import React, { useCallback, useEffect, useState } from 'react'
import { MOVE_SAVED_SEARCH } from '../../../../services/graphql/mutations'
import { GET_FOLDERS_OF_ORG, GET_PORTFOLIO_BY_CATEGORY_QUERY } from '../../../../services/graphql/queries'
import { IPortfolioFileType } from '../../../../interfaces/reference/IReference'

interface IMoveModal {
  savedSearchId: number
  currentFolderId?: number
  open: boolean
  setOpen: (value: boolean) => void
  category: IPortfolioFileType
}

// Modal used to move a Search to a Folder
export const MoveModal = ({ savedSearchId, currentFolderId, open, setOpen, category }: IMoveModal) => {
  const { user } = useSearchUserContext()

  // used to disable the move search button in case of an error
  const [hadError, setHadError] = useState(false)
  // to show the success message
  const [success, setSuccess] = useState<boolean>(false)
  // the folderId of the folder that the saved search will be moved to
  const [folderId, setFolderId] = useState('')
  const [folderName, setFolderName] = useState<string | undefined>(undefined)
  // the list of folders available
  const [folders, setFolders] = useState<ILabeledValue[]>([])

  // Appsync - mutation and query
  const { loading, error, data } = useQuery(GET_FOLDERS_OF_ORG, {
    variables: { orgId: user.orgId }
  })

  const [moveSearch, mutationResult] = useMutation(MOVE_SAVED_SEARCH, {
    refetchQueries: [
      { query: GET_FOLDERS_OF_ORG, variables: { orgId: user.orgId } },
      { query: GET_PORTFOLIO_BY_CATEGORY_QUERY, variables: { orgId: user.orgId, category: category } }
    ]
  })

  useEffect(() => {
    if (!loading && !error) {
      const folders: ILabeledValue[] = []
      if (data?.getFolders) {
        // the folder optons to move to. The current folder will be disabled to the user.
        data.getFolders.map(folder =>
          folders.push({ label: folder.name, value: folder.folderId, disabled: folder.folderId === currentFolderId })
        )
        // set the values sorted by ascending order of folder name
        setFolders(folders.sort((a, b) => a.label.localeCompare(b.label)))
      }
    }
  }, [data, loading, error])

  // If error set the error as true. This will help make the Move Search button to be disabled. Cant rely on mutationResult.called alone.
  useEffect(() => {
    if (mutationResult?.error) {
      mutationResult.reset()
      setHadError(true)
    }
  }, [mutationResult.error])

  useEffect(() => {
    if (mutationResult?.data) {
      setSuccess(true)
    }
  }, [mutationResult.data])

  const handleMove = useCallback(() => {
    setFolderName(folders.find(item => item.value.toString() === folderId)?.label)
    moveSearch({ variables: { savedSearch: { folderId, savedSearchIds: [savedSearchId] } } })
  }, [setFolderName, moveSearch, folders, folderId, savedSearchId])

  //   Only render the modal if the open state is true
  //   There are two views for the modal
  //   - Shows a list of folder names and Move button
  //   - Once the user has moved the search it shows a success message
  return open ? (
    <Modal
      actions={
        success
          ? [
              <PrimaryButton key='close' onClick={() => setOpen(false)}>
                Close
              </PrimaryButton>
            ]
          : [
              <NakedButton key='cancel' onClick={() => setOpen(false)} disabled={mutationResult.called}>
                Cancel
              </NakedButton>,
              <PrimaryButton
                key='move'
                onClick={handleMove}
                loading={mutationResult.called}
                disabled={mutationResult.called || hadError || loading || folderId === undefined || folderId.length == 0}
              >
                Move Search
              </PrimaryButton>
            ]
      }
      analytics
      analyticsTitle='msof-move-to-folder-saved-search'
      onClose={() => {
        mutationResult.reset()
        setOpen(false)
      }}
      closeButton={false}
      title='Move to'
      open={open}
      withFooter
      withHeader
    >
      <div>
        {success ? (
          <>
            <p className='cb-padding-8' style={{ backgroundColor: '#C9DFD3', border: '1px solid #1D7846' }}>
              The Search has been successfully moved to the folder <span className='cb-font-weight-bold'>{folderName}</span>
            </p>
            <Spacer />
          </>
        ) : (
          <>
            <p className='cb-padding-8'>Move the file to the chosen folder</p>
            <Spacer />
            {loading ? (
              <Spinner ariaLabel='Getting folders list' />
            ) : (
              <Select
                ariaLabel='Folder names'
                labelClassName='cb-font-weight-bold'
                labelNoValue='Select Folder'
                name='folderOptions'
                onChange={setFolderId}
                selectId={`folder-options`}
                value={folderId}
                values={folders}
                disabled={success}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  ) : null
}

export default MoveModal
