import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Input, NakedButton, PrimaryButton, Spacer, Spinner, ILabeledValue } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { SAVE_CRITERIA_PREF } from '../../../services/graphql/mutations'
import { GET_CRITERIA_PREF } from '../../../services/graphql/queries'
import { ICriteria } from '../../search/search-form/ICriteria'
import { IUserData } from '../../search/search-form/IUserData'
import { SavedCriteriaSectionProps } from '../../search/search-form/Search'
import { ISectionSummary } from '../../search/search-form/summary/ISectionSummary'
import CancelEditCriteriaDetailModal from './CancelEditCriteriaDetailModal'
import { ICriteriaPref } from './SavedCriteria'
// this is a style that a child of this comp is expecting already.  so we import it here
// as opposed to the child comp (also, the child can be dynamic so we do it at the parent for clarity)
import './SavedCriteriaDetail.scss'

interface ISavePref {
  userId: number
  orgId?: number
  name: string
  categoryGroupCd: number
  criteriaId?: number
  criteria: string
  userData?: string
}

interface IInput {
  name: string
  validationMsg: string | undefined
  validationType: 'success' | 'error' | 'general' | undefined
}

const SavedCriteriaDetail = ({
  preference,
  comp: Comp,
  setExpandedRow,
  setAddingNew,
  checkDupPrefName,
  setSuccess
}: {
  preference: Partial<ICriteriaPref>
  comp: React.FC<SavedCriteriaSectionProps>
  setExpandedRow: (value: number) => void
  setAddingNew: (value: ILabeledValue | undefined) => void
  checkDupPrefName: (name: string) => boolean
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const initPrefName = (): IInput => {
    const errorMessage = inputStringValidation(preference.name)
    return {
      name: preference.name ? preference.name : '',
      validationMsg: errorMessage ? errorMessage : '',
      validationType: errorMessage ? 'error' : undefined
    }
  }

  const { user } = useSearchUserContext()
  const [prefName, setPrefName] = useState<IInput>(initPrefName())
  const [criteria, setCriteria] = useState<ICriteria>()
  const [userData, setUserData] = useState<IUserData>()
  const [cancel, setCancel] = useState<boolean>(false)
  const [origPrefName, setOrigPrefName] = useState<string>(preference.name ? preference.name : '')

  const [getCriteria, { loading, error, data }] = useLazyQuery(GET_CRITERIA_PREF, { fetchPolicy: 'network-only' })
  // for cache update on a mutation, if you look at the params, you can print out
  // cache and see all the 'watched' queries as well as a list of cache.  cache is mapped by
  // __typename key which is automatically inserted as part of the result.  the value of the typename is
  // determined by the return type in schema.graphql for the get calls; mutation will look for typename AND
  // default key id / __id to find the correct entry in cache. for more complex obj, you will do the cache
  // update for CRUD your self (need more reading.  don't understand fully)
  // for every watched query, under fields: you can specify cache to update
  // in my case I want to update the pref list (in case of add new) refresh cache (in case of pref name change)
  // note the return on add new... you have to add your new entry to existing and return the result
  const [saveCriteria, mutateResult] = useMutation(SAVE_CRITERIA_PREF, {
    update(cache, { data }) {
      cache.modify({
        // will enter all the watched queries mutations it seems
        fields: {
          getCriteriaPreferences(existing = [], func) {
            if (preference.criteriaId) {
              // this is existing... we remove it then we can safely add the updated version
              const addme = cache.updateFragment(
                {
                  fragment: gql`
                    fragment NewCriteriaPreference on CriteriaPreference {
                      categoryGroupCd
                      createdBy
                      createdDate
                      criteriaId
                      lastModifiedBy
                      lastModifiedDate
                      name
                      uploadFileCount
                      uploadFileName
                    }
                  `
                },
                () => data.saveCriteriaPreference
              )
            } else {
              const addme = cache.writeFragment({
                data: data.saveCriteriaPreference,
                fragment: gql`
                  fragment NewCriteriaPreference on CriteriaPreference {
                    categoryGroupCd
                    createdBy
                    createdDate
                    criteriaId
                    lastModifiedBy
                    lastModifiedDate
                    name
                    uploadFileCount
                    uploadFileName
                  }
                `
              })
              return [...existing, addme]
            }
          }
        }
      })
    }
  })

  // on mount of new edit, clear the previous success message
  useEffect(() => {
    setSuccess(false)
  }, [])

  // pref passed from props, should contain the criteria id for the selected saved criteria
  useEffect(() => {
    if (preference.criteriaId) {
      getCriteria({
        variables: { criteriaId: preference.criteriaId, orgId: user.orgId, createdBy: preference.createdBy }
      })
    }
  }, [preference])

  // we check for result of the save mutation.  if we saved OK, we then close the
  // table row if we don't do this we will get render table while updating comp error
  useEffect(() => {
    if (mutateResult.data) {
      const criteriaId = mutateResult.data.saveCriteriaPreference
      if (criteriaId !== -1) {
        // close the accordion
        preference.criteriaId ? setExpandedRow(-1) : setAddingNew(undefined)
        // setsuccess message
        setSuccess(true)
      }
    }
  }, [mutateResult.data])

  const validateInputString = (name: string) => {
    const errorMessage = inputStringValidation(name)
    if (errorMessage) {
      setPrefName({ name, validationMsg: errorMessage, validationType: 'error' })
    } else if (checkDupPrefName(name) && name.trim().toLocaleLowerCase() !== origPrefName.trim().toLocaleLowerCase()) {
      setPrefName({ name, validationMsg: 'The preference name you entered already exists', validationType: 'error' })
    } else {
      setPrefName({ name, validationMsg: '', validationType: 'general' })
      setOrigPrefName(name)
    }
  }

  const handleCriteria = useCallback((criteriaData: ICriteria, summary: ISectionSummary): void => {
    setCriteria(prev => {
      const x: ICriteria = { ...prev, ...criteriaData }
      return Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0))
    })
  }, [])

  const handleUserData = (userDataData: IUserData): void => {
    setUserData(prev => {
      const x: IUserData = { ...prev, ...userDataData }
      return Object.fromEntries(
        Object.entries(x).filter(([key, value]) =>
          value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
        )
      )
    })
  }

  const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
    let pref: ISavePref
    // todo: zip files
    if (preference.criteriaId) {
      // existing
      pref = {
        userId: ensure(user.userId),
        orgId: ensure(user.orgId),
        categoryGroupCd: ensure(preference.categoryGroupCd),
        criteriaId: ensure(preference.criteriaId),
        name: prefName.name,
        criteria: JSON.stringify(criteria),
        userData: JSON.stringify(userData)
      }
    } else {
      // new
      pref = {
        userId: ensure(user.userId),
        orgId: ensure(user.orgId),
        categoryGroupCd: ensure(preference.categoryGroupCd),
        name: prefName.name,
        criteria: JSON.stringify(criteria),
        userData: JSON.stringify(userData)
      }
    }

    saveCriteria({
      variables: { saveCriteria: pref }
    })
  }

  if (loading || mutateResult.loading) {
    return <Spinner />
  }

  if (error || mutateResult.error) {
    return <p title='Preferences Error'>There was an error working with your preferences. {error}</p>
  }

  // data is the criteria detail will always be there due to having a preference
  // we want to render if add new (criteria id undefined) or first load (having data)
  // we do not want to render this if we have already saved.  since that is when
  // we collapes the row and thus comp is removed from DOM and will cause update
  // unmounted error
  if ((data || preference.criteriaId === undefined) && !mutateResult.data) {
    return (
      <>
        <Spacer />
        <div className='display-flex justify-content-between align-items-center'>
          <div style={{ minWidth: '50%' }}>
            <Input
              ariaLabel='criteria preference name'
              clearable
              floating
              label='Enter preference name'
              value={prefName.name}
              maxLength={50}
              onChange={e => {
                validateInputString(e.target.value)
              }}
              validation={prefName.validationType}
              validationMsg={prefName.validationMsg}
            />
          </div>
          <div className='display-flex align-items-center'>
            <PrimaryButton disabled={Boolean(prefName.validationMsg)} aria-describedby={'Save'} onClick={e => handleSave(e)}>
              Save and Close
            </PrimaryButton>
            <NakedButton onClick={() => setCancel(true)}>Cancel</NakedButton>
          </div>
          {cancel ? (
            <CancelEditCriteriaDetailModal
              criteriaId={preference.criteriaId}
              setAddingNew={setAddingNew}
              setExpandedRow={setExpandedRow}
              open={cancel}
              setOpen={setCancel}
            />
          ) : null}
        </div>
        <Spacer />
        <Comp
          selectedCriteria={data?.getCriteriaPreference.criteria}
          selectedUserData={data?.getCriteriaPreference.userData}
          handleCriteria={handleCriteria}
          handleUserData={handleUserData}
          myaccount={true}
        />
      </>
    )
  }
  return null
}

export default SavedCriteriaDetail

const inputStringValidation = (name: string | undefined): string => {
  let errorMessage = ''
  if (name) {
    name.trim().length > 50 ? (errorMessage = 'The name/title must be limited to 50 alphanumeric characters') : null
    name.trim().length === 0 ? (errorMessage = 'The name/title is required before the save continues.') : null
  } else {
    errorMessage = 'The name/title is required before the save continues.'
  }
  return errorMessage
}
