import './geography.scss'
import { FileUpload, Icon, Input, PrimaryButton } from '@cb/apricot-react'
import { TooltipHeader } from '@msss/ui-components'
import { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import FeatureProvider from '../../../../../context/FeatureProvider'
import useMedia from '../../../../../utils/hooks/useMedia'
import { IGeoZipSection } from './Geography'
import { GEOGRAPHY } from './Enums'
import { ICheck, IZipUpload } from '../../ICriteria'

const { SUPPORT_SITE_URL } = process.env

enum zipValidationState {
  general = 'general',
  success = 'success',
  error = 'error'
}
enum zipFileType {
  Excel = '.xlsx',
  Tab = '.tsv',
  Csv = '.csv',
  Txt = '.txt'
}

const ZipCodes = ({ updateData, data, zipUploadData, updateZipUpload }: IGeoZipSection) => {
  const shortwidth = useMedia(650, false, true)
  // used to determine if we have manually entered zip code vs file
  const [manualZipCount, setManualZipCount] = useState<number>(0)
  const [zipCodeValue, setZipCodeValue] = useState<string>('')
  const [zipValidation, setZipValidation] = useState<string>(zipValidationState.success)
  const [invalidZip, setInvalidZip] = useState<string[]>([])
  const [successCount, setSuccessCount] = useState(0)
  const [uploadedName, setUploadedName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string[]>([])

  const MAX_ZIP_ERROR = 'Limit of 10,000 zip codes has been exceeded. Please correct and try again.'
  const NO_VALID_ZIP = 'There are no valid zip codes in your file. Please correct and try again.'
  const EMPTY_FILE_ERROR = 'The uploaded file was empty. Please correct and try again.'

  const stringToArray = (fileData: string, delim: string): { zipResult: ICheck[]; uploadFile: boolean } => {
    const lines = fileData.split('\n')
    let invalid: string[] = []
    let uploadFile: boolean = true
    let count = 0
    let zip: ICheck[] = []
    const re2 = /^\d{2}?$/
    const re3 = /^\d{3}?$/
    const re5 = /^\d{5}?$/
    lines.forEach(row => {
      const zipcode = row.replaceAll('"', '').trim()
      // if delim is not null, file could be tab, csv, txt, xlsx
      if (delim && delim.length > 0) {
        const values = row.split(delim)
        values.forEach(value => {
          const zipValue = value.replaceAll('"', '').trim()
          const zipLength = zipValue.length
          if (re2.test(zipValue) || re3.test(zipValue) || re5.test(zipValue)) {
            count++
            if (
              zip.filter(e => {
                return e.label === zipcode
              }).length === 0
            ) {
              zip.push({ section: `zip${zipValue.length}`, label: zipValue, value: zipValue })
            }
          } else {
            if (zipcode.length > 0) invalid.push(zipcode)
          }
        })
      } else {
        //const zipLength = zipcode.length
        if (re2.test(zipcode) || re3.test(zipcode) || re5.test(zipcode)) {
          count++
          if (
            zip.filter(e => {
              return e.label === zipcode
            }).length === 0
          ) {
            zip.push({ section: `zip${zipcode.length}`, label: zipcode, value: zipcode })
          }
        } else {
          if (zipcode.length > 0) invalid.push(zipcode)
          //setInvalidZip([...invalidZip, zipcode])
        }
      }
    })
    // If ZIP codes are more than 10k
    if (count > 10000) {
      setErrorMessage([MAX_ZIP_ERROR])
      uploadFile = false
      invalid = []
      zip = []
      return { zipResult: zip, uploadFile }
    }

    // If there is not any valid ZIP codes
    if (invalid.length > 0 && zip.length === 0 && errorMessage.length === 0) {
      setErrorMessage([NO_VALID_ZIP])
      uploadFile = false
      invalid = []
      return { zipResult: zip, uploadFile }
    } else if (invalid.length === 0 && zip.length === 0 && errorMessage.length === 0) {
      // If file is empty
      setErrorMessage([EMPTY_FILE_ERROR])
      uploadFile = false
      return { zipResult: zip, uploadFile }
    }

    setInvalidZip(invalid)
    setSuccessCount(zip.length)
    return { zipResult: zip, uploadFile }
  }

  const handleZipUpload = (event: any) => {
    event.preventDefault()
    setErrorMessage([])
    setSuccessCount(0)
    setInvalidZip([])
    const file = event.target.files[0]
    const fileType = file?.type
    let delim = ''
    const fileName = file?.name
    if (
      fileName &&
      !(
        fileName.endsWith(zipFileType.Csv) ||
        fileName.endsWith(zipFileType.Excel) ||
        fileName.endsWith(zipFileType.Tab) ||
        fileName.endsWith(zipFileType.Txt)
      )
    ) {
      setErrorMessage([...errorMessage, 'The file is not .xlsx, .txt, .csv, or .tsv. Please correct and try again.'])
    } else if (fileName.length > 50) {
      setErrorMessage([...errorMessage, `The zip filename + ext can not be greater than 50 characters: found ${fileName.length}`])
    } else {
      if (fileName?.indexOf('.tsv') > -1 || fileName?.indexOf('.txt') > -1) {
        delim = '\t'
      }
      if (fileName?.indexOf('.csv') > -1) {
        delim = ','
      }
      if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        readExcel(file, fileName)
      } else if (file) {
        const fileReader = new FileReader()
        fileReader.readAsText(file)
        fileReader.onloadend = () => {
          const data = fileReader?.result
          if (data !== null) {
            const { zipResult, uploadFile } = stringToArray(data.toString(), delim)
            const zipData: IZipUpload = { zip: zipResult, fileName: fileName, successCount: zipResult.length.toString() }
            console.log('after processing all zip in file', zipData)
            if (uploadFile) {
              updateZipUpload(zipData)
              setUploadedName(fileName)
            }
          }
        }
      } else {
        updateZipUpload({ zip: [], fileName: '', successCount: '' })
      }
    }
  }

  const readExcel = (file: any, fileName: string) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
    fileReader.onloadend = () => {
      const bufferArray = fileReader.result
      const wb = XLSX.read(bufferArray, { type: 'buffer' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_csv(ws)
      let delim = ''

      if (fileName?.indexOf('.xlsx') > -1) {
        delim = ','
      }
      const { zipResult, uploadFile } = stringToArray(data, delim)
      console.log(zipResult, 'testzip')
      const zipData: IZipUpload = { zip: zipResult, fileName: fileName, successCount: zipResult.length.toString() }
      if (uploadFile) {
        updateZipUpload(zipData)
      }
    }
  }

  useEffect(() => {
    const zips = data.filter(e => e.section?.startsWith(GEOGRAPHY.zip))
    console.log('use effect data,zipuploaddata data.filter ', zips)
    console.log('use effect data,zipuploaddata data.filter of zipuploaddata', zipUploadData)
    console.log('use effect data,zipuploaddata data.filter of invalidzip', invalidZip)

    // if no zipuploaddata, then it is either a manual zip or new search
    if (zipUploadData) {
      // with zipuploaddata -- this is from load from saved search
      zipUploadData.fileName ? setUploadedName(zipUploadData.fileName) : setUploadedName('')
      if (zipUploadData.zip) {
        setSuccessCount(zipUploadData.zip.length)
        setManualZipCount(0)
        // do not remove this comment
        // do not blank out invalid because on a partial/full error, this will allow show of errors
        // the clearing will happen on a clean file or manual or new search
      }
    } else {
      setInvalidZip([])
      setUploadedName('')
      setSuccessCount(0)
      zips.length === 0 ? setManualZipCount(0) : setManualZipCount(zips.length)
    }
  }, [data, zipUploadData])

  useEffect(() => {
    if (errorMessage && errorMessage.length) {
      // need to manually remove file from hidden remove link so same file can be re-uploaded
      const rmFile: HTMLElement | null = document.querySelector('.ms-upload-zip-height a.cb-file-element-rm')
      rmFile?.click()
    }
  }, [errorMessage])

  return (
    <>
      <TooltipHeader
        header='Zip Code'
        id='upload-zip-code'
        tooltipMsg='You can upload only one file per order. Each file can include up to 10,000 Zip codes. The codes are either 2, 3, or 5 digits. Please close the file for proper uploading.'
      />
      <div className='display-flex justify-content-between'>
        <div className='display-flex align-items-center'>
          {/* allow fdbk to display, but hide with CSS - need to programmatically remove files */}
          {/* disable this when we have manually entered zip/s */}
          <FeatureProvider
            featureKey='zip_code_upload'
            altContent={
              <FileUpload
                className='ms-upload-zip-height'
                disabled
                id='zipcode-file-upload'
                label={
                  <>
                    <Icon name='upload' decorative className='cb-no-margin cb-padding-right-8' />{' '}
                    <span style={{ whiteSpace: 'nowrap' }} className='cb-font-size-large'>
                      {' '}
                      Upload Zip Code
                    </span>
                  </>
                }
              />
            }
          >
            <FileUpload
              className='ms-upload-zip-height'
              disabled={Boolean(uploadedName || manualZipCount > 0)}
              id='zipcode-file-upload'
              label={
                <>
                  <Icon name='upload' decorative className='cb-no-margin cb-padding-right-8' />{' '}
                  <span style={{ whiteSpace: 'nowrap' }} className='cb-font-size-large'>
                    {' '}
                    Upload Zip Code
                  </span>
                </>
              }
              onFileSelected={handleZipUpload}
            />
          </FeatureProvider>
          <span className='cb-font-weight-medium cb-margin-left-16 cb-margin-right-16'>OR</span>

          {/* disable this when we have 50 manually entered zips | has zipfile*/}
          <Input
            ariaLabel='zip code'
            clearable
            disabled={Boolean(uploadedName)}
            floating
            id='enter-zip-code'
            label='Enter Zip Code'
            maxLength={5}
            onChange={(event: { target: { value: string } }) => {
              setZipCodeValue(event.target.value)
              //  there is a way to do 'or' but i can't get it to work
              const re2 = /^\d{2}?$/
              const re3 = /^\d{3}?$/
              const re5 = /^\d{5}?$/
              if (re2.test(event.target.value) || re3.test(event.target.value) || re5.test(event.target.value)) {
                setZipValidation(zipValidationState.success)
              } else {
                setZipValidation(zipValidationState.error)
              }
            }}
            type='text'
            {...(zipValidation !== zipValidationState.success ? { validation: zipValidationState.error } : {})}
            validationMsg='You have entered an invalid zip code. Zip codes must numeric and 2, 3, or 5 digits in length.'
            value={zipCodeValue}
          />

          {/* disable this when error | 50 manually entered zips | has zipfile */}
          <PrimaryButton
            className='cb-margin-left-8'
            disabled={zipValidation === zipValidationState.error || uploadedName ? true : false || manualZipCount >= 50}
            id='apply-zip-codes-btn'
            onClick={e => {
              e.preventDefault
              if (zipCodeValue.length > 0) {
                if (updateData) {
                  setErrorMessage([])
                  updateData(true, { section: `zip${zipCodeValue.length}`, label: zipCodeValue, value: zipCodeValue })
                  setZipValidation(zipValidationState.success)
                  setZipCodeValue('')
                }
              }
            }}
            style={{ width: '35%' }}
          >
            Apply
          </PrimaryButton>
        </div>
        <div
          className='cb-gray5-bg cb-padding-left-16 cb-padding-right-24 cb-padding-top-8'
          role={'region'}
          aria-labelledby='zip-codes-need-help'
          style={{ height: shortwidth ? '100%' : '110px' }}
        >
          <h4 className='cb-h6' id='zip-codes-need-help'>
            Need Help<span className='sr-only'> with zip codes</span>?
          </h4>
          <a href={`${SUPPORT_SITE_URL}/search-criteria/geography`} rel='noopener noreferrer' target='_blank'>
            Tips for uploading Zip codes
          </a>
          <br />
          <a href='https://tools.usps.com/zip-code-lookup.htm' rel='noopener noreferrer' target='_blank'>
            Find ZIP codes at usps.com
          </a>
        </div>
      </div>
      {successCount > 0 && (
        <p
          className='cb-input-helper cb-validation-success cb-font-size-regular cb-margin-bottom-8 cb-margin-left-8'
          id='zip-upload-success'
        >
          {`${successCount} zip codes from your zip code file ${uploadedName} were successfully loaded.`}
        </p>
      )}
      {errorMessage && errorMessage.length > 0 && (
        <p
          className='cb-input-helper cb-validation-error cb-font-size-regular cb-margin-bottom-8 cb-margin-left-8'
          id='zip-upload-error'
        >
          {errorMessage}
        </p>
      )}
      {invalidZip && invalidZip.length > 0 && (
        <p
          className='cb-input-helper cb-validation-error cb-font-size-regular cb-margin-bottom-8 cb-margin-left-8'
          id='invalid-zip'
        >
          {`The following zip codes are invalid and were not loaded: ${
            invalidZip.length < 6 ? invalidZip.join(', ') : `${invalidZip.slice(0, 5).join(', ')} & ${invalidZip.length - 5} more`
          }`}
        </p>
      )}
    </>
  )
}

export default ZipCodes
