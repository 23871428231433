import { ViewSavedSearchDetailsProps } from '../SearchesOrdersContainer'
import SearchCriteria from '../order-details/criteria/SearchCriteria'

const CriteriaTab = ({ savedSearchId, accordionSavedSearchId }: ViewSavedSearchDetailsProps) => {
  return (
    <div>
      <SearchCriteria savedSearchId={savedSearchId} accordionSavedSearchId={accordionSavedSearchId} />
    </div>
  )
}

export default CriteriaTab
