import './SAGrid.scss'
import { Tooltip } from '@cb/apricot-react'
import cn from 'classnames'
import { useCallback, useState } from 'react'

interface SAGridProps {
  clusters: string[]
  setClusters: (clusters: string[]) => void
}

const NH_MIN = 1
const NH_MAX = 31

const HS_MIN = 1
const HS_MAX = 29

const SAGrid = ({ clusters, setClusters }: SAGridProps) => {
  const [nhGrid] = useState<Array<string>>(() =>
    [...Array(NH_MAX - NH_MIN + 1).keys()].map((key: number) => (key + NH_MIN < 10 ? `0${key + NH_MIN}` : `${key + NH_MIN}`))
  )
  const [hsGrid] = useState<Array<string>>(() =>
    [...Array(HS_MAX - HS_MIN + 1).keys()].map((key: number) => (key + HS_MIN < 10 ? `0${key + HS_MIN}` : `${key + HS_MIN}`))
  )
  const [focusedCluster, setFocusedCluster] = useState<string>('')
  const [tabbedToCluster, setTabbedToCluster] = useState<string>('')

  /*
   * helper fns
   */

  const cleanupClusters = useCallback((clusters: string[]) => {
    const cleanClusters = [...clusters].filter(
      (cluster: string, i: number) => i === clusters.findIndex((c: string) => c === cluster)
    ) // remove duplicates
    cleanClusters.sort() // keep in numeric order
    return cleanClusters
  }, [])

  const isHSSelected = useCallback(
    (hsCluster: string) => {
      return clusters.includes(`00${hsCluster}`) || clusters.includes('0000')
    },
    [clusters]
  )

  const isNHSelected = useCallback(
    (nhCluster: string) => {
      return clusters.includes(`${nhCluster}00`) || clusters.includes('0000')
    },
    [clusters]
  )

  const isClusterSelected = useCallback(
    (nhCluster: string, hsCluster: string) => {
      return (
        clusters.includes(`${nhCluster}${hsCluster}`) ||
        clusters.includes(`${nhCluster}00`) ||
        clusters.includes(`00${hsCluster}`) ||
        clusters.includes('0000')
      )
    },
    [clusters]
  )

  /*
   * keyboard helpers
   */

  const handleArrowKeyNavigation = useCallback((e: any, nhCluster: string, hsCluster: string) => {
    switch (e.key) {
      case 'ArrowUp': {
        let newNhCluster
        if (nhCluster === '00') {
          newNhCluster = NH_MAX
        } else if (nhCluster === `${NH_MIN}`) {
          newNhCluster = '00'
        } else {
          newNhCluster = Number(nhCluster) - 1
        }
        const gridCell = document.getElementById(`sa-select-${newNhCluster}${hsCluster}`)
        gridCell?.scrollIntoView({ behavior: 'smooth' })
        gridCell?.focus()
        break
      }
      case 'ArrowDown': {
        let newNhCluster
        if (nhCluster === '00') {
          newNhCluster = NH_MIN
        } else if (nhCluster === `${NH_MAX}`) {
          newNhCluster = '00'
        } else {
          newNhCluster = Number(nhCluster) + 1
        }
        const gridCell = document.getElementById(`sa-select-${newNhCluster}${hsCluster}`)
        gridCell?.scrollIntoView({ behavior: 'smooth' })
        gridCell?.focus()
        break
      }
      case 'ArrowLeft': {
        let newHsCluster
        if (hsCluster === '00') {
          newHsCluster = HS_MAX
        } else if (hsCluster === `${HS_MIN}`) {
          newHsCluster = '00'
        } else {
          newHsCluster = Number(hsCluster) - 1
        }
        const gridCell = document.getElementById(`sa-select-${nhCluster}${newHsCluster}`)
        gridCell?.focus()
        break
      }
      case 'ArrowRight': {
        let newHsCluster
        if (hsCluster === '00') {
          newHsCluster = HS_MIN
        } else if (hsCluster === `${HS_MAX}`) {
          newHsCluster = '00'
        } else {
          newHsCluster = Number(hsCluster) + 1
        }
        const gridCell = document.getElementById(`sa-select-${nhCluster}${newHsCluster}`)
        gridCell?.focus()
        break
      }
      default: {
        break // not an arrow key - no action taken
      }
    }
  }, [])

  const isEnterOrSpace = useCallback((e: any) => e.key === 'Enter' || e.key === ' ', [])

  /*
   * selection handler fns
   */

  const handleAllSelection = useCallback(() => {
    setClusters(previousClusters => {
      const newClusters: string[] = []
      if (!previousClusters.includes('0000')) {
        newClusters.push('0000')
      }
      return newClusters
    })
  }, [setClusters])

  const handleHSSelection = useCallback(
    (hsCluster: string) => {
      setClusters((previousClusters: string[]) => {
        let newClusters: string[] = [...previousClusters]
        if (previousClusters.includes(`00${hsCluster}`)) {
          // already highlighting column - remove it & any individual clusters
          newClusters = newClusters.filter((cluster: string) => !cluster.endsWith(hsCluster))

          // check for highlighted rows
          for (const nhC of nhGrid) {
            if (newClusters.includes(`${nhC}00`)) {
              newClusters = newClusters.filter((cluster: string) => cluster !== `${nhC}00`)
              for (const hsC of hsGrid) {
                if (hsC !== hsCluster && !newClusters.includes(`00${hsC}`)) {
                  newClusters.push(`${nhC}${hsC}`)
                }
              }
            }
          }
        } else if (previousClusters.includes('0000')) {
          // already highlighting column via all - remove it & add all other columns
          newClusters = [] // remove all
          for (const hsC of hsGrid) {
            if (hsC !== hsCluster) {
              newClusters.push(`00${hsC}`)
            }
          }
        } else {
          // not already highlighting column - add it
          newClusters = newClusters.filter((cluster: string) => !cluster.endsWith(hsCluster))
          newClusters.push(`00${hsCluster}`)

          // check to see if completed any rows
          for (const nhC of nhGrid) {
            let rowCompleted = true
            for (const hsC of hsGrid) {
              if (hsC !== hsCluster && !newClusters.includes(`${nhC}${hsC}`) && !newClusters.includes(`00${hsC}`)) {
                rowCompleted = false
              }
            }
            if (rowCompleted) {
              newClusters = newClusters.filter((cluster: string) => !cluster.startsWith(nhC))
              newClusters.push(`${nhC}00`)
            }
          }

          let allIncluded = true
          for (const hsC of hsGrid) {
            if (!newClusters.includes(`00${hsC}`)) {
              allIncluded = false
            }
          }
          if (allIncluded) {
            // all are selected
            return ['0000']
          }
        }
        return cleanupClusters(newClusters)
      })
    },
    [nhGrid, hsGrid, cleanupClusters, setClusters]
  )

  const handleNHSelection = useCallback(
    (nhCluster: string) => {
      setClusters((previousClusters: string[]) => {
        let newClusters: string[] = [...previousClusters]
        if (previousClusters.includes(`${nhCluster}00`)) {
          // already highlighting row - remove it & any individual clusters
          newClusters = newClusters.filter((cluster: string) => !cluster.startsWith(nhCluster))

          // check for highlighted rows
          for (const hsC of hsGrid) {
            if (newClusters.includes(`00${hsC}`)) {
              newClusters = newClusters.filter((cluster: string) => cluster !== `00${hsC}`)
              for (const nhC of nhGrid) {
                if (nhC !== nhCluster && !newClusters.includes(`${nhC}00`)) {
                  newClusters.push(`${nhC}${hsC}`)
                }
              }
            }
          }
        } else if (previousClusters.includes('0000')) {
          // already highlighting row via all - remove it & add all other rows
          newClusters = [] // remove all
          for (const nhC of nhGrid) {
            if (nhC !== nhCluster) {
              newClusters.push(`${nhC}00`)
            }
          }
        } else {
          // not already highlighting row
          newClusters = newClusters.filter((cluster: string) => !cluster.startsWith(nhCluster))
          newClusters.push(`${nhCluster}00`)

          // check to see if completed any columns
          for (const hsC of hsGrid) {
            let columnCompleted = true
            for (const nhC of nhGrid) {
              if (nhC !== nhCluster && !newClusters.includes(`${nhC}${hsC}`) && !newClusters.includes(`${nhC}00`)) {
                columnCompleted = false
              }
            }
            if (columnCompleted) {
              newClusters = newClusters.filter((cluster: string) => !cluster.endsWith(hsC))
              newClusters.push(`00${hsC}`)
            }
          }

          let allIncluded = true
          for (const nhC of nhGrid) {
            if (!newClusters.includes(`${nhC}00`)) {
              allIncluded = false
            }
          }
          if (allIncluded) {
            // all are selected
            return ['0000']
          }
        }
        return cleanupClusters(newClusters)
      })
    },
    [nhGrid, hsGrid, cleanupClusters, setClusters]
  )

  const handleClusterSelection = useCallback(
    (nhCluster: string, hsCluster: string) => {
      const selectedCluster = `${nhCluster}${hsCluster}`
      setClusters((previousClusters: string[]) => {
        if (previousClusters.includes(selectedCluster)) {
          // exact cluster found - remove it
          return previousClusters.filter((cluster: string) => cluster !== selectedCluster)
        } else if (previousClusters.includes('0000')) {
          // cluster found in all - remove it and add all others
          const newClusters: string[] = [] // remove all
          for (const hsC of hsGrid) {
            if (hsC !== hsCluster) {
              newClusters.push(`00${hsC}`)
            }
          }
          for (const nhC of nhGrid) {
            if (nhC !== nhCluster) {
              newClusters.push(`${nhC}00`)
            }
          }
          return newClusters
        } else {
          let found = false
          let newClusters = [...previousClusters]
          if (previousClusters.includes(`00${hsCluster}`)) {
            // cluster found in column
            found = true
            newClusters = newClusters.filter((cluster: string) => cluster !== `00${hsCluster}`)
            for (const nhC of nhGrid) {
              if (nhC !== nhCluster && !newClusters.includes(`${nhC}00`)) {
                newClusters.push(`${nhC}${hsCluster}`)
              }
            }
          }
          if (previousClusters.includes(`${nhCluster}00`)) {
            // cluster found in row
            found = true
            newClusters = newClusters.filter((cluster: string) => cluster !== `${nhCluster}00`)
            for (const hsC of hsGrid) {
              if (hsC !== hsCluster && !newClusters.includes(`00${hsC}`)) {
                newClusters.push(`${nhCluster}${hsC}`)
              }
            }
          }
          if (found) {
            // cluster found in column / row - return modified cluster list
            return cleanupClusters(newClusters)
          } else {
            // cluster not found anywhere
            // check to see if cluster completes column
            let columnCompleted = true
            for (const nhC of nhGrid) {
              if (nhC !== nhCluster && !newClusters.includes(`${nhC}${hsCluster}`) && !newClusters.includes(`${nhC}00`)) {
                columnCompleted = false
              }
            }
            if (columnCompleted) {
              newClusters = newClusters.filter((cluster: string) => !cluster.endsWith(hsCluster))
              newClusters.push(`00${hsCluster}`)
            }
            // check to see if cluster completes row
            let rowCompleted = true
            for (const hsC of hsGrid) {
              if (hsC !== hsCluster && !newClusters.includes(`${nhCluster}${hsC}`) && !newClusters.includes(`00${hsC}`)) {
                rowCompleted = false
              }
            }
            if (rowCompleted) {
              newClusters = newClusters.filter((cluster: string) => !cluster.startsWith(nhCluster))
              newClusters.push(`${nhCluster}00`)
            }
            if (!columnCompleted && !rowCompleted) {
              // did not complete column or row - add it
              newClusters.push(selectedCluster)
            } else {
              let allIncluded = true
              for (const hsC of hsGrid) {
                if (!newClusters.includes(`00${hsC}`)) {
                  allIncluded = false
                }
              }
              if (allIncluded) {
                // all are selected
                return ['0000']
              }
            }
            return cleanupClusters(newClusters)
          }
        }
      })
    },
    [nhGrid, hsGrid, cleanupClusters, setClusters]
  )

  /*
   * focus handler fns
   */

  // never reset tabbed to value, only focused value
  const handleBlur = useCallback(() => setFocusedCluster(''), [])

  const handleAllFocus = useCallback(() => {
    setFocusedCluster('0000')
    setTabbedToCluster('0000')
  }, [])

  const handleHSFocus = useCallback((hsCluster: string) => {
    setFocusedCluster(`00${hsCluster}`)
    setTabbedToCluster(`00${hsCluster}`)
  }, [])

  const handleNHFocus = useCallback((nhCluster: string) => {
    setFocusedCluster(`${nhCluster}00`)
    setTabbedToCluster(`${nhCluster}00`)
  }, [])

  const handleClusterFocus = useCallback((nhCluster: string, hsCluster: string) => {
    setFocusedCluster(`${nhCluster}${hsCluster}`)
    setTabbedToCluster(`${nhCluster}${hsCluster}`)
  }, [])

  return (
    <div className='sa-grid-container'>
      <table
        aria-activedescendant={focusedCluster ? `sa-select-${focusedCluster}` : undefined}
        className='cb-table cb-table-light sa-grid'
        role='grid'
      >
        <caption>
          <div className='grid-title columns'>
            <span className='sr-only'>columns are </span>High School Clusters
          </div>
          <div className='grid-title rows'>
            <span className='sr-only'>rows are </span>Educational Neighborhood Clusters
          </div>
        </caption>
        <thead role='rowgroup'>
          <tr role='row'>
            <td
              aria-label='Educational Neighborhood cluster ALL, High School cluster ALL'
              aria-selected={clusters.includes('0000')}
              className={cn(
                'cb-font-weight-bold header',
                clusters.includes('0000') && 'selected',
                focusedCluster === '0000' && 'focused'
              )}
              id='sa-select-0000'
              onBlur={handleBlur}
              onClick={handleAllSelection}
              onFocus={handleAllFocus}
              onKeyDown={(e: any) => {
                if (isEnterOrSpace(e)) {
                  e.preventDefault()
                  handleAllSelection()
                } else {
                  handleArrowKeyNavigation(e, '00', '00')
                }
              }}
              onMouseEnter={handleAllFocus}
              onMouseLeave={handleBlur}
              role='gridcell'
              tabIndex={!tabbedToCluster || tabbedToCluster === '0000' ? 0 : -1}
            >
              All
              <Tooltip tooltipId='sa-select-0000-tooltip' placement='top' trigger='sa-select-0000'>
                EN ALL, HS ALL
              </Tooltip>
            </td>
            {hsGrid.map((hsCluster: string) => (
              <th
                aria-label={`Educational Neighborhood cluster ALL, High School cluster ${hsCluster}`}
                aria-selected={isHSSelected(hsCluster)}
                className={cn(
                  'header',
                  isHSSelected(hsCluster) && 'selected',
                  (focusedCluster === `00${hsCluster}` || focusedCluster === '0000') && 'focused'
                )}
                id={`sa-select-00${hsCluster}`}
                key={hsCluster}
                onBlur={handleBlur}
                onClick={() => handleHSSelection(hsCluster)}
                onFocus={() => handleHSFocus(hsCluster)}
                onKeyDown={(e: any) => {
                  if (isEnterOrSpace(e)) {
                    e.preventDefault()
                    handleHSSelection(hsCluster)
                  } else {
                    handleArrowKeyNavigation(e, '00', hsCluster)
                  }
                }}
                onMouseEnter={() => handleHSFocus(hsCluster)}
                onMouseLeave={handleBlur}
                role='gridcell'
                tabIndex={tabbedToCluster === `00${hsCluster}` ? 0 : -1}
              >
                {Number(hsCluster)}
                <Tooltip tooltipId={`sa-select-00${hsCluster}-tooltip`} placement='top' trigger={`sa-select-00${hsCluster}`}>
                  EN ALL, HS {Number(hsCluster)}
                </Tooltip>
              </th>
            ))}
          </tr>
        </thead>
        <tbody role='rowgroup'>
          {nhGrid.map((nhCluster: string) => (
            <tr key={nhCluster} role='row'>
              <th
                aria-label={`Educational Neighborhood cluster ${nhCluster}, High School cluster ALL`}
                aria-selected={isNHSelected(nhCluster)}
                className={cn(
                  'header',
                  isNHSelected(nhCluster) && 'selected',
                  (focusedCluster === `${nhCluster}00` || focusedCluster === '0000') && 'focused'
                )}
                id={`sa-select-${nhCluster}00`}
                onBlur={handleBlur}
                onClick={() => handleNHSelection(nhCluster)}
                onFocus={() => handleNHFocus(nhCluster)}
                onKeyDown={(e: any) => {
                  if (isEnterOrSpace(e)) {
                    e.preventDefault()
                    handleNHSelection(nhCluster)
                  } else {
                    handleArrowKeyNavigation(e, nhCluster, '00')
                  }
                }}
                onMouseEnter={() => handleNHFocus(nhCluster)}
                onMouseLeave={handleBlur}
                role='gridcell'
                tabIndex={tabbedToCluster === `${nhCluster}00` ? 0 : -1}
              >
                {Number(nhCluster)}
                <Tooltip tooltipId={`sa-select-${nhCluster}00-tooltip`} placement='top' trigger={`sa-select-${nhCluster}00`}>
                  EN {Number(nhCluster)}, HS ALL
                </Tooltip>
              </th>
              {hsGrid.map((hsCluster: string) => {
                const selected = isClusterSelected(nhCluster, hsCluster)
                const focused = [`${nhCluster}${hsCluster}`, `${nhCluster}00`, `00${hsCluster}`, '0000'].includes(focusedCluster)
                return (
                  <td
                    aria-label={`Educational Neighborhood cluster ${nhCluster}, High School cluster ${hsCluster}`}
                    aria-selected={selected}
                    className={cn(selected && 'selected', focused && 'focused')}
                    id={`sa-select-${nhCluster}${hsCluster}`}
                    key={`${nhCluster}${hsCluster}`}
                    onBlur={handleBlur}
                    onClick={() => handleClusterSelection(nhCluster, hsCluster)}
                    onFocus={() => handleClusterFocus(nhCluster, hsCluster)}
                    onKeyDown={(e: any) => {
                      if (isEnterOrSpace(e)) {
                        e.preventDefault()
                        handleClusterSelection(nhCluster, hsCluster)
                      } else {
                        handleArrowKeyNavigation(e, nhCluster, hsCluster)
                      }
                    }}
                    onMouseEnter={() => handleClusterFocus(nhCluster, hsCluster)}
                    onMouseLeave={handleBlur}
                    role='gridcell'
                    tabIndex={tabbedToCluster === `${nhCluster}${hsCluster}` ? 0 : -1}
                  >
                    <Tooltip
                      tooltipId={`sa-select-${nhCluster}${hsCluster}-tooltip`}
                      placement='top'
                      trigger={`sa-select-${nhCluster}${hsCluster}`}
                    >
                      EN {Number(nhCluster)}, HS {Number(hsCluster)}
                    </Tooltip>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SAGrid
