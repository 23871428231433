import { InfoNotification, SecondaryButton, Spacer } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { ICampaignOrderRun, IRecommendation } from './ICampaignFaces'
import { ISavedSearchInput } from '../search/search-form/ISearchFaces'
import { useMutation } from '@apollo/client'
import { COPY_SEARCH, UPDATE_PI } from '../../services/graphql/mutations'
import { CopyModal } from '../msof/common/MsofModals'

interface ICopySearchResult {
  savedSearch: ISavedSearchInput
  excludedCriteria?: any
}

interface IShowData {
  orgId?: number
  data: IRecommendation[]
  expired: boolean
}

const ShowData = ({ orgId, data, expired }: IShowData) => {
  const navigate = useNavigate()

  const { user } = useSearchUserContext()

  const processRuns = (runs: ICampaignOrderRun[]) => {
    // do a reduce to get total names
    const totalPrevious = runs.reduce((total, curr): number => total + (curr.orderRunType === 1 ? curr.namesReported : 0), 0)
    // filter out non search order first
    const temp = runs.filter(r => r.orderRunType === 1)
    // get the dates out
    const tempDates = temp.map(r => new Date(r.processDate))
    // find the latest
    const tempLatest = tempDates.reduce((latest, curr) => (latest > curr ? latest : curr))
    const formatOption: Intl.DateTimeFormatOptions = {
      timeZone: 'UTC',
      year: 'numeric',
      day: 'numeric',
      month: 'long'
    }
    const dateFormtter = new Intl.DateTimeFormat('en-US', formatOption)

    return { totalPrevious, lastOrderDate: dateFormtter.format(tempLatest) }
  }

  const [copyResult, setCopyResult] = useState<ICopySearchResult | null>()
  const handleCopyCancel = () => setCopyResult(null)
  const [updateCampaignEstimate] = useMutation(UPDATE_PI)

  const [copySearch] = useMutation(COPY_SEARCH, { onCompleted: data => handleCopyComplete(data) })
  const [curentOrderId, setCurrentOrderId] = useState<number | null>()
  const [currentCampaignId, setCurrentCampaignId] = useState<string>()

  const handleCopyComplete = (data: any) => {
    const { savedSearch, excludedCriteria } = data.copySearch
    updateCampaignEstimate({
      variables: { orderId: curentOrderId, newOrderId: savedSearch.order.orderId, campaignId: currentCampaignId }
    })
    if (excludedCriteria && Object.keys(excludedCriteria).length > 0) {
      setCopyResult({ savedSearch, excludedCriteria })
    } else {
      navigate(`/search-students?savedSearchId=${data.copySearch.savedSearch.savedSearchId}`)
    }
  }

  const datamapper = (r: IRecommendation) => {
    const savedSearch = r.savedSearch
    const order = r.savedSearch.order
    const { totalPrevious, lastOrderDate } = processRuns(order.orderRuns)
    const handleCopy = () => {
      copySearch({ variables: { savedSearchId: r.savedSearch.savedSearchId, userId: user.userId } })
    }

    return (
      <div
        aria-labelledby={`order-number-${r.orderId} search-name-${r.orderId}`}
        className='cb-border-radius-16 cb-white-bg cb-padding-32 cb-padding-top-16 cb-padding-bottom-32 cb-margin-bottom-16'
        key={r.orderId}
        role='region'
      >
        {/* using role & aria-level to not have heading styles */}
        <div aria-level={4} id={`order-number-${r.orderId}`} role='heading'>
          ORDER NUMBER {r.orderId}
        </div>
        <Spacer size='48' />
        <h5 className='cb-h4' id={`search-name-${r.orderId}`}>
          {savedSearch.name}_PI
        </h5>
        <Spacer />
        <div className='cb-margin-right-16'>
          <span className='cb-border-right cb-padding-right-8'>Selected Cohorts: {r.cohortYears.replaceAll(',', ', ')}</span>
          <span className='cb-border-right cb-padding-right-8 cb-padding-left-8'>Last Order Date: {lastOrderDate}</span>
          <span className='cb-padding-left-8'>Previously licensed Name: {totalPrevious.toLocaleString()}</span>
        </div>
        <Spacer />
        <div className='display-flex justify-content-between'>
          <div className='cb-margin-right-16'>
            <span className='cb-margin-right-8 cb-border cb-border-radius cb-padding-bottom-4 cb-padding-top-4 cb-padding-left-8 cb-padding-right-8 '>
              New Available Names: {r.newAvailable.toLocaleString()}
            </span>
          </div>
          <div>
            <SecondaryButton
              onClick={() => {
                setCurrentOrderId(r.orderId)
                setCurrentCampaignId(r?.campaignId)
                handleCopy()
              }}
              id={`copy-pi-order-${r.orderId}`}
            >
              Edit<span className='sr-only'> {savedSearch.name}_PI</span>
            </SecondaryButton>
          </div>
          {copyResult && copyResult.excludedCriteria && (
            <CopyModal
              savedSearch={copyResult.savedSearch}
              excludedCriteria={copyResult.excludedCriteria}
              userId={user.userId}
              open={copyResult !== null}
              onCancel={handleCopyCancel}
            />
          )}
        </div>
      </div>
    )
  }

  const message = 'Please note the matches you have clicked on have expired. Matches on this page have been updated.'

  return (
    <div className='container'>
      {expired ? (
        <div>
          <InfoNotification dismissible title={message} />
        </div>
      ) : (
        ''
      )}
      <div className=''>
        <h3>Top Matches as of {new Date(data[0].campaignDate).toLocaleDateString()}</h3>
      </div>
      <Spacer />
      <div>
        The information provided is determined by the order criteria included in your institution's 20 most recent College Board
        Search orders within the last 15 months. This information does not constitute a recommendation or strategy from the
        College Board. All orders must comply with the Terms and Conditions of Student Search Service&reg;
      </div>
      <Spacer />
      <div>{data.map(datamapper)}</div>
      <Spacer />
    </div>
  )
}

export default ShowData
