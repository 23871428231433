import { ILabeledValue, Spacer } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useCallback, useEffect, useState } from 'react'
import CriteriaNAdditional from './CriteriaNAdditional'
import MajorGroups from './MajorGroups'
import { additionalOptions, excludeMajors, majorsChoiceOptions } from './Options'
import { ICriteria } from '../../ICriteria'
import { IUserData } from '../../IUserData'
import { ISavedCriteriaData, SavedCriteriaSectionProps } from '../../Search'
import { ISectionSummary } from '../../summary/ISectionSummary'
import { Section } from '../../summary/Section'
import { Summary } from '../../summary/Summary'
import { ReferenceService } from '../../../../../services/rest/ReferenceService'
import { IBits } from '../../summary/IBits'
import { AvailableMySavedCriteriaTypes } from '../../../../my-account/saved-criteria/options'

export interface ICriteriaNAdditional {
  setSavedCriteriaData: (value: ISavedCriteriaData) => void
  myaccount: boolean
  changed: boolean
  data: IMajorData
  updateData: ({ selectedAdditionalOptions, selectedMajorChoice, selectedMajors, sendUpdate }: Partial<IMajorData>) => void
}

export interface IMajorGroups {
  data: IMajorData
  updateData: ({ selectedAdditionalOptions, selectedMajorChoice, selectedMajors, sendUpdate }: Partial<IMajorData>) => void
  selectedMajorGroup: string
  setSelectedMajorGroup: (groupCode: string) => void
  majorGroups: Major[]
  majorsLists: any
}

export interface Major {
  dataGroupCode: string
  label: string
  value: string
}

export interface IMajorData {
  selectedAdditionalOptions: string[]
  selectedMajorChoice: string
  selectedMajors: string[]
  sendUpdate: boolean
}

const IntendedMajor = (props: SavedCriteriaSectionProps): JSX.Element => {
  const hasChanged = (): boolean => {
    if (
      selectedMajorGroup === '-2' &&
      data.selectedAdditionalOptions.length === 0 &&
      data.selectedMajorChoice === majorsChoiceOptions[0].value.toString() &&
      data.selectedMajors.length === 0 &&
      data.sendUpdate
    )
      return false
    else return true
  }

  const dataInit: IMajorData = {
    selectedAdditionalOptions: [],
    selectedMajorChoice: majorsChoiceOptions[0].value.toString(),
    selectedMajors: [],
    sendUpdate: true // used to slow down external calls
  }

  const loadFromSaved = (savedCriteria, userData): IMajorData => {
    const fromdb: IMajorData = {
      selectedAdditionalOptions: [],
      selectedMajorChoice: majorsChoiceOptions[0].value.toString(),
      selectedMajors: [],
      sendUpdate: false
    }
    userData ? (userData.majorChoice ? (fromdb.selectedMajorChoice = userData.majorChoice) : null) : null
    if (savedCriteria) {
      if (savedCriteria.major1) {
        const additionalRef = additionalOptions.map(e => e.value.toString())
        const additionalVal: string[] = []
        const rest: string[] = savedCriteria.major1.filter(e => {
          // parse out addition options
          if (additionalRef.includes(e)) {
            additionalVal.push(e)
            return false
          } else {
            return true
          }
        })
        fromdb.selectedAdditionalOptions = additionalVal
        // assign the rest
        fromdb.selectedMajors = rest
        fromdb.sendUpdate = true
      }
    }
    return fromdb
  }

  const { selectedCriteria, selectedUserData, handleCriteria, handleUserData, myaccount } = props
  const [data, setData] = useState<IMajorData>(dataInit)
  const [selectedMajorGroup, setSelectedMajorGroup] = useState<string>('-2')
  const [majorGroups, setMajorGroups] = useState<Array<Major>>([])
  const [majorsLists, setMajorsLists] = useState<any>({})

  const [savedCriteriaData, setSavedCriteriaData] = useState<ISavedCriteriaData>()

  const [changed, setChanged] = useState<boolean>(hasChanged)

  const setup = async (criteria?: ICriteria, userData?: IUserData) => {
    // https://reference-assets-dev.msss-nonprod.collegeboard.org/x-refs/MajorGroups.json
    const majList = await ReferenceService.getReferenceData('x-refs/MajorGroups')
    const groups: Major[] = []
    const majors: any = {}
    const list = majList.filter((item: { code: string }) => excludeMajors.indexOf(item.code) === -1)
    list?.forEach(({ code, description, majors: majorsToAdd }: { code: string; description: string; majors: any[] }) => {
      const dataGroupCode = `0${code}`
      groups.push({ dataGroupCode, label: description, value: dataGroupCode })
      majors[dataGroupCode] = majorsToAdd
        .filter((item: { code: any }) => excludeMajors.indexOf(String(item.code)) === -1)
        .map((major: any) => ({
          dataGroupCode,
          label: major.description,
          value: `${major.code}`
        }))
    })
    setMajorGroups(groups)
    setMajorsLists(majors)
    setData(loadFromSaved(criteria, userData))
  }

  useEffect(() => {
    // fetch major groups on load
    setup(selectedCriteria, selectedUserData)
  }, [])

  useEffect(() => {
    if (savedCriteriaData && savedCriteriaData.criteriaId !== -1) {
      setup(savedCriteriaData.criteria, savedCriteriaData.userData)
      setSavedCriteriaData({ ...savedCriteriaData, criteriaId: -1 })
    }
  }, [savedCriteriaData])

  useEffect(() => {
    if (data.sendUpdate) {
      const criteria: ICriteria = { major1: [...ensure(data.selectedMajors), ...ensure(data.selectedAdditionalOptions)] }
      handleCriteria(criteria, toSummary())
      setSavedCriteria(criteria)
      setChanged(hasChanged())
      // always process userdata
      const userDataData: IUserData = { majorChoice: data.selectedMajorChoice }
      if (handleUserData) {
        handleUserData(userDataData)
        setSavedUserData(userDataData)
        setChanged(hasChanged())
      }
    }
  }, [data.selectedMajors, data.selectedAdditionalOptions, data.selectedMajorChoice])

  // these are for creating my saved critera set in sfs
  const setSavedCriteria = useCallback((criteriaData: ICriteria): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: ICriteria = { ...prev.criteria, ...criteriaData }
        return { ...prev, criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0)) }
      }
      // prev undefined
      const x: ICriteria = criteriaData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.IntendedMajor,
        userData: {},
        criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0))
      }
    })
  }, [])

  const setSavedUserData = (userDataData: IUserData): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: IUserData = { ...prev.userData, ...userDataData }
        return {
          ...prev,
          userData: Object.fromEntries(
            Object.entries(x).filter(([key, value]) =>
              value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
            )
          )
        }
      }
      // prev undefinded
      const x: IUserData = userDataData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.SegmentAnalysis,
        userData: Object.fromEntries(
          Object.entries(x).filter(([key, value]) =>
            value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
          )
        ),
        criteria: {}
      }
    })
  }

  const clearSelections = (event: any) => {
    event.preventDefault()
    resetData()
    setSelectedMajorGroup('-2')
  }

  const removeMajorChoice = () => {
    updateData({ selectedMajorChoice: majorsChoiceOptions[0].value.toString() })
  }

  const toSummary = (): ISectionSummary => {
    const bits: IBits = {
      name: 'Major Choice',
      sort: 1,
      values: [majorsChoiceOptions.filter((opt: ILabeledValue) => opt.value === data.selectedMajorChoice)[0].label],
      remove: removeMajorChoice
    }
    return {
      section: Section.IM,
      data: [
        ...(data.selectedAdditionalOptions?.length || data.selectedMajors?.length ? [bits] : []),
        ...(data.selectedAdditionalOptions?.length
          ? [
              {
                remove: (label: string) => {
                  const value: any = additionalOptions.find((option: any) => label === option.label)?.value
                  const newSelectedOptions: string[] | undefined = data.selectedAdditionalOptions?.filter(
                    (option: string) => value !== option
                  )
                  updateData({ selectedAdditionalOptions: newSelectedOptions })
                },
                name: 'Additional Options',
                values: additionalOptions
                  .filter((option: ILabeledValue) => data.selectedAdditionalOptions?.includes(option.value.toString()))
                  .map((option: ILabeledValue) => option.label)
              }
            ]
          : []),
        ...(data.selectedMajors?.length
          ? [
              {
                name: 'Intended Major',
                categories: majorGroups
                  .map((majorGroup: Major) => ({
                    remove: (label: string) => {
                      const removedMajor: Major = majorsLists[majorGroup.value].find((major: Major) => label === major.label)
                      const newSelectedMajors = data.selectedMajors?.filter(
                        (majorCode: string) => majorCode !== majorGroup.value && majorCode !== removedMajor.value
                      )
                      updateData({ selectedMajors: newSelectedMajors })
                    },
                    label: majorGroup.label,
                    values: majorsLists[majorGroup.value]
                      ?.filter((major: Major) => data.selectedMajors?.includes(major.value))
                      .map((major: Major) => major.label)
                  }))
                  .filter((data: any) => data.values?.length)
              }
            ]
          : [])
      ]
    }
  }

  const resetData = () => {
    setData({
      selectedAdditionalOptions: [],
      selectedMajorChoice: majorsChoiceOptions[0].value.toString(),
      selectedMajors: [],
      sendUpdate: true
    })
    const criteria: ICriteria = { major1: [] }
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
    const userDataData: IUserData = { majorChoice: `${majorsChoiceOptions[0].value}` }
    if (handleUserData) {
      handleUserData(userDataData)
      setSavedUserData(userDataData)
      setChanged(hasChanged())
    }
  }

  const updateData = ({
    selectedAdditionalOptions,
    selectedMajorChoice,
    selectedMajors,
    sendUpdate = true
  }: {
    selectedAdditionalOptions?: string[]
    selectedMajorChoice?: string
    selectedMajors?: string[]
    sendUpdate?: boolean
  }) => {
    if (selectedAdditionalOptions) {
      setData((prev: any) => ({ ...prev, selectedAdditionalOptions, sendUpdate }))
    }
    if (selectedMajorChoice) {
      setData((prev: any) => ({ ...prev, selectedMajorChoice, sendUpdate }))
    }
    if (selectedMajors) {
      setData((prev: any) => ({ ...prev, selectedMajors, sendUpdate }))
    }
  }

  return (
    <>
      <div className='cb-margin-left-8'>
        <p>
          College Board asks test takers, except students taking the AP Exam, about their intended major. The student's recent
          choice is selected as a match. Since younger students are less likely to have an intended major, choose major groups
          instead.
        </p>
        <Spacer />
        <CriteriaNAdditional
          setSavedCriteriaData={setSavedCriteriaData}
          myaccount={myaccount}
          data={data}
          updateData={updateData}
          changed={changed}
        />
        <Spacer />
        <MajorGroups
          data={data}
          majorGroups={majorGroups}
          majorsLists={majorsLists}
          selectedMajorGroup={selectedMajorGroup}
          setSelectedMajorGroup={setSelectedMajorGroup}
          updateData={updateData}
        />
        <Spacer />
        <Summary summaryId='intended-major-summary' {...toSummary()} savedCriteria={savedCriteriaData} clear={clearSelections} />
      </div>
    </>
  )
}

export default IntendedMajor
