import { useLazyQuery } from '@apollo/client'
import { NakedButton, Modal, PrimaryButton } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { useCallback, useEffect } from 'react'
import { GET_CRITERIA_PREF } from '../../../services/graphql/queries'
import { IMySavedCriteriaModalControl } from '../../my-account/saved-criteria/MyCriteriaList'
import { AvailableMySavedCriteriaTypes } from '../../my-account/saved-criteria/options'
import { ISavedCriteriaData } from '../search-form/Search'
import './modals.scss'

interface ApplySavedCriteriaModalProps {
  openModal: IMySavedCriteriaModalControl
  handleApply: (value: ISavedCriteriaData) => void // apply selected criteria
  handleCancel: (value: IMySavedCriteriaModalControl) => void // cancel/close modal
  type: AvailableMySavedCriteriaTypes
  changed: boolean
}

const ApplySavedCriteriaModal = ({ openModal, handleApply, handleCancel, type, changed }: ApplySavedCriteriaModalProps) => {
  const [getCriteria, { loading, error, data }] = useLazyQuery(GET_CRITERIA_PREF, { fetchPolicy: 'network-only' })
  const { user } = useSearchUserContext()

  const apply = useCallback(() => {
    if (openModal.criteriaId && openModal.createdBy && openModal.criteriaId !== -1) {
      getCriteria({
        variables: { criteriaId: openModal.criteriaId, orgId: user.orgId, createdBy: openModal.createdBy }
      })
    }
  }, [openModal, user, getCriteria])

  const onCancel = useCallback(() => {
    handleCancel({ open: false })
  }, [])

  const onApply = useCallback(() => {
    apply()
  }, [apply])

  useEffect(() => {
    if (!changed) apply()
  }, [changed])

  useEffect(() => {
    if (data) {
      handleApply({
        criteriaId: ensure(openModal.criteriaId),
        criteria: data.getCriteriaPreference.criteria,
        userData: data.getCriteriaPreference.userData,
        type: type
      })
    }
  }, [data])

  return (
    <Modal
      actions={[
        <NakedButton key='cancel' onClick={onCancel}>
          Cancel
        </NakedButton>,
        <PrimaryButton key='apply' onClick={onApply} loading={loading} disabled={loading || data}>
          Apply
        </PrimaryButton>
      ]}
      disableHeightAdjustment={false}
      headerId='apply-saved-criteria-modal-header'
      modalId='apply-saved-criteria-modal'
      analytics
      analyticsTitle='apply-saved-criteria'
      open={openModal.open && changed}
      title={<h2 className='ms-modal-header-fontsize'>Apply Search Criteria</h2>}
      withFooter
      withHeader
      closeButton={false}
      onClose={onCancel}
    >
      <b>Are you sure you want to apply a Saved Criteria set and delete your previous selections?</b>
      {error && <b>The system encountered an error while processing your request</b>}
    </Modal>
  )
}

export default ApplySavedCriteriaModal
