import { useEffect, useRef } from 'react'

export enum KEYS {
  BACKSPACE = 'BACKSPACE',
  TAB = 'TAB',
  ENTER = 'ENTER',
  RETURN = 'RETURN',
  SHIFT = 'SHIFT',
  ESC = 'ESC',
  SPACE = 'SPACE',
  LEFT = 'LEFT',
  UP = 'UP',
  RIGHT = 'RIGHT',
  DOWN = 'DOWN',
  DEL = 'DEL',
  DELETE = 'DELETE'
}

const KEY_INFO = {
  BACKSPACE: { key: 'Backspace', keyCode: 8 },
  TAB: { key: 'Tab', keyCode: 9 },
  ENTER: { key: 'Enter', keyCode: 13 },
  RETURN: { key: 'Enter', keyCode: 13 },
  SHIFT: { key: 'Shift', keyCode: 16 },
  ESC: { key: 'Escape', keyCode: 27 },
  SPACE: { key: ' ', keyCode: 32 },
  LEFT: { key: 'ArrowLeft', keyCode: 37 },
  UP: { key: 'ArrowUp', keyCode: 38 },
  RIGHT: { key: 'ArrowRight', keyCode: 39 },
  DOWN: { key: 'ArrowDown', keyCode: 40 },
  DEL: { key: 'Delete', keyCode: 46 },
  DELETE: { key: 'Backspace', keyCode: 46 }
}

export enum KEY_EVENTS {
  KEYDOWN = 'keydown',
  KEYPRESS = 'keypress',
  KEYUP = 'keyup'
}

const KEY_EVENT_TYPES = new Set([KEY_EVENTS.KEYDOWN, KEY_EVENTS.KEYPRESS, KEY_EVENTS.KEYUP])

interface KeyEventParams {
  eleId?: string
  handler: (e: any) => void
  keyEvent?: KEY_EVENTS
  targetKey: string
}

export const useKeyEvent = ({ eleId, handler, keyEvent = KEY_EVENTS.KEYDOWN, targetKey }: KeyEventParams) => {
  const savedHandler = useRef<any>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (KEY_EVENT_TYPES.has(keyEvent) && (!targetKey || KEY_INFO[targetKey]) && typeof handler === 'function') {
      const eventListener = (e: any) => {
        if (!targetKey || e.key === KEY_INFO[targetKey].key || e.keyCode === KEY_INFO[targetKey].keyCode) {
          savedHandler.current(e)
        }
      }

      const element = eleId ? document.getElementById(eleId) : document
      element?.addEventListener(keyEvent, eventListener)
      return () => element?.removeEventListener(keyEvent, eventListener)
    }
  }, [handler])
}
