import { ErrorNotification, Icon } from '@cb/apricot-react'
import { ICMSMenu, ICMSMenuItem, Roles, getCMSMenusByIds, sanitizeHtml } from '@msss/ui-common'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LILP_MENUS } from '../../constants/content'
import { Permissions } from '../common/Permissions'

interface MenusProps {
  hasPortfolioData: boolean
  isPortfolioDataError: boolean
  menuContent: Record<string, any>[]
  portfolioData: any[]
}

const Menus = ({ hasPortfolioData, isPortfolioDataError, menuContent, portfolioData }: MenusProps) => {
  const [quickLinksContent, setQuickLinksContent] = useState<ICMSMenu>()
  const [savedSearchesTitle, setSavedSearchesTitle] = useState<string>()

  useEffect(() => {
    if (menuContent) {
      const menus = getCMSMenusByIds(menuContent, [LILP_MENUS.ids.quickLinks, LILP_MENUS.ids.recentSearches])
      const quickLinksMenu = menus.find((menu: ICMSMenu) => menu.id === LILP_MENUS.ids.quickLinks)
      const recentSearchesMenu = menus.find((menu: ICMSMenu) => menu.id === LILP_MENUS.ids.recentSearches)
      setQuickLinksContent(quickLinksMenu)
      setSavedSearchesTitle(recentSearchesMenu?.title)
    }
  }, [menuContent])

  return (
    <>
      {isPortfolioDataError && (
        <div className='col-xs-12 col-sm-10 offset-sm-1 cb-margin-bottom-48'>
          <ErrorNotification className='cb-white-bg' title='Error'>
            There was an error fetching Saved Searches.
          </ErrorNotification>
        </div>
      )}
      <div className='col-xs-12 col-sm-6 col-md-5 offset-md-1 col-lg-4'>
        <h2 className='cb-h4 cb-margin-bottom-16' id='recent-searches'>
          {sanitizeHtml(savedSearchesTitle)}
        </h2>
        <nav
          aria-labelledby='recent-searches'
          className='cb-menu-list cb-menu-list-vertical cb-menu-list-separated cb-menu-list-glyph cb-border-radius cb-box-shadow-light cb-white-bg'
          id='recent-searches-nav'
        >
          <ul>
            {hasPortfolioData &&
              portfolioData.map((item, i) => (
                <li key={item.savedSearchId}>
                  <Link
                    className='cb-menu-link'
                    id={`recent-searches-${i}`}
                    to={
                      item.orderStatus === '1' || item.orderStatus === '2'
                        ? `/search-students?savedSearchId=${item.savedSearchId}`
                        : `/my-searches?orderId=${item.orderId}`
                    }
                  >
                    <span>{item.name}</span>
                    <Icon decorative name='right' />
                  </Link>
                </li>
              ))}
            <li>
              <Permissions
                roles={Roles.SEARCH_AND_ADMIN}
                altContent={
                  <Link
                    id={`recent-searches-${portfolioData.length}`}
                    className='cb-menu-link cb-disabled'
                    onClickCapture={e => e.preventDefault()}
                    to='/my-searches'
                  >
                    <span>Go to My Searches, Orders & Files</span>
                    <Icon decorative name='right' />
                  </Link>
                }
              >
                <Link id={`recent-searches-${portfolioData.length}`} className='cb-menu-link' to='/my-searches'>
                  <span>Go to My Searches, Orders & Files</span>
                  <Icon decorative name='right' />
                </Link>
              </Permissions>
            </li>
          </ul>
        </nav>
      </div>
      <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4 offset-lg-2'>
        <h2 className='cb-h4 cb-margin-bottom-16' id='quick-links'>
          {sanitizeHtml(quickLinksContent?.title)}
        </h2>
        <nav
          aria-labelledby='quick-links'
          className='cb-menu-list cb-menu-list-vertical cb-menu-list-separated cb-menu-list-glyph cb-border-radius cb-box-shadow-light cb-white-bg'
          id='quick-links-nav'
        >
          <ul>
            {quickLinksContent?.menuItems?.map((menuItem: ICMSMenuItem, i: number) => (
              <li key={sanitizeHtml(menuItem.id)}>
                <a
                  id={`quick-links-${i}`}
                  className='cb-menu-link'
                  href={sanitizeHtml(menuItem.url)}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <span>{sanitizeHtml(menuItem.title)}</span>
                  <Icon decorative name='right' />
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Menus
