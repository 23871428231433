const getHeaders = async () => {
  const cb = (window as any).cb
  const headers = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'X-CB-Catapult-Authentication-Token': 'CBLogin' + cb.core.iam.getAuthSession().sessionId,
    'X-CB-Catapult-Authorization-Token': cb.core.iam.getJWTToken()
  }

  return headers
}

export const ServiceUtils = {
  getHeaders
}
