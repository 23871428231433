import { numberFormatter } from '@msss/ui-common'
import { Row } from 'react-table'

interface SubtitleProps {
  row: Row<object>
}

export const Subtitle = ({ row }: SubtitleProps) => (
  <>
    {row.values.itemTypeDescr ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>Type:</span>
        {row.values.itemTypeDescr};{''}
      </>
    ) : null}
    {row.values.lastModifiedDateFormatted ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>Modified Date:</span>
        {row.values.lastModifiedDateFormatted};{''}
      </>
    ) : null}
    {row.values.orderStatusDescr ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>Status:</span>
        {row.values.orderStatusDescr};{''}
      </>
    ) : (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>Status:</span>Saved;{''}
      </>
    )}
    {row.values.namesDesired ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>Names Ordered:</span>
        {numberFormatter(row.values.namesDesired)};{''}
      </>
    ) : null}
    {row.values.orderStatus && (row.values.orderStatus === '4' || row.values.orderStatus === '5') ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>Names Received:</span>
        {numberFormatter(row.values.namesDelivered ? row.values.namesDelivered : 0)};{''}
      </>
    ) : null}
    {row.values.orderStatus && (row.values.orderStatus === '4' || row.values.orderStatus === '5') ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>Total:</span>
        {''}
        {numberFormatter(row.values.totalAmount ? row.values.totalAmount : 0, { isCurrency: true })};{''}
      </>
    ) : null}
  </>
)
