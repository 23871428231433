import { ILabeledValue } from '@cb/apricot-react'
import React, { useCallback } from 'react'
import { Column } from 'react-table'
import { Subtitle } from './Subtitle'
import DownloadAvailableIcon from '../common/DownloadAvailableIcon'
import ListAllView from '../common/folders/ListAllView'
import ViewSavedSearchDetails from './order-details/ViewSavedSearchDetails'

interface ListAllSearchesProps {
  columns: Array<Column<object>>
  data: Array<object>
  hiddenColumns: string[]
  msofFilter: ILabeledValue[] | undefined
  msofGlobalFilter: string | undefined
  view: string
}

const ListAllSearches = ({ columns, data, hiddenColumns, msofFilter, msofGlobalFilter, view }: ListAllSearchesProps) => {
  const renderHeader = useCallback(
    ({ row }) => (
      <div id={`so-${row.values.savedSearchId}-header`} style={{ width: '100%' }}>
        <div className='row display-flex justify-content-between'>
          <h3 className='cb-h6'>{row.values.name?.replace(/&amp;/g, '&') || 'Untitled Search'}</h3>
          {row.values.downloadsPending && <DownloadAvailableIcon />}
        </div>
        <p className='cb-accordion-heading-content' id={`so-${row.values.savedSearchId}-header-sub-title`}>
          <Subtitle row={row} />
        </p>
      </div>
    ),
    []
  )

  const renderPanel = useCallback(
    ({ row }) => (
      <ViewSavedSearchDetails
        savedSearchId={row.values.savedSearchId}
        accordionSavedSearchId={row.values.savedSearchId}
        orderId={row.values.orderId}
        status={row.values.orderStatus}
        ownerUserId={row.values.ownerUserId}
        startDate={row.values.startDate}
        namesDesired={row.values.namesDesired}
        namesDelivered={row.values.namesDelivered}
        folderId={row.values.folderId}
      />
    ),
    []
  )

  return (
    <ListAllView
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
      id='searches-orders-view-all'
      msofFilter={msofFilter}
      msofGlobalFilter={msofGlobalFilter}
      renderHeader={renderHeader}
      renderPanel={renderPanel}
      view={view}
    />
  )
}

export default ListAllSearches
