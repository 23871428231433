export const degreeOptions = [
  { label: 'Specialized training or certificate program', value: '1', key: 'degree_1' },
  { label: 'Two-year associate of arts or sciences degree', value: '3', key: 'degree_3' },
  { label: `Bachelor's degree`, value: '6', key: 'degree_6' },
  { label: `Master's degree`, value: '8', key: 'degree_8' },
  { label: 'Doctoral or related degree', value: '10', key: 'degree_10' },
  { label: 'Other', value: '15', key: 'degree_15' },
  { label: 'Undecided', value: '16', key: 'degree_16' },
  { label: 'No response', value: '0', key: 'degree_0' }
]

export const financialAidOptions = [
  { label: 'Planning to apply for financial aid', value: 'Y', key: 'financialAid_Y' },
  { label: 'Not planning to apply for financial aid', value: 'N', key: 'financialAid_N' },
  { label: 'Undecided about financial aid', value: 'X', key: 'financialAid_X' },
  { label: 'No response', value: 'U', key: 'financialAid_U' }
]

export const religionOptions = [
  { label: 'Select All', value: 'all' },
  { label: 'Prefer not to answer', value: '108' },
  {
    label: 'African Methodist Episcopal',
    value: '2'
  },
  { label: 'Anglican', value: '4' },
  {
    label: 'Assembly of God',
    value: '8'
  },
  { label: `Baha'l`, value: '10' },
  { label: 'Baptist', value: '11' },
  { label: 'Southern Baptist Convention', value: '87' },
  { label: 'Buddhism', value: '15' },
  { label: 'Christian-Disciples', value: '18' },
  { label: 'Christian Reformed Church of America', value: '21' },
  { label: 'Church of the Brethren', value: '25' },
  { label: 'Church of Christ', value: '22' },
  { label: 'United Church of Christ', value: '93' },
  { label: 'Christian Science (Church of Christ, Scientist)', value: '23' },
  { label: 'Church of God', value: '24' },
  { label: 'Church of Jesus Christ of Latter-day Saints', value: '26' },
  { label: 'Church of the Nazarene', value: '27' },
  { label: 'Episcopal', value: '32' },
  { label: 'Hinduism', value: '46' },
  { label: 'Islam/Muslim/Moslem', value: '48' },
  { label: 'Judaism', value: '52' },
  { label: 'Evangelical Lutheran Church in America', value: '37' },
  { label: 'Lutheran Church-Missouri Synod', value: '55' },
  { label: 'Mennonite', value: '57' },
  { label: 'Methodist', value: '58' },
  { label: 'United Methodist', value: '94' },
  { label: 'Eastern Orthodox churches', value: '65' },
  { label: 'Pentecostal Bodies', value: '68' },
  { label: 'Presbyterian Church (USA)', value: '71' },
  { label: 'Reformed Church in America', value: '76' },

  { label: 'Roman Catholic', value: '80' },
  { label: 'Seventh-day Adventist', value: '84' },
  { label: 'Sikhism', value: '85' },
  { label: 'Society of Friends (Quaker)', value: '86' },
  { label: 'Unitarian Universalist Association', value: '91' },
  { label: 'Wesleyan Church', value: '95' },
  { label: 'Worldwide Church of God', value: '98' },
  { label: 'Other', value: '106' },
  { label: 'No Preference or affiliation', value: '107' },
  { label: 'No response', value: '0' }
]

export const religionChoiceOptions = [
  { label: 'Include all', value: '' },
  { label: 'Include only students who indicated a student religion', value: 'S' },
  { label: 'Include only students who indicated a student religious interest', value: 'C' },
  { label: 'Include students who indicated a student religion or religious interest', value: 'B' }
]

export const rotcOptions = [{ label: 'Planning to participate in ROTC during college', value: 'Y' }]
