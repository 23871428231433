import { Select, Spacer, Dropdown, DropdownItem, PrimaryButton } from '@cb/apricot-react'
import { PageContainer, useSearchUserContext } from '@msss/ui-components'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { siteName } from '../constants/app'
import { FeatureContext } from '../context/FeatureProvider'

import { getRoles } from '../utils/generalHelpers'
import { getAnyValidAdminOrgRole } from '../utils/tokens'

// need a select org drop down for user to change org

export function CurrentOrganization({ currPath }: { currPath: string }) {
  console.log('...currPath....', currPath)
  const PATH_ALLOW_ORG_SWITCH = '/my-searches'

  const navigate = useNavigate()

  const getOrgName = (oid: number = -1) => {
    const name = user.associations?.find(e => e.value === oid)?.label
    return name ? name : 'not found'
  }

  const getParentOrgId = (oid: number = -1) => {
    const parentOrgId = user.associations?.find(e => e.value === oid)?.parentOrgId
    return parentOrgId ? parentOrgId : -1
  }
  const { user, setUser } = useSearchUserContext()
  const [currentOrgName, setCurrentOrgName] = useState(getOrgName(user.orgId))
  const [parentOrgId, setParentOrgId] = useState(getParentOrgId(user.orgId))

  const updateOrg = (v: HTMLAnchorElement) => {
    // find the orgid of v and set it to the user.orgid
    const org = user.associations?.find(e => e.label === v.text)
    const orgId = org?.value as number
    const diCode = org?.diCode
    const parentOrgId = getParentOrgId(orgId)
    setCurrentOrgName(getOrgName(orgId))
    setParentOrgId(parentOrgId)
    setUser({
      ...user,
      orgId,
      parentOrgId,
      dicode: diCode,
      roles: getRoles(orgId, user)
    })
    if (orgId === parentOrgId) {
      // navigate to home page as MSOF is no longer available at org level log in
      navigate('/')
    }
  }

  const getItems = () =>
    currPath.startsWith(PATH_ALLOW_ORG_SWITCH) || currPath.endsWith('/') ? (
      <Dropdown
        callBack={v => updateOrg(v)}
        closeOnClick
        closeOnClickOutside
        dropdownId='select-org-dropdown'
        dropdownMenuId='select-org-dropdown-menu'
        title={currentOrgName}
      >
        {user.associations?.map(a => (
          <DropdownItem
            disabled={a.value === user.orgId}
            dropdownItemId={`select-org-dropdown-item-${a.value}`}
            key={a.value}
            label={a.label}
          />
        ))}
      </Dropdown>
    ) : (
      <div>
        <Spacer size='16' />
        <p className='cb-font-size-small'>{user.associations?.find(o => o.value === user.orgId)?.label}</p>
        <Spacer size='8' />
      </div>
    )

  return (
    <div
      aria-label={
        currPath.startsWith(PATH_ALLOW_ORG_SWITCH) || currPath.endsWith('/') ? 'Organization Selection' : 'Selected Organization'
      }
      className='cb-white-bg'
      role='region'
    >
      <div className='container display-flex justify-content-end'>{getItems()}</div>
    </div>
  )
}

function SelectOrganization() {
  const { user, setUser } = useSearchUserContext()

  //console.log('----SELECT ORG====', { hasAccess, orgId: user.orgId })
  const location = useLocation()
  const navigate = useNavigate()
  const [orgId, setOrgId] = useState(user.orgId)
  const loginAsOrgRole = getAnyValidAdminOrgRole()

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (orgId) {
      const org = user.associations?.find(e => e.value === orgId)
      setUser({
        ...user,
        orgId: orgId as number,
        dicode: org?.diCode,
        orgName: org?.label,
        parentOrgId: org?.parentOrgId,
        parentOrgName: org?.parentOrgName,
        roles: getRoles(orgId, user)
      })

      localStorage.removeItem('loginAsId')
    }
  }

  const handleLoginAs = (e: any) => {
    e.preventDefault()

    if (loginAsOrgRole) {
      localStorage.setItem('loginAsId', String(loginAsOrgRole.orgId))
      setUser({
        ...user,
        orgId: loginAsOrgRole.orgId,
        loginAsOrgId: loginAsOrgRole.orgId,
        roles: [loginAsOrgRole]
      })
    }

    navigate('/login-as')
  }

  console.log(location.pathname)

  return (
    <>
      {user.orgId === -1 && user.associations ? (
        <PageContainer pageLoaded='org-selection' siteName={siteName} title='Organization Selection'>
          <div className='cb-white-bg'>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-6'>
                  <Spacer size='72' />
                  <div className='cb-select'>
                    <h1 className='cb-h3' id='welcome'>
                      Welcome to College Board Search
                    </h1>
                    <Spacer size='16' />
                    <p>Select organization here.</p>
                    <Spacer />
                    <form>
                      <Select
                        css=''
                        floating
                        label='Select Organization'
                        labelNoValue='Select Organization'
                        onChange={value => setOrgId(parseInt(value))}
                        selectId='select-org'
                        value={orgId?.toString()}
                        values={user.associations}
                      />

                      <Spacer />
                      {loginAsOrgRole !== undefined && (
                        <>
                          <p>
                            Login to any institution in order to review their orders and/or reports.
                            <a
                              id='login-as-link'
                              onClick={e => handleLoginAs(e)}
                              className='cb-padding-left-8 cb-blue1-color'
                              href='#'
                            >
                              Login As Institution
                            </a>
                          </p>
                          <Spacer />
                        </>
                      )}
                      <PrimaryButton id='continue-with-selected-org-btn' type='submit' onClick={e => handleSubmit(e)}>
                        Continue
                      </PrimaryButton>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageContainer>
      ) : user.orgId && user.orgId > -1 ? (
        navigate(`${location.pathname}`)
      ) : null}
    </>
  )
}

export default SelectOrganization
