import React from 'react'

const NoFoldersFound = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-sm-12 cb-font-weight-bold cb-margin-bottom-8'>Folders</div>
        <div className='col-xs-12'>
          <div
            aria-label='sort results'
            className='display-flex justify-content-left align-items-center cb-black1-bg cb-margin-bottom-8'
            role='region'
          >
            <span className='cb-white-color cb-margin-left-16' style={{ padding: '11px 8px 12px 8px' }}>
              Name
            </span>
            <span aria-hidden className='cb-white-color cb-margin-left-8 cb-icon cb-sort' />
          </div>
        </div>
      </div>
      <div className='container cb-border-radius-bottom-left-8 cb-border-radius-bottom-right-8 cb-border cb-blue5-tint-1-border'>
        <div className='row'>
          <div className='col-xs-12 cb-padding-16'>Please select 'Create Folder' to start adding folders.</div>
        </div>
      </div>
    </div>
  )
}

export default NoFoldersFound
