export interface IExamRangeOption {
  min: number
  max: number
  step: number
  pushable: number
  disabled: boolean
}

export const satRadioOptions = [
  {
    label: 'No Selection',
    value: 'none',
    disabled: false,
    rangeOptions: { min: 0, max: 0, step: 0, pushable: 0, disabled: false }
  },
  {
    value: 'SAT',
    label: 'Any SAT test takers',
    disabled: false,
    rangeOptions: { min: 400, max: 1600, step: 10, pushable: 50, disabled: false }
  },
  {
    value: 'satRM',
    label: 'Total Score',
    disabled: false,
    rangeOptions: { min: 400, max: 1600, step: 10, pushable: 50, disabled: false }
  },
  {
    value: 'satR',
    label: 'Evidence Based Reading & Writing Section Score',
    disabled: false,
    rangeOptions: {
      min: 200,
      max: 800,
      step: 10,
      pushable: 50,
      disabled: false
    }
  },
  {
    value: 'satM',
    label: 'Math Section Score',
    disabled: false,
    rangeOptions: { min: 200, max: 800, step: 10, pushable: 50, disabled: false }
  },
  {
    value: 'satRT',
    label: 'Reading Test Score',
    disabled: false,
    rangeOptions: { min: 10, max: 40, step: 1, pushable: 5, disabled: false }
  },
  { value: 'satMT', label: 'Math Test Score', rangeOptions: { min: 10, max: 40, step: 1, pushable: 5, disabled: false } },
  {
    value: 'satWT',
    label: 'Writing Test Score',
    disabled: false,
    rangeOptions: { min: 10, max: 40, step: 1, pushable: 5, disabled: false }
  }
]
