import { FlagpoleApi, getHeaders } from '../../context/api'

type ServerError = { errorMessage: string }

/*
  API to get Subscription Volume info like annual volume, balance etc
*/
const getSubscriptionVolume = async (authOrgId: number, deptId: number) => {
  console.log('Looking up subscription volume Dept ID=%s', deptId)
  const headers = getHeaders()
  const userContext = { deptId }

  // Sample response
  // {
  //   "startDate": "2021-09-01T04:00:00.000Z",
  //   "endDate": "2022-09-01T03:59:59.999Z",
  //   "annualVolume": 99999999,
  //   "balance": 99957955,
  //   "transactional": false
  // }
  const response = await FlagpoleApi.post(`/subscriptions/volume?orgId=${authOrgId}`, { userContext }, { headers })
  console.log('response...', response.data)
  const subscriptionVolume = response.data
  return subscriptionVolume
}

export const FlagpolePublic = {
  getSubscriptionVolume
}
