import { useMutation, useQuery } from '@apollo/client'
import { numberFormatter } from '@msss/ui-common'
import React, { useEffect, useState } from 'react'
import { GET_QUOTE_QUERY, GET_SUBSCRIPTIONS_QUERY } from '../../../../services/graphql/queries'
import { QuoteAlacarte, QuoteInfo, QuoteSubscription } from './Interfaces'
import { useRefDataContext } from '../../../../context/RefDataContext'
import { Spacer, Spinner } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import { PRICE_CALCULATION } from '../../../../services/graphql/mutations'

export const getYearEndDate = (year: string) => new Date(Number(year), 7, 31, 23, 59, 59)
export interface IPriceAdjustment {
  subscriptionId: number
  featureTierId: number
  adjustmentPrice?: number
}

const QuoteFeatures = ({ quoteInfo }: QuoteInfo) => {
  const refData = useRefDataContext()
  // const [quote, setQuote] = useState()
  const [subscriptions, setSubscriptions] = useState<QuoteSubscription[]>([])
  const [alacartes, setAlacartes] = useState<QuoteAlacarte[]>([])
  const [purchasedAlacartes, setPurchasedAlacartes] = useState<QuoteAlacarte[]>([])
  const [planCost, setPlanCost] = useState<number>(0)
  const [purchasedPlanCost, setPurchasedPlanCost] = useState<number>(0)
  const [priceAdjustments, setPriceAdjustments] = useState<IPriceAdjustment[]>([])
  const { user } = useSearchUserContext()
  const [subtotal, setSubtotal] = useState<number>(0)
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  const {
    error: quoteError,
    loading: quoteLoading,
    data: quoteData
  } = useQuery(GET_QUOTE_QUERY, {
    variables: { quoteId: quoteInfo.quoteId }
  })

  const {
    data: purchasedData,
    loading: loadingPurchased,
    error: getSubscriptionsError
  } = useQuery(GET_SUBSCRIPTIONS_QUERY, { variables: { subscriberId: user.orgId } })

  const [calculatePrice, { loading: priceLoading, error: priceError, data: priceData }] = useMutation(PRICE_CALCULATION, {
    onCompleted: priceData => handlePriceCalculation(priceData)
  })

  const hasQuoteData: boolean = !quoteLoading && !quoteError && quoteData && quoteData.getQuote
  const hasPurchase: boolean = !loadingPurchased && !getSubscriptionsError && purchasedData && purchasedData.getSubscriptions

  const handlePriceCalculation = (data: any) => {
    if (data && data?.calculatePrice) {
      setSubtotal(data?.calculatePrice.totalPrice)
      setPlanCost(data?.calculatePrice.planPrice)
      setPurchasedPlanCost(data?.calculatePrice.purchasePlanPrice)
      if (data && data?.calculatePrice && data?.calculatePrice.priceAdjustments) {
        data?.calculatePrice.priceAdjustments?.forEach(adjustment => {
          if (adjustment.purchasePrice > 0) {
            priceAdjustments.push({
              subscriptionId: adjustment?.featureTierPurchaseId,
              featureTierId: adjustment?.featureTierId,
              adjustmentPrice: adjustment?.purchasePrice
            })
          }
          setPriceAdjustments(priceAdjustments)
        })
      }
    } else {
      setSubtotal(0)
      setPlanCost(0)
    }
  }

  const isFeatureInAdjustment = feature => {
    if (priceAdjustments.some(adjustment => feature.featureTierId === adjustment.featureTierId)) {
      // If found, return true
      return true
    }
    // If no matching featureId is found, return false
    return false
  }

  useEffect(() => {
    if (hasPurchase && hasQuoteData && Object.keys(refData?.featureTiers).length > 0) {
      // get list of features based on searchyear
      const featureTiersForSearchYear = refData?.featureTiers[quoteData.getQuote.searchYear]
      const alacartes: QuoteAlacarte[] = []
      purchasedData?.getSubscriptions
        .filter(
          plan =>
            plan.alacarte &&
            plan.searchYear === quoteData.getQuote.searchYear &&
            (plan.status === 'Active' || plan.status === 'Locked') &&
            new Date(plan.endDate) > currentDate
        )
        .map(item => {
          const featureTier: any = Object.values(featureTiersForSearchYear).find(
            ft => ft.featureTierId === item.featureTier.featureTierId
          )
          if (item?.alacarte && featureTier.features[0].variablePercent > 0) {
            alacartes.push({
              featureTierId: item.featureTier.featureTierId,
              featureName: featureTier?.features[0]?.name,
              feature: featureTier.features[0]
            })
          }
        })
      setPurchasedAlacartes(alacartes)
    }
  }, [purchasedData, quoteData])

  // using quota data and feature tier data to create subscription and ala carte details of the quote for display
  useEffect(() => {
    if (hasQuoteData && Object.keys(refData?.featureTiers).length > 0) {
      // const quoteDetails = quoteData.getQuote

      // get list of features based on searchyear
      const featureTiersForSearchYear = refData?.featureTiers[quoteData.getQuote.searchYear]

      const subscriptions: QuoteSubscription[] = []
      const alacartes: QuoteAlacarte[] = []

      quoteData.getQuote.featureTierItems.map(item => {
        const featureTier: any = Object.values(featureTiersForSearchYear).find(ft => ft.featureTierId === item.featureTierId)

        if (!featureTier?.alacarte) {
          subscriptions.push({
            featureTierId: item.featureTierId,
            featureName: featureTier?.name,
            count: item.count,
            annualVolume: featureTier?.annualVolume,
            price: featureTier?.price,
            features: featureTier?.features
          })
        } else {
          // check if ala-carte is not in purchased ala-carte list
          const isInPurchase = purchasedAlacartes?.findIndex(pa => pa?.featureTierId === item.featureTierId) > -1
          if (!isInPurchase)
            alacartes.push({
              featureTierId: item.featureTierId,
              featureName: featureTier?.features[0]?.name,
              feature: featureTier.features[0],
              count: item.count
            })
        }
      })
      setSubscriptions(subscriptions)
      setAlacartes(alacartes)
    }
  }, [hasQuoteData, purchasedAlacartes])

  useEffect(() => {
    if (quoteData && quoteData.getQuote && quoteData.getQuote?.featureTierItems?.length > 0) {
      console.log('...refData?.featureTiers...', refData?.featureTiers)
      const subscriptions = quoteData.getQuote?.featureTierItems?.map(sub => {
        const featureTiersForSearchYear = refData?.featureTiers[quoteData.getQuote.searchYear]
        const featureTier: any = Object.values(featureTiersForSearchYear).find(ft => ft.featureTierId === sub.featureTierId)
        return {
          featureTierId: featureTier ? featureTier?.featureTierId : sub.featureTierId,
          quantity: sub.count,
          alacarte: featureTier ? featureTier?.alacarte : false,
          searchYear: quoteData.getQuote.searchYear,
          isAdmin: false,
          purchasePrice: featureTier?.alacarte ? featureTier?.features[0]?.fixedPrice : featureTier?.price,
          subscriptionId: 0,
          subscriberId: user.orgId,
          status: 'Active',
          adjustmentPrice: 0
        }
      })
      calculatePrice({
        variables: {
          purchase: {
            orgId: user.orgId!,
            searchYear: quoteData.getQuote.searchYear,
            subscriptions: subscriptions
          }
        }
      })
    }
  }, [quoteData])

  return (
    <>
      <p className='cb-font-weight-bold'>Subscription(s)</p>
      <Spacer />
      {subscriptions.map((item, i) => (
        <div key={`quote_sub_${item.featureTierId}`}>
          <div className='row'>
            <div className='col-xs-6'>
              <p className=''>
                {item.featureName} ({item.count})
              </p>
            </div>
            <div className='col-xs-2'>
              <p>{numberFormatter(item.price ?? 0, { isCurrency: true })}</p>
            </div>
            <div className='col-xs-4'>
              <p>{numberFormatter(item.annualVolume ?? 0)}</p>
            </div>
          </div>

          <ul>
            {item.features?.map(feature => {
              return (
                <React.Fragment key={`quote_sub_feature_${feature.featureId}`}>
                  <li className='cb-margin-left-8'>{feature?.name}</li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
      ))}

      {((alacartes && alacartes.length > 0) || (priceAdjustments && priceAdjustments.length > 0)) && (
        <div>
          <p className='cb-font-weight-bold cb-padding-top-24'>Additional A La Carte Features</p>
          <Spacer />

          {alacartes.map((item, i) => (
            <div key={`quote_alacarte_${item.featureTierId}`}>
              <div className='row'>
                <div className='col-xs-6'>
                  <p className=''>
                    {item.featureName} ({item.count})
                    <br />
                    <span className='cb-margin-left-16 cb-font-size-small'>
                      {item.feature?.pricingPolicy === 'Fixed'
                        ? `${numberFormatter(item.feature?.fixedPrice ?? 0, { isCurrency: true })} / ${
                            item?.featureName?.startsWith('Conn') ? 'audience' : 'account'
                          }`
                        : `${item.feature?.variablePercent ?? 0}% added to the relevant plan price`}
                    </span>
                  </p>
                </div>

                <div className='col-xs-2'>
                  <p>
                    {item.feature?.pricingPolicy === 'Fixed'
                      ? `${numberFormatter(item.feature?.fixedPrice ?? 0, {
                          isCurrency: true
                        })}`
                      : `${numberFormatter(
                          ((item.feature?.variablePercent ?? 0) * ((planCost ?? 0) + (purchasedPlanCost ?? 0))) / 100,
                          {
                            isCurrency: true
                          }
                        )}`}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {planCost &&
            planCost > 0 &&
            priceAdjustments &&
            priceAdjustments.length > 0 &&
            purchasedAlacartes
              ?.filter(tier => isFeatureInAdjustment(tier))
              .map((item, i) => (
                <div key={`purchased-quote_alacarte_${item.featureTierId}-${i}`}>
                  <div className='row'>
                    <div className='col-xs-6'>
                      <p className=''>
                        {item.featureName}
                        <br />
                        <span className='cb-margin-left-16 cb-font-size-small'>
                          {item.feature?.pricingPolicy === 'Fixed'
                            ? `${numberFormatter(item.feature?.fixedPrice ?? 0, { isCurrency: true })} / account`
                            : `${item.feature?.variablePercent ?? 0}% added to the relevant plan price`}
                        </span>
                      </p>
                    </div>

                    <div className='col-xs-2'>
                      <p>
                        {item.feature?.pricingPolicy === 'Fixed'
                          ? `${numberFormatter(item.feature?.fixedPrice ?? 0, {
                              isCurrency: true
                            })}`
                          : `${numberFormatter(((item.feature?.variablePercent ?? 0) * (planCost ?? 0)) / 100, {
                              isCurrency: true
                            })}`}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      )}
      <div className='row'>
        <div className='col-xs-10 cb-align-right'>
          <span className='cb-padding-right-32 cb-margin-top-8'>SUB TOTAL: </span>
          {priceLoading && <Spinner ariaLabel='Calculating Price' />}
        </div>
        <div className='col-xs-2'>{numberFormatter(subtotal, { isCurrency: true })}</div>
      </div>
    </>
  )
}

export default QuoteFeatures
