import { useMutation } from '@apollo/client'
import { Modal, NakedButton, PrimaryButton, Spacer, Spinner } from '@cb/apricot-react'
import { MouseEvent, useState } from 'react'
import { DELETE_CRITERIA_PREF } from '../../../services/graphql/mutations'

const DeleteCriteriaDetail = ({
  criteriaId,
  name,
  type,
  disabled
}: {
  criteriaId: number
  name: string
  type: string
  disabled: boolean
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    setOpen(true)
  }

  return (
    <>
      <NakedButton disabled={disabled} noPadding onClick={handleDelete}>
        Delete
      </NakedButton>
      {open ? <DeleteSavedCriteriaModal criteriaId={criteriaId} name={name} type={type} open={open} setOpen={setOpen} /> : null}
    </>
  )
}

export default DeleteCriteriaDetail

const DeleteSavedCriteriaModal = ({
  criteriaId,
  name,
  type,
  open,
  setOpen
}: {
  criteriaId: number
  name: string
  type: string
  open: boolean
  setOpen: (value: boolean) => void
}) => {
  // this mutation will update cache removing the pref from it.
  const [deleteCriteria, mutateResult] = useMutation(DELETE_CRITERIA_PREF, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getCriteriaPreferences(existing, func) {
            // lets make sure delete is success.  mutation returns true/false (based on how you write
            // it in tne service sides...) in apollo policy apollo.ts we set the key to criteriaId
            // it will know to use the passed in variables criteriaId in the call to find and remove
            if (data.deleteCriteriaPreference) {
              return func.DELETE
            }
          }
        }
      })
    }
  })

  const handleDelete = () => {
    deleteCriteria({
      variables: { criteriaId }
    })
  }

  return open ? (
    <Modal
      actions={[
        <NakedButton key='cancel' onClick={() => setOpen(false)} disabled={mutateResult.called}>
          Cancel
        </NakedButton>,
        <PrimaryButton key='delete' onClick={handleDelete} loading={mutateResult.called} disabled={mutateResult.called}>
          Delete
        </PrimaryButton>
      ]}
      analytics
      analyticsTitle='mysavedcriteria-delete-saved-criteria'
      onClose={() => {
        mutateResult.reset()
        setOpen(false)
      }}
      closeButton={false}
      title='Delete saved criteria'
      open={open}
      withFooter
      withHeader
    >
      <span>Are you sure you want to delete the following criteria set? This action cannot be undone.</span>
      <Spacer />
      <div>Criteria type: {type}</div>
      <div>Name: {name}</div>
      {mutateResult.error ? (
        !mutateResult.data.deleteCriteriaPreference ? (
          <p className='cb-margin-top-8'>Delete failed please try again later</p>
        ) : null
      ) : null}
    </Modal>
  ) : null
}
