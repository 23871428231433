import { Spacer } from '@cb/apricot-react'
import { Loader, MsssError, PageContainer, Table, useSearchUserContext } from '@msss/ui-components'
import { useEffect, useMemo, useState } from 'react'
import { siteName } from '../../constants/app'
import refPath from '../../assets/ref_path.json'
import { ReferenceService } from '../../services/rest/ReferenceService'
import OrgResultsTable from './OrgResultsTable'

interface Organization {
  orgId: string
  orgName: string
  diCode: string
  state: string
  stateName: string
}

const SearchOrg = () => {
  const { user } = useSearchUserContext()

  const [data, setData] = useState<Organization[]>([])

  const columns = useMemo(
    () => [
      { Header: 'Org ID', accessor: 'orgId', disableGlobalFilter: true },
      { Header: 'Name', accessor: 'orgName' },
      { Header: 'DI Code', accessor: 'diCode' },
      { Header: 'State Code', accessor: 'state', disableGlobalFilter: true },
      { Header: 'State', accessor: 'stateName', disableGlobalFilter: true }
    ],
    []
  )

  useEffect(() => {
    const setup = async () => {
      type refpathtype = typeof refPath
      const organizations = 'organizations'
      const path = refPath[organizations].xref
      const data: Organization[] = await ReferenceService.getReferenceData(path)
      setData(data)
    }
    setup()
  }, [])

  if (!user.loginAsOrgId) {
    return (
      <MsssError
        title='Access Denied'
        message="You don't have access to the login as feature."
        siteName={siteName}
        errorCode='403'
      />
    )
  }

  if (!data.length) {
    return (
      <PageContainer pageLoading siteName={siteName}>
        <Loader />
      </PageContainer>
    )
  }

  return (
    <PageContainer pageLoaded siteName={siteName} title='Login As Institution'>
      <div className='msss-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <Spacer size='72' />
              <h1 className='cb-h3'>Login As Institution</h1>
              <Spacer size='16' />
              <p>Search by Name or DI Code to select an Institution. Then click on institution to view your records.</p>

              <Spacer size='32' />
              <Table columns={columns} data={data}>
                <OrgResultsTable />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default SearchOrg
