import '../../geography/geography.scss'
import { Select, Slider } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useEffect, useState } from 'react'
import { satRadioOptions } from './CriteriaSelectionOptions'
import { IExamCriteria } from '../IExamCriteria'
import NoExamsRange from '../NoExamsRange'

type SatProps = {
  satExamsCriteria: IExamCriteria[]
  addSatExamCriteria: (lowScore: number, highScore: number, exam: string) => void
  satOption: string
  handleSatOption: (value: string) => void
}

const SAT = (props: SatProps): JSX.Element => {
  const { satExamsCriteria, addSatExamCriteria, satOption, handleSatOption } = props

  const [satScoreLow, setSatScoreLow] = useState<number>(400)
  const [satScoreHigh, setSatScoreHigh] = useState<number>(1600)
  const [satExam, setSatExam] = useState<string>('none')
  const [selectionValue, setSelectionValue] = useState<string>('No Selection')
  const [min, setMin] = useState(400)
  const [max, setMax] = useState(1600)
  const [pushable, setPushable] = useState<number>(50)
  const [step, setStep] = useState<number>(10)
  const [applyDisabled, setApplyDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (satOption === 'SAT') {
      satRadioOptions.map(option => {
        if (option.value !== satOption) option.disabled = true
      })
    } else {
      satRadioOptions.map(option => {
        option.disabled = false
        satExamsCriteria.map(criteria => {
          if (criteria.exam === option.value) {
            option.disabled = true
          }
        })
      })
    }
    if (satOption && satOption !== 'none' && satOption !== 'SAT') {
      setApplyDisabled(false)
    } else {
      setApplyDisabled(true)
    }
    setSatExam(satOption)
    const satRangeOptions = ensure(satRadioOptions.find(item => item.value === satOption)).rangeOptions
    setSatScoreLow(prevSatScoreLow => (prevSatScoreLow = satRangeOptions.min))
    setSatScoreHigh(prevSatScoreHigh => (prevSatScoreHigh = satRangeOptions.max))
    setMin(prevMin => (prevMin = satRangeOptions.min))
    setMax(prevMax => (prevMax = satRangeOptions.max))
    setStep(prevStep => (prevStep = satRangeOptions.step))
    setPushable(prevPushable => (prevPushable = satRangeOptions.pushable))
  }, [satOption, satExamsCriteria])

  const handleScoreRange = (value: string[]) => {
    setSatScoreLow(parseInt(value[0]))
    setSatScoreHigh(parseInt(value[1]))
  }

  const handleSatExam = (value: string) => {
    setSatExam(value)
    handleSatOption(value)
  }

  const reset = () => {
    handleSatOption('none')
    const satRangeOptions = ensure(satRadioOptions.find(item => item.value === satOption)).rangeOptions
    setSatScoreLow(prevSatScoreLow => (prevSatScoreLow = satRangeOptions.min))
    setSatScoreHigh(prevSatScoreHigh => (prevSatScoreHigh = satRangeOptions.max))
    setMin(prevMin => (prevMin = satRangeOptions.min))
    setMax(prevMax => (prevMax = satRangeOptions.max))
    setStep(prevStep => (prevStep = satRangeOptions.step))
    setPushable(prevPushable => (prevPushable = satRangeOptions.pushable))
  }

  const add = () => {
    // if (satExam === 'satAny') {
    //   addSatAny()
    // } else {
    addSatExamCriteria(satScoreLow, satScoreHigh, satOption)
    // }
    // once the sat exam is added reset the ui options
    reset()
  }

  const selectedSatExams = () => {
    const newSatExamsCriteria = [...satExamsCriteria]
    return newSatExamsCriteria.reduce((acc: string[], current) => acc.concat(current.exam), [])
  }

  return (
    <>
      <div className='cb-margin-left-8'>
        {/* List of SAT Exams Row */}
        <div className='row'>
          <div className='col-xs-4'>
            <Select
              ariaLabel='Select an SAT exam'
              css=''
              name='satOption'
              onChange={handleSatOption}
              selectId='cb-exams-sat-options'
              value={satOption}
              values={satRadioOptions}
            />
          </div>
          {satOption === 'none' || satOption === 'SAT' ? (
            <NoExamsRange />
          ) : (
            <div className='col-xs-8'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='cb-margin-top-48 cb-margin-right-8 cb-margin-bottom-32 cb-margin-left-8'>
                    {satOption === 'satRM' && (
                      <Slider
                        format={{ to: (value: number) => value.toFixed(0), from: (value: string) => parseInt(value) }}
                        label={['lowest SAT score', 'highest SAT score']}
                        margin={50}
                        onChange={handleScoreRange}
                        pips={{
                          mode: 'positions',
                          values: [0, 100],
                          density: 4
                        }}
                        range={{ max: 1600, min: 400 }}
                        start={[satScoreLow, satScoreHigh]}
                        step={10}
                        tooltips={{ sticky: true }}
                      />
                    )}
                    {(satOption === 'satR' || satOption === 'satM') && (
                      <Slider
                        format={{ to: (value: number) => value.toFixed(0), from: (value: string) => parseInt(value) }}
                        label={['lowest SAT score', 'highest SAT score']}
                        margin={50}
                        onChange={handleScoreRange}
                        pips={{
                          mode: 'positions',
                          values: [0, 100],
                          density: 4
                        }}
                        range={{ max: 800, min: 200 }}
                        start={[satScoreLow, satScoreHigh]}
                        step={10}
                        tooltips={{ sticky: true }}
                      />
                    )}
                    {(satOption === 'satRT' || satOption === 'satMT' || satOption === 'satWT') && (
                      <Slider
                        format={{ to: (value: number) => value.toFixed(0), from: (value: string) => parseInt(value) }}
                        label={['lowest SAT score', 'highest SAT score']}
                        margin={5}
                        onChange={handleScoreRange}
                        pips={{
                          mode: 'positions',
                          values: [0, 100],
                          density: 4
                        }}
                        range={{ max: 40, min: 10 }}
                        start={[satScoreLow, satScoreHigh]}
                        step={1}
                        tooltips={{ sticky: true }}
                      />
                    )}
                  </div>
                </div>
                <div className='col-xs-12 cb-align-right'>
                  <button
                    className='cb-btn cb-btn-primary cb-padding-left-32 cb-padding-right-32 ms-applynow'
                    disabled={applyDisabled}
                    id='apply-sat-btn'
                    onClick={add}
                    type='button'
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SAT
