import { Spacer, Tab, Tabs } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { useState, useEffect, useCallback } from 'react'
import { ISavedCriteriaData, SavedCriteriaSectionProps } from '../../Search'
import { Summary } from '../../summary/Summary'
import { Section } from '../../summary/Section'
import CommonCriteria from './CommonCriteria'
import { ICriteria } from '../../ICriteria'
import { IBits, ICbExamsBits, IScoreBand } from '../../summary/IBits'
import { ExamSummaryEnums } from './ExamSummaryEnums'
import { assessmentOptions, scoreSendsOptions } from './CriteriaSelectionOptions'
import { IApExamCriteria } from './ap/IApExamCriteria'
import { allApOptions, apOptions } from './ap/CriteriaSelectionOptions'
import { IUserData } from '../../IUserData'
import { IExamCriteria } from './IExamCriteria'
import PSAT from './psat/PSAT'
import SAT from './sat/SAT'
import AP from './ap/AP'
import { satRadioOptions } from './sat/CriteriaSelectionOptions'
import { psatRadioOptions } from './psat/CriteriaSelectionOptions'
import { AvailableMySavedCriteriaTypes } from '../../../../my-account/saved-criteria/options'

const DEFAULT_ASSESSMENT = 'O'
const DEFAULT_SCORE_LOGIC = 'OR'
const DEFAULT_SCORE_SENDS = ''

export const getExams = (type: string, selectedCriteria?: ICriteria): IExamCriteria[] | IApExamCriteria[] => {
  if (selectedCriteria) {
    let ref: string[] = []
    const all: IExamCriteria[] = []
    switch (type) {
      case 'AP':
        ref = allApOptions.map(e => e.value)
        break
      case 'SAT':
        ref = satRadioOptions.map(e => e.value)
        break
      case 'PSAT':
        ref = psatRadioOptions.map(e => e.value)
        break
    }

    // here forEach is important here vs a map
    ref.forEach(k => {
      const single = selectedCriteria[k as keyof ICriteria]
      if (single) {
        const value: string[] = single[0].split(',')
        all.push({ exam: k, lowScore: parseInt(value[0]), highScore: parseInt(value[1]) })
      }
    })

    if (type === 'PSAT' || type === 'SAT') return all

    let allap: IApExamCriteria[] = []
    allap = all.reduce((p, c) => {
      //const range = c.band.split(',')
      const found = p.findIndex(e => e.apScoreLow === c.lowScore && e.apScoreHigh === c.highScore)
      if (found > -1) {
        p[found].apExams.push(c.exam)
        return p
      } else {
        p.push({ apScoreLow: c.lowScore, apScoreHigh: c.highScore, apExams: [c.exam] })
        return p
      }
    }, allap)

    return allap
  }
  return []
}

const CbExams = (props: SavedCriteriaSectionProps): JSX.Element => {
  const hasChanged = (): boolean => {
    if (
      assessment === DEFAULT_ASSESSMENT &&
      scoreLogic === DEFAULT_SCORE_LOGIC &&
      scoreSends === DEFAULT_SCORE_SENDS &&
      apOption === 'none' &&
      apExamsCriteria.length === 0 &&
      psatOption === 'none' &&
      psatExamsCriteria.length === 0 &&
      satOption === 'none' &&
      satExamsCriteria.length === 0
    )
      return false
    else return true
  }

  const { selectedCriteria, selectedUserData, handleCriteria, handleUserData, myaccount } = props

  // selectedCriteria and selectedUserData are from load saved search.
  // getassessment/getscore/getexamoption/getexams will either return saved search data
  // or defaults
  const getAssessment = () => {
    if (selectedCriteria) {
      if (selectedCriteria.assessment) {
        return selectedCriteria.assessment[0]
      } else {
        return DEFAULT_ASSESSMENT
      }
    } else {
      return DEFAULT_ASSESSMENT
    }
  }

  const getScore = (key: string): string => {
    let default_val: string = ''

    switch (key) {
      case 'scoreLogic':
        default_val = DEFAULT_SCORE_LOGIC
        break
      case 'scoreSend':
        default_val = DEFAULT_SCORE_SENDS
        break
      case 'scoreType':
        default_val = 'Highest'
    }

    if (selectedUserData) {
      //const y = selectedUserData[key as keyof IUserData]
      const x: string = selectedUserData[key]
      if (x) {
        return x
      } else {
        return default_val
      }
    }
    return default_val
  }

  const getExamOption = (key: string): string => {
    const default_val: string = 'none'
    if (selectedCriteria) {
      if (selectedCriteria.testTaken) {
        const found = selectedCriteria.testTaken.find(e => e === key)
        if (found) {
          return found
        } else {
          return default_val
        }
      }
    }
    return default_val
  }

  // SearchUser from useContext
  const { user } = useSearchUserContext()

  // state to keep track of summary from various sub sections of exams scores like common criteria, ap, sat and psat
  const [summaryData, setSummaryData] = useState<IBits[]>([])
  const [examSummaryData, setExamSummaryData] = useState<ICbExamsBits[]>([])
  const [scoreType, setScoreType] = useState<string>(getScore('scoreType'))

  // ### Criteria of the component
  const [assessment, setAssessment] = useState<string>(getAssessment())
  const [scoreLogic, setScoreLogic] = useState<string>(getScore('scoreLogic'))
  const [scoreSends, setScoreSends] = useState<string>(getScore('scoreSend'))

  // It has previous selected ap exams. this will be used to disable ap exams
  const [apExamsCriteria, setApExamsCriteria] = useState<IApExamCriteria[]>(getExams('AP', selectedCriteria) as IApExamCriteria[])
  const [psatExamsCriteria, setPsatExamsCriteria] = useState<IExamCriteria[]>(
    getExams('PSAT', selectedCriteria) as IExamCriteria[]
  )
  const [satExamsCriteria, setSatExamsCriteria] = useState<IExamCriteria[]>(getExams('SAT', selectedCriteria) as IExamCriteria[])
  // default is No Selection
  const [apOption, setApOption] = useState(getExamOption('AP'))
  const [satOption, setSatOption] = useState(getExamOption('SAT'))
  const [psatOption, setPsatOption] = useState(getExamOption('PSAT'))
  //const [satAny, setSatAny] = useState<boolean>(false)
  //const [psatAny, setPsatAny] = useState<boolean>(false)

  // Select saved criteria set
  const [savedCriteriaData, setSavedCriteriaData] = useState<ISavedCriteriaData>()

  const [changed, setChanged] = useState<boolean>(hasChanged)

  /*
  useEffect(() => {
    console.log('exams.....', selectedCriteria)
    console.log('exams.....', selectedUserData)
  }, [])
  */

  /*
    all the useEffect hooks
  */
  useEffect(() => {
    // we should not pass value O to solr or saving in criteria db
    if (assessment !== 'O') {
      const criteria: ICriteria = { assessment: [assessment] }
      handleCommonCritiera(criteria)
    } else {
      const criteria: ICriteria = { assessment: [] }
      handleCommonCritiera(criteria)
    }
    const assessmentLabel =
      assessment !== 'undefined' ? [ensure(assessmentOptions.find(option => option.value === assessment)).label] : []

    const bits: IBits = {
      name: 'Exam Taking Options',
      values: assessmentLabel,
      remove: removeAssessment,
      sort: ExamSummaryEnums.EXAM_TAKING_OPTIONS
    }
    handleSummaryData(bits)

    if (assessment !== 'S') {
      // clear AP, PSAT and SAT criteria (business rule)
      setApOption('none')
      setPsatOption('none')
      setSatOption('none')
      setApExamsCriteria([])
      setPsatExamsCriteria([])
      setSatExamsCriteria([])
    }
  }, [assessment])

  useEffect(() => {
    // criteria
    let criteria: ICriteria = {}
    const dicode = user?.dicode
    if (dicode && scoreSends) {
      criteria = { satDiCodes: [dicode] }
    } else {
      criteria = { satDiCodes: [] }
    }
    handleCommonCritiera(criteria)

    // summary
    const scoreSendsLabel =
      scoreSends !== 'undefinded' ? [ensure(scoreSendsOptions.find(option => option.value === scoreSends)).label] : []

    const bits: IBits = {
      name: 'Score Sends',
      values: scoreSendsLabel,
      remove: removeScoreSends,
      sort: ExamSummaryEnums.SCORE_SENDS
    }
    //set score send to userData
    const userData: IUserData = {
      scoreSend: scoreSends
    }
    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
    }

    handleSummaryData(bits)
  }, [scoreSends])

  useEffect(() => {
    const userData: IUserData = { scoreLogic: scoreLogic }
    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
    }

    // summary
    const bits: IBits = {
      name: 'Search Logic',
      values: [scoreLogic],
      remove: removeScoreLogic,
      sort: ExamSummaryEnums.SCORE_LOGIC
    }
    handleSummaryData(bits)
  }, [scoreLogic])

  // this is for test taken critera.  we build this everytime
  // either of the three values change
  useEffect(() => {
    const testTaken: string[] = []
    apOption === 'AP' ? testTaken.push('AP') : null
    satOption === 'SAT' ? testTaken.push('SAT') : null
    psatOption === 'PSAT' ? testTaken.push('PSAT') : null
    const criteria: ICriteria = { testTaken }
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
  }, [apOption, satOption, psatOption])

  useEffect(() => {
    // criteria
    if (apOption === 'AP') {
      setApExamsCriteria([])
    }

    // summary
    const testTakenLabel = apOption === 'AP' ? [ensure(apOptions.find(option => option.value === 'AP')).label] : []

    const bits: IBits = {
      name: 'AP Exams',
      values: testTakenLabel,
      remove: removeAnyApTestTaker,
      sort: ExamSummaryEnums.AP_ANY
    }
    handleSummaryData(bits)
  }, [apOption])

  useEffect(() => {
    // summary
    const testTakenLabel = satOption === 'SAT' ? [ensure(satRadioOptions.find(option => option.value === 'SAT')).label] : []

    const bits: IBits = {
      name: 'SAT',
      values: testTakenLabel,
      remove: removeAnySatTestTaker,
      sort: ExamSummaryEnums.SAT_ANY
    }
    handleSummaryData(bits)

    if (satOption === 'SAT') {
      // finally clear any sat criteria previous selected bcoz you cannot have any sat criteria and selected sat criteria together
      setSatExamsCriteria([])
    }
  }, [satOption])

  useEffect(() => {
    // summary
    const testTakenLabel = psatOption === 'PSAT' ? [ensure(psatRadioOptions.find(option => option.value === 'PSAT')).label] : []

    const bits: IBits = {
      name: 'PSAT',
      values: testTakenLabel,
      remove: removeAnyPsatTestTaker,
      sort: ExamSummaryEnums.PSAT_ANY
    }
    handleSummaryData(bits)

    if (psatOption === 'PSAT') {
      // finally clear any psat criteria previous selected bcoz you cannot have any sat criteria and selected sat criteria together
      setPsatExamsCriteria([])
    }
  }, [psatOption])

  useEffect(() => {
    // part 1: summary
    const scoreBands = apExamsCriteria.map(criteria => {
      const labels = criteria.apExams.map(exam => {
        // eg: AP Exams Art History 3 - 5
        const apExamLabel =
          ensure(allApOptions.find(option => option.value === exam)).label +
          ' ' +
          criteria.apScoreLow +
          ' - ' +
          criteria.apScoreHigh
        return apExamLabel
      })

      const scoreBand: IScoreBand = {
        remove: removeApExamCriteria,
        lowScore: criteria.apScoreLow,
        highScore: criteria.apScoreHigh,
        values: labels,
        editScore: editApScore,
        minScore: 1,
        maxScore: 5,
        minRange: 1,
        step: 1,
        exam: ''
      }
      return scoreBand
    })

    const cbExamBits: ICbExamsBits = {
      name: 'AP Exams',
      sort: ExamSummaryEnums.AP,
      scoreBands: scoreBands
    }

    handleExamSummaryData(cbExamBits)

    // part 2: criteria for save/solr
    let apCriteria: ICriteria = {}
    allApOptions.map(apOption => {
      apCriteria = { ...apCriteria, [apOption.value]: '' }
    })
    apExamsCriteria.map(apBand => {
      apBand.apExams.map(exam => {
        const apScoreBand = [apBand.apScoreLow + ',' + apBand.apScoreHigh]
        apCriteria = { ...apCriteria, [exam]: apScoreBand }
      })
    })

    // part 3: remove Any AP exam criteria
    if (apExamsCriteria && apExamsCriteria.length > 0) {
      setApOption('select')
    }
    handleApCriteria(apCriteria)
  }, [apExamsCriteria])

  useEffect(() => {
    const userData: IUserData = {
      scoreLogic: scoreLogic,
      scoreType: scoreType
    }
    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
      setChanged(hasChanged())
    }
  }, [scoreLogic, scoreType])

  useEffect(() => {
    // part 1: summary
    const scoreBands = satExamsCriteria.map(criteria => {
      const label =
        ensure(satRadioOptions.find(option => option.value === criteria.exam)).label +
        ' ' +
        criteria.lowScore +
        ' - ' +
        criteria.highScore

      const rangeOptions = ensure(satRadioOptions.find(option => option.value === criteria.exam)).rangeOptions
      //console.log(`range options for exam:${criteria.exam}; rangeOptions: ${JSON.stringify(rangeOptions)}`)
      const scoreBand: IScoreBand = {
        remove: removeSatExamCriteria,
        lowScore: criteria.lowScore,
        highScore: criteria.highScore,
        values: [label],
        editScore: editSatScore,
        minScore: rangeOptions.min,
        maxScore: rangeOptions.max,
        minRange: rangeOptions.pushable,
        step: rangeOptions.step,
        exam: criteria.exam
      }
      return scoreBand
    })

    const cbExamBits: ICbExamsBits = {
      name: 'SAT',
      sort: ExamSummaryEnums.SAT,
      scoreBands: scoreBands
    }

    handleExamSummaryData(cbExamBits)

    // part 2: criteria for save/solr
    let satCriteria: ICriteria = {}
    satRadioOptions.map(satOption => {
      if (satOption.value !== 'none' && satOption.value !== 'SAT') satCriteria = { ...satCriteria, [satOption.value]: '' }
    })
    satExamsCriteria.map(criteria => {
      const satScoreBand = [criteria.lowScore + ',' + criteria.highScore]
      satCriteria = { ...satCriteria, [criteria.exam]: satScoreBand }
    })
    handleSatCriteria(satCriteria)
  }, [satExamsCriteria])

  useEffect(() => {
    // part 1: summary
    const scoreBands = psatExamsCriteria.map(criteria => {
      const label =
        ensure(psatRadioOptions.find(option => option.value === criteria.exam)).label +
        ' ' +
        criteria.lowScore +
        ' - ' +
        criteria.highScore

      const rangeOptions = ensure(psatRadioOptions.find(option => option.value === criteria.exam)).rangeOptions

      const scoreBand: IScoreBand = {
        remove: removePsatExamCriteria,
        lowScore: criteria.lowScore,
        highScore: criteria.highScore,
        values: [label],
        editScore: editPsatScore,
        minScore: rangeOptions.min,
        maxScore: rangeOptions.max,
        minRange: rangeOptions.pushable,
        step: rangeOptions.step,
        exam: criteria.exam
      }
      return scoreBand
    })

    const cbExamBits: ICbExamsBits = {
      name: 'PSAT',
      sort: ExamSummaryEnums.PSAT,
      scoreBands: scoreBands
    }

    handleExamSummaryData(cbExamBits)

    // part 2: criteria for save/solr
    let psatCriteria: ICriteria = {}
    psatRadioOptions.map(psatOption => {
      if (psatOption.value !== 'none' && psatOption.value !== 'PSAT') psatCriteria = { ...psatCriteria, [psatOption.value]: '' }
    })

    psatExamsCriteria.map(criteria => {
      const psatScoreBand = [criteria.lowScore + ',' + criteria.highScore]
      psatCriteria = { ...psatCriteria, [criteria.exam]: psatScoreBand }
    })

    handleSatCriteria(psatCriteria)
  }, [psatExamsCriteria])

  useEffect(() => {
    if (savedCriteriaData && savedCriteriaData.criteriaId !== -1) {
      clearAll()
      console.log('cbexam... ', savedCriteriaData)
      if (savedCriteriaData.criteria.assessment) handleAssessment(savedCriteriaData.criteria.assessment[0])
      if (savedCriteriaData.criteria.testTaken) handleApOption(savedCriteriaData.criteria.testTaken[0])
      if (savedCriteriaData.userData.scoreLogic) handleScoreLogic(savedCriteriaData.userData.scoreLogic)
      if (savedCriteriaData.userData.scoreSend) handleScoreSends(savedCriteriaData.userData.scoreSend)

      setApExamsCriteria(getExams('AP', savedCriteriaData.criteria) as IApExamCriteria[])
      setPsatExamsCriteria(getExams('PSAT', savedCriteriaData.criteria) as IExamCriteria[])
      setSatExamsCriteria(getExams('SAT', savedCriteriaData.criteria) as IExamCriteria[])
      setSavedCriteriaData({ ...savedCriteriaData, criteriaId: -1 })
    }
  }, [savedCriteriaData])

  /* useEffects() end */

  /* AP Callbacks  */

  // these are for creating my saved critera set in sfs
  const setSavedCriteria = useCallback((criteriaData: ICriteria): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: ICriteria = { ...prev.criteria, ...criteriaData }
        return { ...prev, criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0)) }
      }
      // prev undefined
      const x: ICriteria = criteriaData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.CBExams,
        userData: {},
        criteria: Object.fromEntries(Object.entries(x).filter(([key, value]) => value.length > 0))
      }
    })
  }, [])

  const setSavedUserData = (userDataData: IUserData): void => {
    setSavedCriteriaData(prev => {
      if (prev) {
        const x: IUserData = { ...prev.userData, ...userDataData }
        return {
          ...prev,
          userData: Object.fromEntries(
            Object.entries(x).filter(([key, value]) =>
              value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
            )
          )
        }
      }
      // prev undefinded
      const x: IUserData = userDataData
      return {
        criteriaId: -1,
        type: AvailableMySavedCriteriaTypes.CBExams,
        userData: Object.fromEntries(
          Object.entries(x).filter(([key, value]) =>
            value !== undefined ? Boolean(typeof value === 'boolean' || value.length > 0) : false
          )
        ),
        criteria: {}
      }
    })
  }

  // Add ap exam criteria (callback in the child AP component)
  const addApExamCriteria = (apScoreLow: number, apScoreHigh: number, apExams: string[]) => {
    // find if new criteria already exists (could be undefined)
    const existingCriteria = apExamsCriteria.find(item => item.apScoreLow === apScoreLow && item.apScoreHigh === apScoreHigh)

    if (existingCriteria === undefined) {
      const updatedCriteria = [
        ...apExamsCriteria,
        {
          apScoreLow,
          apScoreHigh,
          apExams
        }
      ]
      setApExamsCriteria(updatedCriteria)
    } else {
      // eg: existing ranges are ['3-5', '2-4'] and then add '3-5'

      // Get the apCriteria without '3-5'
      const filteredCriteria = apExamsCriteria.filter(item => {
        return !(item.apScoreLow === apScoreLow && item.apScoreHigh === apScoreHigh)
      })

      // combine the filtered criteria (all criteria with the range we are updating)
      const updatedCriteria = [
        ...filteredCriteria,
        {
          apScoreLow,
          apScoreHigh,
          apExams: [...existingCriteria.apExams, ...apExams]
        }
      ]
      setApExamsCriteria(updatedCriteria)
    }
  }

  // Edit AP Score (called from summary)
  const editApScore = (prevLow: number, prevHigh: number, newLow: number, newHigh: number) => {
    // update only if there are any changes to score
    if (prevLow != newLow || prevHigh != newHigh) {
      // step 1: find old criteria (should exist)
      const oldCriteriaIndex = apExamsCriteria.findIndex(item => {
        return item.apScoreLow === prevLow && item.apScoreHigh === prevHigh
      })

      // step 2: find if new criteria already exists (may not)
      const existingCriteriaIndex = apExamsCriteria.findIndex(item => {
        return item.apScoreLow === newLow && item.apScoreHigh === newHigh
      })

      // step 3: update existing
      if (oldCriteriaIndex !== -1) {
        const updatedCriteria = [...apExamsCriteria]

        if (existingCriteriaIndex === -1) {
          updatedCriteria.splice(oldCriteriaIndex, 1, {
            apScoreLow: newLow,
            apScoreHigh: newHigh,
            apExams: [...apExamsCriteria[oldCriteriaIndex].apExams]
          })
          setApExamsCriteria(updatedCriteria)
        } else {
          updatedCriteria.splice(oldCriteriaIndex, 1, {
            apScoreLow: newLow,
            apScoreHigh: newHigh,
            apExams: [...apExamsCriteria[existingCriteriaIndex].apExams, ...apExamsCriteria[oldCriteriaIndex].apExams]
          })
          updatedCriteria.splice(existingCriteriaIndex, 1) // remove it
          setApExamsCriteria(updatedCriteria)
        }
      }
    }
  }
  // Remove ap exam criteria
  const removeApExamCriteria = (apExamWithScoreBand: string) => {
    // eg value=Art History 1 - 5
    // We have to extract the name of the exam (Art History), find the exam value and remove from array
    const newApExamsCriteria = [...apExamsCriteria]
    const updatedApExamsCriteria = newApExamsCriteria.map(item => {
      // eg: 3 - 5
      const scoreRange = item.apScoreLow + ' - ' + item.apScoreHigh
      // is this the score band in this criteria?
      const index = apExamWithScoreBand.indexOf(item.apScoreLow + ' - ' + item.apScoreHigh)
      if (index >= 0) {
        const paramApExam = apExamWithScoreBand.substring(0, index)
        const apExams = item.apExams.filter(
          apExam => apExam !== ensure(allApOptions.find(option => option.label === paramApExam.trim())).value
        )
        return {
          apScoreLow: item.apScoreLow,
          apScoreHigh: item.apScoreHigh,
          apExams
        }
      } else {
        return item
      }
    })

    const validApExamsCriteria = updatedApExamsCriteria.filter(item => item.apExams && item.apExams.length > 0)

    setApExamsCriteria(validApExamsCriteria)
  }

  const handleApOption = (value: string) => {
    setApOption(value)
  }

  const handleSatOption = (value: string) => {
    setSatOption(value)
  }

  const handlePsatOption = (value: string) => {
    setPsatOption(value)
  }

  /* AP Callbacks end */

  /* PSAT Callbacks */

  // const addPsatAny = () => {
  //   setPsatAny(true)
  // }

  // Add psat exam criteria (callback in the child AP component)
  const addPsatExamCriteria = (lowScore: number, highScore: number, exam: string) => {
    const newPsatExamsCriteria = [...psatExamsCriteria, { lowScore, highScore, exam }]
    setPsatExamsCriteria(newPsatExamsCriteria)
  }

  // Edit PSAT Score (called from summary)
  const editPsatScore = (prevLow: number, prevHigh: number, newLow: number, newHigh: number, exam: string) => {
    // update only if there are any changes to score
    if (prevLow != newLow || prevHigh != newHigh) {
      // step 1: find old criteria (should exist)
      const oldCriteriaIndex = psatExamsCriteria.findIndex(item => {
        return item.exam === exam
      })

      // step 2: update existing
      if (oldCriteriaIndex !== -1) {
        const updatedCriteria = [...psatExamsCriteria]
        updatedCriteria.splice(oldCriteriaIndex, 1, {
          lowScore: newLow,
          highScore: newHigh,
          exam: psatExamsCriteria[oldCriteriaIndex].exam
        })
        setPsatExamsCriteria(updatedCriteria)
      }
    }
  }

  // Remove sat exam criteria
  const removePsatExamCriteria = (psatExamWithScoreBand: string) => {
    // eg value=Total Score 320 - 1520
    // We have to extract the name of the exam (Total Score), find the exam value and remove from array
    const newPsatExamsCriteria = [...psatExamsCriteria]
    const updatedPsatExamsCriteria = newPsatExamsCriteria.map(item => {
      // eg: 320 - 1520
      const scoreRange = item.lowScore + ' - ' + item.highScore
      // is this the score band in this criteria?
      const index = psatExamWithScoreBand.indexOf(scoreRange)
      if (index >= 0) {
        const paramPsatExam = psatExamWithScoreBand.substring(0, index)
        if (item.exam === ensure(psatRadioOptions.find(option => option.label === paramPsatExam.trim())).value) {
          return {
            lowScore: item.lowScore,
            highScore: item.highScore,
            exam: ''
          }
        } else {
          return item
        }
      } else {
        return item
      }
    })

    const validPsatExamsCriteria = updatedPsatExamsCriteria.filter(item => item.exam && item.exam.length > 0)

    setPsatExamsCriteria(validPsatExamsCriteria)
  }

  /* PSAT Callbacks end */

  /* SAT Callbacks */

  // const addSatAny = () => {
  //   setSatAny(true)
  // }

  // Add sat exam criteria (callback in the child SAT component)
  const addSatExamCriteria = (lowScore: number, highScore: number, exam: string) => {
    const newSatExamsCriteria = [...satExamsCriteria, { lowScore, highScore, exam }]

    setSatExamsCriteria(newSatExamsCriteria)
  }

  // Edit SAT Score (called from summary)
  const editSatScore = (prevLow: number, prevHigh: number, newLow: number, newHigh: number, exam: string) => {
    // update only if there are any changes to score
    if (prevLow != newLow || prevHigh != newHigh) {
      // step 1: find old criteria (should exist)
      const oldCriteriaIndex = satExamsCriteria.findIndex(item => {
        return item.exam === exam
      })

      // step 2: update existing
      if (oldCriteriaIndex !== -1) {
        const updatedCriteria = [...satExamsCriteria]
        updatedCriteria.splice(oldCriteriaIndex, 1, {
          lowScore: newLow,
          highScore: newHigh,
          exam: satExamsCriteria[oldCriteriaIndex].exam
        })
        setSatExamsCriteria(updatedCriteria)
      }
    }
  }

  // Remove sat exam criteria
  const removeSatExamCriteria = (satExamWithScoreBand: string) => {
    // eg value=Total Score 400 - 1600
    // We have to extract the name of the exam (Total Score), find the exam value and remove from array
    const newSatExamsCriteria = [...satExamsCriteria]
    const updatedSatExamsCriteria = newSatExamsCriteria.map(item => {
      // eg: 400 - 1600
      const scoreRange = item.lowScore + ' - ' + item.highScore
      // is this the score band in this criteria?
      const index = satExamWithScoreBand.indexOf(scoreRange)
      if (index >= 0) {
        const paramSatExam = satExamWithScoreBand.substring(0, index)
        if (item.exam === ensure(satRadioOptions.find(option => option.label === paramSatExam.trim())).value) {
          return {
            lowScore: item.lowScore,
            highScore: item.highScore,
            exam: ''
          }
        } else {
          return item
        }
      } else {
        return item
      }
    })

    const validSatExamsCriteria = updatedSatExamsCriteria.filter(item => item.exam && item.exam.length > 0)

    setSatExamsCriteria(validSatExamsCriteria)
  }
  /* PSAT Callbacks end */

  const clearSelections = (event: any) => {
    event.preventDefault()
    clearAll()
  }

  const clearAll = () => {
    setAssessment(DEFAULT_ASSESSMENT)
    setScoreLogic(DEFAULT_SCORE_LOGIC)
    setScoreSends(DEFAULT_SCORE_SENDS)
    setApOption('none')
    setApExamsCriteria([])
    setPsatOption('none')
    setPsatExamsCriteria([])
    setSatOption('none')
    setSatExamsCriteria([])
    // need clear userData and criteria data
    let criteria: ICriteria = { assessment: [], satDiCodes: [] }
    allApOptions.map(apOption => {
      criteria = { ...criteria, [apOption.value]: '' }
    })
    psatRadioOptions.map(psatRadioOptions => {
      criteria = { ...criteria, [psatRadioOptions.value]: '' }
    })
    satRadioOptions.map(satRadioOptions => {
      criteria = { ...criteria, [satRadioOptions.value]: '' }
    })
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
    // clear userData

    const userData: IUserData = {
      scoreLogic: '',
      scoreType: '',
      scoreSend: ''
    }
    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
      setChanged(hasChanged())
    }
  }

  useEffect(() => {
    // Fix summary view one click behind summary data, examSummary data
    const criteria: ICriteria = {}
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
  }, [summaryData, examSummaryData])

  const handleSummaryData = (summaryData: IBits) => {
    // Remove any existing values
    setSummaryData(prevSummaryData => {
      const filteredDataArray = prevSummaryData.filter(data => data.sort !== summaryData.sort)
      return filteredDataArray
    })

    setSummaryData(prevSummaryData => [...prevSummaryData, summaryData])
  }

  const handleExamSummaryData = (examSummaryData: ICbExamsBits) => {
    // Remove any existing values
    setExamSummaryData(prevExamSummaryData => {
      const filteredDataArray = prevExamSummaryData.filter(data => data.sort !== examSummaryData.sort)
      return filteredDataArray
    })

    setExamSummaryData(prevExamSummaryData => [...prevExamSummaryData, examSummaryData])
  }

  const handleCommonCritiera = (criteria: ICriteria) => {
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
  }

  const handleApCriteria = (criteria: ICriteria) => {
    // for AP, need reset all ap and add new set
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
    const userData: IUserData = {
      scoreLogic: scoreLogic,
      scoreType: scoreType
    }
    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
      setChanged(hasChanged())
    }
  }

  const handleSatCriteria = (criteria: ICriteria) => {
    // for SAT, need reset all ap and add new set
    handleCriteria(criteria, toSummary())
    setSavedCriteria(criteria)
    setChanged(hasChanged())
    const userData: IUserData = {
      scoreLogic: scoreLogic,
      scoreType: scoreType
    }
    if (handleUserData) {
      handleUserData(userData)
      setSavedUserData(userData)
      setChanged(hasChanged())
    }
  }

  // const handlePsatCriteria = (criteria: ICriteria) => {
  //   // for PSAT, need reset all ap and add new set
  //   handleCriteria(criteria, toSummary())
  //   const userData: IUserData = {
  //     scoreLogic: scoreLogic,
  //     scoreType: scoreType
  //   }
  //   handleUserData ? handleUserData(userData) : {}
  // }

  /*
    functions to handle state
  */
  const handleAssessment = (value: string) => {
    setAssessment(value)
  }

  const handleScoreLogic = (value: string) => {
    setScoreLogic(value)
  }

  const handleScoreSends = (value: string) => {
    setScoreSends(value)
  }

  /*
    remove functions
  */
  const removeAssessment = () => {
    setAssessment(DEFAULT_ASSESSMENT)
  }

  const removeScoreLogic = () => {
    setScoreLogic(DEFAULT_SCORE_LOGIC)
  }

  const removeScoreSends = () => {
    setScoreSends(DEFAULT_SCORE_SENDS)
  }

  const removeAnyApTestTaker = () => {
    setApOption('none')
  }

  const removeAnySatTestTaker = () => {
    setSatOption('none')
  }

  const removeAnyPsatTestTaker = () => {
    setPsatOption('none')
  }

  const toSummary = () => {
    // sort default summary based on the SummarySection enums
    summaryData.sort((a, b) => {
      if (typeof a.sort !== 'undefined' && typeof b.sort !== 'undefined') {
        return a.sort - b.sort
      } else {
        return 0
      }
    })
    // sort exam score bands summary based on the SummarySection enums
    examSummaryData.sort((a, b) => {
      if (typeof a.sort !== 'undefined' && typeof b.sort !== 'undefined') {
        return a.sort - b.sort
      } else {
        return 0
      }
    })

    return {
      section: Section.EXAMS,
      data: summaryData,
      cbExamsData: examSummaryData
    }
  }

  return (
    <>
      <div className='cb-margin-left-8'>
        <p>
          Select a College Board Exam, Exam Section or Exam Subject and use the Score Band to determine the scores you would like
          to see from students of interest.
        </p>
        <Spacer size='16' />
        {/* Common criteria like assessment, score sends in the top of the accordion */}
        <CommonCriteria
          setSavedCriteriaData={setSavedCriteriaData}
          myaccount={myaccount}
          assessment={assessment}
          handleAssessment={handleAssessment}
          scoreLogic={scoreLogic}
          handleScoreLogic={handleScoreLogic}
          scoreSends={scoreSends}
          handleScoreSends={handleScoreSends}
          changed={changed}
        />

        {/* Tabs for AP, PSAT and SAT */}
        {assessment === 'S' && (
          <Tabs>
            <Tab label='AP Exams' panelId='ap' selected>
              <AP
                apExamsCriteria={apExamsCriteria}
                addApExamCriteria={addApExamCriteria}
                apOption={apOption}
                handleApOption={handleApOption}
              />
            </Tab>
            <Tab label='PSAT' panelId='psat'>
              <PSAT
                psatExamsCriteria={psatExamsCriteria}
                addPsatExamCriteria={addPsatExamCriteria}
                psatOption={psatOption}
                handlePsatOption={handlePsatOption}
              />
            </Tab>
            <Tab label='SAT' panelId='sat'>
              <SAT
                satExamsCriteria={satExamsCriteria}
                addSatExamCriteria={addSatExamCriteria}
                satOption={satOption}
                handleSatOption={handleSatOption}
              />
            </Tab>
          </Tabs>
        )}
        <Spacer />
        <Summary summaryId='cbexams' {...toSummary()} savedCriteria={savedCriteriaData} clear={clearSelections} />
        <Spacer />
      </div>
    </>
  )
}

export default CbExams
