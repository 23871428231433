import { useLazyQuery } from '@apollo/client'
import { ErrorNotification, Expandable, Spinner } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import React, { useEffect, useState } from 'react'
import { GET_SAVED_SEARCH_QUERY } from '../../../../../services/graphql/queries'
import { ISectionCriteria } from '../../../../../interfaces/ISectionCriteria'
import { ICriteria } from '../../../../search/search-form/ICriteria'
import { IUserData } from '../../../../search/search-form/IUserData'
import { ViewSavedSearchDetailsProps } from '../../SearchesOrdersContainer'
import { getCriteriaLabels, ICriteriaDetails, ICriteriaLabelValues } from './CriteriaHelper'
import './SearchCriteria.scss'

// Its used by all individual Criteria components. They all have the same props
export interface CriteriaProps {
  selectedCriteria?: ICriteria
  selectedUserData?: IUserData
  title: string
}

const NOT_AVAILABLE = 'N/A'

const SearchCriteria = ({ savedSearchId, accordionSavedSearchId }: ViewSavedSearchDetailsProps) => {
  const { user } = useSearchUserContext()
  const [criteriaDetails, setCriteriaDetails] = useState<ICriteriaDetails[]>()
  const expandCutoff: number = 18

  const [getSavedSearch, { loading, error, data }] = useLazyQuery(GET_SAVED_SEARCH_QUERY)
  // used to fetch the
  useEffect(() => {
    if (savedSearchId && savedSearchId !== -1 && savedSearchId == accordionSavedSearchId) {
      getSavedSearch({
        variables: { savedSearchId }
      })
    }
  }, [savedSearchId, accordionSavedSearchId])

  useEffect(() => {
    const setup = async () => {
      let criteria: ISectionCriteria = {}
      let excludedCriteria: any = {}
      let userData: IUserData = {}
      if (data && data.getSavedSearch) {
        // come parsed now in cache policies
        criteria = data.getSavedSearch.criteria.criteria
        userData = data.getSavedSearch.criteria.userData
        if (data.getSavedSearch.criteria.excludedCriteria) {
          excludedCriteria = data.getSavedSearch.criteria.excludedCriteria
        }
        const criteriaDetails: ICriteriaDetails[] = await getCriteriaLabels(
          criteria,
          userData,
          excludedCriteria,
          user.orgId!.toString(),
          user.loginAsOrgId
        )
        console.log('SearchCriteria: ', criteriaDetails)
        setCriteriaDetails(criteriaDetails)
      }
    }
    setup()
  }, [data])

  if (loading) {
    return <Spinner />
  }
  if (error) {
    return (
      <ErrorNotification title='Error'>There was an error fetching Criteria details for ID={savedSearchId}.</ErrorNotification>
    )
  }
  if (data) {
    const criteriaDetailsDataMainSection: ICriteriaDetails[] = []
    const criteriaDetailsDataExpandSection: ICriteriaDetails[] = []

    if (criteriaDetails) {
      const criteriaDetailsData: ICriteriaDetails[] = criteriaDetails.filter(criteria => {
        let hasValues = true
        if (criteria.sectionData.filter(dataItem => dataItem.values && dataItem.values.length > 0).length === 0) {
          hasValues = false
        }
        return hasValues
      })
      let counter = 0
      for (let i = 0; i < criteriaDetailsData.length; i++) {
        const item: ICriteriaDetails = criteriaDetailsData[i]
        if (counter < expandCutoff) {
          criteriaDetailsDataMainSection.push(item)
        } else {
          criteriaDetailsDataExpandSection.push(item)
        }
        counter++ // for the section
        for (let j = 0; j < item.sectionData.length; j++) {
          // eg: dataItem.values is like list of all states
          const dataItem: ICriteriaLabelValues = item.sectionData[j]
          if (dataItem.values) {
            counter += dataItem.values.length ?? 0
          }
        }
      }
    }

    return (
      <div>
        {/* Criteria Selections Header */}
        {/* <h5 className='cb-h6 cb-padding-bottom-8'>Criteria</h5> */}
        {!criteriaDetails && <Spinner />}
        {criteriaDetails &&
          criteriaDetailsDataMainSection &&
          criteriaDetailsDataMainSection.map(section => {
            const sectionData = section.sectionData

            if (sectionData.filter(criteria => criteria.values && criteria.values.length > 0).length === 0) {
              return null
            }

            return (
              <React.Fragment key={section.sectionTitle}>
                <h6 className='cb-font-regular cb-font-weight-bold cb-margin-top-16'>{section.sectionTitle}</h6>
                {sectionData
                  .filter(criteria => criteria.values && criteria.values.length > 0)
                  .map(criteria => {
                    return (
                      <React.Fragment key={`criteria-${criteria.key}`}>
                        <div>
                          {criteria.label && <span className='cb-font-weight-medium'>{criteria.label}: </span>}
                          {criteria.values && criteria.values.join(criteria.useSemicolon ? '; ' : ', ')}
                        </div>
                      </React.Fragment>
                    )
                  })}
              </React.Fragment>
            )
          })}
        {criteriaDetails && criteriaDetailsDataExpandSection && criteriaDetailsDataExpandSection.length > 0 && (
          <Expandable offScreen='show more'>
            {criteriaDetailsDataExpandSection.map(section => {
              const sectionData = section.sectionData

              if (sectionData.filter(criteria => criteria.values && criteria.values.length > 0).length === 0) {
                return null
              }

              return (
                <React.Fragment key={section.sectionTitle}>
                  <h6 className='cb-font-regular cb-font-weight-bold cb-margin-top-16'>{section.sectionTitle}</h6>
                  {sectionData
                    .filter(criteria => criteria.values && criteria.values.length > 0)
                    .map(criteria => {
                      return (
                        <React.Fragment key={`criteria-${criteria.key}`}>
                          <div>
                            {criteria.label && <span className='cb-font-weight-medium'>{criteria.label}: </span>}
                            {criteria.values && criteria.values.join(criteria.useSemicolon ? '; ' : ', ')}
                          </div>
                        </React.Fragment>
                      )
                    })}
                </React.Fragment>
              )
            })}
          </Expandable>
        )}
      </div>
    )
  }

  return null
}

export default SearchCriteria
