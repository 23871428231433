import CBInputMethod from '@cb/apricot/CBInputMethod'
import { Checkbox, CheckboxGroup, Filter, NakedButton, Spacer } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import { useCallback, useEffect } from 'react'
import { FilterColumn } from './FilterOptions'

interface OtherFiltersProps {
  name: string
  setFilter: any
  topSearchFilter: boolean
  ownedByMeFilter: boolean
  handleTopSearchFilter: (event: boolean) => void
  handleOwnedByMeFilter: (event: boolean) => void
  clearFilter: () => void
}

// This is a custom component that uses the Filter component from apricot and adds the other interactions needed by all our filters
export const OtherFilters = ({
  name,
  setFilter,
  topSearchFilter,
  ownedByMeFilter,
  handleTopSearchFilter,
  handleOwnedByMeFilter,
  clearFilter
}: OtherFiltersProps) => {
  const { user } = useSearchUserContext()

  useEffect(() => {
    CBInputMethod()
  }, [])

  useEffect(() => {
    if (topSearchFilter) {
      setFilter(FilterColumn.TOP_SEARCH, true)
    } else {
      setFilter(FilterColumn.TOP_SEARCH, '')
    }
  }, [topSearchFilter])

  useEffect(() => {
    if (ownedByMeFilter) {
      setFilter(FilterColumn.OWNER_USER_ID, user.userId)
    } else {
      setFilter(FilterColumn.OWNER_USER_ID, '')
    }
  }, [ownedByMeFilter])

  // clear the filter
  const clear = useCallback(() => {
    clearFilter()
  }, [clearFilter])

  const activeFilters = useCallback(() => {
    let count = 0
    if (topSearchFilter) count++
    if (ownedByMeFilter) count++
    return count
  }, [topSearchFilter, ownedByMeFilter])

  return (
    <>
      <Filter
        activeFilters={activeFilters()}
        buttonId='other-btn'
        buttonLabel={name}
        filterFooter={
          <NakedButton id='reset-other-filter-search-options-btn' onClick={clear} noPadding small>
            Reset
          </NakedButton>
        }
        filterHeaderId='other-filter-popover-header'
        filterId='other-filter-popover'
        popoverTitle={name}
      >
        <>
          <Spacer size='24' />
          <CheckboxGroup fieldsetId='other-filter-search-options' legend='filter options' legendClassName='sr-only' vertical>
            <Checkbox
              checked={topSearchFilter}
              id='filter-top-search-option'
              label='Top Search'
              onChange={(checked: boolean) => handleTopSearchFilter(checked)}
            />
            <Checkbox
              checked={ownedByMeFilter}
              id='filter-owned-by-me-option'
              label='Owned by me'
              onChange={(checked: boolean) => handleOwnedByMeFilter(checked)}
            />
          </CheckboxGroup>
        </>
      </Filter>
    </>
  )
}

export default OtherFilters
