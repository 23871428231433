//The user is prompted to give the search a name/title.
//The name/title must be limited to 50 alphanumeric characters including special characters of ???.
//The name/title is required before the save continues.
//The name/title must be unique within the logged in org/dept.

import { UNTITLED_SEARCH_NAME } from './SearchFormConstants'

export const SaveSearchValidation = (searchName: string) => {
  let errorMessage = ''
  //console.log("=====ERROR----", {searchName, len: searchName.length})
  if (searchName === '' || searchName === undefined) {
    errorMessage = 'The name/title is required before the save continues.'
  }

  if (searchName !== '' && searchName.length > 50) {
    errorMessage = 'The name/title must be limited to 50 alphanumeric characters'
  }

  if (searchName !== '' && UNTITLED_SEARCH_NAME.match(`^${searchName}$`)) {
    errorMessage = `${UNTITLED_SEARCH_NAME} can not be name/title of a search name`
  }

  console.log('Error Message', errorMessage)
  return { errorMessage }
}
