import { ErrorNotification, InfoNotification } from '@cb/apricot-react'
import { PageContainer } from '@msss/ui-components'
import { ReactChild } from 'react'

interface IMsssDialogProps {
  title: string
  message?: string | ReactChild
  errorCode?: string
}

export function MsssError({ title, message, errorCode = '403' }: IMsssDialogProps) {
  return (
    <PageContainer pageError={errorCode} siteName=''>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 cb-margin-top-72'>
            <ErrorNotification title={title}>{message}</ErrorNotification>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export function MsssInfo({ title, message, errorCode = '403' }: IMsssDialogProps) {
  return (
    <PageContainer pageError={errorCode} siteName=''>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 cb-margin-top-72'>
            <InfoNotification title={title}>{message}</InfoNotification>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}
