import CBInputMethod from '../../../../../utils/CBInputMethode'
import { Checkbox, CheckboxGroup, Select, Spacer } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { TooltipHeader } from '@msss/ui-components'
import { useEffect, useState } from 'react'
import { ReferenceService } from '../../../../../services/rest/ReferenceService'
import { optionsInterntional, pathInternational, pathProvince } from './Options'
import { IGeoSection } from './Geography'
import { ICheck } from '../../ICriteria'

const International = ({ updateData, data, sectionid }: IGeoSection): JSX.Element => {
  const [checks1, setChecks1] = useState<ICheck[]>()
  const [checks2, setChecks2] = useState<ICheck[]>()
  const [checks3, setChecks3] = useState<ICheck[]>()
  const [maxItems, setMaxItems] = useState(0)
  const [currOption, setCurrOption] = useState('-1')
  // used to figure out when to reset the tab to default
  // we always want to reset tab to default unless it is from
  // UNCHECKING select all
  const [resetSelect, setResetSelect] = useState(true)

  const make3columns = (list: ICheck[]): void => {
    list.sort((a: ICheck, b: ICheck) => a.label.localeCompare(b.label))
    const rem = list.length % 3
    const length = Math.floor(list.length / 3)
    setMaxItems(list.length)
    setChecks3(list.splice(-length))
    setChecks2(list.splice(-(length + (rem !== 0 ? rem - 1 : 0))))
    setChecks1(list)
  }

  useEffect(() => {
    CBInputMethod()
  }, [])

  useEffect(() => {
    resetSelect && data.filter(d => d.section === sectionid).length === 0 ? getList('-1') : null
  }, [data, sectionid, resetSelect])

  const getList = async (o: string): Promise<void> => {
    setCurrOption(o)
    let path = pathInternational
    let codeKey = 'code'

    switch (o) {
      case '5':
        path = pathProvince
        codeKey = 'regionFipsCode'
        break
      default:
        path = `${path}${o}`
        break
    }

    let list = []
    if (o !== '-1') {
      list = await ReferenceService.getReferenceData(path)
    }

    make3columns(list.map((i: any): ICheck => ({ section: sectionid, currOption: o, label: i.description, value: i[codeKey] })))
  }

  const handle = (checked: boolean, label: string): void => {
    const full = [...ensure(checks1), ...ensure(checks2), ...ensure(checks3)]

    // used to figure out when to reset the tab to default
    // we only want to allow reset (clear the select) when
    // 'clear all' is clicked.  so when we have a checked
    // we will have item in summary so clear all click will reset
    // if we are still in the section and just clicking around,
    // we don't want reset
    setResetSelect(checked)

    switch (label) {
      case 'Select all':
        full.map(e => {
          if (updateData) {
            updateData(checked, e)
          }
        })

        break
      default: {
        const x = ensure(full.find(f => f.label === label))
        if (updateData) {
          updateData(checked, x)
        }
      }
    }
  }

  const includes = (value: string): boolean => {
    return data ? !(data.filter(e => e.section === sectionid).findIndex(e => e.value === value) === -1) : false
  }

  const includesAll = (): boolean => {
    return data ? data.filter(e => e.section === sectionid).filter(e => e.currOption === currOption).length === maxItems : false
  }

  return (
    <>
      <p>You can search by both states and international regions.</p>
      <Spacer />
      <TooltipHeader header='Select Region' id='geo-select-region' tooltipMsg='Search by the International Region' />
      <div className='row'>
        <div className='col-sm-3'>
          <Select
            ariaLabel='select international regions'
            floating
            name='internationregions'
            onChange={getList}
            selectId='geo-international-regions-options'
            value={currOption}
            values={optionsInterntional}
          />
        </div>
        <div className='col-sm-9 cb-border cb-border-1 cb-border-radius' style={{ minHeight: '300px' }}>
          <div className='cb-padding-16'>
            {checks1 && checks1.length ? (
              <CheckboxGroup fieldsetId='geo-country-options' legend='Countries' legendClassName='sr-only' vertical={true}>
                <Checkbox
                  checked={includesAll()}
                  className='cb-font-weight-medium'
                  id='geo-country-select-all-option'
                  label='Select all'
                  onChange={(checked: boolean): void => handle(checked, 'Select all')}
                />
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='cb-margin-right-24'>
                      {checks1
                        ? checks1.map(c => (
                            <Checkbox
                              checked={includes(c.value)}
                              className='cb-padding-bottom-8'
                              id={`geo-country-option-${c.value}`}
                              key={c.value}
                              label={c.label}
                              onChange={(checked: boolean): void => handle(checked, c.label)}
                            />
                          ))
                        : null}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='cb-margin-right-24'>
                      {checks2
                        ? checks2.map(c => (
                            <Checkbox
                              checked={includes(c.value)}
                              className='cb-padding-bottom-8'
                              id={`geo-country-option-${c.value}`}
                              key={c.value}
                              label={c.label}
                              onChange={(checked: boolean): void => handle(checked, c.label)}
                            />
                          ))
                        : null}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div>
                      {checks3
                        ? checks3.map(c => (
                            <Checkbox
                              checked={includes(c.value)}
                              className='cb-padding-bottom-8'
                              id={`geo-country-option-${c.value}`}
                              key={c.value}
                              label={c.label}
                              onChange={(checked: boolean): void => handle(checked, c.label)}
                            />
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </CheckboxGroup>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default International
