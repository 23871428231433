import { KEY_EVENTS, KEYS, useKeyEvent } from '../../../hooks/useKeyEvent'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { conditionalScroll } from '../../../../services/location/LocationService'
import { ACTIONS, reducer } from './helpers/TypeaheadReducer'
import MultiSelectInput from './MultiSelectInput'
import TypeAheadItem from './TypeaheadItem'
import cn from 'classnames'
import './styles/Typeahead.scss'
import { Icon } from '@cb/apricot-react'
import { MAX_PEERS_PER_GROUP } from '../PeerGroups'

interface TypeAheadProps {
  customClasses?: string
  id: string
  initializeOnFocus?: boolean
  initializeOnRender?: boolean
  inputGroupAriaLabel: string
  label: string
  maxOptionsCount?: number
  maxSelectionCount?: number
  minValueLength?: number
  noResultsLabel?: string
  onBlur?: (e: any) => void
  onChange?: (e: any) => void
  onClick?: (option: any, options: any[]) => void
  onFocus?: (e: any) => void
  onRemoveTag: (removedTagInfo: any) => void
  options: any[]
  placeholder?: string
  tags: any[]
}

const TypeAhead = ({
  customClasses,
  id,
  initializeOnFocus = false,
  initializeOnRender = false,
  inputGroupAriaLabel = '',
  label = 'autocomplete input',
  maxOptionsCount,
  maxSelectionCount,
  minValueLength = 0,
  noResultsLabel = 'No results found',
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
  onFocus = () => {},
  onRemoveTag = () => {},
  options = [],
  placeholder = '',
  tags = []
}: TypeAheadProps) => {
  const [{ value, keyBoardNavigationPosition, showMenu, activeDescendant }, dispatchTypeaheadState] = useReducer(reducer, {
    value: '',
    keyBoardNavigationPosition: -1,
    showMenu: initializeOnRender,
    activeDescendant: null
  })

  // need to convert tags to selections datastructure
  const tags2Selections = tags => {
    let fullTags
    if (options) {
      fullTags = options.filter((option: any) =>
        tags.find(
          tag => tag.orgId.toString() === option.orgId && option.orgName.toLowerCase().includes(tag.orgName.toLowerCase())
        )
      )
    }
    return fullTags
  }

  const initialRender = useRef(true)
  const inputFocus = useRef(false)
  const keyBoardNavigation = useRef(false)
  const keyBoardNavigationList = useRef([])
  const overrideScrollOnFocus = useRef(false)
  const typeaheadRef = useRef(null)
  const typedValue = useRef('')

  const [selections, setSelections] = useState<any>(tags2Selections(tags))
  const [latestActionDescription, setLatestActionDescription] = useState<string>(null)
  const [optionsCount, setOptionsCount] = useState<number>(maxOptionsCount)

  const selectionMap = selections.reduce((acc, tag) => {
    acc[tag.uid] = tag
    return acc
  }, {})

  useEffect(() => {
    if (maxOptionsCount) {
      setOptionsCount(maxOptionsCount)
      if (maxOptionsCount < options.length) {
        const target = document.getElementById('menu-list-item-load-more')

        if (target) {
          const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
              setOptionsCount(previous => previous + maxOptionsCount)
            }
          })
          observer.observe(target)
          return () => observer.disconnect()
        }
      }
    }
  }, [options, maxOptionsCount])

  useEffect(() => {
    if (!initialRender.current) {
      setSelections(tags2Selections(tags))
    }
  }, [tags])

  useEffect(() => {
    // handle active descendant on key navigation
    if (keyBoardNavigationPosition === -1) {
      if (activeDescendant) {
        dispatchTypeaheadState({
          type: ACTIONS.SET_DESECENDENT,
          activeDescendant: null
        })
      }
    } else {
      if (activeDescendant !== keyBoardNavigationList.current[keyBoardNavigationPosition]?.uid) {
        dispatchTypeaheadState({
          type: ACTIONS.SET_DESECENDENT,
          activeDescendant: keyBoardNavigationList.current[keyBoardNavigationPosition]?.uid
        })
      }
    }
  }, [keyBoardNavigationPosition])

  useKeyEvent({
    keyEvent: KEY_EVENTS.KEYDOWN,
    targetKey: KEYS.ENTER,
    handler: e => {
      if (keyBoardNavigationPosition !== -1 && showMenu) {
        e.preventDefault()
        const currentSelection = keyBoardNavigationList.current[keyBoardNavigationPosition]
        onClickMultiTypeaheadItem(currentSelection)
      }
    }
  })

  useKeyEvent({
    keyEvent: KEY_EVENTS.KEYDOWN,
    targetKey: KEYS.SPACE,
    handler: e => {
      if (keyBoardNavigationPosition !== -1 && showMenu) {
        e.preventDefault()
        const currentSelection = keyBoardNavigationList.current[keyBoardNavigationPosition]
        onClickMultiTypeaheadItem(currentSelection)
      }
    }
  })

  useKeyEvent({
    // handle Escape press when menu is open
    keyEvent: KEY_EVENTS.KEYDOWN,
    targetKey: KEYS.ESC,
    handler: () => {
      if (showMenu) {
        dispatchTypeaheadState({
          type: ACTIONS.RESET_EXCEPT_INPUT,
          showMenu: Boolean(initializeOnFocus)
        })
      }
    }
  })

  useKeyEvent({
    // handle Tab press when menu is open
    keyEvent: KEY_EVENTS.KEYDOWN,
    targetKey: KEYS.TAB,
    handler: e => {
      if (!showMenu) {
        return
      }
      // clicking shift + tab or tab with no value while on the input
      if (e.target.id === id) {
        if (e.shiftKey || !e.target.value) {
          dispatchTypeaheadState({
            type: ACTIONS.RESET_EXCEPT_INPUT,
            showMenu: Boolean(initializeOnFocus)
          })
        }
      }
    }
  })

  useKeyEvent({
    // handle arrow up press when menu is open
    keyEvent: KEY_EVENTS.KEYDOWN,
    targetKey: KEYS.UP,
    handler: e => {
      if (!inputFocus.current || !showMenu) {
        return
      }
      e.preventDefault()
      keyBoardNavigation.current = true

      if (keyBoardNavigationPosition === -1) {
        // when no selection is make in the menu list yet
        const newPosition = keyBoardNavigationList.current.length - 1
        dispatchTypeaheadState({
          type: ACTIONS.SET_FOCUS_POSITION,
          value: keyBoardNavigationList.current[newPosition].orgName || typedValue.current || '',
          keyBoardNavigationPosition: newPosition
        })
      } else if (keyBoardNavigationPosition === 0) {
        // when the 1st item in the menu is selected
        dispatchTypeaheadState({
          type: ACTIONS.SET_FOCUS_POSITION,
          value: typedValue.current,
          keyBoardNavigationPosition: -1
        })
      } else {
        // when somewhere in the middle of the menu list
        const newPosition = keyBoardNavigationPosition - 1
        dispatchTypeaheadState({
          type: ACTIONS.SET_FOCUS_POSITION,
          value: keyBoardNavigationList.current[newPosition].orgName || typedValue.current || '',
          keyBoardNavigationPosition: newPosition
        })
      }
    }
  })

  useKeyEvent({
    // handle arrow down press when menu is open
    keyEvent: KEY_EVENTS.KEYDOWN,
    targetKey: KEYS.DOWN,
    handler: e => {
      if (!inputFocus.current || !showMenu) {
        return
      }
      e.preventDefault()
      keyBoardNavigation.current = true

      if (keyBoardNavigationPosition === keyBoardNavigationList.current.length - 1) {
        // when the last item in the menu is selected
        dispatchTypeaheadState({
          type: ACTIONS.SET_FOCUS_POSITION,
          value: typedValue.current,
          keyBoardNavigationPosition: -1
        })
      } else {
        // when no selection is made or somewhere in the middle of the menu list
        const newPosition = keyBoardNavigationPosition + 1
        dispatchTypeaheadState({
          type: ACTIONS.SET_FOCUS_POSITION,
          value: keyBoardNavigationList.current[newPosition].orgName || typedValue.current || '',
          keyBoardNavigationPosition: newPosition
        })
      }
    }
  })

  // conditionally display the appropriate message to the screen reader
  const ariaAvailableResults = useCallback(
    currentOptions =>
      currentOptions.length > 0 ? `${currentOptions.slice(0, maxOptionsCount).length} results are available` : noResultsLabel,
    [maxOptionsCount, noResultsLabel]
  )

  // handle bluring from TH input
  const onBlurTH = useCallback(() => {
    inputFocus.current = false
    dispatchTypeaheadState({
      type: ACTIONS.CUSTOM,
      value: typedValue.current || '',
      keyBoardNavigation: -1
    })
    onBlur()
  }, [onBlur])

  // handle on change of TH input
  const onChangeTH = useCallback(
    e => {
      const newValue = e.target.value
      dispatchTypeaheadState({
        type: ACTIONS.CUSTOM,
        value: newValue,
        keyBoardNavigationPosition: -1,
        showMenu: true
      })
      onChange(newValue.trim())
      typedValue.current = newValue
    },
    [onChange]
  )

  const onClickMultiTypeaheadItem = useCallback(
    currentSelection => {
      if (!currentSelection.isDisabled) {
        const isSelected = Boolean(selectionMap[currentSelection.uid])
        if (!isSelected) {
          const newSelections = [currentSelection, ...selections]
          setSelections(newSelections)
          onClick(currentSelection, newSelections)
          setLatestActionDescription(`${currentSelection.orgName} selected`)
        } else {
          const newSelections = selections.filter(selection => selection.uid !== currentSelection.uid)
          setSelections(newSelections)
          onClick(currentSelection, newSelections)
          setLatestActionDescription(`${currentSelection.orgName} removed`)
        }
        if (typedValue.current || value) {
          // comment out for true multiselect
          // typedValue.current = ''
          // dispatchTypeaheadState({type: ACTIONS.SELECT_MULTISELECT_OPTION})

          overrideScrollOnFocus.current = true
          setTimeout(() => {
            dispatchTypeaheadState({
              type: ACTIONS.CUSTOM,
              keyBoardNavigationPosition: keyBoardNavigationList.current.findIndex(item => item.uid === currentSelection.uid)
            })
            conditionalScroll(null, `menu-list-item-${currentSelection.uid}`, false, {
              containerId: `${id}-menu-list-container`,
              scrollOptions: { block: 'nearest' }
            })
            setTimeout(() => (overrideScrollOnFocus.current = false), 1000)
          }, 250)
        }
      }
    },
    [selections, selectionMap, value, onClick]
  )

  // handle clicking list item
  const onClickListItem = useCallback(() => {
    const isFocusedOnItem = keyBoardNavigationPosition !== -1 && showMenu
    const currentSelection = keyBoardNavigationList.current[keyBoardNavigationPosition]

    if (isFocusedOnItem) {
      onClickMultiTypeaheadItem(currentSelection)
    }
  }, [keyBoardNavigationPosition, showMenu, onClickMultiTypeaheadItem])

  // handle focus on menu item by mouse
  const onFocusListItem = useCallback(e => {
    const { nodeName } = e.currentTarget
    let dataSet
    if (nodeName === 'A') {
      dataSet = e.currentTarget.dataset
    } else {
      dataSet = e.currentTarget.parentNode.dataset
    }
    dispatchTypeaheadState({
      type: ACTIONS.CUSTOM,
      keyBoardNavigationPosition: Number(dataSet['listItemOption'])
    })
  }, [])

  const onFocusListItemCB = useCallback(e => onFocusListItem(e), [])

  // handle focus on TH input
  const onFocusTH = useCallback(
    e => {
      dispatchTypeaheadState({
        type: ACTIONS.ON_FOCUS,
        showMenu: Boolean(initializeOnFocus)
      })
      inputFocus.current = true
      onFocus(e.target.value)
    },
    [initializeOnFocus, onFocus]
  )

  const onKeyBoardNavigationFocus = useCallback(eleId => {
    if (!overrideScrollOnFocus.current) {
      conditionalScroll(null, eleId, false, { containerId: `${id}-menu-list-container`, scrollOptions: { block: 'nearest' } })
    }
  }, [])

  keyBoardNavigationList.current = []
  let iterator = -1

  // handle mouse movement over menu list
  const onMouseOverTH = useCallback(() => {
    keyBoardNavigation.current = false
  }, [])

  const onRemoveTagTH = useCallback(
    ({ value: tagValue, currentSelections }) => {
      setLatestActionDescription(`${tagValue.orgName} removed`)
      setSelections([...currentSelections])
      onRemoveTag({ tagValue, currentSelections })
    },
    [onRemoveTag]
  )

  const inputProps = {
    ariaActiveDescendant: activeDescendant && `menu-list-item-${activeDescendant}`,
    ariaControls: `${id}-menu-list`,
    ariaDescribedBy:
      value.trim().length >= minValueLength && showMenu
        ? !options.length
          ? `${id}-no-results-found-container`
          : `${id}-available-results-count`
        : null,
    ariaExpanded: Boolean(showMenu && value.trim().length >= minValueLength),
    ariaHasPopup: showMenu && value.trim().length >= minValueLength ? (options.length > 0 ? 'listbox' : 'true') : null,
    ariaLabel: label,
    ariaOwns: options.length > 0 ? `${id}-menu-list` : `${id}-typeahead-no-results`,
    id,
    label,
    onBlur: onBlurTH,
    onChange: onChangeTH,
    onFocus: onFocusTH,
    value
  }

  const multiSelectInputProps = {
    inputGroupAriaLabel,
    maxSelectionCount,
    onRemoveTag: onRemoveTagTH,
    placeholder: placeholder || label,
    tags: selections
  }

  return (
    <div className={cn('typeahead-container', selections.length && 'extra-height', customClasses)} ref={typeaheadRef}>
      <div className='typeahead-input-container' id={`${id}-input-container`}>
        <div className='typeahead-input-form-container'>
          <MultiSelectInput {...inputProps} {...multiSelectInputProps} />
        </div>
      </div>
      <span aria-live='polite' className='sr-only' id={`${id}-available-results-count`} role='status'>
        {options.length && value.trim().length >= minValueLength && showMenu ? ariaAvailableResults(options) : null}
      </span>
      <span aria-live='polite' className='sr-only' id={`${id}-latest-action`} role='status'>
        {latestActionDescription}
      </span>

      {showMenu &&
        Boolean(options.length) &&
        value.trim().length >= minValueLength &&
        (!maxSelectionCount || tags.length < maxSelectionCount) && (
          <div className='typeahead-menu-list-container' id={`${id}-menu-list-container`}>
            <nav
              className='cb-menu-list cb-menu-list-vertical cb-menu-list-condensed'
              onFocus={onMouseOverTH}
              onMouseOver={onMouseOverTH}
            >
              <ul aria-label={label} aria-multiselectable={maxSelectionCount !== 1} id={`${id}-menu-list`} role='listbox'>
                {options.slice(0, optionsCount).map((m, i) => {
                  const itemId = `menu-list-item-${m.uid}`
                  const isSelected = Boolean(selectionMap[m.uid])
                  const isDisabled = selections.length >= maxSelectionCount
                  keyBoardNavigationList.current.push({
                    ...m,
                    id: itemId,
                    isDisabled
                  })
                  if (i === optionsCount - 1 && optionsCount < options.length) {
                    keyBoardNavigationList.current.push({
                      id: 'menu-list-item-load-more',
                      isDisabled: false
                    })
                  }
                  return (
                    <TypeAheadItem
                      cityState={m.city || m.state ? `${m.city || '--'}, ${m.state || '--'}` : '--'}
                      id={itemId}
                      isDisabled={isDisabled}
                      isSelected={isSelected}
                      iterator={++iterator}
                      key={`item-${m.uid}`}
                      keyBoardNavigation={keyBoardNavigation.current}
                      keyBoardNavigationPosition={keyBoardNavigationPosition}
                      onClick={onClickListItem}
                      onFocus={onFocusListItemCB}
                      onKeyBoardNavigationFocus={onKeyBoardNavigationFocus}
                      orgName={m.orgName}
                    />
                  )
                })}
                {optionsCount < options.length && (
                  <TypeAheadItem
                    id='menu-list-item-load-more'
                    iterator={++iterator}
                    loader
                    keyBoardNavigation={keyBoardNavigation.current}
                    keyBoardNavigationPosition={keyBoardNavigationPosition}
                    onFocus={onFocusListItemCB}
                    onKeyBoardNavigationFocus={onKeyBoardNavigationFocus}
                  />
                )}
              </ul>
            </nav>
          </div>
        )}
      {!options.length && showMenu && value.trim().length >= minValueLength && (
        <div
          aria-live='polite'
          className='typeahead-menu-list-container typeahead-no-results-found'
          id={`${id}-no-results-found-container`}
          role='status'
        >
          <Icon color='red1' decorative name='exclamation-circle' size='16' />
          <p className='cb-margin-left-16'>Enter a valid keyword or name.</p>
        </div>
      )}
      {maxSelectionCount && showMenu && tags.length >= maxSelectionCount && (
        <div
          aria-live='polite'
          className='typeahead-menu-list-container typeahead-max-results'
          id={`${id}-max-results-container`}
          role='status'
        >
          <Icon color='red1' decorative name='exclamation-circle' size='16' />
          <p className='cb-margin-left-16'>You reached the maximum number of {MAX_PEERS_PER_GROUP} institutions per group.</p>
        </div>
      )}
    </div>
  )
}

export default TypeAhead
