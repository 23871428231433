import DownloadAvailableIcon from '../DownloadAvailableIcon'
import { IFolder } from './IFolder'

interface IFolderCardProps {
  folderCountDesc?: string
  folderId: number
  folderName: string
  count?: number
  setFolder: (folder: IFolder) => void
  downloadsPending: number
}

export const FolderName = ({ folderName, maxChars = 40 }) => {
  if (folderName.length > maxChars) {
    return <>{folderName.slice(0, maxChars) + '...'}</>
  }

  return <>{folderName}</>
}

const FolderCard = ({ folderCountDesc, folderId, folderName, count, setFolder, downloadsPending }: IFolderCardProps) => {
  const handleFolderClick = e => {
    e.preventDefault()
    setFolder({ folderId, folderName, count: 0, downloadsPending: 0 })
  }

  return (
    <div className='cb-margin-top-8 cb-margin-bottom-8 cb-padding-16 cb-border-radius cb-border cb-blue5-tint-2-border'>
      <div className='row'>
        <div className='col-xs-12'>
          <a
            href='#'
            className='cb-padding-top-8 cb-padding-bottom-8 cb-font-weight-bold'
            onClick={handleFolderClick}
            title={folderName}
          >
            <FolderName folderName={folderName} />
          </a>
        </div>
      </div>
      <div className='row cb-margin-top-16'>
        <div className='col-xs-10'>
          {folderCountDesc && (
            <>
              {folderCountDesc}: <span className='cb-font-weight-bold'>{count}</span>
            </>
          )}
        </div>
        <div className='col-xs-2'>{downloadsPending > 0 && <DownloadAvailableIcon />}</div>
      </div>
    </div>
  )
}

export default FolderCard
