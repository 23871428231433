import { ILabeledValue } from '@cb/apricot-react'
import { ensure, FeatureKeys, Roles } from '@msss/ui-common'
import { ReferenceService } from '../../../../../services/rest/ReferenceService'
import { ISectionCriteria } from '../../../../../interfaces/ISectionCriteria'
import { IUserData } from '../../../../search/search-form/IUserData'
import { CategoryGroupCode, Section } from '../../../../search/search-form/summary/Section'
import refPath from '../../../../../assets/ref_path.json'
import { GEOGRAPHY } from '../../../../search/search-form/criteria/geography/Enums'
import { IApExamCriteria } from '../../../../search/search-form/criteria/cb-exams/ap/IApExamCriteria'
import { getExams } from '../../../../search/search-form/criteria/cb-exams/CbExams'
import { ICriteria } from '../../../../search/search-form/ICriteria'
import { allApOptions } from '../../../../search/search-form/criteria/cb-exams/ap/CriteriaSelectionOptions'
import { IExamCriteria } from '../../../../search/search-form/criteria/cb-exams/IExamCriteria'
import { psatRadioOptions } from '../../../../search/search-form/criteria/cb-exams/psat/CriteriaSelectionOptions'
import { satRadioOptions } from '../../../../search/search-form/criteria/cb-exams/sat/CriteriaSelectionOptions'
import {
  assessmentOptions,
  scoreLogicOptions,
  scoreSendsOptions
} from '../../../../search/search-form/criteria/cb-exams/CriteriaSelectionOptions'
import {
  financialAidOptions,
  religionChoiceOptions,
  religionOptions
} from '../../../../search/search-form/criteria/college-pref/CriteriaSelectionOptions'
import { majorsChoiceOptions } from '../../../../search/search-form/criteria/intended-major/Options'
import { alsoIncludeStudentsMap, ALSO_INCLUDE_STUDENTS } from '../../../../search/search-form/criteria/segment-analysis/options'
import { HOME_SCHOOLED, INCLUDE_ALL_STUDENTS, ROTC_HISTORY_LABEL } from '../../../../search/search-form/SearchFormConstants'
import { GET_PEER_GROUPS } from '../../../../../services/graphql/queries'
import { newClient } from '../../../../../apollo/Apollo'
import { neighborhoodKeyLabelMap } from '../../../../search/search-form/criteria/land-scape/CriteriaSelectionOptions'
import { PARENTEMAILONLABEL } from '../../../../search/search-form/criteria/ParentEmailPref'
import {
  hsAcademicOptions,
  hsSportsOptions,
  hsArtMusicOptions,
  hsActivitiesOptions,
  hsActivitiesAwdOption,
  hsActivitiesYrsOptions
} from '../../../../search/search-form/criteria/hs-courses-activities/CriteriaSelectionOptions'

// It has only the section and the JSX element of individual accordion criteria
// It is used to create the config for all the accordions
export interface ICriteriaConfig {
  section: Section
  featureKey?: string
  roleRestricted?: number[]
  sectionTitle: string
  sectionConfig: ICriteriaSectionConfig[]
}

export interface ICriteriaSectionConfig {
  title?: string
  key: string
  label?: Array<string>
  labelOptional?: Array<string>
  labelOnExists?: string
  labelOnNotExists?: string
  data?: string[] | undefined
  dataOptional?: string[] | undefined
  refPath?: string
  refPathOptional?: string
  refOptions?: ILabeledValue[]
  useSemicolon?: boolean
  noSort?: boolean
  criteria?: ICriteria
  callback?: (x: ICriteriaSectionConfig) => Promise<string[]>
}

// Array of this object is what is sent back by the service.
// This has the section/accordion, sortOrder, details of the section (an array of ICriteriaLabelValues)
// Eg: Graduating Class and all its criteria data
export interface ICriteriaDetails {
  sectionTitle: string
  featureKey?: string
  roleRestricted?: number[]
  sectionSortId?: number
  sectionData: ICriteriaLabelValues[]
}

// This is the object that stores the data for a individual criteria
// Eg: Cohort Years and array of years
export interface ICriteriaLabelValues {
  label?: string
  key: string
  values?: string[]
  data?: string[]
  useSemicolon?: boolean
}

export interface ISelectedData {
  label?: Array<string>
  labelOptional?: Array<string>
  refPath?: string
  refPathOptional?: string
  data: string[] | undefined
  dataOptional?: string[] | undefined
}

export interface IReferenceData {
  labelFields?: Array<string>
  refPath?: string
  value: string
}

const getHsActivity = async ({ data }: ICriteriaSectionConfig) => {
  const result: string[] = []
  if (!data) return result

  const activityData = data[0]
  for (let i = 1; i <= 27; i++) {
    if (activityData?.[`hsActivityYrs${i}`]) {
      const activityLabel = ensure(hsActivitiesOptions.find(option => option.value === `${i}`)).label
      let yrs = activityData[`hsActivityYrs${i}`][0]
      if (yrs.includes(',')) {
        // handle edge case where multiple years were selected in legacy
        // keep least specific year
        const yrsSplit = yrs.split(',')
        let min = Number(yrsSplit[0].trim())
        yrsSplit.forEach((actNum: string) => {
          const num = Number(actNum.trim())
          if (min > num) min = num
        })
        yrs = `${min}`
      }
      const yrsLabel = ensure(hsActivitiesYrsOptions.find(option => option.value === yrs)).label
      let awdLabel = ''
      if (activityData?.[`hsActivityAwd${i}`]) awdLabel = `, ${hsActivitiesAwdOption.label}`
      result.push(`${activityLabel}, ${yrsLabel}${awdLabel}`)
    }
  }

  return result
}

const getPeerGroups = async ({ data, dataOptional }: ICriteriaSectionConfig) => {
  const result: string[] = []
  // check to make sure we have peer group criteria in user data before
  // parse out user data first to check if we need to make call to get peergroup
  const userData: IUserData = data ? JSON.parse(data[0]) : {}

  if (
    dataOptional &&
    (userData?.favPeers1 || userData.favPeers2 || userData.favPeers3 || userData.favPeers4 || userData.favPeers5)
  ) {
    const orgId = dataOptional[0]
    const loginAsOrgId = dataOptional[1]
    // if you have loginAsOrgId then use that for doing auth of appsync client
    const authOrgId = loginAsOrgId ? loginAsOrgId : orgId
    const client = newClient(process.env['APPSYNC_PROVIDER_URL'], parseInt(authOrgId))
    const resp = await client.query({ query: GET_PEER_GROUPS, variables: { orgId } })
    if (resp && data) {
      const all = resp.data.getPeerGroups?.map(e => ({ id: e.sequence, name: e.name }))
      const src: number[] = [1, 2, 3, 4, 5]
      src.forEach(s => {
        if (userData[`favPeers${s}`]) {
          const r = all?.find(e => e.id === s)
          r ? result.push(`Peer Group ${s}: ${r.name}`) : null
        }
      })
    }
  }

  return new Promise<string[]>(resolve => resolve(result))
}

// simple function to get label from reference using the label array of fields that will be used for the label
const getLabel = async ({ refPath, value, labelFields }: IReferenceData) => {
  let label = value // set the default
  if (refPath) {
    const ref = await ReferenceService.getReferenceData(refPath)
    type reftype = typeof ref
    const reference = ref[value as keyof reftype]

    if (reference) {
      if (labelFields) {
        const descr = labelFields.map(labelField => reference[labelField])
        label = descr.join('-')
      }
    }
  }

  return label
}

const gpaCriteria = async ({ refPath, data }: ICriteriaSectionConfig) => {
  const labels: string[] = []
  if (data) {
    // convert gpa to array of number from array of string
    // must have the a,b argument... if you do an empty sort(), it will use
    // ascii value sort wchich will be WRONG... 1,22,3 for example
    const gpaValues = data.map(e => parseInt(e)).sort((a, b) => a - b)
    // check for zero if exits set norespond then remove from array
    if (gpaValues[0] === 0) {
      // remove the zero
      gpaValues.shift()
      const label = await getLabel({ refPath, value: '0', labelFields: ['description'] })
      labels.push(label)
    }
    if (gpaValues && gpaValues.length > 1) {
      const highLabel = 'High: ' + (await getLabel({ refPath, value: gpaValues[0].toString(), labelFields: ['description'] }))
      labels.push(highLabel)
      const lowLabel =
        'Low: ' + (await getLabel({ refPath, value: gpaValues[gpaValues.length - 1].toString(), labelFields: ['description'] }))
      labels.push(lowLabel)
    } else if (gpaValues && gpaValues.length == 1) {
      const highLabel = 'High: ' + (await getLabel({ refPath, value: gpaValues[0].toString(), labelFields: ['description'] }))
      labels.push(highLabel)
      const lowLabel = 'Low: ' + (await getLabel({ refPath, value: gpaValues[0].toString(), labelFields: ['description'] }))
      labels.push(lowLabel)
    }
  }

  return labels
}

const intendedMajorCriteriaLabels = async ({ refPath, data }: ICriteriaSectionConfig) => {
  const labels: string[] = []
  if (data) {
    if (refPath) {
      const ref = await ReferenceService.getReferenceData(refPath)
      type reftype = typeof ref

      data.forEach(value => {
        // No response has value of 0
        if (value.startsWith('0') && value.length > 1) {
          value = value.substring(1)
        }
        if (value) {
          const reference = ref[value as keyof reftype]
          if (reference) {
            const label = reference['description']
            if (label) {
              labels.push(label)
            }
          } else {
            labels.push(value)
          }
        }
      })
    }
  }
  return labels
}

const cbExamsApCriteria = async ({ criteria }: ICriteriaSectionConfig) => {
  let labels: string[] = []

  if (criteria) {
    // check if Any AP exam option is set
    if (criteria.testTaken) {
      const found = criteria.testTaken.find(e => e === 'AP')

      if (found) {
        labels = ['Any AP Exam Taker']

        return labels
      }
    }

    const apExamsCriteria = getExams('AP', criteria) as IApExamCriteria[]

    if (apExamsCriteria && apExamsCriteria.length > 0) {
      labels = apExamsCriteria.map(exam => {
        let label = 'Score band ' + exam.apScoreLow + ' - ' + exam.apScoreHigh + ' ('

        const apExamNames = exam.apExams.map(ap => {
          // eg: AP Exams Art History
          return ensure(allApOptions.find(option => option.value === ap)).label
        })

        label = label.concat(apExamNames.join(', '))
        label = label.concat(')')

        return label
      })
    }
  }
  return labels
}

const cbExamsPsatCriteria = async ({ criteria }: ICriteriaSectionConfig) => {
  let labels: string[] = []

  if (criteria) {
    // check if Any PSAT exam option is set
    if (criteria.testTaken) {
      const found = criteria.testTaken.find(e => e === 'PSAT')

      if (found) {
        labels = ['Any PSAT Exam Taker']

        return labels
      }
    }
    const psatExamsCriteria = getExams('PSAT', criteria) as IExamCriteria[]

    if (psatExamsCriteria && psatExamsCriteria.length > 0) {
      labels = psatExamsCriteria.map(psat => {
        let label = 'Score band ' + psat.lowScore + ' - ' + psat.highScore + ' ('
        const examName = ensure(psatRadioOptions.find(option => option.value === psat.exam)).label
        label = label.concat(examName)
        label = label.concat(')')

        return label
      })
    }
  }
  return labels
}

// TODO see if all 3 exams section can be made into 1 function
const cbExamsSatCriteria = async ({ criteria }: ICriteriaSectionConfig) => {
  let labels: string[] = []
  if (criteria) {
    // check if Any SAT exam option is set
    if (criteria.testTaken) {
      const found = criteria.testTaken.find(e => e === 'SAT')

      if (found) {
        labels = ['Any SAT Exam Taker']

        return labels
      }
    }

    const satExamsCriteria = getExams('SAT', criteria) as IExamCriteria[]

    if (satExamsCriteria && satExamsCriteria.length > 0) {
      labels = satExamsCriteria.map(sat => {
        let label = 'Score band ' + sat.lowScore + ' - ' + sat.highScore + ' ('
        const examName = ensure(satRadioOptions.find(option => option.value === sat.exam)).label
        label = label.concat(examName)
        label = label.concat(')')

        return label
      })
    }
  }
  return labels
}

const hsClusterCriteriaLabels = async ({ data }: ICriteriaSectionConfig) => {
  if (data?.length) {
    return data.map(item => {
      let en = item?.substring(0, 2) || ''
      en = en === '00' ? 'ALL' : Number(en) < 10 ? `${Number(en)}` : en
      let hs = item?.substring(2, 4) || ''
      hs = hs === '00' ? 'ALL' : Number(hs) < 10 ? `${Number(hs)}` : hs
      return 'EN: ' + en + ', HS: ' + hs
    })
  }

  return []
}

// This function is used to get labels using a reference array available locally in the app
// eg: assessment, score sends, etc many minor small lookups we can use this approach.
const getLabelsFromRefArray = async ({ data, refOptions }: ICriteriaSectionConfig) => {
  if (data) {
    if (refOptions) {
      const labels = data.map(value => {
        return ensure(refOptions.find(option => option.value === value)).label
      })

      return labels
    } else {
      return data
    }
  }

  return []
}

// This function takes the config values and criteria codes and gets the labels from reference
const getLabelsFromRefObject = async ({ label, labelOptional, refPath, refPathOptional, data, dataOptional }: ISelectedData) => {
  // the data it self is the label, like cohort year and zipcodes
  if (!refPath && !refPathOptional && data) return data

  let labels: string[] = []
  let ref: any
  if (refPath) {
    ref = await ReferenceService.getReferenceData(refPath)
  }
  // eg: Canada
  if (refPathOptional) {
    // eg: State
    const ref2 = await ReferenceService.getReferenceData(refPathOptional)
    ref = { ...ref, ...ref2 }
  }
  type reftype = typeof ref

  if (data) {
    labels = data.map((value): string => {
      const reference = ref[value as keyof reftype]
      let description = value
      if (label) {
        if (reference) {
          const descr = label.map(label => reference[label])
          description = descr.join('-')
        }
      }
      return description
    })
  }

  if (dataOptional && dataOptional.length > 0) {
    const labelsOptional = dataOptional.map((value): string => {
      const reference = ref[value as keyof reftype]
      let description = value
      if (labelOptional) {
        if (reference) {
          const descr = labelOptional.map(label => reference[label])
          description = descr.join('-')
        }
      }
      return description
    })

    labels = [...labels, ...labelsOptional]
  }

  return labels
}

const concatZips = (zip2?: string[], zip3?: string[], zip5?: string[]) => {
  let allZips: string[] = []

  if (zip2) {
    allZips = allZips.concat(zip2)
  }

  if (zip3) {
    allZips = allZips.concat(zip3)
  }

  if (zip5) {
    allZips = allZips.concat(zip5)
  }

  return allZips
}

export const getCriteriaLabels = async (
  criteria: ISectionCriteria,
  userData: IUserData,
  excludedCriteria: any,
  orgId: string,
  loginAsOrgId: any
) => {
  let criteriaLabelValues: ICriteriaDetails[] = []

  const gradYearCriteria = criteria[CategoryGroupCode.GRADYEAR]
  const geographyCriteria = criteria[CategoryGroupCode.GEOGRAPHY]
  const examsCriteria = criteria[CategoryGroupCode.EXAMS]
  const demographicsCriteria = criteria[CategoryGroupCode.DEMOGRAPHICS]
  const emailPrefCriteria = criteria[CategoryGroupCode.EMAIL]
  const intendedMajorCriteria = criteria[CategoryGroupCode.IM]
  const hsAcadPerfCriteria = criteria[CategoryGroupCode.HSACADPERF]
  const colPlanPrefCriteria = criteria[CategoryGroupCode.COLLEGEPREF]
  const nrpCriteria = criteria[CategoryGroupCode.NRP]
  const saCriteria = criteria[CategoryGroupCode.SA]
  const landscapeCriteria = criteria[CategoryGroupCode.LANDSCAPE]
  const parentEmail = criteria[CategoryGroupCode.PARENTEMAIL]
  const hsCoursesActivitiesCriteria = criteria[CategoryGroupCode.HS_COURSES_ACTIVITIES]
  const hsActivityCriteria = criteria[CategoryGroupCode.HSACTIVITY]

  console.log('getCriteriaLabels:', { criteria, userData, excludedCriteria })

  // custom
  const allZips = userData.zipFilename
    ? [userData.zipFilename]
    : concatZips(geographyCriteria?.zip2, geographyCriteria?.zip3, geographyCriteria?.zip5)
  const showCPPReligionChoice =
    colPlanPrefCriteria?.degree ||
    excludedCriteria?.degree ||
    demographicsCriteria?.finAidNeed ||
    excludedCriteria?.finAidNeed ||
    colPlanPrefCriteria?.collegeSize ||
    excludedCriteria?.collegeSize ||
    colPlanPrefCriteria?.collegeLocation ||
    excludedCriteria?.collegeLocation ||
    colPlanPrefCriteria?.collegeSetting ||
    excludedCriteria?.collegeSetting ||
    colPlanPrefCriteria?.collegeType ||
    excludedCriteria?.collegeType ||
    colPlanPrefCriteria?.collegeAffiliation ||
    excludedCriteria?.collegeAffiliation ||
    colPlanPrefCriteria?.collegeGender ||
    excludedCriteria?.collegeGender ||
    colPlanPrefCriteria?.collegeHousingPlans ||
    excludedCriteria?.collegeHousingPlans ||
    demographicsCriteria?.rotcCollege ||
    excludedCriteria?.rotcCollege
  const sasAlsoIncludeOptions: string[] = []
  if (userData?.unknownNhCluster === 'Y' || excludedCriteria?.unknownNhCluster === 'Y') {
    sasAlsoIncludeOptions.push(ensure(alsoIncludeStudentsMap.get(ALSO_INCLUDE_STUDENTS.noNHC)?.label))
  }
  if (userData?.unknownHsCluster === 'Y' || excludedCriteria?.unknownHsCluster === 'Y') {
    sasAlsoIncludeOptions.push(ensure(alsoIncludeStudentsMap.get(ALSO_INCLUDE_STUDENTS.noHSC)?.label))
  }

  const getFullPeerGrouups = (): string => {
    let x
    // unfortunately will need to check all 5 fields
    // this may be bad as we are hardcodeing the fields... but I am not sure if we can
    // fuzzy match keys like we can with strings?
    if (userData?.favPeers1 || userData.favPeers2 || userData.favPeers3 || userData.favPeers4 || userData.favPeers5) {
      x = { ...userData }
    }

    if (
      excludedCriteria?.favPeers1 ||
      excludedCriteria.favPeers2 ||
      excludedCriteria.favPeers3 ||
      excludedCriteria.favPeers4 ||
      excludedCriteria.favPeers5
    ) {
      x = { ...x, ...excludedCriteria }
    }
    return JSON.stringify(x)
  }

  // This config is an array of all accordions, with section and title. This is used by the render all the acoordion criteria
  const CRITERIA_CONFIG: ICriteriaConfig[] = [
    {
      section: Section.GRADYEAR,
      sectionTitle: 'Graduating Class',
      sectionConfig: [
        {
          title: 'HS Grad Class',
          key: 'cohortYear',
          data: gradYearCriteria?.cohortYear
        },
        {
          title: 'New prospects',
          key: 'customerOrgs',
          data: gradYearCriteria?.customerOrgs,
          labelOnExists: 'Include only new students not included in my other orders',
          labelOnNotExists: INCLUDE_ALL_STUDENTS
        }
      ]
    },
    {
      section: Section.IMC,
      sectionTitle: 'Interest In My College',
      sectionConfig: [
        {
          key: 'customerOrgs',
          data: gradYearCriteria?.engaged,
          labelOnExists: 'Include only students interested in my college'
        }
      ]
    },
    {
      section: Section.IMPEERS,
      featureKey: FeatureKeys.IMP.key,
      sectionTitle: 'Interest In My Peers',
      sectionConfig: [
        {
          key: 'favPeers',
          // 7/14/2023 - peer group needs to support history so now we have the possiblity of
          // approved groups as well as unapproved groups.  the service will return unapproved in
          // excluded criteria and valid ones still in user data
          // we will now need to combine them for display in msof->criteria tab
          data: getFullPeerGrouups() ? [getFullPeerGrouups()] : undefined,
          dataOptional: [orgId, loginAsOrgId],
          callback: getPeerGroups
        }
      ]
    },
    {
      section: Section.EXAMS,
      sectionTitle: 'College Board Exams',
      sectionConfig: [
        {
          title: 'Exam Taking Options',
          key: 'assessment',
          data: examsCriteria?.assessment,
          refOptions: assessmentOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'PSAT Exams',
          key: 'psat',
          criteria: examsCriteria,
          callback: cbExamsPsatCriteria
        },
        {
          title: 'SAT Exams',
          key: 'sat',
          criteria: examsCriteria,
          callback: cbExamsSatCriteria
        },
        {
          title: 'AP Exams',
          key: 'ap',
          criteria: examsCriteria,
          callback: cbExamsApCriteria
        },
        {
          title: 'Score Sends',
          key: 'scoreSend',
          data: userData?.scoreSend ? [userData.scoreSend] : undefined,
          refOptions: scoreSendsOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Search Logic',
          key: 'scoreLogic',
          data: examsCriteria && userData?.scoreLogic ? [userData.scoreLogic] : undefined,
          refOptions: scoreLogicOptions,
          callback: getLabelsFromRefArray
        }
      ]
    },
    {
      section: Section.GEOGRAPHY,
      sectionTitle: 'Geography',
      sectionConfig: [
        {
          title: 'U.S states & territories',
          key: 'state',
          label: ['description'],
          data: geographyCriteria?.state,
          refPath: refPath[GEOGRAPHY.state].objects,
          refPathOptional: refPath['territoriesArmed'].objects
        },
        {
          title: 'County',
          key: 'county',
          data: geographyCriteria?.county,
          label: ['state', 'description'],
          refPath: refPath[GEOGRAPHY.county].objects
        },
        {
          title: 'Geomarket',
          key: 'epsCode',
          data: geographyCriteria?.epsCode,
          label: ['code', 'description'],
          refPath: refPath[GEOGRAPHY.market].objects
        },
        {
          title: 'Metropolitan statistical area (MSA)',
          key: 'msaCode',
          data: geographyCriteria?.msaCode,
          label: ['state', 'description'],
          //refPath: refPath[GEOGRAPHY.msa as keyof refpathtype].objects
          refPath: refPath[GEOGRAPHY.msa].objects
        },
        {
          title: 'Zip code',
          key: 'zipCode',
          data: allZips || excludedCriteria?.zip5
        },
        {
          title: 'International',
          key: 'international',
          label: ['description'],
          data: [...(geographyCriteria?.country || ''), ...(geographyCriteria?.regionFips || '')].filter(
            (option: string) => option
          ),
          refPath: refPath[GEOGRAPHY.international].objects,
          refPathOptional: refPath['regionFips'].objects
        },
        {
          title: 'Home Schooled',
          key: 'homeSchooled',
          data: geographyCriteria?.homeSchooled,
          labelOnExists: HOME_SCHOOLED
        }
      ]
    },
    {
      section: Section.DEMOGRAPHICS,
      sectionTitle: 'Demographics',
      sectionConfig: [
        {
          title: 'Gender',
          key: 'gender',
          label: ['description'],
          data: demographicsCriteria?.gender,
          refPath: refPath['gender'].objects
        },
        {
          title: 'Ethnicity',
          key: 'ethnicity',
          label: ['description'],
          data: demographicsCriteria?.ethnicity,
          refPath: refPath['ethnicity'].objects
        },
        {
          title: 'First Generation',
          key: 'firstGenEdLevel',
          label: ['description'],
          data: demographicsCriteria?.firstGenEdLevel,
          refPath: refPath['firstGenEdLevel'].objects
        }
      ]
    },
    {
      section: Section.HSACADPERF,
      sectionTitle: 'High School Academic Performance',
      sectionConfig: [
        {
          key: 'gpa',
          data: hsAcadPerfCriteria?.gpa,
          refPath: refPath['grade'].objects,
          callback: gpaCriteria
        }
      ]
    },
    {
      section: Section.IM,
      sectionTitle: 'Intended Major',
      sectionConfig: [
        {
          title: 'Intended Major Choice',
          key: 'majorChoice',
          data: intendedMajorCriteria?.major1 && userData?.majorChoice ? [userData.majorChoice] : undefined,
          refOptions: majorsChoiceOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Additional Options',
          key: 'addOptions',
          data: intendedMajorCriteria?.major1?.filter(itm => ['1987', '0', '1989'].includes(itm)) || undefined,
          refPath: refPath['major'].objects,
          callback: intendedMajorCriteriaLabels
        },
        {
          title: 'Majors',
          key: 'major',
          data: intendedMajorCriteria?.major1?.filter(itm => !['1987', '0', '1989'].includes(itm)) || undefined,
          refPath: refPath['major'].objects,
          callback: intendedMajorCriteriaLabels
        }
      ]
    },
    {
      section: Section.EMAIL,
      sectionTitle: 'Email & Postal Address Preferences',
      sectionConfig: [
        {
          key: 'emailPref',
          label: ['description'],
          data: emailPrefCriteria?.sellable,
          refPath: refPath['emailPref'].objects
        }
      ]
    },
    {
      section: Section.PARENTEMAIL,
      featureKey: FeatureKeys.PARENTEMAIL.key,
      sectionTitle: 'Parent Contacts',
      sectionConfig: [
        {
          key: 'parentEmail',
          data: parentEmail?.parentEmail || excludedCriteria?.parentEmail,
          labelOnExists: PARENTEMAILONLABEL
        }
      ]
    },
    {
      section: Section.SA,
      featureKey: FeatureKeys.SAS.key,
      roleRestricted: Roles.SAS,
      sectionTitle: 'Segment Analysis',
      sectionConfig: [
        {
          data: sasAlsoIncludeOptions.length ? sasAlsoIncludeOptions : undefined,
          key: 'clusterOptions',
          title: 'Cluster Options'
        },
        {
          data: excludedCriteria?.hsCluster, // will always be excluded; no longer supported
          key: 'hsCluster',
          title: '2010 Cluster Selections',
          useSemicolon: true,
          callback: hsClusterCriteriaLabels
        },
        {
          data: saCriteria?.hsCluster2020 || excludedCriteria?.hsCluster2020,
          key: 'hsCluster2020',
          title: '2020 Cluster Selections',
          useSemicolon: true,
          callback: hsClusterCriteriaLabels
        }
      ]
    },
    {
      section: Section.LANDSCAPE,
      featureKey: FeatureKeys.LANDSCAPE.key,
      sectionTitle: 'Landscape Context',
      sectionConfig: [
        {
          title: 'Neighborhood Challenge',
          key: 'landNhChallenge',
          data: landscapeCriteria?.landNhChallenge || excludedCriteria?.landNhChallenge,
          refOptions: Array.from(neighborhoodKeyLabelMap.values()),
          callback: getLabelsFromRefArray
        },
        {
          key: 'landSatChallenge',
          data: landscapeCriteria?.landSatChallenge || excludedCriteria?.landSatChallenge,
          title: 'SAT Scores in Context',
          labelOnExists: 'Only include students with a SAT score in the top 25th percentile of SAT takers in their high school'
        }
      ]
    },
    {
      section: Section.COLLEGEPREF,
      featureKey: FeatureKeys.STUDENT_PLANS.key,
      sectionTitle: 'Student Plans & Preferences: College Plans and Preferences',
      sectionConfig: [
        {
          title: 'Educational Aspirations',
          key: 'eduAspir',
          label: ['description'],
          data: colPlanPrefCriteria?.degree || excludedCriteria?.degree,
          refPath: refPath['degree'].objects
        },
        {
          title: 'Financial Aid Plans',
          key: 'finAidNeed',
          data: demographicsCriteria?.finAidNeed || excludedCriteria?.finAidNeed,
          refOptions: financialAidOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'College Size',
          key: 'colSize',
          label: ['description'],
          data: colPlanPrefCriteria?.collegeSize || excludedCriteria?.collegeSize,
          refPath: refPath['collegeSize'].objects
        },
        {
          title: 'College Location',
          key: 'collegeLocation',
          label: ['description'],
          data: colPlanPrefCriteria?.collegeLocation || excludedCriteria?.collegeLocation,
          refPath: refPath['collegeLocation'].objects
        },
        {
          title: 'Campus Setting',
          key: 'campusSettings',
          label: ['description'],
          data: colPlanPrefCriteria?.collegeSetting || excludedCriteria?.collegeSetting,
          refPath: refPath['collegeSetting'].objects
        },
        {
          title: 'College Type',
          key: 'collegeType',
          label: ['description'],
          data: colPlanPrefCriteria?.collegeType || excludedCriteria?.collegeType,
          refPath: refPath['collegeType'].objects
        },
        {
          title: 'College Control',
          key: 'collegeAffiliation',
          label: ['description'],
          data: colPlanPrefCriteria?.collegeAffiliation || excludedCriteria?.collegeAffiliation,
          refPath: refPath['collegeAffiliation'].objects
        },
        {
          title: 'College Student Body',
          key: 'collegeGender',
          label: ['description'],
          data: colPlanPrefCriteria?.collegeGender || excludedCriteria?.collegeGender,
          refPath: refPath['collegeGender'].objects
        },
        {
          title: 'College Living Plans',
          key: 'collegeHousingPlans',
          label: ['description'],
          data: colPlanPrefCriteria?.collegeHousingPlans || excludedCriteria?.collegeHousingPlans,
          refPath: refPath['collegeHousingPlans'].objects
        },
        {
          key: 'rotcCollege',
          data: demographicsCriteria?.rotcCollege || excludedCriteria?.rotcCollege,
          title: 'ROTC Plans',
          labelOnExists: 'Planning to participate in ROTC during college'
        },
        {
          title: 'Religion Choice',
          key: 'religionChoice',
          data:
            showCPPReligionChoice && (userData?.religionChoice || excludedCriteria?.religionChoice)
              ? [userData?.religionChoice || excludedCriteria.religionChoice]
              : showCPPReligionChoice
              ? ['']
              : [],
          refOptions: religionChoiceOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Religion',
          key: 'religion',
          label: ['description'],
          data: demographicsCriteria?.religion || excludedCriteria?.religion,
          refOptions: religionOptions,
          callback: getLabelsFromRefArray
        }
      ]
    },
    {
      section: Section.HS_COURSES_ACTIVITIES,
      featureKey: FeatureKeys.STUDENT_PLANS.key,
      sectionTitle: 'Student Plans & Preferences: High School Courses and Activities',
      sectionConfig: [
        {
          title: 'English',
          key: 'hsEnglishYrs',
          noSort: true,
          data: hsAcademicOptions
            .filter(option =>
              // could have both
              [
                ...(hsCoursesActivitiesCriteria?.hsEnglishYrs || []),
                ...(excludedCriteria?.hsEnglishYrs || []),
                ...(hsCoursesActivitiesCriteria?.hsEnglishHnrs || excludedCriteria?.hsEnglishHnrs || [])
              ].includes(option.value)
            )
            .map(option => option.value),
          refOptions: hsAcademicOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Foreign/classical languages',
          key: 'hsLanguageYrs',
          noSort: true,
          data: hsAcademicOptions
            .filter(option =>
              // could have both
              [
                ...(hsCoursesActivitiesCriteria?.hsLanguageYrs || []),
                ...(excludedCriteria?.hsLanguageYrs || []),
                ...(hsCoursesActivitiesCriteria?.hsLanguageHnrs || excludedCriteria?.hsLanguageHnrs || [])
              ].includes(option.value)
            )
            .map(option => option.value),
          refOptions: hsAcademicOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Mathematics',
          key: 'hsMathYrs',
          noSort: true,
          data: hsAcademicOptions
            .filter(option =>
              // could have both
              [
                ...(hsCoursesActivitiesCriteria?.hsMathYrs || []),
                ...(excludedCriteria?.hsMathYrs || []),
                ...(hsCoursesActivitiesCriteria?.hsMathHnrs || excludedCriteria?.hsMathHnrs || [])
              ].includes(option.value)
            )
            .map(option => option.value),
          refOptions: hsAcademicOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Natural sciences',
          key: 'hsScienceYrs',
          noSort: true,
          data: hsAcademicOptions
            .filter(option =>
              // could have both
              [
                ...(hsCoursesActivitiesCriteria?.hsScienceYrs || []),
                ...(excludedCriteria?.hsScienceYrs || []),
                ...(hsCoursesActivitiesCriteria?.hsScienceHnrs || excludedCriteria?.hsScienceHnrs || [])
              ].includes(option.value)
            )
            .map(option => option.value),
          refOptions: hsAcademicOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Social sciences/history',
          key: 'hsHistoryYrs',
          noSort: true,
          data: hsAcademicOptions
            .filter(option =>
              // could have both
              [
                ...(hsCoursesActivitiesCriteria?.hsHistoryYrs || []),
                ...(excludedCriteria?.hsHistoryYrs || []),
                ...(hsCoursesActivitiesCriteria?.hsHistoryHnrs || excludedCriteria?.hsHistoryHnrs || [])
              ].includes(option.value)
            )
            .map(option => option.value),
          refOptions: hsAcademicOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Art & music',
          key: 'hsArtMusic',
          data: hsCoursesActivitiesCriteria?.hsArtMusic || excludedCriteria?.hsArtMusic,
          refOptions: hsArtMusicOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Athletics',
          key: 'hsSports',
          // could have both
          data: [...(hsCoursesActivitiesCriteria?.hsSports || []), ...(excludedCriteria?.hsSports || [])],
          refOptions: hsSportsOptions,
          callback: getLabelsFromRefArray
        },
        {
          title: 'Activities',
          key: 'hsActivity',
          data: hsActivityCriteria || excludedCriteria ? [hsActivityCriteria || excludedCriteria] : undefined,
          useSemicolon: true,
          callback: getHsActivity
        },
        {
          key: 'rotcHs',
          data: demographicsCriteria?.rotcHs || excludedCriteria?.rotcHs,
          title: 'ROTC History',
          labelOnExists: ROTC_HISTORY_LABEL
        }
      ]
    },
    {
      section: Section.NRP,
      sectionTitle: 'CB National Recognition Programs',
      sectionConfig: [
        {
          key: 'nrpAfam',
          data: nrpCriteria?.nrpAfam || excludedCriteria?.nrpAfam,
          labelOnExists: 'African American'
        },
        {
          key: 'nrpHisp',
          data: nrpCriteria?.nrpHisp || excludedCriteria?.nrpHisp,
          labelOnExists: 'Hispanic'
        },
        {
          key: 'nrpIndg',
          data: nrpCriteria?.nrpIndg || excludedCriteria?.nrpIndg,
          labelOnExists: 'Indigenous'
        },
        {
          key: 'nrpRural',
          data: nrpCriteria?.nrpRural || excludedCriteria?.nrpRural,
          labelOnExists: 'Rural and Small-Town'
        }
      ]
    }
  ]

  // using the config create a display object
  criteriaLabelValues = await Promise.all(
    CRITERIA_CONFIG.map(async section => {
      const sectionConfig = section.sectionConfig

      const criteriaLabelValues = await Promise.all(
        sectionConfig.map(async criteria => {
          let labels: string[] = []

          if (criteria.labelOnExists) {
            if (criteria.data && criteria.data.length > 0) {
              labels = [criteria.labelOnExists]
            } else {
              if (criteria.labelOnNotExists) {
                labels = [criteria.labelOnNotExists]
              }
            }
          } else {
            // special scenarios (gpa, cb exams, intended major?)
            if (criteria.callback) {
              labels = await criteria.callback({
                key: criteria.key,
                refPath: criteria.refPath,
                data: criteria.data,
                dataOptional: criteria.dataOptional,
                criteria: criteria.criteria,
                refOptions: criteria.refOptions
              })
            } else {
              labels = await getLabelsFromRefObject({
                label: criteria.label,
                labelOptional: criteria.labelOptional,
                refPath: criteria.refPath,
                refPathOptional: criteria.refPathOptional,
                data: criteria.data,
                dataOptional: criteria.dataOptional
              })
            }
          }
          return {
            label: criteria.title ? criteria.title : undefined,
            key: criteria.key,
            values: labels && !criteria.noSort ? [...labels].sort() : labels ? [...labels] : [],
            data: criteria.data ? [...criteria.data] : [],
            useSemicolon: criteria.useSemicolon
          }
        })
      )

      return {
        sectionTitle: section.sectionTitle,
        featureKey: section.featureKey,
        roleRestricted: section.roleRestricted,
        sectionData: criteriaLabelValues
      }
    })
  )

  return criteriaLabelValues
}
