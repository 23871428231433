import { Illustration, IllustrationName } from '@cb/apricot-react'
import { getCMSPanelByFieldId, getIcon, sanitizeHtml } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { Link } from 'react-router-dom'
import './CtaCards.scss'

interface CtaCardsProps {
  content: Record<string, any>
}

const CtaCards = ({ content }: CtaCardsProps) => {
  const ctaCards = getCMSPanelByFieldId(content.field_panel, 'CTAPanel')?.field_promotion_component

  return (
    <div className='col-xs-12'>
      <div className='cb-card-list-horizontal justify-content-around'>
        {ctaCards.map((card, i) => (
          <CtaCard content={card} id={`lilp-${i}`} key={sanitizeHtml(card.id)} />
        ))}
      </div>
    </div>
  )
}

export default CtaCards

interface CtaCardProps {
  content: Record<string, any>
  id: string
}

const CtaCard = ({ content, id }: CtaCardProps) => {
  const { user } = useSearchUserContext()

  return (
    <div className='msss-card cb-card cb-card-cta'>
      <div className='cb-card-header justify-content-center'>
        <Illustration decorative name={getIcon(content.field_icon) as IllustrationName} />
      </div>
      <div className='cb-card-content cb-align-center display-flex flex-column justify-content-between flex-grow-1'>
        <div>
          <h2 className='cb-font-weight-regular cb-margin-bottom-16 cb-h4' id={`cta-card-title-${id}`}>
            {sanitizeHtml(content.title)}
          </h2>
          {content.field_body_content && (
            <div
              className='cb-margin-bottom-16'
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(content.field_body_content?.processed) }}
            />
          )}
        </div>
        <div className='cb-card-action justify-content-center'>
          {content.field_cta_link?.url?.includes('collegeboardsearch') ? (
            <Link
              aria-describedby={`cta-card-title-${id}`}
              className={`cb-btn ${user.orgId === user.parentOrgId ? 'cb-disabled' : ''}`}
              data-cbtrack-promo
              id={`cta-card-link-${id}`}
              onClickCapture={e => {
                if (user.orgId === user.parentOrgId) {
                  e.preventDefault()
                }
              }}
              to={sanitizeHtml(content.field_cta_link?.url.split('https://collegeboardsearch.collegeboard.org/').pop())}
            >
              {sanitizeHtml(content.field_cta_link?.title)}
            </Link>
          ) : (
            <a
              aria-describedby={`cta-card-title-${id}`}
              className='cb-btn'
              data-cbtrack-promo
              href={sanitizeHtml(content.field_cta_link?.url)}
              id={`cta-card-link-${id}`}
              rel='noopener noreferrer'
              target='_blank'
            >
              {sanitizeHtml(content.field_cta_link?.title)}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
