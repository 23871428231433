import { Tab, Tabs } from '@cb/apricot-react'
import CriteriaTab from '../order-view/CriteriaTab'
import OverViewTab from '../order-view/OverViewTab'
import DownloadsTab from '../order-view/DownloadsTab'
import BillingTab from '../order-view/BillingTab'
import { OrderOverView } from './SearchOrderDetails'

export interface OrderViewTabProps {
  searchOrder: OrderOverView
  savedSearchId: number
}
const OrderViewTabs = ({ savedSearchId, searchOrder }: OrderViewTabProps) => {
  return (
    <div className='align-items-stretch'>
      <Tabs>
        <Tab label='Overview' panelId={'1'} selected>
          <OverViewTab savedSearchId={savedSearchId} searchOrder={searchOrder} />
        </Tab>
        <Tab label='Criteria' panelId={'2'}>
          <CriteriaTab savedSearchId={savedSearchId} accordionSavedSearchId={savedSearchId} />
        </Tab>
        <Tab label='Downloads' panelId={'3'}>
          <DownloadsTab searchOrder={searchOrder} savedSearchId={savedSearchId} />
        </Tab>
        <Tab label='Billing' panelId={'4'}>
          <BillingTab searchOrder={searchOrder} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default OrderViewTabs
