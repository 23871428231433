import '@cb/apricot/CBBase'
import '@cb/apricot/CBButton'
import '@cb/apricot/CBForm'
import '@cb/apricot/CBGlyph'
import '@cb/apricot/CBGrid'
import '@cb/apricot/CBLogo'
import './assets/ms_app.scss'
import { CBWidgets } from '@msss/ui-components'
import { Component } from 'react'
import AppMain from './AppMain'
import { MsssError } from './components/error/MsssDialog'
import { appId } from './constants/app'

interface IState {
  error: boolean | undefined
}

class AppEntry extends Component<{}, IState> {
  constructor(props) {
    super(props)

    this.state = { error: undefined }
  }

  static getDerivedStateFromError(err) {
    // will persist until site refresh
    // only catches errors from children - not from anything in AppEntry
    return { error: Boolean(err) }
  }

  render() {
    const { error } = this.state

    return (
      <>
        {!error && <AppMain />}
        {error && (
          <CBWidgets appId={appId}>
            <MsssError
              title='An unexpected error occurred. Please try again later.'
              message={
                <p>
                  If the error persists please contact Support at{' '}
                  <a href='mailto:collegeboardsearch@collegeboard.org' target='_top'>
                    collegeboardsearch@collegeboard.org
                  </a>
                  .
                </p>
              }
              errorCode='500'
            />
          </CBWidgets>
        )}
      </>
    )
  }
}

export default AppEntry
