import { useState, useEffect } from 'react'
import { ICriteria } from '../ICriteria'
import { Spacer } from '@cb/apricot-react-spacer'
import { Summary } from '../summary/Summary'
import { Section } from '../summary/Section'
import { SectionProps } from '../Search'
import { ILabeledValue, ToggleSwitch } from '@cb/apricot-react'
import { INCLUDE_ALL_STUDENTS } from '../SearchFormConstants'

export const PARENTEMAILONLABEL = 'Include only students who have a verified parent email'
const ParentEmailPref = (props: SectionProps): JSX.Element => {
  const initEmailOnly = (): boolean => {
    let result = false
    if (selectedCriteria) {
      selectedCriteria.parentEmail ? (selectedCriteria.parentEmail[0] === 'Y' ? (result = true) : null) : null
    }
    return result
  }

  const { selectedCriteria, handleCriteria } = props
  const [parentEmail, setParentEmail] = useState<boolean>(initEmailOnly())

  useEffect(() => {
    const criteria: ICriteria = {
      parentEmail: parentEmail ? ['Y'] : []
    }
    handleCriteria(criteria, toSummary())
  }, [parentEmail])

  const clearSelections = (event: any) => {
    event.preventDefault()
    setParentEmail(false)
  }

  const remove = (label: string): void => {
    setParentEmail(false)
  }

  const toSummary = () => {
    return {
      section: Section.PARENTEMAIL,
      data: parentEmail
        ? [
            {
              name: 'Parent Contacts',
              values: [PARENTEMAILONLABEL],
              remove: remove
            }
          ]
        : []
    }
  }

  return (
    <div className='cb-margin-left-8'>
      <div>
        <ToggleSwitch
          ariaLabel={INCLUDE_ALL_STUDENTS}
          offLabel={INCLUDE_ALL_STUDENTS}
          on={parentEmail}
          onChange={setParentEmail}
          onLabel={PARENTEMAILONLABEL}
          switchId='parent-email-preference-switch'
        />
      </div>
      <Spacer />
      <Summary summaryId='parentemailprefId' {...toSummary()} clear={clearSelections} />
      <Spacer />
    </div>
  )
}

export default ParentEmailPref
