import { ReactChild, ReactChildren, ReactElement } from 'react'

export interface RefCode {
  description: string
}

export interface IntRefCode extends RefCode {
  code: number
}

export interface CharRefCode extends RefCode {
  code: string
}

//feature flagging
export interface IFeature {
  featureId: number
  key: string
  name: string
  active: boolean
  betaTier: boolean
  alacarte: boolean
}
export interface IFeatureInfoSummary {
  featureId: number
  key: string
  name: string
}
export interface IFeatureTierFeature {
  featureId: number
  key: string
  name: string
  active: boolean
  description: boolean
  createDate: Date
  updateDate?: Date
}
export interface IFeatureTier {
  quantity?: any
  featureTierId: number
  name: string
  annualVolume: number
  startDate: Date
  endDate: Date
  alacarte: boolean
  includedFeatureNames?: string[]
  feature?: IFeatureInfoSummary
}
export interface IFeatureTierDetail {
  featureTierId: number
  name: string
  annualVolume: number
  active: boolean
  betaTier: boolean
  alacarte: boolean
  createDate: Date
  updateDate?: Date
}

export interface IFeatureState {
  features: Map<string, IFeature>
  startDate: Date
  endDate: Date
}

export interface IFeatureInfo {
  startDate: Date
  endDate: Date
  featureNames: String[]
  futureFeatureNames: String[]
  priorFeatureNames: String[]
  featureKeys: String[]
  futureFeatureKeys: String[]
  priorFeatureKeys: String[]
  isEnrollmentPlanningFeatureActive: boolean
  enrollmentPlanningFeatureEndDate: Date | undefined
  isSegmentAnalysisFeatureActive: boolean
  segmentAnalysisFeatureEndDate: Date | undefined
  alacarteFeatureNames: String[]
  futureAlacarteFeatureNames: String[]
  priorAlacarteFeatureNames: String[]
  alacarteFeatureKeys: String[]
  futureAlacarteFeatureKeys: String[]
  priorAlacarteFeatureKeys: String[]
  featureTierNames: String[]
  futureFeatureTierNames: String[]
  priorFeatureTierNames: String[]
  featureTiers: IFeatureTier[]
  futureFeatureTiers: IFeatureTier[]
  priorFeatureTiers: IFeatureTier[]
  alacarteFeatureTiers: IFeatureTier[]
  futureAlacarteFeatureTiers: IFeatureTier[]
  priorAlacarteFeatureTiers: IFeatureTier[]
  subscriptionType: IAccountSubscriptionType
  totalConnectionsAudiences: Number
  featuresTotalConnectionAudience: Number
}

export enum IAccountSubscriptionType {
  UNKNOWN = 'UNKNOWN',
  ACTIVE_SUBSCRIPTION = 'ACTIVE_SUBSCRIPTION',
  TRANSACTIONAL = 'TRANSACTIONAL'
}
export interface IFeatureAccessContext {
  loadingFeatures: boolean
  addUsage: (featureKey: string) => void
  checkAccess: (key: string) => boolean
  clearUsage: () => void
  isLoaded: () => boolean
  isTransactional: () => boolean
  searchYearEndDate: string
  searchYearStartDate: string
  searchYear: number
  getFeatureInfo: (refresh: boolean | undefined) => IFeatureInfo
}

export interface IFeatureContext {
  onUsage: () => void
  hasAccess: boolean
}

export interface FeatureProps {
  featureKey: string
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[] | JSX.Element
  altContent?: ReactElement<any, any>
  showLoader?: boolean
}

export interface PermissionProps {
  roles: number[]
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[] | JSX.Element
  altContent?: ReactElement<any, any>
}

export enum ActionTypes {
  UPDATE_ERR_MSG = 'UPDATE_ERR_MSG',
  GET_INTERNATIONAL_DATA = 'GET_INTERNATIONAL_DATA'
}

export interface ContextProps {
  state: StateProps
  getInternationalData: () => Promise<void>
}

export interface StateProps {
  internationaldata: any[]
  errorMsg: ''
}

export interface ActionPayload {
  type: ActionTypes
  payload?: any
}

export enum IPortfolioFileType {
  Search = 'Search',
  SAS = 'SAS',
  Other = 'Other',
  LivingRecord = 'LivingRecord',
  Files = 'Files'
}
