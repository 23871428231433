import CBInputMethod from '../../../../../utils/CBInputMethode'
import { Spacer, Select, ILabeledValue, CheckboxGroup, Checkbox } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { TooltipHeader } from '@msss/ui-components'
import { useEffect, useState } from 'react'
import { ReferenceService } from '../../../../../services/rest/ReferenceService'
import { pathState, pathMsa } from './Options'
import { ICheck } from '../../ICriteria'
import { IGeoSection } from './Geography'
import { GEOGRAPHY } from './Enums'

const MSAs = ({ updateData, data, sectionid }: IGeoSection): JSX.Element => {
  const [msaStateList, setMsaStateList] = useState<ILabeledValue[]>([])
  const [checks1, setChecks1] = useState<ICheck[]>()
  const [checks2, setChecks2] = useState<ICheck[]>()
  const [checks3, setChecks3] = useState<ICheck[]>()
  const [currOption, setCurrOption] = useState('-1')
  const [maxItems, setMaxItems] = useState(0)
  // used to figure out when to reset the tab to default
  // we always want to reset tab to default unless it is from
  // UNCHECKING select all
  const [resetSelect, setResetSelect] = useState(true)

  const make3columns = (list: ICheck[]): void => {
    list.sort((a: ICheck, b: ICheck) => a.label.localeCompare(b.label))
    setMaxItems(list.length)
    if (list.length > 0) {
      const length = list.length / 3
      if (length >= 1) {
        setChecks3(list.splice(-Math.floor(length)))
      } else {
        setChecks3([])
      }
      if (length + 0.5 > 1) {
        setChecks2(list.splice(-Math.floor(length + 0.5)))
      } else {
        setChecks2([])
      }
      setChecks1(list)
    } else {
      setChecks3([])
      setChecks2([])
      setChecks1([])
    }
  }

  useEffect(() => {
    CBInputMethod()
  }, [])

  useEffect(() => {
    // fetch states on load for the dropdown default
    const disableItem = (label: string): boolean => {
      return data ? !(data.filter(e => e.section === GEOGRAPHY.state).findIndex(e => e.label === label) === -1) : false
    }

    const setup = async () => {
      const initList: ILabeledValue[] = [{ label: 'Select state', value: '-1' }]
      const list = await ReferenceService.getReferenceData(pathState)
      setMsaStateList([
        ...initList,
        ...list
          .sort((a: any, b: any) => a.description.localeCompare(b.description))
          .map((i: any): ILabeledValue => ({ label: i.description, value: i.code }))
      ])

      // if the data has now value from this tab, we also reset the select to default
      resetSelect && data.filter(d => d.section === sectionid).length === 0 ? getList('-1') : null
    }
    setup()
  }, [data, sectionid, resetSelect])

  const getList = async (option: string): Promise<void> => {
    setCurrOption(option)
    if (option !== '-1') {
      const list = await ReferenceService.getReferenceData(`${pathMsa}${option}`)
      make3columns(
        list.map((i: any) => ({
          section: sectionid,
          currOption: option,
          label: `${option}-${i.description}`,
          value: `${option}-${i.code}`
        }))
      )
    } else {
      make3columns([])
    }
  }

  const handle = (checked: boolean, label: string): void => {
    const full = [...ensure(checks1), ...ensure(checks2), ...ensure(checks3)]

    // used to figure out when to reset the tab to default
    // we only want to allow reset (clear the select) when
    // 'clear all' is clicked.  so when we have a checked
    // we will have item in summary so clear all click will reset
    // if we are still in the section and just clicking around,
    // we don't want reset
    setResetSelect(checked)

    switch (label) {
      case 'Select all':
        full.map(e => {
          if (updateData) {
            updateData(checked, e)
          }
        })

        break
      default: {
        const x = ensure(full.find(f => f.label === label))
        if (updateData) {
          updateData(checked, x)
        }
      }
    }
  }

  const includes = (value: string): boolean => {
    return data ? !(data.filter(e => e.section === sectionid).findIndex(e => e.value === value) === -1) : false
  }

  const includesAll = (): boolean => {
    return data ? data.filter(e => e.section === sectionid).filter(e => e.currOption === currOption).length === maxItems : false
  }

  return (
    <>
      <p>You can search by MSAs when selecting states.</p>
      <Spacer />
      <TooltipHeader
        header='Select MSAs'
        id='geo-select-msa'
        tooltipMsg='Search by Metropolitan Statistical Areas (MSAs)'
      />
      <div className='row'>
        <div className='col-sm-3'>
          <Select
            ariaLabel='select state'
            onChange={getList}
            selectId='geo-msa-state-options'
            value={currOption}
            values={msaStateList}
          />
        </div>
        <div className='col-sm-9 cb-border cb-border-1 cb-border-radius' style={{ minHeight: '300px' }}>
          <div className='cb-padding-16'>
            {checks1 && checks1.length ? (
              <CheckboxGroup
                fieldsetId='geo-msa-city-options'
                legend='Metropolitan Statistical Areas'
                legendClassName='sr-only'
                vertical={true}
              >
                <Checkbox
                  checked={includesAll()}
                  className='cb-font-weight-medium'
                  id='geo-msa-city-select-all-option'
                  label='Select all'
                  onChange={(checked): void => handle(checked, 'Select all')}
                />
                <div className='row'>
                  <div className='col-sm-4'>
                    {checks1
                      ? checks1.map(c => (
                          <Checkbox
                            checked={includes(c.value)}
                            className='cb-padding-bottom-8'
                            id={`geo-msa-city-option-${c.value}`}
                            key={c.value}
                            label={c.label}
                            onChange={(checked): void => handle(checked, c.label)}
                          />
                        ))
                      : null}
                  </div>
                  <div className='col-sm-4'>
                    {checks2
                      ? checks2.map(c => (
                          <Checkbox
                            checked={includes(c.value)}
                            className='cb-padding-bottom-8'
                            id={`geo-msa-city-option-${c.value}`}
                            key={c.value}
                            label={c.label}
                            onChange={(checked): void => handle(checked, c.label)}
                          />
                        ))
                      : null}
                  </div>
                  <div className='col-sm-4'>
                    {checks3
                      ? checks3.map(c => (
                          <Checkbox
                            checked={includes(c.value)}
                            className='cb-padding-bottom-8'
                            id={`geo-msa-city-option-${c.value}`}
                            key={c.value}
                            label={c.label}
                            onChange={(checked): void => handle(checked, c.label)}
                          />
                        ))
                      : null}
                  </div>
                </div>
              </CheckboxGroup>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default MSAs
