import { TagSet } from '@cb/apricot-react'
import { IRemovable } from './IRemovable'

export const Removable = ({ values, valueType, remove }: IRemovable): JSX.Element => {
  return (
    <>
      <TagSet
        label={`Selected`}
        offScreen='Remove'
        tags={values}
        onClick={(v, e): void => {
          e.preventDefault()
          remove(v, valueType)
        }}
      />
    </>
  )
}
