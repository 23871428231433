import CBInputMethod from '../../../../../utils/CBInputMethode'
import { Checkbox, CheckboxGroup, Select, Spacer } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { TooltipHeader } from '@msss/ui-components'
import { useEffect, useState } from 'react'
import { ReferenceService } from '../../../../../services/rest/ReferenceService'
import { optionsStates, pathState, pathTerr, apoReference } from './Options'
import { IGeoSection } from './Geography'
import { GEOGRAPHY } from './Enums'
import { ICheck } from '../../ICriteria'

const TabState = ({ updateData, data, sectionid }: IGeoSection): JSX.Element => {
  const [checks1, setChecks1] = useState<ICheck[]>()
  const [checks2, setChecks2] = useState<ICheck[]>()
  const [checks3, setChecks3] = useState<ICheck[]>()
  const [maxItems, setMaxItems] = useState(0)
  const [currOption, setCurrOption] = useState('state')

  const make3columns = (isTerr: boolean, list: ICheck[]): void => {
    list.sort((a: ICheck, b: ICheck) => a.label.localeCompare(b.label))
    // if terr, append apo to list
    if (isTerr) {
      list = list.concat(apoReference)
    }
    const length = Math.ceil(list.length / 3)
    setMaxItems(list.length)
    setChecks3(list.splice(-length))
    setChecks2(list.splice(-length))
    setChecks1(list)
  }

  useEffect(() => {
    CBInputMethod()
  }, [])

  useEffect(() => {
    // fetch states on load for the dropdown default
    const setup = async () => {
      const list = await ReferenceService.getReferenceData(pathState)
      make3columns(
        false,
        list.map((i: any) => ({ section: GEOGRAPHY.state, currOption: currOption, label: i.description, value: i.code }))
      )
      setCurrOption('state')
    }
    setup()
  }, [])

  const getList = async (o: string): Promise<void> => {
    setCurrOption(o)
    let isTerr: boolean = false
    let path = pathState

    switch (o) {
      case 'state':
        path = pathState
        break
      case 'terr':
        path = pathTerr
        isTerr = true
        break
    }

    const list = await ReferenceService.getReferenceData(path)
    make3columns(
      isTerr,
      list.map((i: any): ICheck => ({ section: GEOGRAPHY.state, currOption: o, label: i.description, value: i.code }))
    )
  }

  const handle = (checked: boolean, label: string): void => {
    const full = [...ensure(checks1), ...ensure(checks2), ...ensure(checks3)]

    switch (label) {
      case 'Select all':
        {
          const x = data.filter(e => e.section === GEOGRAPHY.county || e.section === GEOGRAPHY.market)
          // need to filter out disabled states first
          const partfull = full.filter(f => x.findIndex(e => e.value.startsWith(f.value)) === -1)
          partfull.map(e => {
            if (updateData) {
              updateData(checked, e)
            }
          })
        }
        break
      default: {
        const x = ensure(full.find(f => f.label === label))
        if (updateData) {
          updateData(checked, x)
        }
      }
    }
  }

  // this is for if they select items from other tabs first, then the state tab will
  // need to diable the checkbox for that state we do this because they are all OR together.
  // if they picked the same state as other tabs, the state will match all the kids
  // from that state which will make other tabs meaningless
  const disableCheck = (value: string): boolean => {
    return data
      ? !(
          data
            .filter(e => e.section === GEOGRAPHY.market || e.section === GEOGRAPHY.county)
            .findIndex(e => e.value.startsWith(value)) === -1
        )
      : false
  }

  const includes = (value: string): boolean => {
    return data ? !(data.filter(e => e.section === sectionid).findIndex(e => e.value === value) === -1) : false
  }

  const includesAll = (): boolean => {
    return data ? data.filter(e => e.section === sectionid).filter(e => e.currOption === currOption).length === maxItems : false
  }

  const genCheckBoxes = (checks: ICheck[] | undefined): JSX.Element | null => {
    return checks ? (
      <div className='col-sm-4'>
        <div className='cb-margin-right-24'>
          {checks
            ? checks.map(c => (
                <Checkbox
                  checked={includes(c.value)}
                  className='cb-padding-bottom-8'
                  disabled={disableCheck(c.value)}
                  id={`geo-states-terr-option-${c.value}`}
                  key={c.value}
                  label={c.label}
                  onChange={(checked: boolean): void => handle(checked, c.label)}
                />
              ))
            : null}
        </div>
      </div>
    ) : null
  }

  return (
    <>
      <p>Selecting a state searches within the entire state. Use Geomarkets, Counties, or MSAs to narrow your search.</p>
      <Spacer />
      <TooltipHeader
        header='Select U.S. States or Territories'
        id='geo-select-state-territories'
        tooltipMsg='Choose from a list of U.S. States and Territories to make your selection(s).'
      />
      <div className='row'>
        <div className='col-sm-4'>
          <Select
            ariaLabel='select U.S states and territories'
            floating
            onChange={getList}
            selectId='geo-states-terr-selection-options'
            values={optionsStates}
          />
        </div>
        <div className='col-sm-8 cb-border cb-border-1 cb-border-radius'>
          <div className='cb-padding-16'>
            <CheckboxGroup
              fieldsetId='geo-states-terr-options'
              legend='U.S. States or Territories'
              legendClassName='sr-only'
              vertical={true}
            >
              <Checkbox
                checked={includesAll()}
                className='cb-font-weight-medium'
                id='geo-states-terr-select-all-option'
                label='Select all'
                onChange={(checked: boolean): void => handle(checked, 'Select all')}
              />
              <div className='row'>
                {genCheckBoxes(checks1)}
                {genCheckBoxes(checks2)}
                {genCheckBoxes(checks3)}
              </div>
            </CheckboxGroup>
          </div>
        </div>
      </div>
    </>
  )
}

export default TabState
