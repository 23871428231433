import { Icon, NakedButton } from '@cb/apricot-react'
import { IPeerInfo, MAX_PEERS_PER_GROUP } from './PeerGroups'
import { useEffect, useState } from 'react'

export enum PeerGroupAction {
  Lock,
  Edit,
  Empty
}
interface PeerGroupInfoProps {
  //action?: PeerGroupAction
  searchYear: number
  currSearchYear: number
  name?: string
  peers?: IPeerInfo[]
  sequence: number
  setCurrGroup: (v: number) => void
}

//const PeerGroupInfo = ({ action, name, peers = [], sequence, setCurrGroup }: PeerGroupInfoProps) => {
const PeerGroupInfo = ({ searchYear, currSearchYear, name, peers = [], sequence, setCurrGroup }: PeerGroupInfoProps) => {
  const getAllowedAction = () => {
    return peers.length ? (searchYear === currSearchYear ? PeerGroupAction.Lock : PeerGroupAction.Edit) : PeerGroupAction.Empty
  }

  const [action, setAction] = useState<PeerGroupAction>(getAllowedAction)

  useEffect(() => {
    setAction(getAllowedAction())
  }, [searchYear, currSearchYear, peers])

  const handleEdit = () => {
    console.log('handle edit for ', sequence)
    setCurrGroup(sequence)
  }

  return (
    <>
      <div className='pg-info display-flex justify-content-between align-items-end'>
        <h2 className='cb-h5 pg-title'>
          <span id={`pg-name-${sequence}`}>{`Peer Group ${sequence}${name ? `: ${name}` : ''}`}</span>
          {action === PeerGroupAction.Lock && (
            <Icon
              aria-label='peer group is locked'
              color='black1'
              name='lock'
              role='img'
              size='16'
              style={{ marginLeft: '8px' }}
            />
          )}
          {action === PeerGroupAction.Edit && <NakedButton onClick={() => handleEdit()}>Review</NakedButton>}
        </h2>
        <p className='pg-stats' id={`pg-stats-${sequence}`}>
          {peers.length ?? 0} of {MAX_PEERS_PER_GROUP} Selected
        </p>
      </div>
      <div
        aria-describedby={`pg-stats-${sequence}`}
        aria-labelledby={`pg-name-${sequence}`}
        className='pg-list'
        role='region'
        tabIndex={0}
      >
        {peers.map(peer => (
          <p className='peer' key={`selected-org-${sequence}-${peer.orgId}`}>
            {peer.orgName}
          </p>
        ))}
        {!peers.length && <p className='peer'>No Selection</p>}
      </div>
    </>
  )
}

export default PeerGroupInfo
