import { NakedButton } from '@cb/apricot-react'
import { dateTimeFormatter, getFileFormat, ISearchUser } from '@msss/ui-common'
import axios from 'axios'
import { isPast } from 'date-fns'
import fileDownload from 'js-file-download'
import { ChangeFormatModal } from './MsofModals'

export const downloadFile = async (
  fileId: number,
  fileName: string,
  downloadUrl: string,
  auditDownload: any,
  setDownloads: any,
  user: any
) => {
  // You have to replace the file extension in the file name with ".zip" (because, well, it's a Zip)
  const saveFileName = `${fileName.substring(0, fileName.lastIndexOf('.'))}.zip`
  let success = true

  // Do an axios GET call to retrieve the file data from the signed URL.  Make sure to set the response
  // type as "blob" so that the data is encoded properly....
  try {
    const res = await axios.get(downloadUrl, { responseType: 'blob' })
    if (res) {
      fileDownload(res.data, saveFileName)
      return { fileId, success }
    }
  } catch (error) {
    success = false
  } finally {
    auditDownload({
      variables: { fileId, userId: user.userId, success },
      onCompleted: () => setDownloads((prev: number) => prev + 1)
    })
  }
  return { fileId, success }
}

export const getChangeFormatLink = (
  orderId: number | undefined,
  orderRun: number | undefined,
  fileId: number,
  fileName: string,
  fileType: string,
  expirationDate: any,
  changeFormat: number,
  setChangeFormat: any,
  user: ISearchUser
) => {
  // Change format link is disabled if
  // 1) The expiration date is past due
  // 2) If you are coming to the page using login-as feature
  const canChangeFormat = !isPast(expirationDate) && user.loginAsOrgId === undefined && fileType !== 'SAS'
  const format = getFileFormat(fileName)
  return (
    <>
      <NakedButton disabled={!canChangeFormat || !format} id={`change-format-${fileId}`} onClick={() => setChangeFormat(fileId)}>
        Change File Format
      </NakedButton>
      {canChangeFormat && format && (
        <ChangeFormatModal
          btnId={`change-format-${fileId}`}
          open={changeFormat === fileId}
          orderId={orderId}
          orderRun={orderRun}
          fileId={fileId}
          fileType={fileType}
          format={format}
          onClose={() => setChangeFormat(0)}
        />
      )}
    </>
  )
}

export const getDownloadLink = (fileName: string, outputFiles: any[], startDownload: any, user: ISearchUser): JSX.Element => {
  const matchingFile: any = outputFiles.find(item => item.fileName === fileName)

  // We can only download if
  // 1) The release date is null or in the past,
  // 2) The expiration date (1 year from create date) has NOT passed, and
  // 3) There is a list of users who can access the file, and it contains the logged in user ID.
  // 4) If you are NOT coming to the page using login-as feature
  //
  // If these don't pass, disabled Download button for you!
  const canDownload =
    matchingFile &&
    (!matchingFile.releaseDate || isPast(matchingFile.releaseDate)) &&
    !isPast(matchingFile.expirationDate) &&
    matchingFile.accessList &&
    matchingFile.accessList.some((accessItem: any) => accessItem.userId === user.userId)

  return (
    <>
      <NakedButton
        disabled={!canDownload}
        id={`download-file-${matchingFile.fileId}`}
        onClick={() => {
          startDownload(matchingFile.fileId, fileName)
        }}
      >
        Download
      </NakedButton>
    </>
  )
}

export const getDownloadStatus = (outputFile: any) => {
  return !outputFile || !outputFile.downloads || outputFile.downloads.length === 0 ? (
    <strong>Not Downloaded</strong>
  ) : (
    <>
      <strong>Downloaded</strong>
      {outputFile.downloads.map((download: any, i: number) => {
        return (
          <p key={i}>
            {download.username}
            <br />
            {dateTimeFormatter.format(new Date(download.downloadDate))}
          </p>
        )
      })}
    </>
  )
}
