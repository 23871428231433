import { useMutation } from '@apollo/client'
import { Modal, PrimaryButton, NakedButton, Spacer } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import React, { useEffect, useState } from 'react'
import { REMOVE_SAVED_SEARCH } from '../../../../services/graphql/mutations'
import { GET_FOLDERS_OF_ORG, GET_PORTFOLIO_BY_CATEGORY_QUERY } from '../../../../services/graphql/queries'
import { IPortfolioFileType } from '../../../../interfaces/reference/IReference'

interface IRemoveModal {
  selectedSearches: number[]
  folderName: string
  folderId: number
  open: boolean
  setOpen: (value: boolean) => void
  setRemoveSuccess: (value: number) => void
  category: IPortfolioFileType
}

// Modal used to move a Search to a Folder
export const RemoveModal = ({
  selectedSearches,
  folderName,
  folderId,
  open,
  setOpen,
  setRemoveSuccess,
  category
}: IRemoveModal) => {
  const { user } = useSearchUserContext()

  console.log(selectedSearches)
  // used to disable the move search button in case of an error
  const [hadError, setHadError] = useState<boolean>(false)

  // Appsync - mutation and query
  const [removeSearch, removeMutationResult] = useMutation(REMOVE_SAVED_SEARCH, {
    refetchQueries: [
      { query: GET_FOLDERS_OF_ORG, variables: { orgId: user.orgId } },
      { query: GET_PORTFOLIO_BY_CATEGORY_QUERY, variables: { orgId: user.orgId, category: category } }
    ]
  })

  // If error set the error as true. This will help make the Move Search button to be disabled. Cant rely on mutationResult.called alone.
  useEffect(() => {
    if (removeMutationResult?.error) {
      removeMutationResult.reset()
      setHadError(true)
    }
  }, [removeMutationResult.error])

  useEffect(() => {
    if (removeMutationResult?.data) {
      setRemoveSuccess(selectedSearches.length)
      setOpen(false)
    }
  }, [removeMutationResult.data])

  const handleRemove = () => {
    removeSearch({ variables: { savedSearch: { folderId, savedSearchIds: selectedSearches } } })
  }

  //   Only render the modal if the open state is true
  //   There are two views for the modal
  //   - Shows a list of folder names and Move button
  //   - Once the user has moved the search it shows a success message
  return open ? (
    <Modal
      actions={[
        <NakedButton key='cancel' onClick={() => setOpen(false)} disabled={removeMutationResult.called}>
          Cancel
        </NakedButton>,
        <PrimaryButton
          key='remove'
          onClick={handleRemove}
          loading={removeMutationResult.called}
          disabled={removeMutationResult.called || hadError}
        >
          Remove
        </PrimaryButton>
      ]}
      analytics
      analyticsTitle='msof-remove-from-folder-saved-search'
      onClose={() => {
        removeMutationResult.reset()
        setOpen(false)
      }}
      closeButton={false}
      title='Remove from Folder'
      open={open}
      withFooter
      withHeader
    >
      <div>
        <p className='cb-font-weight-bold'>Removing ({selectedSearches.length}) Searches and Orders from Folder</p>
        <p>Are you sure you want to continue? </p>
        <Spacer />
        <p className='cb-font-weight-bold'>{folderName}</p>
      </div>
    </Modal>
  ) : null
}

export default RemoveModal
