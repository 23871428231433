export const filterTypeOptions = [
  {
    label: 'Saved Search',
    value: 'SavedSearch',
    column: 'itemType'
  },
  {
    label: 'Single Order',
    value: 'SingleOrder',
    column: 'itemType'
  },
  {
    label: 'Standing Order',
    value: 'StandingOrder',
    column: 'itemType'
  }
]

export const filterOrderStatusOptions = [
  {
    label: 'Saved',
    value: '1',
    column: 'orderStatus'
  },
  {
    label: 'Not Submitted',
    value: '2',
    column: 'orderStatus'
  },
  {
    label: 'Submitted',
    value: '3',
    column: 'orderStatus'
  },
  {
    label: 'Partially Fulfilled',
    value: '4',
    column: 'orderStatus'
  },
  {
    label: 'Fulfilled',
    value: '5',
    column: 'orderStatus'
  },
  {
    label: 'Cancelled',
    value: '6',
    column: 'orderStatus'
  }
]
