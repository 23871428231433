import { TagSet } from '@cb/apricot-react'
import { Fragment } from 'react'
import { ICategorizedRemovable } from './IRemovable'

export const CategorizedRemovable = ({ categories }: ICategorizedRemovable): JSX.Element => {
  return (
    <>
      {categories.map((category, i) => {
        return (
          <Fragment key={i}>
            <div className={`cb-font-weight-bold cb-padding-bottom-16${i !== 0 ? ' cb-padding-top-32' : ''}`}>
              {category.label}
            </div>
            <TagSet
              label={`Selected`}
              offScreen='Remove'
              tags={category.values}
              onClick={(v, e): void => {
                e.preventDefault()
                category.remove(v)
              }}
            />
          </Fragment>
        )
      })}
    </>
  )
}
