import * as React from 'react'

/**
 * if width of screen is smaller than setwidth then show
 * minwidth else maxwidth
 */
const useMedia = (setwidth: number, maxwidth: any, minwidth: any) => {
  const query = `(min-width: ${setwidth}px)`
  const [iswide, setIswide] = React.useState<boolean>(false)

  React.useLayoutEffect(() => {
    const media = window.matchMedia(query)
    setIswide(media.matches) //new initial state
    const listener = () => {
      setIswide(media.matches)
    }
    media.addEventListener('change', listener)
    return () => {
      media.removeEventListener('change', listener)
    }
  }, [query])

  const context = React.useMemo(() => {
    return iswide ? maxwidth : minwidth
  }, [iswide, maxwidth, minwidth])
  return context
}
export default useMedia
