import { useQuery } from '@apollo/client'
import { useSearchUserContext } from '@msss/ui-components'

import { QUERY } from './Query'
import { IRecommendation } from './ICampaignFaces'
import ShowError from './ShowError'
import NotFound from './NotFound'
import ShowLoading from './ShowLoading'
import ShowData from './ShowData'

export const Prospects = ({ campId }: { campId?: string }) => {
  const { user } = useSearchUserContext()

  const { loading, error, data } = useQuery(QUERY, { variables: { orgId: user.orgId } })

  if (loading) {
    return <ShowLoading />
  }

  if (error) {
    return <ShowError error={error} />
  }

  const recommendations: IRecommendation[] = data.getRecommendations
  const expired = campId !== '0' && campId !== recommendations[0].campaignId

  if (recommendations) {
    return <ShowData orgId={user.orgId} data={recommendations} expired={expired} />
  } else {
    return <NotFound />
  }
}

export default Prospects
