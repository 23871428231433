export const DATA_DASHBOARD = {
  name: 'node--functional_content',
  params: `
    filter[field_id]=SAS-Dashboard
    &fields[node--functional_content]=title,field_overview
  `
}

export const LILP_MENUS = {
  name: 'menu_link_content--panels',
  ids: {
    recentSearches: 'df694e71-b813-4cb6-a17c-1fa0107d6899',
    quickLinks: '61b3c936-2c49-4b93-b229-b77ac7ad815e'
  },
  params: 'fields[menu_link_content--panels]=title,link,parent,weight'
}

export const LILP_PAGE = {
  name: 'node--promotion',
  params: `
    filter[field_id]=LILP
    &include=field_panel.field_promotion_component,field_panel.field_resource_component,field_panel.field_resource_component.field_thumbnail_media.field_media_image
    &fields[node--promotion]=title,body,field_panel
    &fields[component--promotion_component]=title,field_body_content,field_cta_link,field_icon,field_id
    &fields[panel--benefit_panel]=title,field_promotion_component,field_id
    &fields[panel--menu_panel]=title,field_id
    &fields[panel--resource_panel]=title,field_overview,field_resource_component,field_id
    &fields[resource--external_link]=title,field_external_description,field_external_link,field_thumbnail_media
    &fields[resource--video]=title,field_external_description,field_video_link,field_video_aria_label,field_video_duration,field_thumbnail_media
    &fields[media--image]=field_media_image
    &fields[file--file]=uri
  `
}

export const SYSTEM_ALERT = {
  name: 'component--alert',
  params: `
    filter[status]=1
    &fields[component--alert]=title,field_body_content,field_alert_type,field_alert_visibility_settings
  `
}
