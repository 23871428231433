export interface IExamRangeOption {
  min: number
  max: number
  step: number
  pushable: number
  disabled: boolean
}

export const psatRadioOptions = [
  {
    label: 'No Selection',
    value: 'none',
    disabled: false,
    rangeOptions: { min: 0, max: 0, step: 0, pushable: 0, disabled: false }
  },
  {
    value: 'PSAT',
    label: 'Any PSAT test takers',
    disabled: false,
    rangeOptions: { min: 320, max: 1520, step: 10, pushable: 50, disabled: false }
  },
  {
    value: 'psatRM',
    label: 'Total Score',
    disabled: false,
    rangeOptions: { min: 320, max: 1520, step: 10, pushable: 50, disabled: false }
  },
  {
    value: 'psatR',
    label: 'Evidence Based Reading & Writing Section Score',
    disabled: false,
    rangeOptions: {
      min: 160,
      max: 760,
      step: 10,
      pushable: 50,
      disabled: false
    }
  },
  {
    value: 'psatM',
    label: 'Math Section Score',
    disabled: false,
    rangeOptions: { min: 160, max: 760, step: 10, pushable: 50, disabled: false }
  },
  {
    value: 'psatRT',
    label: 'Reading Test Score',
    disabled: false,
    rangeOptions: { min: 8, max: 38, step: 1, pushable: 5, disabled: false }
  },
  { value: 'psatMT', label: 'Math Test Score', rangeOptions: { min: 8, max: 38, step: 1, pushable: 5, disabled: false } },
  {
    value: 'psatWT',
    label: 'Writing Test Score',
    disabled: false,
    rangeOptions: { min: 8, max: 38, step: 1, pushable: 5, disabled: false }
  }
]
