import { ILabeledValue } from '@cb/apricot-react'
import { ICheck } from '../../ICriteria'

export const optionsStates: ILabeledValue[] = [
  { label: 'U.S. States', value: 'state' },
  { label: 'Territories', value: 'terr' }
]

export const apoReference: ICheck[] = [
  { section: 'state', currOption: 'terr', label: 'Armed Forces Africa, Canada, Europe, Middle East', value: 'AE' },
  { section: 'state', currOption: 'terr', label: 'Armed Forces America (except Canada)', value: 'AA' },
  { section: 'state', currOption: 'terr', label: 'Armed Forces Pacific', value: 'AP' }
]

export const optionsInterntional: ILabeledValue[] = [
  { label: 'Select region', value: '-1' },
  { label: 'African Region', value: '1' },
  { label: 'Asia and Pacific Rim Region', value: '2' },
  { label: 'Canadian Region', value: '5' },
  { label: 'Caribbean Region', value: '6' },
  { label: 'Central American Region', value: '7' },
  { label: 'Central and Eastern European Region', value: '8' },
  { label: 'Middle East and Southern Asia Region', value: '9' },
  { label: 'South American Region', value: '4' },
  { label: 'Western European Region', value: '3' }
]

// pathes for references
// some examples
//reference-assets-dev.msss-nonprod.collegeboard.org/x-refs/InternationalRegion/6.json
//reference-assets-dev.msss-nonprod.collegeboard.org/Province.json
export const pathState = 'x-refs/US_States'
export const pathTerr = 'x-refs/US_Territories'
export const pathArmed = 'x-refs/US_Armed_Forces'
export const pathCounty = 'x-refs/County/'
export const pathGeo = 'x-refs/Geomarket/'
export const pathMsa = 'x-refs/Msa/'
export const pathInternational = 'x-refs/InternationalRegion/'
export const pathProvince = 'Province'
export const pathCountry = 'Country'
export const pathPrintFormat = 'PrintFormat'
export const pathSortSequence = 'SortSequence'
export const pathFileFormat = 'FileFormat'
export const pathBillingType = 'BillingType'
export const pathNotificationFrequency = 'NotificationFrequency'
export const pathDataloadSchedule = 'DataloadSchedule'
export const pathFeatureTiersRef = 'submodel/FeatureTiers'
export const pathFeatureTiersAllRef = 'submodel/FeatureTiers-All'
