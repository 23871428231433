// this enum holds actual solr criteria name
// except 'zip'.  since we have variation of zip,
// 'zip' is here to allow string operations to find it
// within the data array

export enum GEOGRAPHY {
  state = 'state',
  market = 'epsCode',
  county = 'county',
  zip = 'zip',
  // these 3 are for indexing purpose
  zip2 = 'zip2',
  zip3 = 'zip3',
  zip5 = 'zip5',
  msa = 'msaCode',
  international = 'country'
}
