import { Modal, NakedButton, PrimaryButton } from '@cb/apricot-react'

const CancelEditCriteriaDetailModal = ({
  criteriaId,
  setExpandedRow,
  setAddingNew,
  open,
  setOpen
}: {
  criteriaId: number | undefined
  setExpandedRow: (value: number) => void
  setAddingNew: (value: undefined) => void
  open: boolean
  setOpen: (value: boolean) => void
}) => {
  const handleCancel = () => {
    criteriaId ? setExpandedRow(-1) : setAddingNew(undefined)
  }

  return open ? (
    <Modal
      actions={[
        <NakedButton key='no' onClick={() => setOpen(false)}>
          No
        </NakedButton>,
        <PrimaryButton key='yes' onClick={handleCancel}>
          Yes
        </PrimaryButton>
      ]}
      analytics
      analyticsTitle='mysavedcriteria-cancel-edit-saved-criteria'
      onClose={() => {
        setOpen(false)
      }}
      closeButton={false}
      title={<>Cancel {criteriaId ? 'edit' : 'create'} saved criteria</>}
      open={open}
      withFooter
      withHeader
    >
      <span>Are you sure you want to cancel {criteriaId ? 'editing' : 'creating'} the criteria set?</span>
    </Modal>
  ) : null
}

export default CancelEditCriteriaDetailModal
