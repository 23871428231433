import React from 'react'

type NoAPExamsProps = {}
const NoAPExams = (props: NoAPExamsProps): JSX.Element => {
  return (
    <>
      <div className='col-xs-8 cb-border cb-border-1 cb-border-radius '>
        <div className='cb-padding-16 display-flex' />
      </div>
    </>
  )
}

export default NoAPExams
