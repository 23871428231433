import { useMutation, useQuery } from '@apollo/client'
import { ButtonRow, ErrorNotification, PrimaryButton, Spacer, Toast } from '@cb/apricot-react'
import { Loader, useSearchUserContext } from '@msss/ui-components'
import { useCallback, useEffect, useRef, useState, useContext } from 'react'
import FeatureProvider from '../../../context/FeatureProvider'
import { SAVE_PEER_GROUPS } from '../../../services/graphql/mutations'
import { GET_PEER_GROUPS } from '../../../services/graphql/queries'
import FeatureUnavailable from '../../error/FeatureUnavailable'
import NewPGModal from './NewPGModal'
import PeerGroupInfo from './PeerGroupInfo'
import './PeerGroups.scss'
import { ensure } from '@msss/ui-common'
import { FeatureAccessContext } from '../../../context/FeatureAccessProvider'

export interface IPeerInfo {
  orgId: number
  orgName: string
}

export interface IPeerGroup {
  name?: string
  peerGroupId?: number
  peers?: IPeerInfo[]
  sequence: number
  searchYear: number
  currSearchYear?: number
}

export const MAX_PEERS_PER_GROUP = 20

const PeerGroups = () => {
  const { user } = useSearchUserContext()
  const { searchYear } = useContext(FeatureAccessContext)
  const lastPGSavedBkupRef = useRef([])
  const [groups, setGroups] = useState<Array<IPeerGroup>>([])
  const [newPGModalOpen, setNewPGModalOpen] = useState<boolean>(false)
  const [showFailToSaveMsg, setShowFailToSaveMsg] = useState<string>('')
  const [currGroup, setCurrGroup] = useState<number>()
  const [currSearchYear, setCurrSearchYear] = useState<number>(searchYear)
  const [notReviewed, setNotReviewed] = useState<boolean>(false)

  const [savePeerGroups] = useMutation(SAVE_PEER_GROUPS, {
    onCompleted: data => {
      if (!data.savePeerGroups) {
        setGroups(previous => {
          const index = lastPGSavedBkupRef.current[0]
          setShowFailToSaveMsg(previous[index].name)

          const newGroups: IPeerGroup[] = [...previous]
          newGroups.splice(index, 1, lastPGSavedBkupRef.current[1]) // reset
          return newGroups
        })
      }
      lastPGSavedBkupRef.current = [] // clear
    }
  })

  const { loading, error, data } = useQuery(GET_PEER_GROUPS, {
    variables: { orgId: user.orgId }
  })

  const hasAllPeerGroups = groups.every((group: IPeerGroup) => group.peers?.length > 0)

  useEffect(() => {
    if (searchYear) setCurrSearchYear(searchYear)
  }, [searchYear])

  useEffect(() => {
    // if curr group is updated, that means we are editing (from PeerGroupInfo.tsx)
    if (currGroup) setNewPGModalOpen(true)
  }, [currGroup])

  useEffect(() => {
    // clear out curr group since edit/add modal is closed
    // this will ready the curr seq for the next open
    console.log('i am closing modal for peergroup')
    if (!newPGModalOpen) setCurrGroup(undefined)
  }, [newPGModalOpen])

  useEffect(() => {
    if (!loading && !error) {
      const groupList: IPeerGroup[] = []
      if (data?.getPeerGroups && currSearchYear) {
        let i = 1
        for (const group of data.getPeerGroups) {
          groupList.push({ ...group, sequence: i++, currSearchYear: currSearchYear })
        }
      }
      // fill up to 5
      for (let i = groupList.length; i < 5; i++) {
        groupList.push({ sequence: i + 1, searchYear: currSearchYear, currSearchYear: currSearchYear })
      }
      setGroups(groupList)
      // find out if any group that has not been reviewed
      if (groupList.find(g => g.searchYear !== g.currSearchYear)) {
        setNotReviewed(true)
      } else setNotReviewed(false)
    }
  }, [data, loading, error, currSearchYear, searchYear])

  const onNewPeerGroupSave = useCallback(
    (name: string, peers: IPeerInfo[]) => {
      console.log(`on save of peer group: ${name}, currGroup is`, currGroup, groups)
      setGroups(previous => {
        const index = currGroup
          ? previous.findIndex(({ sequence }: IPeerGroup) => currGroup === sequence) // current matched one
          : previous.findIndex(({ name }: IPeerGroup) => !name) // the next empty one
        lastPGSavedBkupRef.current = [index, previous[index]]
        console.log('found prev is  is', previous[index])

        const save = {
          orgId: user.orgId,
          sequence: currGroup ? currGroup : previous[index].sequence,
          name,
          peers: peers.map((peer: IPeerInfo) => peer.orgId),
          searchYear: currSearchYear,
          userid: user.userId
        }

        savePeerGroups({
          variables: {
            peerGroups: [save]
          }
        })

        const newGroups: IPeerGroup[] = [...previous]
        // groups needs to have full obj since save is a smaller version
        // replace peers back with full fat and add back currSearchYear
        newGroups.splice(index, 1, { ...save, peers: peers, currSearchYear: currSearchYear })
        // check if we still stuff to review
        if (newGroups.find(g => g.searchYear !== g.currSearchYear)) {
          setNotReviewed(true)
        } else setNotReviewed(false)
        return newGroups
      })
    },
    [user.orgId, savePeerGroups, currGroup, currSearchYear, searchYear]
  )

  return (
    <FeatureProvider featureKey='interest_in_my_peers' altContent={<FeatureUnavailable id='peer-groups' name='Peer Groups' />}>
      <>
        <div className='row'>
          <div className='col-xs-12'>
            <p>
              A peer group is a list of five to 20 institutions you select that are similar to your school. It allows you to
              connect with potential students who added either one or more of the institutions you chose to their BigFuture®
              college lists. You can create up to five peer groups with customized names.{' '}
              <b>However, once you save a group, you cannot change it until the following September.</b>
            </p>
            <Spacer size='72' />
            {(loading || !searchYear) && <Loader />}
            {error && <ErrorNotification title='Error'>There was an error fetching Peer Groups.</ErrorNotification>}
            {!loading && !error && searchYear && (
              <>
                {notReviewed ? (
                  <>
                    <div className='display-flex justify-content-end'>
                      <Toast message={'Please review your peer groups for this year'} autoClose={false} />
                    </div>
                    <Spacer />
                  </>
                ) : null}
                <ButtonRow align='end'>
                  <PrimaryButton disabled={hasAllPeerGroups} onClick={() => setNewPGModalOpen(true)}>
                    Add to Peer Group
                  </PrimaryButton>
                </ButtonRow>
              </>
            )}
            {showFailToSaveMsg && (
              <ErrorNotification
                className='cb-box-shadow-light cb-margin-top-16 cb-margin-bottom-16'
                dismissible
                notificationId='error-saving-peer-group'
                onDismiss={() => setShowFailToSaveMsg('')}
                title={<b>Error Saving Peer Group</b>}
              >
                <span style={{ marginTop: '-12px' }}>Error saving peer group {showFailToSaveMsg}. Please try again.</span>
              </ErrorNotification>
            )}
          </div>
          {!loading && !error && currSearchYear && (
            <div className='col-xs-12'>
              <div className='peer-group-container'>
                {groups.map((group: IPeerGroup) => (
                  <PeerGroupInfo
                    searchYear={group.searchYear}
                    currSearchYear={ensure(group.currSearchYear)}
                    key={group.sequence}
                    name={group.name}
                    peers={group.peers}
                    sequence={group.sequence}
                    setCurrGroup={setCurrGroup}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        {!loading && !error && newPGModalOpen && (
          <NewPGModal
            groups={groups}
            onSave={onNewPeerGroupSave}
            open={newPGModalOpen}
            parentOrgId={user.parentOrgId}
            setOpen={setNewPGModalOpen}
            sequence={currGroup}
          />
        )}
      </>
    </FeatureProvider>
  )
}

export default PeerGroups
