import { ErrorNotification, List } from '@cb/apricot-react'
import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_DEPARTMENTS_OF_ORG_QUERY } from '../../services/graphql/queries'
import { useNavigate } from 'react-router-dom'
import { Loader, useSearchUserContext } from '@msss/ui-components'

interface DepartmentProps {
  orgId: string
  accordionOrgId: string
  orgName: string
  diCode: string
}

const Departments = ({ orgId, accordionOrgId, orgName, diCode }: DepartmentProps) => {
  const { user, setUser } = useSearchUserContext()
  const navigate = useNavigate()
  const [getDepartments, { loading, error, data }] = useLazyQuery(GET_DEPARTMENTS_OF_ORG_QUERY, {
    fetchPolicy: 'network-only' // Doesn't check cache before making a network request
  })

  useEffect(() => {
    if (orgId && parseInt(orgId) !== -1 && orgId === accordionOrgId) {
      getDepartments({
        variables: { orgId }
      })
    }
  }, [orgId, accordionOrgId])

  const handleDepartment = (e, deptId, deptName, orgId, orgName, diCode) => {
    e.preventDefault()
    setUser({
      ...user,
      orgId: deptId as number,
      orgName: deptName,
      parentOrgId: parseInt(orgId),
      parentOrgName: orgName,
      dicode: diCode
    })
    navigate('/')
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return (
      <ErrorNotification title='Error'>
        There was an error fetching Departments of Organization having ID={orgId}.
      </ErrorNotification>
    )
  }
  if (data) {
    const item = data.getDepartments
    return (
      <>
        {item.length > 0 && <h3 className='cb-h6'>Departments:</h3>}
        {item.length > 0 && (
          <List flush marker='reset'>
            {item.map((dept: any) => {
              return (
                <li key={dept.orgId}>
                  <a
                    href='#'
                    className='cb-blue1-color'
                    onClick={e => handleDepartment(e, dept.orgId, dept.orgName, orgId, orgName, diCode)}
                  >
                    <span>{dept.orgName}</span>
                  </a>
                </li>
              )
            })}
          </List>
        )}
      </>
    )
  }

  return null
}

export default Departments
