import React from 'react'

const DownloadAvailableIcon = () => {
  return (
    <div>
      <div className='cb-icon cb-icon-16 cb-download cb-margin-right-32' aria-label='Download Available' />
      <div
        className='cb-icon cb-icon-circular cb-green3-color'
        aria-hidden
        style={{
          fontSize: '5px',
          position: 'relative',
          right: '-12px',
          top: '-22px'
        }}
      />
    </div>
  )
}

export default DownloadAvailableIcon
