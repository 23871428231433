import { ILabeledValue } from '@cb/apricot-react'
import { Table, useTableContext } from '@msss/ui-components'
import React, { useCallback, useEffect, useState } from 'react'
import { Column } from 'react-table'
import { MSOF_VIEW } from './ViewToggle'
import FolderCard from './FolderCard'
import { IFolder } from './IFolder'
import { IPortfolioItem } from '../IPortfolioItem'

interface FoldersProps {
  allFolders: IFolder[] | undefined
  columns: Array<Column<object>>
  data: IPortfolioItem[]
  folderCountDesc?: string
  hiddenColumns: string[]
  id: string
  msofFilter: ILabeledValue[] | undefined
  msofGlobalFilter: string | undefined
  setFolder: (folder: IFolder) => void
  view: string
}

const FoldersView = ({
  allFolders,
  columns,
  data,
  folderCountDesc,
  hiddenColumns,
  id,
  msofFilter,
  msofGlobalFilter,
  setFolder,
  view
}: FoldersProps) => (
  <div style={{ display: view === MSOF_VIEW.FOLDERS ? 'block' : 'none' }} className='cb-margin-bottom-32'>
    <Table columns={columns} data={data} globalFilter={msofGlobalFilter} hiddenColumns={hiddenColumns}>
      <FoldersTable
        allFolders={allFolders}
        folderCountDesc={folderCountDesc}
        id={id}
        msofFilter={msofFilter}
        msofGlobalFilter={msofGlobalFilter}
        setFolder={setFolder}
      />
    </Table>
  </div>
)

export default FoldersView

interface FoldersTableProps {
  allFolders: IFolder[] | undefined
  folderCountDesc?: string
  id: string
  msofFilter: ILabeledValue[] | undefined
  msofGlobalFilter: string | undefined
  setFolder: (folder: IFolder) => void
}

const FoldersTable = ({ allFolders, folderCountDesc, id, msofFilter, msofGlobalFilter, setFolder }: FoldersTableProps) => {
  const [folders, setFolders] = useState<IFolder[] | undefined>(undefined)
  const [sort, setSort] = useState<string>('asc')

  const { globalFilter, rows, setFilter, setGlobalFilter } = useTableContext()

  useEffect(() => {
    // apply the global filter
    if (msofGlobalFilter) {
      setGlobalFilter(msofGlobalFilter)
    } else {
      setGlobalFilter('')
    }
  }, [msofGlobalFilter])

  // apply the rest of the filters
  useEffect(() => {
    if (msofFilter) {
      msofFilter.forEach(item => setFilter(item.label, item.value))
    }
  }, [msofFilter])

  useEffect(() => {
    // create a map of all folders with key as folderId
    const allFoldersMap =
      allFolders && allFolders.length
        ? allFolders.reduce((acc, item) => {
            return {
              ...acc,
              [item.folderId]: { folderId: item.folderId, folderName: item.folderName, count: 0, downloadsPending: 0 }
            }
          }, {})
        : {}

    // update the folder's searches/orders count
    // this way all folders that dont have any searches/orders will also be listed
    const allFoldersMapWithCount = rows.reduce((acc, item) => {
      if (item.values.folderName !== null) {
        if (acc[item.values.folderId]) {
          acc[item.values.folderId].count++
          // if there are any downloads pending then increment the count
          if (item.values.downloadsPending) {
            acc[item.values.folderId].downloadsPending++
          }

          return { ...acc }
        } else {
          return {
            ...acc,
            [item.values.folderId]: {
              folderId: item.values.folderId,
              folderName: item.values.folderName,
              count: 1,
              downloadsPending: item.values.downloadsPending ? 1 : 0
            }
          }
        }
      } else {
        return acc
      }
    }, allFoldersMap)

    // get the folders as an array
    const allFoldersWithCount: IFolder[] = Object.values(allFoldersMapWithCount)

    if (sort === 'asc') {
      allFoldersWithCount.sort((a, b) => a.folderName.localeCompare(b.folderName))
    } else {
      allFoldersWithCount.sort((a, b) => b.folderName.localeCompare(a.folderName))
    }
    // if global search filter exists, filter out any folder that doesnt match the filter
    if (globalFilter) {
      setFolders(
        allFoldersWithCount.filter(item => {
          if (item.count === 0) {
            return item.folderName.toLowerCase().indexOf(globalFilter.toLowerCase()) >= 0
          } else return item
        })
      )
    } else {
      setFolders(allFoldersWithCount)
    }
  }, [rows, allFolders])

  const sortFolders = useCallback(() => {
    if (sort === 'asc') {
      setFolders(prevFolders => prevFolders?.sort((a, b) => b.folderName.localeCompare(a.folderName)))
      setSort('desc')
    } else {
      setFolders(prevFolders => prevFolders?.sort((a, b) => a.folderName.localeCompare(b.folderName)))
      setSort('asc')
    }
  }, [sort])

  return (
    <div className='row'>
      <div className='col-sm-12 cb-font-weight-bold cb-margin-bottom-8'>Folders</div>
      <div className='col-sm-4' role='status'>
        {folders && (
          <>
            <span className='cb-font-weight-bold'>{folders.length}</span> {folders.length === 1 ? 'Folder' : 'Folders'}
          </>
        )}
      </div>
      {/* Headers TODO NEED REWRITE  */}
      <div className='col-xs-12'>
        <div
          aria-label='sort results'
          className='display-flex justify-content-left align-items-center cb-black1-bg cb-margin-bottom-8'
          role='region'
        >
          <a
            aria-current={true}
            aria-label={`Sort by name - ${sort === 'asc' ? 'ascending' : 'descending'}`}
            className='cb-white-color cb-margin-left-16'
            href='#'
            id={`${id}-name`}
            key='name'
            onClick={(e: any) => {
              e.preventDefault()
              sortFolders()
            }}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                sortFolders()
              }
            }}
            role='button'
            style={{ padding: '11px 8px 12px 8px', textDecoration: 'none' }}
            tabIndex={0}
          >
            Name
            <span aria-hidden className={`cb-margin-left-8 cb-icon ${sort === 'asc' ? 'cb-sort-asc' : 'cb-sort-desc'}`} />
          </a>
        </div>
      </div>

      {/* Folders */}
      {folders && folders.length > 0 && (
        <div className='col-xs-12' style={{ maxHeight: '345px', overflow: 'scroll' }}>
          <div className='container cb-border-radius-bottom-left-8 cb-border-radius-bottom-right-8 cb-border cb-blue5-tint-1-border'>
            <div className='row'>
              {folders
                .filter(folder => folder.folderName !== undefined && folder.folderName !== '')
                .map((folder, i) => (
                  <div className='col-xs-4 cb-padding-right-8 cb-padding-left-8' key={folder.folderName}>
                    <FolderCard
                      folderCountDesc={folderCountDesc}
                      folderId={folder.folderId}
                      folderName={folder.folderName}
                      count={folder.count}
                      setFolder={setFolder}
                      downloadsPending={folder.downloadsPending}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
