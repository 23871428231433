export const inputStringValidation = (name: string | undefined): string => {
  let errorMessage = ''
  if (name) {
    name.trim().length > 100 ? (errorMessage = 'The folder name must be limited to 100 alphanumeric characters') : null
    name.trim().length === 0 ? (errorMessage = 'The folder name is required') : null
  } else {
    errorMessage = 'The folder name is required'
  }
  return errorMessage
}
