/**
 * this file is a mimic of the Apricot React component TagSet
 * in order to resolve an a11y issue with Tag not responding
 * when space is pressed while Tag has focus
 */

interface TagSetProps {
  label: string
  offScreen: string
  tags: string[]
  onClick: (tagName: string, e: any) => void
}

export const TagSet = ({ label, offScreen, tags, onClick }: TagSetProps) => (
  <div className='cb-tag-set cb-btn-row' role='region' aria-label={label}>
    {tags.map(tag => (
      <Tag key={tag} tag={tag} offScreen={offScreen} onClick={onClick} />
    ))}
  </div>
)

interface TagProps {
  tag: string
  offScreen: string
  onClick: (tagName: string, e: any) => void
}

const Tag = ({ tag, offScreen, onClick }: TagProps) => (
  <a
    className='cb-btn cb-btn-tag'
    href='#'
    onClick={e => {
      e.preventDefault()
      onClick(tag, e)
    }}
    onKeyDown={e => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        onClick(tag, e)
      }
    }}
    role='button'
  >
    <span>{tag}</span>
    <span className='cb-icon cb-x-mark'>
      <span className='sr-only'>{offScreen}</span>
    </span>
  </a>
)
