import { ErrorNotification, Spinner } from '@cb/apricot-react'
import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_ORDER_QUERY } from '../../../../../services/graphql/queries'

// Its used by all individual Criteria components. They all have the same props

const OrderRecipient = ({ orderId }: { orderId: number }) => {
  const [getOrder, { loading, error, data }] = useLazyQuery(GET_ORDER_QUERY)
  // used to fetch the
  useEffect(() => {
    if (orderId && orderId > 0) {
      getOrder({
        variables: { orderId }
      })
    }
  }, [orderId])

  useEffect(() => {}, [data])

  if (loading) {
    return <Spinner />
  }
  if (error) {
    console.log('error', error)
    return <ErrorNotification title='Error'>There was an error fetching order data for ID={orderId}.</ErrorNotification>
  }
  if (data) {
    return (
      <>
        <div>
          <div>
            {data.getOrder.submittedBy?.firstName} {data.getOrder.submittedBy?.lastName}
          </div>
          {data.getOrder.recipients &&
            data.getOrder.recipients.map((recipient: any) => (
              <div key={`${orderId}-recipient-${recipient.email}`}>
                {recipient.firstName} {recipient.lastName}
              </div>
            ))}
        </div>
      </>
    )
  }
  return 'N/A'
}

export default OrderRecipient
