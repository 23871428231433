import { createContext, useContext } from 'react'

export interface ISystemAlertContext {
  count: number
  refreshAlerts: () => void
}

const defaultSystemAlertContext = {
  count: 0,
  refreshAlerts: () => {}
}

const SystemAlertContext = createContext<ISystemAlertContext>(defaultSystemAlertContext)

export const SystemAlertContextProvider = SystemAlertContext.Provider

export const useSystemAlertContext = () => useContext(SystemAlertContext)
