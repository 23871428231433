import gql from 'graphql-tag'

export const COPY_SEARCH = gql`
  mutation ($savedSearchId: Int!, $userId: Int!) {
    copySearch(savedSearchId: $savedSearchId, userId: $userId) {
      savedSearch {
        savedSearchId
        accessLevel
        activity
        criteria
        description
        flagged
        userData
        uploadFileStatus
        uploadFileName
        uploadFileCount
        topSearch
        portfolioStatus
        portfolioType
        orgId
        namesAvailable
        name
        order {
          orderId
          additionalNamesProjected
          billingType
          endDate
          fileFormat
          namesAvailable
          namesDesired
          notificationFrequency
          oneTimeRun
          orderStatus
          orgId
          orderType
          poNumber
          printFormat
          sasOrder
          originalCost
          searchYear
          sortSequence
          startDate
          submitDate
        }
      }
      excludedCriteria
    }
  }
`

export const SAVE_UPDATE_SEARCH = gql`
  mutation ($savedSearch: SaveSavedSearchInput) {
    saveSavedSearch(savedSearch: $savedSearch) {
      error
      savedSearchId
      criteriaId
    }
  }
`

export const SAVE_CRITERIA_PREF = gql`
  mutation ($saveCriteria: CriteriaPreferenceInput) {
    saveCriteriaPreference(criteriaPreference: $saveCriteria) {
      criteriaId
      name
      categoryGroupCd
      uploadFileName
      uploadFileCount
      createdDate
      createdBy
      lastModifiedDate
      lastModifiedBy
    }
  }
`

export const DELETE_CRITERIA_PREF = gql`
  mutation ($criteriaId: Int!) {
    deleteCriteriaPreference(criteriaId: $criteriaId)
  }
`

export const SAVE_USER_UI_PREFERENCE = gql`
  mutation ($userPreference: SaveUserPreferenceInput) {
    saveUserPreferences(userPreference: $userPreference)
  }
`

export const SAVE_UPDATE_ORDER = gql`
  mutation ($order: OrderInput) {
    saveOrder(order: $order)
  }
`

export const SUBMIT_ORDER = gql`
  mutation MyMutation($orderId: Int!, $userId: Int!) {
    submitOrder(orderId: $orderId, userId: $userId)
  }
`

export const UPDATE_PARTIAL_ORDER = gql`
  mutation ($order: OrderUpdateInput!) {
    updateOrder(order: $order)
  }
`

export const DELETE_SAVED_SEARCH = gql`
  mutation ($savedSearchId: Int!, $deletedBy: Int!) {
    deletePortfolioItem(savedSearchId: $savedSearchId, deletedBy: $deletedBy)
  }
`
export const AUDIT_DOWNLOAD = gql`
  mutation ($fileId: Int!, $success: Boolean!, $userId: Int!) {
    auditDownload(fileId: $fileId, success: $success, userId: $userId)
  }
`

export const CHANGE_FILE_FORMAT = gql`
  mutation ($changeRequest: ChangeFormatInput!) {
    changeFormat(changeRequest: $changeRequest)
  }
`

export const STOP_ORDER = gql`
  mutation ($orderId: Int!, $userId: Int!) {
    stopOrder(orderId: $orderId, userId: $userId)
  }
`

export const GENERATE_LIVING_RECORD = gql`
  mutation ($deptId: Int!, $username: String!) {
    generateLivingRecord(deptId: $deptId, username: $username)
  }
`

export const SAVE_PEER_GROUPS = gql`
  mutation ($peerGroups: [PeerGroupInput]) {
    savePeerGroups(peerGroups: $peerGroups)
  }
`

export const UPDATE_PI = gql`
  mutation ($orderId: Int!, $newOrderId: Int!, $campaignId: String!) {
    updateCampaignEstimate(orderId: $orderId, newOrderId: $newOrderId, campaignId: $campaignId)
  }
`

export const MOVE_SAVED_SEARCH = gql`
  mutation ($savedSearch: FolderSearchAssocInput) {
    saveSearchFolderAssoc(savedSearch: $savedSearch) {
      folderId
      savedSearchIds
    }
  }
`

export const REMOVE_SAVED_SEARCH = gql`
  mutation ($savedSearch: FolderSearchAssocInput) {
    deleteSearchFolderAssoc(savedSearch: $savedSearch) {
      folderId
      savedSearchIds
    }
  }
`

export const CREATE_FOLDER = gql`
  mutation ($folder: FolderDetailsInput) {
    saveFolder(folder: $folder) {
      folderId
      name
      orgId
    }
  }
`

export const DELETE_FOLDERS = gql`
  mutation ($folderIds: [Int!]) {
    deleteFolders(folderIds: $folderIds)
  }
`
export const PRICE_CALCULATION = gql`
  mutation ($purchase: SubscriptionsInput!) {
    calculatePrice(purchase: $purchase) {
      planPrice
      purchasePlanPrice
      totalPrice
      planCount
      priceAdjustments {
        featureTierPurchaseId
        featureTierId
        adjustmentPrice
        variablePercent
        purchasePrice
      }
    }
  }
`
