// AP tab options
export const apOptions = [
  { label: 'No Selection', value: 'none' },
  { label: 'Any AP Exam Taker', value: 'AP' },
  { label: 'Select AP Exams', value: 'select' }
]
export const apArtOptions = [
  { label: 'Art History', value: 'apArtHist' },
  { label: '2-D Art and Design', value: 'apStudioArtGeneral' },
  { label: '3-D Art and Design', value: 'apStudioArt3D' },
  { label: 'Drawing', value: 'apStudioArtDraw' },
  { label: 'Music Theory', value: 'apMusicTheory' }
]

export const apEnglishOptions = [
  { label: 'English Language and Composition', value: 'apEnflLangComp' },
  { label: 'English Literature and Composition', value: 'apEnglLitComp' }
]

export const apHistoryOptions = [
  { label: 'Macroeconomics', value: 'apEconMacro' },
  { label: 'Microeconomics', value: 'apEconMicro' },
  { label: 'Human Geography', value: 'apHumanGeography' },
  { label: 'Comparative Government and Politics', value: 'apGovPoliticComp' },
  { label: 'United States Government and Politics', value: 'apGovPoliticUS' },
  { label: 'European History', value: 'apHistEuropean' },
  { label: 'United States History', value: 'apHistUS' },
  { label: 'World History: Modern', value: 'apWorldHistory' },
  { label: 'Psychology', value: 'apPsychology' }
]
export const apScienceOptions = [
  { label: 'Biology', value: 'apBiology' },
  { label: 'Chemistry', value: 'apChemistry' },
  { label: 'Environmental Science', value: 'apEnvScience' },
  { label: 'Physics 1:  Algebra-Based', value: 'apPhysics1' },
  { label: 'Physics 2:  Algebra-Based', value: 'apPhysics2' },
  { label: 'Physics B', value: 'apPhysicsB' },
  { label: 'Physics C: Electricity and Magnetism', value: 'apPhysicsCElec' },
  { label: 'Physics C: Mechanics', value: 'apPhysicsCMech' }
]
export const apMathOptions = [
  { label: 'Calculus AB', value: 'apMathCalcAB' },
  { label: 'Calculus BC', value: 'apMathCalcBC' },
  { label: 'Computer Science A', value: 'apCompSciA' },
  { label: 'Computer Science Principles', value: 'apCompSciP' },
  { label: 'Statistics', value: 'apStatistics' }
]
export const apCapstoneOptions = [
  { label: 'Capstone: Seminar', value: 'apSeminar' },
  { label: 'Capstone: Research', value: 'apResearch' }
]
export const apLanguageOptions = [
  { label: 'Chinese Language and Culture', value: 'apChinese' },
  { label: 'French Language and Culture', value: 'apFrenchLang' },
  { label: 'German Language and Culture', value: 'apGermanLang' },
  { label: 'Italian Language and Culture', value: 'apItalianLang' },
  { label: 'Japanese Language and Culture', value: 'apJapaneseLang' },
  { label: 'Latin: Vergil', value: 'apLatinVergil' },
  { label: 'Spanish Language and Culture', value: 'apSpanishLang' },
  { label: 'Spanish Literature and Culture', value: 'apSpanishLit' }
]

export const allApOptions = [
  ...apArtOptions,
  ...apEnglishOptions,
  ...apHistoryOptions,
  ...apScienceOptions,
  ...apMathOptions,
  ...apCapstoneOptions,
  ...apLanguageOptions
]
