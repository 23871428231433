import { useLazyQuery } from '@apollo/client'
import { ErrorNotification } from '@cb/apricot-react'
import { dateFormatter, dateTimeFormatter, ensure, numberFormatter } from '@msss/ui-common'
import { Loader } from '@msss/ui-components'
import { useEffect, useState } from 'react'
import { GET_SEARCH_ORDER_DETAILS_QUERY } from '../../../../services/graphql/queries'
import { ICheck } from '../../../search/search-form/ICriteria'
import { filterTypeOptions } from '../options'
import OrderViewTabs from './OrderViewTabs'
import { ViewSavedSearchDetailsProps } from './ViewSavedSearchDetails'

export interface OrderOverView {
  savedSearchId: number
  orderId: number
  itemTypeDescr: string
  namesAvailable: number
  namesDesired: number
  owner: string
  createDateFormatted: string
  namesDelivered: number
  formattedTotalAmount: string
  totalRuns: number
  name: string
  description: string
  uploadFileCount: number
  uploadFileName: string
  status: string
  billingType: number
  poNumber: string
  lastModifiedDateFormatted: string
  orderOwner: string
  printFormat: number
  sortSequence: number
  submitDateFormatted: string
  fileFormat: number
  notificationFrequency: number
  startDateFormated: string
  startSchedule: number
  endDateFormatted: string
  endSchedule: number
  folderId: number
  folderName: string
}
const SearchOrderDetails = ({ savedSearchId, accordionSavedSearchId }: ViewSavedSearchDetailsProps) => {
  const [getPortfolioItem, { loading, error, data }] = useLazyQuery(GET_SEARCH_ORDER_DETAILS_QUERY, {
    fetchPolicy: 'network-only' // Doesn't check cache before making a network request
  })

  const [updatedDesc, setUpdatedDesc] = useState<string | undefined>()

  useEffect(() => {
    if (savedSearchId && savedSearchId !== -1 && savedSearchId === accordionSavedSearchId) {
      getPortfolioItem({
        variables: { savedSearchId }
      })
    }
  }, [savedSearchId, accordionSavedSearchId, updatedDesc])

  if (loading) {
    return <Loader />
  }
  if (error) {
    return (
      <ErrorNotification title='Error'>
        There was an error fetching Saved Search details for Saved Search ID={savedSearchId}.
      </ErrorNotification>
    )
  }
  if (data) {
    const item = data.getPortfolioItem

    const searchOrder: OrderOverView = {
      ...item,
      ...{ formattedTotalAmount: item.totalAmount ? item.totalAmount : null },
      ...{ lastModifiedDateFormatted: item.lastModifiedDate ? dateTimeFormatter.format(new Date(item.lastModifiedDate)) : null },
      ...{ createDateFormatted: item.createDate ? dateTimeFormatter.format(new Date(item.createDate)) : null },
      ...{ submitDateFormatted: item.submitDate ? dateTimeFormatter.format(new Date(item.submitDate)) : null },
      ...{ formattedTotalAmount: item.totalAmount ? numberFormatter(item.totalAmount, { isCurrency: true }) : null },
      ...{ startDateFormated: item.startDate ? dateFormatter.format(new Date(item.startDate)) : null },
      ...{ endDateFormatted: item.endDate ? dateFormatter.format(new Date(item.endDate)) : null },
      ...{
        itemTypeDescr: item.itemType
          ? ensure(filterTypeOptions.find((option: ICheck) => option.value === item.itemType.toString())).label
          : null
      }
    }

    return (
      <>
        <div className='col-xs-12'>
          <OrderViewTabs searchOrder={searchOrder} savedSearchId={savedSearchId} />
        </div>
      </>
    )
  }
  return null
}

export default SearchOrderDetails
