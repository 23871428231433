import { useLazyQuery, useMutation } from '@apollo/client'
import { NakedButton } from '@cb/apricot-react'
import { dateFormatter, ensure, numberFormatter } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { useEffect, useRef, useState } from 'react'
import { ICheck } from '../../../search/search-form/ICriteria'
import { filterTypeOptions } from '../options'

import { GET_DOWNLOAD_URL_QUERY, GET_SEARCH_ORDER_DETAILS_QUERY } from '../../../../services/graphql/queries'
import { AUDIT_DOWNLOAD } from '../../../../services/graphql/mutations'

import { downloadFile, getChangeFormatLink, getDownloadLink, getDownloadStatus } from '../../common/FileHelpers'
import SystemErrorModal from '../../../search/modals/SystemErrorModal'
import { ViewSavedSearchDetailsProps } from './ViewSavedSearchDetails'

export interface FileDownloadInfo {
  fileId?: number
  fileName?: string
}

const OrderRunDetails = ({ savedSearchId, accordionSavedSearchId }: ViewSavedSearchDetailsProps) => {
  const { user } = useSearchUserContext()
  const [getPortfolioItem, { data }] = useLazyQuery(GET_SEARCH_ORDER_DETAILS_QUERY)
  const fileDownloadInfo = useRef<FileDownloadInfo | undefined>()
  const [auditDownload] = useMutation(AUDIT_DOWNLOAD)
  const [downloads, setDownloads] = useState(0)
  const [changeFormat, setChangeFormat] = useState(0)
  const [downloadError, setDownloadError] = useState<string>()
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)

  // This query gets a signed URL that allows the file to be downloaded for a very short period of
  // time. Use an onComplete function that will pull the file info from the useRef that stores it,
  // and download the file using the downloadFile() function....

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL_QUERY, {
    onCompleted: async data => {
      if (fileDownloadInfo.current) {
        const { fileId, fileName } = fileDownloadInfo.current
        if (fileId && fileName) {
          const resp = await downloadFile(fileId, fileName, data.getDownloadUrl, auditDownload, setDownloads, user)
          if (resp === undefined || resp?.success === false) {
            console.log(`FAILED to download ${resp?.fileId}`)
            setDownloadError(`FAILED to download ${resp?.fileId}`)
            setOpenErrorModal(true)
          }
        }
      }
    },
    onError: error => {
      console.log(`FAILED to get download url ${error}`)
      setDownloadError(`FAILED to get download url ${error}`)
      setOpenErrorModal(true)
    }
  })

  useEffect(() => {
    if (savedSearchId && savedSearchId !== -1 && savedSearchId === accordionSavedSearchId) {
      getPortfolioItem({
        variables: { savedSearchId }
      })
    }
  }, [savedSearchId, accordionSavedSearchId, downloads])

  const { getPortfolioItem: portfolioItem } = data || {}

  const hasFiles = (outputFiles: any[], runInfo: any) =>
    outputFiles.find((outputFile: any) => outputFile.fileId === runInfo.fileId)

  const getViewRunOverview = (orderId: number, orderRun: number) => {
    return (
      <NakedButton id={`view-details-${orderId}-${orderRun}`} onClick={function noRefCheck() {}}>
        View Run Overview
      </NakedButton>
    )
  }

  const startDownload = (fileId: number, fileName: string) => {
    // Set the file info into the useRef so it can be accessed from the onComplete function the
    // AppSync query that retrieves our signed URL...
    fileDownloadInfo.current = { fileId: fileId, fileName: fileName }

    // Get the signed URL from AppSync for this file ID....
    getDownloadUrl({ variables: { fileId } })
  }

  return (
    <>
      {portfolioItem && portfolioItem.totalRuns > 0 && (
        <table className='cb-table cb-margin-top-48'>
          <thead>
            <tr>
              <th scope='col'>File</th>
              <th scope='col'>Status</th>
              <th scope='col'>Action</th>
            </tr>
          </thead>
          <tbody>
            {portfolioItem.orderRuns
              .filter((runInfo: any) => hasFiles(portfolioItem.outputFiles, runInfo))
              .map((runInfo: any) => {
                const type = ensure(
                  filterTypeOptions.find((option: ICheck) => option.value === portfolioItem.itemType.toString())
                ).label
                const createdOn = dateFormatter.format(new Date(runInfo.processDate))
                // calculate available until date - 1 year later
                const availUntil = new Date(createdOn)
                availUntil.setFullYear(availUntil.getFullYear() + 1)

                const outputFile = portfolioItem.outputFiles.find((outputFile: any) => outputFile.fileId === runInfo.fileId)
                return (
                  <tr key={runInfo.fileName}>
                    <th scope='row'>
                      <b>{runInfo.fileName}</b>
                      <br />
                      {type}; Created: {createdOn}; Available until: {dateFormatter.format(new Date(availUntil))}
                      <br />
                      Names received: {numberFormatter(runInfo.namesReported)}
                      {runInfo.totalAmount ? <>; Total: {numberFormatter(runInfo.totalAmount, { isCurrency: true })}</> : null}
                    </th>
                    <td>{getDownloadStatus(outputFile)}</td>
                    <td>
                      {portfolioItem.outputFiles &&
                        portfolioItem.outputFiles.length > 0 &&
                        getDownloadLink(runInfo.fileName, portfolioItem.outputFiles, startDownload, user)}
                      {portfolioItem.outputFiles &&
                        portfolioItem.outputFiles.length > 0 &&
                        getChangeFormatLink(
                          portfolioItem.orderId,
                          runInfo.orderRun,
                          runInfo.fileId,
                          runInfo.fileName,
                          'Search',
                          availUntil,
                          changeFormat,
                          setChangeFormat,
                          user
                        )}
                      {/* TODO: Uncomment when we have a View Run Overview component....
                      getViewRunOverview(runInfo.orderId, runInfo.ordeRun)
                      */}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      )}
      {downloadError ? <SystemErrorModal openModal={openErrorModal} setOpenModal={setOpenErrorModal} /> : null}
    </>
  )
}

export default OrderRunDetails
