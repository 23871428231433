import { InfoNotification, Spacer } from '@cb/apricot-react'

interface FeatureUnavailableProps {
  id: string
  name: string
}

const FeatureUnavailable = ({ id, name }: FeatureUnavailableProps) => {
  const display = name ? `the ${name} feature` : 'this feature'
  return (
    <>
      <Spacer />
      <InfoNotification notificationId={`${id}-unavailable`} title="We're Sorry">
        Sorry, {display} is not available based on your purchased subscriptions. Please contact the Search administrator for
        further information and options for obtaining access.
      </InfoNotification>
    </>
  )
}

export default FeatureUnavailable
