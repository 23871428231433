import { Tabs, Tab } from '@cb/apricot-react'
import Counties from './Counties'
import { IGeoSection } from './Geography'
import { GEOGRAPHY } from './Enums'
import GeoMarkets from './GeoMarkets'
import International from './International'
import MSAs from './MSAs'
import TabState from './States'

const GeographyTabs = ({ updateData, data }: IGeoSection) => {
  return (
    <div className='align-items-stretch'>
      <Tabs>
        <Tab label='US States &amp; Territories' panelId={`${GEOGRAPHY.state}`} selected>
          <TabState updateData={updateData} data={data} sectionid={GEOGRAPHY.state} />
        </Tab>
        <Tab label='Geomarkets' panelId={`${GEOGRAPHY.market}`}>
          <GeoMarkets updateData={updateData} data={data} sectionid={GEOGRAPHY.market} />
        </Tab>
        <Tab label='Counties' panelId={`${GEOGRAPHY.county}`}>
          <Counties updateData={updateData} data={data} sectionid={GEOGRAPHY.county} />
        </Tab>
        <Tab label='MSAs' panelId={`${GEOGRAPHY.msa}`}>
          <MSAs updateData={updateData} data={data} sectionid={GEOGRAPHY.msa} />
        </Tab>
        <Tab label='International' panelId={`${GEOGRAPHY.international}`}>
          <International updateData={updateData} data={data} sectionid={GEOGRAPHY.international} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default GeographyTabs
