import { ILabeledValue } from '@cb/apricot-react'

export const additionalOptions: ILabeledValue[] = [
  { label: 'Include Undecided', value: '1987' },
  { label: 'Include No Response', value: '0' },
  { label: 'Other', value: '1989' }
]

export const majorsChoiceOptions: ILabeledValue[] = [
  { label: 'Any Choice', value: '5' },
  { label: `Student's First Choice`, value: '1' }
]

export const excludeMajors = [
  '1987',
  '0',
  '1989',
  '1384',
  '947',
  '1360',
  '1360',
  '1367',
  '1368',
  '1384',
  '1389',
  '1390',
  '1473',
  '1606',
  '1617',
  '1644',
  '1646',
  '186',
  '188',
  '1950',
  '261',
  '307',
  '334',
  '336',
  '337',
  '339',
  '340',
  '341',
  '342',
  '373',
  '472',
  '508',
  '523',
  '524',
  '535',
  '784',
  '8',
  '947',
  '988'
]
