import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createHttpLink } from '@apollo/client/link/http'
import { createAuthLink } from 'aws-appsync-auth-link'

const region = 'us-east-1'

const cb = (window as any).cb

const getAuthHeaders = (orgId: number) => {
  console.info('Generating authorization headers for Org ID=%s.....', orgId)
  return {
    Authorization: Buffer.from(
      JSON.stringify({
        orgId,
        catapultToken: {
          authzToken: cb.core.iam.getJWTToken(),
          authnToken: 'CBLogin ' + cb.core.iam.getAuthSession().sessionId
        }
      })
    ).toString('base64')
  }
}

const parse = (value: string) => (value ? JSON.parse(value) : {})

const apolicies = {
  typePolicies: {
    Query: {
      fields: {
        getSavedSearch: {
          merge(existing: any, incoming: any) {
            return {
              ...incoming,
              criteria: {
                ...incoming?.criteria,
                userData: {
                  ...parse(incoming?.criteria.userData),
                  zipFileCount: incoming?.uploadFileCount,
                  zipFilename: incoming?.uploadFileName
                }
              }
            }
          }
        }
      }
    },
    // for each cache obj, the default key apollo looks for is id or __id, if we did not set this key
    // from schema.graphql in the returned obj, apollo won't know how to find key in the map of cache
    // we use keyFields to indicate custom key.  the same also allows compound key since it is an array of
    // strings
    Criteria: {
      fields: {
        // the above getSavedSearch parse userdata already
        // the getsavedcriteria userdata is NOT parsed!
        // but, both are Criteria type so its children will match 'userData' and 'criteria' below
        // parse will fire cause userData double parse - object object error.
        // so, i check only parse if type is string
        userData(value: any) {
          if (typeof value === 'string') {
            return parse(value)
          }
          return value
        },
        criteria(value: string) {
          return parse(value)
        },
        excludedCriteria(value: string) {
          return parse(value)
        }
      }
    },
    CriteriaPreference: {
      keyFields: ['criteriaId']
    }
  }
}

export const newClient = (appSyncUrl: string | undefined, orgId: number) => {
  if (!appSyncUrl) {
    throw new Error('Unable to initialize client - No AppSync Provider URL specified')
  }
  const auth: any = { type: 'AWS_LAMBDA' }

  // Now we have to create a couple of "links" that build the AppSync request.
  // First is the Auth Link, which sets the region, URL and auth propertis (meaning the API key)....
  const authLink = createAuthLink({ url: appSyncUrl, region, auth })

  // Next, we have to add the Catapult auth headers.  AppSync is a public endpoint, so we want code on the
  // serverside to use auth headers to verify access for each request!
  const headersLink = ApolloLink.from([
    setContext((request, previousContext = {}) => ({
      headers: {
        ...previousContext.headers,
        ...getAuthHeaders(orgId),
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/graphql'
      }
    }))
  ])
  // Concatenate these links to a final HTTP link which builds the final HTTP request from the gathered
  // attributes....
  const link = ApolloLink.from([
    authLink as unknown as ApolloLink, // Workaround for strange Typescript issue with AuthLink vs ApolloLink
    headersLink,
    createHttpLink({ uri: appSyncUrl })
  ])
  // Build the client with the Apollo Link and default cache options....
  return new ApolloClient({ link, cache: new InMemoryCache(apolicies) })
}
