// academics

export const hsYrsOptions = [
  { label: '1 year of study', value: '1' },
  { label: '2 years of study', value: '2' },
  { label: '3 years of study', value: '3' },
  { label: '4 years of study', value: '4' },
  { label: '4 or more years of study', value: '4.5' }
]

export const hsHnrsOptions = [{ label: 'Honors', value: 'Y' }]

export const hsAcademicOptions = [{ label: 'Less than 1 year of study', value: '0.5' }, ...hsYrsOptions, ...hsHnrsOptions]

export enum AcademicsTypes {
  English = 'english',
  Language = 'language',
  Math = 'math',
  Science = 'science',
  History_ = 'history'
}

// art & music

export const hsArtMusicOptions = [
  { label: 'Acting or the production of a play', value: '1' },
  { label: 'Art history or art appreciation', value: '2' },
  { label: 'Dance', value: '3' },
  { label: 'Drama or theater for appreciation', value: '4' },
  { label: 'Music history, theory, or appreciation', value: '5' },
  { label: 'Music, instrumental or vocal performance', value: '6' },
  { label: 'Photography or filmmaking', value: '7' },
  { label: 'Studio art and design', value: '8' },
  { label: 'No course work or experience in this area', value: '52' }
]

// athletics

export const hsSportsOptions = [
  { label: 'Archery', value: '1', display: false },
  { label: 'Badminton', value: '2', display: false },
  { label: 'Baseball', value: '3', display: true },
  { label: 'Basketball', value: '4', display: true },
  { label: 'Bowling', value: '5', display: true },
  { label: 'Boxing', value: '6', display: false },
  { label: 'Cheerleading', value: '46', display: true },
  { label: 'Cricket', value: '7', display: false },
  { label: 'Cross-Country', value: '8', display: true },
  { label: 'Diving', value: '9', display: false },
  { label: 'Equestrian', value: '10', display: false },
  { label: 'Fencing', value: '11', display: false },
  { label: 'Field Hockey', value: '12', display: true },
  { label: 'Football', value: '13', display: true },
  { label: 'Football (Non-Tackle)', value: '47', display: false },
  { label: 'Golf', value: '14', display: true },
  { label: 'Gymnastics', value: '15', display: true },
  { label: 'Handball', value: '16', display: false },
  { label: 'Ice Hockey', value: '17', display: false },
  { label: 'Judo', value: '18', display: false },
  { label: 'Martial Art', value: '19', display: false },
  { label: 'Lacrosse', value: '20', display: true },
  { label: 'Other', value: '48', display: true },
  { label: 'Polo', value: '21', display: false },
  { label: 'Racquetball', value: '22', display: false },
  { label: 'Rifle', value: '23', display: false },
  { label: 'Rodeo', value: '24', display: false },
  { label: 'Rowing (Crew)', value: '25', display: false },
  { label: 'Rugby', value: '26', display: false },
  { label: 'Sailing', value: '27', display: false },
  { label: 'Scuba Diving', value: '28', display: false },
  { label: 'Skiing', value: '29', display: false },
  { label: 'Skin Diving', value: '30', display: false },
  { label: 'Soccer', value: '31', display: true },
  { label: 'Softball', value: '32', display: true },
  { label: 'Squash', value: '33', display: false },
  { label: 'Swimming', value: '34', display: true },
  { label: 'Sync. Swimming', value: '35', display: false },
  { label: 'Table Tennis', value: '36', display: false },
  { label: 'Tennis', value: '37', display: true },
  { label: 'Track And Field', value: '38', display: true },
  { label: 'Track: Indoor', value: '44', display: false },
  { label: 'Track: Outdoor', value: '45', display: false },
  { label: 'Triathlon', value: '39', display: false },
  { label: 'Ultimate Frisbee', value: '49', display: false },
  { label: 'Volleyball', value: '40', display: true },
  { label: 'Volleyball (Sand)', value: '50', display: false },
  { label: 'Water Polo', value: '41', display: false },
  { label: 'Weightlifting', value: '42', display: false },
  { label: 'Wrestling', value: '43', display: true }
]

// activities

export const hsActivitiesOptions = [
  { label: 'Art', value: '2', display: false },
  { label: 'Athletics: Intramural, Junior Varsity', value: '3', display: true },
  { label: 'Athletics: Varsity', value: '4', display: true },
  { label: 'Career-oriented Activity', value: '5', display: true },
  { label: 'Community/Service Activity', value: '6', display: true },
  { label: 'Computer Activity', value: '7', display: false },
  { label: 'Cooperative work or internship', value: '23', display: true },
  { label: 'Dance Activity', value: '8', display: true },
  { label: 'Debate/Public speaking', value: '9', display: true },
  { label: 'Departmental organization (club within my major)', value: '26', display: false },
  { label: 'Drama or Theater', value: '22', display: true },
  { label: 'Environmental or ecology activity', value: '20', display: false },
  { label: 'Ethnic/Cross-cultural Activity', value: '10', display: true },
  { label: 'Foreign Exchange', value: '11', display: true },
  { label: 'Foreign Language Activity', value: '12', display: true },
  { label: 'Fraternity, sorority, or social club', value: '27', display: false },
  { label: 'Government/Political Activity', value: '13', display: true },
  { label: 'Honors program or independent study', value: '1', display: true },
  { label: 'Journalism/Literary Activity', value: '14', display: true },
  { label: 'Music: Instrumental performance', value: '16', display: false },
  { label: 'Music: Vocal performance', value: '17', display: false },
  { label: 'Other activity not listed', value: '25', display: true },
  { label: 'Religious activity', value: '18', display: true },
  { label: 'Reserve Officers Training Corps (Army ROTC, Air Force ROTC, or Navy ROTC)', value: '15', display: false },
  { label: 'School-spirit Activity', value: '21', display: true },
  { label: 'Science/Math Activity', value: '19', display: true },
  { label: 'Work: Part-time', value: '24', display: false }
]

export const hsActivitiesYrsOptions = [
  { label: '1 year', value: '1' },
  { label: '2 years', value: '2' },
  { label: '3 years', value: '3' },
  { label: '4 years', value: '4' }
]

export const hsActivitiesAwdOption = { label: 'held office or received award', value: 'Y' }
