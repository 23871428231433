import { ILabeledValue } from '@cb/apricot-react'
import { INCLUDE_ALL_STUDENTS } from '../../SearchFormConstants'

enum satCheckboxValues {
  all = '',
  only = 'Y'
}

enum neighborhoodCheckboxValues {
  all = '',
  above = 'M,H',
  high = 'H'
}

const satKeyLabelMap = new Map<String, ILabeledValue>([
  [satCheckboxValues.all, { label: INCLUDE_ALL_STUDENTS, value: satCheckboxValues.all }],
  [
    satCheckboxValues.only,
    {
      label: 'Only include students with a SAT score in the top 25th percentile of SAT takers in their high school',
      value: satCheckboxValues.only,
      shortLabel: 'SAT score in top 25th percentile'
    }
  ]
])

const neighborhoodKeyLabelMap = new Map<String, ILabeledValue>([
  [neighborhoodCheckboxValues.all, { label: INCLUDE_ALL_STUDENTS, value: neighborhoodCheckboxValues.all }],
  [
    neighborhoodCheckboxValues.above,
    {
      label: 'Above Average Neighborhood Challenge (neighborhoods at the 50th percentile and higher challenge level)',
      shortLabel: 'Above Average Neighborhood Challenge (50th percentile & higher)',
      value: neighborhoodCheckboxValues.above
    }
  ],
  [
    neighborhoodCheckboxValues.high,
    {
      label: 'High Neighborhood Challenge (neighborhoods at the 75th percentile and higher challenge level)',
      shortLabel: 'High Neighborhood Challenge (75th percentile & higher)',
      value: neighborhoodCheckboxValues.high
    }
  ]
])

export { satCheckboxValues, neighborhoodCheckboxValues, neighborhoodKeyLabelMap, satKeyLabelMap }
