import { FilterWithCheckbox } from '@msss/ui-components'
import { useState } from 'react'
import { filterCriteriaTypeOptions } from './options'

interface FilterOptionsProps {
  setFilter: any
}

const FilterOptions = ({ setFilter }: FilterOptionsProps) => {
  const [criteriaTypeFilter, setCriteriaTypeFilter] = useState<Array<string>>([])

  const clearCriteriaTypeFilter = () => {
    setCriteriaTypeFilter([])
  }

  const handleCriteriaTypeFilter = (event: boolean, value: string | number) => {
    if (event) {
      setCriteriaTypeFilter(prevCriteriaTypeFilter => [...prevCriteriaTypeFilter, value as string])
    } else {
      setCriteriaTypeFilter(prevCriteriaTypeFilter => prevCriteriaTypeFilter.filter(item => item !== value))
    }
  }

  return (
    <FilterWithCheckbox
      name='Criteria Type'
      id='filter-criteria-type'
      options={filterCriteriaTypeOptions}
      setFilter={setFilter}
      column='categoryGroupCd'
      filter={criteriaTypeFilter}
      handleFilter={handleCriteriaTypeFilter}
      clearFilter={clearCriteriaTypeFilter}
    />
  )
}

export default FilterOptions
