import { UNLIMITED_NAMES, dateFormatter, numberFormatter } from '@msss/ui-common'
import { useCallback, useMemo } from 'react'
import ThreeColumnList from './ThreeColumnList'
import { ExpandableTableData, Table } from '@msss/ui-components'

interface TableProps {
  data: Array<object>
}

const SubscriptionTableTiers = ({ data }: TableProps) => {
  const columns = useMemo(
    () => [
      { Header: 'Subscription', accessor: 'name' },
      {
        Header: 'Date',
        accessor: 'startDate',
        sortType: 'dateRange',
        dateRangeEndAccessor: 'endDate',
        Cell: ({ row }) => (
          <>
            {dateFormatter.format(new Date(row.original.startDate))} - {dateFormatter.format(new Date(row.original.endDate))}
          </>
        )
      },
      {
        Header: 'Total Number of Records Included',
        accessor: 'annualVolume',
        Cell: ({ row }) => (
          <>{row.original.annualVolume >= UNLIMITED_NAMES ? 'Unlimited' : numberFormatter(row.original.annualVolume)}</>
        )
      }
    ],
    []
  )

  return (
    <>
      <div className='row'>
        <div className='col-xs-12'>
          <Table columns={columns} data={data}>
            <TiersTable />
          </Table>
        </div>
      </div>
    </>
  )
}

export default SubscriptionTableTiers

const TiersTable = () => {
  const getBtnAriaLabel = useCallback(({ row }) => row.original.name, [])

  const renderExpandedRow = useCallback(
    ({ row }) => (
      <td colSpan={4}>
        <div aria-label='features overview' className='container' role='region'>
          <div className='row'>
            <div className='col-xs-12'>
              <span className='cb-font-weight-medium'>Features</span>
            </div>
          </div>
          {row.original.includedFeatureNames && row.original.includedFeatureNames.length > 0 && (
            <ThreeColumnList theList={row.original.includedFeatureNames} />
          )}
        </div>
      </td>
    ),
    []
  )

  return (
    <ExpandableTableData getBtnAriaLabel={getBtnAriaLabel} id='pf-feat-tiers' renderExpandedRow={renderExpandedRow} showAllRows />
  )
}
