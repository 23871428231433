import React from 'react'
import { MsssInfo } from './MsssDialog'

function Mismatch() {
  return (
    <MsssInfo
      title="We're Sorry"
      message='The account you are trying to login with is not authorized to access this page. If you have another account, please try logging in with that account.'
    />
  )
}

export default Mismatch
