import { useSearchUserContext } from '@msss/ui-components'
import { Link } from 'react-router-dom'

const { LEGACY_BASE_URL, SUPPORT_SITE_URL } = process.env

interface linkTab {
  tab?: string
}

export const UsefulLinks = ({ tab }: linkTab) => {
  const { user } = useSearchUserContext()

  const LINK_TO_LEGACY = `${LEGACY_BASE_URL}/reports-files.htm?orgId=${user.orgId}`

  return (
    <div>
      <div className='container'>
        <div className='row cb-margin-top-8 cb-margin-bottom-8'>
          <div className='col-xs-6'>
            {!tab && (
              <Link id='return-to-my-searches-link' to='/my-searches'>
                <span className='cb-font-size-small'>&lt; Return to My Searches, Orders & Files</span>
              </Link>
            )}
          </div>
          <div className='col-xs-6 cb-align-right'>
            <a className='cb-blue1-color' href={LINK_TO_LEGACY} rel='noopener noreferrer'>
              Return to Classic Search
            </a>
            {' | '}
            <a className='cb-blue1-color' href={SUPPORT_SITE_URL} rel='noopener noreferrer' target='_blank'>
              <span>Support</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsefulLinks
