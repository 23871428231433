import { createContext, useContext } from 'react'

export interface IRefData {
  code: number
  description: string
}

export interface IRefDataCollection {
  [type: string]: IRefData[] | any
}

export const defaultRefDataContext = {}

const RefDataContext = createContext<IRefDataCollection>(defaultRefDataContext)

export const RefDataProvider = RefDataContext.Provider

export const useRefDataContext = () => useContext(RefDataContext)
