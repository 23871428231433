import { ErrorNotification } from '@cb/apricot-react'
import { ApolloError } from '@apollo/client'
import React from 'react'

interface IShowError {
  error: ApolloError
}

const ShowError = ({ error }: IShowError) => {
  return (
    <div className='container'>
      <ErrorNotification title={'System Error:' + error} />
    </div>
  )
}

export default ShowError
