import { Spinner } from '@cb/apricot-react'
import { createContext, useContext } from 'react'
import { IFeatureContext, FeatureProps } from '../interfaces/reference/IReference'
import { FeatureAccessContext } from './FeatureAccessProvider'

export const FeatureContext = createContext<IFeatureContext>({} as IFeatureContext)

const FeatureProvider = ({ featureKey, children, altContent, showLoader = true }: FeatureProps) => {
  const { addUsage, checkAccess, isLoaded } = useContext(FeatureAccessContext)
  const hasAccess = checkAccess(featureKey)
  const onUsage = () => addUsage(featureKey)

  if (!isLoaded() && showLoader) {
    return (
      <div className='cb-padding-48 display-flex justify-content-center'>
        <Spinner palette='blue2' size='16' show timeout={500} />
      </div>
    )
  }
  return (
    <>
      {hasAccess ? (
        <FeatureContext.Provider value={{ onUsage, hasAccess }}>{children}</FeatureContext.Provider>
      ) : (
        altContent || null
      )}
    </>
  )
}

export default FeatureProvider
