import { Row } from 'react-table'

export interface SubtitleProps {
  row: Row<object>
}

export const Subtitle = ({ row }: SubtitleProps) => (
  <>
    {row.values.diCode ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>DI Code:</span>
        {row.values.diCode};{''}
      </>
    ) : null}
    {row.values.stateName ? (
      <>
        <span className='cb-font-weight-medium msof-table-header-span'>State:</span>
        {row.values.stateName};{''}
      </>
    ) : null}
  </>
)
