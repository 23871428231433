import { RadioButton, RadioButtonGroup, Select } from '@cb/apricot-react-forms'
import { TooltipHeader } from '@msss/ui-components'
import { assessmentOptions, scoreLogicOptions, scoreSendsOptions } from './CriteriaSelectionOptions'
import MyCriteriaList from '../../../../my-account/saved-criteria/MyCriteriaList'
import { AvailableMySavedCriteriaTypes } from '../../../../my-account/saved-criteria/options'
import { ISavedCriteriaData } from '../../Search'

type CommonCriteriaProps = {
  myaccount: boolean
  changed: boolean
  setSavedCriteriaData: (value: ISavedCriteriaData) => void
  assessment: string
  handleAssessment: (value: string) => void
  scoreLogic: string
  handleScoreLogic: (value: string) => void
  scoreSends: string
  handleScoreSends: (value: string) => void
}

const CommonCriteria = (props: CommonCriteriaProps): JSX.Element => {
  const {
    assessment,
    handleAssessment,
    scoreLogic,
    handleScoreLogic,
    scoreSends,
    handleScoreSends,
    setSavedCriteriaData,
    myaccount,
    changed
  } = props

  return (
    <>
      <div className='row'>
        <div className='col-xs-12 col-sm-4 cb-margin-bottom-16' style={myaccount ? { visibility: 'hidden' } : undefined}>
          <TooltipHeader header='Saved Criteria' id='cb-exams-saved-criteria' />
          <MyCriteriaList
            type={AvailableMySavedCriteriaTypes.CBExams}
            setSavedCriteriaData={setSavedCriteriaData}
            myaccount={myaccount}
            changed={changed}
          />
        </div>
        <div className='col-xs-12 col-sm-7 offset-sm-1'>
          <p id='cb-exams-assessment-options-label' style={{ display: 'none' }}>
            College Board Exam Options
          </p>
          <RadioButtonGroup
            ariaLabelledBy='cb-exams-assessment-options-label'
            fieldsetId='cb-exams-assessment-options'
            name='assessment'
            onChange={handleAssessment}
            required
            value={assessment}
            vertical
          >
            {assessmentOptions.map(option => {
              return (
                <RadioButton
                  id={`cb-exams-assessment-option-${option.value}`}
                  key={option.value}
                  label={option.label}
                  value={option.value.toString()}
                />
              )
            })}
          </RadioButtonGroup>
        </div>
      </div>
      <div className='row cb-margin-bottom-16 cb-margin-top-16'>
        <div className='col-xs-12 col-sm-4'>
          <TooltipHeader header='Search Logic' id='cb-exams-search-logic' />
          <Select
            ariaLabel='Search Logic'
            name='scoreLogic'
            onChange={handleScoreLogic}
            selectId='cb-exams-search-logic'
            value={scoreLogic}
            values={scoreLogicOptions}
          />
        </div>
        <div className='col-xs-12 col-sm-7 offset-sm-1'>
          <TooltipHeader header='Score Sends' id='cb-exams-score-sends' />
          <Select
            ariaLabel='Score Sends'
            name='scoreSends'
            onChange={handleScoreSends}
            selectId='cb-exams-score-sends'
            value={scoreSends}
            values={scoreSendsOptions}
          />
        </div>
      </div>
    </>
  )
}

export default CommonCriteria
