import React, { useCallback } from 'react'
import { IFolder } from './IFolder'

interface ViewToggleProps {
  view: string
  setView: (view: string) => void
  setFolder: (folder: IFolder | undefined) => void
}

export enum MSOF_VIEW {
  LIST = 'list',
  FOLDERS = 'folders'
}

const ViewToggle = ({ view, setView, setFolder }: ViewToggleProps) => {
  const handleFoldersViewClick = useCallback((e: any) => {
    e.preventDefault()
    setView(MSOF_VIEW.FOLDERS)
    setFolder(undefined)
  }, [])

  const handleListViewClick = useCallback((e: any) => {
    e.preventDefault()
    setView(MSOF_VIEW.LIST)
    setFolder(undefined)
  }, [])

  return (
    <div aria-label='list view or folders view' className='row' role='region'>
      <div className='col-sm-12 cb-margin-bottom-32'>
        {view === MSOF_VIEW.LIST ? (
          <>
            <span className='cb-padding-8'>List View</span>|
            <a href='#' className='cb-padding-8' onClick={handleFoldersViewClick}>
              Folders View
            </a>
          </>
        ) : (
          <>
            <a href='#' className='cb-padding-8' onClick={handleListViewClick}>
              List View
            </a>
            |<span className='cb-padding-8'>Folders View</span>
          </>
        )}
      </div>
    </div>
  )
}

export default ViewToggle
