import { ApolloProvider } from '@apollo/client'
import { IOrgRolePair, ISearchLabeledValue } from '@msss/ui-common'
import { Loader, PageContainer, useSearchUserContext } from '@msss/ui-components'
import React, { Suspense, useEffect, useState } from 'react'
import { Navigate, Routes, Route, useLocation } from 'react-router-dom'

import { allowedLoginAsRoles, getAnyValidOrg } from './utils/tokens'
import { newClient } from './apollo/Apollo'

import FeatureAccessProvider from './context/FeatureAccessProvider'
import { SystemAlertContextProvider } from './context/SystemAlertContext'

import { Prospecto } from './components/pi/Prospecto'
import SelectOrganization, { CurrentOrganization } from './components/SelectOrganization'

import { ASSOCIATION_QUERY } from './services/graphql/queries'
import { IAuditToken } from './AppMain'
import Mismatch from './components/error/Mismatch'
import { MsssError } from './components/error/MsssDialog'
import { IRefDataCollection, RefDataProvider } from './context/RefDataContext'
import { ReferenceService } from './services/rest/ReferenceService'
import { getYearEndDate } from './utils/generalHelpers'

import NoAssociation from './components/error/NoAssociation'
import NavBar from './components/NavBar'
import refPath from './assets/ref_path.json'
import { getRoles } from './utils/generalHelpers'
import MyAccountPopover from './components/my-account/MyAccountPopover'
import LoggedInAs from './components/LoggedInAs'
import Landing from './components/landing/Landing'
import SearchOrg from './components/login-as/SearchOrg'
import SystemAlert from './components/SystemAlert'
import { siteName } from './constants/app'

// these should all be surrounded by <Suspense />
const BulkDownload = React.lazy(() => import('./components/bulk-download/BulkDownload'))
const MsofHome = React.lazy(() => import('./components/msof/MsofHome'))
const MyAccount = React.lazy(() => import('./components/my-account/MyAccount'))
const PurchaseFlow = React.lazy(() => import('./components/search/purchase-flow/PurchaseFlow'))
const Search = React.lazy(() => import('./components/search/search-form/Search'))
const SegmentAnalysis = React.lazy(() => import('./components/sas/SegmentAnalysis'))
const ViewPrintableDetail = React.lazy(() => import('./components/msof/searches-orders/order-details/ViewPrintableDetail'))
const ViewSASPrintableDetail = React.lazy(() => import('./components/msof/sas-orders/sas-details/ViewPrintableDetail'))

const AppRouting = ({ tokenData }: { tokenData?: IAuditToken }) => {
  // set org id and render all the routes
  // if no association, show no ass message
  // if has ass but no org, selct org

  const { user, setUser } = useSearchUserContext()

  const cb = (window as any).cb
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [notMatchOrg, setNotMatchOrg] = useState<boolean>(true)
  const [associations, setAssociations] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [systemAlertCount, setSystemAlertCount] = useState<number>(0)

  const userName = cb.core.iam.getAuthSession().basicProfile.userName
  const firstName = cb.core.iam.getAuthSession().basicProfile.firstName
  const lastName = cb.core.iam.getAuthSession().basicProfile.lastName
  let emailAddress = cb.core.iam.getAuthSession().basicProfile.emailAddress

  const campId = tokenData ? tokenData.campaignId : '0'
  const loginAsId = localStorage.getItem('loginAsId') ? Number(localStorage.getItem('loginAsId')) : undefined
  const getClient = (orgId: number) => newClient(process.env['APPSYNC_PROVIDER_URL'], orgId)

  const [refData, setRefData] = useState<IRefDataCollection>({
    featureTiers: {}
  })

  const setupRefData = async () => {
    const featureTiersData = await ReferenceService.getReferenceData(refPath['featureTiers'].xref)

    const featureTiers = {}
    const today = new Date()
    today.setHours(4, 0, 0)
    Object.entries(featureTiersData).forEach(([key, value]) => {
      const endDate = getYearEndDate(key)
      if (today < endDate) {
        featureTiers[key] = value
      }
    })

    setRefData({
      featureTiers
    })
  }

  useEffect(() => {
    console.log('in main effect...')
    if (!associations) {
      // We need to get the associations before we can present the Select Organization dropdown.  We don't have
      // the Org ID yet, so just grab one from the Catapult org/role pairs.  This is a bootstrapping action, so
      // we're not going to use this client for the AppSync Provider; we'll just use it to get the associations
      // and discard it.....
      // TODO: Look into how we can dynamically change the Org ID in auth link so we don't need to create another client...
      const client = getClient(getAnyValidOrg())

      // We're doing some async stuff so define a function to get the associations....
      const getAssociations = async () => {
        try {
          // Execute the GraphQL query to get the User, which will have the associations populated.
          const resp = await client.query({ query: ASSOCIATION_QUERY, variables: { userName } })
          if (resp) {
            // The getUser field has the User object....
            const { getUser } = resp.data
            if (getUser) {
              // Get the associations from the associations field of the User....
              const associations = getUser.associations
              console.log('Found %s associations', associations.length)
              // Set the associations into state so that the page will know that rendering can be completed.  Also set the
              // loading state to false so we will know to not render the Spinner again....
              setAssociations(associations)
              setLoading(false)
              if (getUser.email) {
                emailAddress = getUser.email
              }

              if (associations) {
                // If we got associations then build the list of display items that will be used to populate the
                // Select Organization dropdown....
                const userId = getUser.userId
                let orgs: ISearchLabeledValue[] = getUser.associations.map((p: any) => {
                  const org = p.department || p.organization
                  return {
                    value: org.orgId,
                    label: org.orgName,
                    parentOrgName: p.organization.orgName,
                    parentOrgId: p.organization.orgId,
                    diCode: p.organization.diCode
                  }
                })
                // Sort the Organization entries alpahetically....
                orgs.sort((a, b) => a.label.localeCompare(b.label))
                // ...Um...I don't get why we're doing this line, will ask...
                orgs = orgs.filter((o, i, me) => i === me.findIndex(org => org.label === o.label && org.value === o.value))

                // Get the Org/Role pairs from the associations.....
                const allRoles: IOrgRolePair[] = getUser.associations.map((association: any) => {
                  const org = association.department || association.organization
                  return { orgId: org.orgId, role: association.role }
                })

                let found: ISearchLabeledValue | undefined
                const orgIdParam = tokenData ? tokenData.orgId : params.get('orgId')
                //console.log('i have org id from either token or url line :', orgIdParam)
                localStorage.setItem('orgId', String(orgIdParam))
                if (orgIdParam) {
                  // force number since url and token types are different
                  const orgIdNumber = Number(orgIdParam)
                  // match org from url in user's associations
                  found = orgs.find(
                    o =>
                      //console.log('in find loop:', o.value, typeof o.value, typeof orgIdParam)
                      o.value === orgIdNumber
                  )
                  //console.log('searching org id from token or url line :', found)
                }
                if (found) {
                  // We good?  Set the User in state so we can render the Select Organization dropdown....
                  const orgId = found.value as number
                  setUser({
                    ...user,
                    userId,
                    emailAddress,
                    userName,
                    firstName,
                    lastName,
                    dicode: found.diCode,
                    associations: orgs,
                    allRoles,
                    roles: getRoles(orgId, user),
                    orgId
                  })
                  setNotMatchOrg(false)
                } else {
                  setUser({ ...user, userId, emailAddress, userName, firstName, lastName, associations: orgs, allRoles })
                }
              }
            }
          }
        } catch (err) {
          console.error('FAILED to initialize the User associations', err)
          setError(true)
          setLoading(false)
          setErrorMessage(String(err))
        }
      }
      if (!error) {
        getAssociations()
      }
    }
    setupRefData()
  }, [])

  if (loading) {
    return (
      <PageContainer pageLoading siteName={siteName}>
        <Loader label='Loading Associations...' />
      </PageContainer>
    )
  }

  if (error) {
    if (String(errorMessage).indexOf('401') > -1) return <NoAssociation />
    if (String(errorMessage).indexOf('413') > -1 || String(errorMessage).indexOf('494') > -1)
      // headers too large
      return (
        <MsssError
          title='Problem with Your Account'
          message={
            <>
              There is an unexpected problem with your account. Please contact your College Board representative or the{' '}
              <a href='mailto:collegeboardsearch@collegeboard.org' target='_top'>
                College Board Search team
              </a>{' '}
              for assistance.
            </>
          }
          errorCode='413'
        />
      )
    return <MsssError title='System is Currently Under the Weather' errorCode='500' />
  }

  // this is to isolate PI condition which is no matching org found in effect
  // and we have tokendata or org id from url line.
  // all other condition needs to select org
  if (notMatchOrg && (tokenData ? tokenData.orgId : params.get('orgId')) && loginAsId === undefined) {
    return <Mismatch />
  }

  if (user.orgId === -1 && associations && user.associations?.length === 1) {
    const orgId = user.associations[0].value as number
    console.log('setting picked org id to:', orgId)
    setUser({
      ...user,
      orgId,
      orgName: user.associations[0].label,
      parentOrgName: user.associations[0].parentOrgName,
      parentOrgId: user.associations[0].parentOrgId,
      dicode: user.associations[0].diCode,
      roles: getRoles(orgId, user)
    })
    // Note: setUser will cause to re-render and then reevaluate all the conditions and will render the main app
    // ENROLLMENT-24290
  }

  if (user.orgId === -1 && associations && window.location.pathname.lastIndexOf('order-detail') === -1) {
    return <SelectOrganization />
  }

  // Login as link sets both orgId and loginAsOrgId as same as the loginAsOrgId
  if (
    associations &&
    user.orgId &&
    user.loginAsOrgId &&
    user.orgId === user.loginAsOrgId &&
    user.roles?.find(e => allowedLoginAsRoles.find(r => r === e.role.toString()))
  ) {
    return (
      <>
        <ApolloProvider client={getClient(user.loginAsOrgId ? user.loginAsOrgId : user.orgId)}>
          <SearchOrg />
        </ApolloProvider>
      </>
    )
  }

  // final return of the application routes
  if ((associations && user.orgId && user.orgId > -1) || loginAsId) {
    // path is order-detail
    if (window.location.pathname.lastIndexOf('sas-order-detail') > -1) {
      return (
        <Suspense fallback={<Loader />}>
          {/* If loginAsOrgId (login as) is available use that to create client else orgId (regular login) */}
          <ApolloProvider client={getClient(user.loginAsOrgId ? user.loginAsOrgId : user.orgId)}>
            <ViewSASPrintableDetail />
          </ApolloProvider>
        </Suspense>
      )
    }
    // path is order-detail
    else if (window.location.pathname.lastIndexOf('order-detail') > -1) {
      return (
        <Suspense fallback={<Loader />}>
          {/* If loginAsOrgId (login as) is available use that to create client else orgId (regular login) */}
          <ApolloProvider client={getClient(user.loginAsOrgId ? user.loginAsOrgId : user.orgId)}>
            <ViewPrintableDetail />
          </ApolloProvider>
        </Suspense>
      )
    } else {
      const msofElement = (
        <>
          <NavBar selected='msof' />
          <Suspense fallback={<Loader />}>
            <MsofHome />
          </Suspense>
        </>
      )
      const myAccountElement = (
        <>
          <NavBar selected='my-account' />
          <Suspense fallback={<Loader />}>
            <RefDataProvider value={refData}>
              <MyAccount />
            </RefDataProvider>
          </Suspense>
        </>
      )
      const sasElement = (
        <>
          <NavBar selected='sas' />
          <Suspense fallback={<Loader />}>
            <SegmentAnalysis />
          </Suspense>
        </>
      )
      return (
        <>
          {/* If loginAsOrgId (login as) is available use that to create client else orgId (regular login) */}
          <ApolloProvider client={getClient(user.loginAsOrgId ? user.loginAsOrgId : user.orgId)}>
            <RefDataProvider value={refData}>
              <FeatureAccessProvider>
                <SystemAlertContextProvider
                  value={{ count: systemAlertCount, refreshAlerts: () => setSystemAlertCount(prev => prev + 1) }}
                >
                  <div className='cb-white-bg'>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-xs-4'>{user.loginAsOrgId !== user.orgId && <MyAccountPopover />}</div>
                        <div className='col-xs-8 cb-align-right'>
                          {user.loginAsOrgId && user.orgName && <LoggedInAs />}
                          {user.loginAsOrgId === undefined && <CurrentOrganization currPath={location.pathname} />}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xs-12'>
                          <SystemAlert />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Routes>
                    <Route
                      path='/'
                      element={
                        <>
                          <NavBar selected='landing' />
                          <Landing />
                        </>
                      }
                    />
                    <Route path='/my-searches'>
                      {/* empty paths needed for when no additional path exists */}
                      <Route path=':selectedTab' element={msofElement} />
                      <Route path='' element={msofElement} />
                    </Route>
                    {user.orgId !== user.parentOrgId && (
                      <Route
                        path='/bulk-download'
                        element={
                          <>
                            <NavBar selected='' />
                            <Suspense fallback={<Loader />}>
                              <BulkDownload />
                            </Suspense>
                          </>
                        }
                      />
                    )}
                    {user.orgId !== user.parentOrgId && (
                      <Route
                        path='/pi'
                        element={
                          <>
                            <NavBar selected='prospect-identifier' />
                            <Suspense fallback={<Loader />}>
                              <Prospecto campId={campId} />
                            </Suspense>
                          </>
                        }
                      />
                    )}
                    {user.orgId !== user.parentOrgId && (
                      <Route
                        path='/search-students'
                        element={
                          <>
                            <NavBar selected='search-students' />
                            <Suspense fallback={<Loader />}>
                              <Search />
                            </Suspense>
                          </>
                        }
                      />
                    )}
                    <Route path='/segment-analysis'>
                      {/* empty paths needed for when no additional path exists */}
                      <Route path=':selectedTab' element={sasElement} />
                      <Route path='' element={sasElement} />
                    </Route>
                    {user.orgId !== user.parentOrgId && (
                      <Route
                        path='/purchase'
                        element={
                          <Suspense fallback={<Loader />}>
                            <PurchaseFlow />
                          </Suspense>
                        }
                      />
                    )}
                    <Route path='/select-organization' element={<SelectOrganization />} />
                    <Route path='/login-as' element={<SearchOrg />} />
                    {user.orgId !== user.parentOrgId && (
                      <Route path='/my-account'>
                        {/* empty paths needed for when no additional path exists */}
                        <Route path=':selectedTab'>
                          <Route path=':subPage' element={myAccountElement} />
                          <Route path='' element={myAccountElement} />
                        </Route>
                        <Route path='' element={myAccountElement} />
                      </Route>
                    )}
                    <Route path='/*' element={<Navigate replace to='/' />} />
                  </Routes>
                </SystemAlertContextProvider>
              </FeatureAccessProvider>
            </RefDataProvider>
          </ApolloProvider>
        </>
      )
    }
  } else {
    console.log('main going to null')
    return null
  }
}

export default AppRouting
