import { Input, SquareButton } from '@cb/apricot-react'
import uniqueId from 'lodash/uniqueId'

import { ChangeEvent, useEffect, useState } from 'react'

const isBlank = (value: number) => {
  if (value === undefined) {
    return true
  }

  if (value.toString().trim().length == 0) {
    return true
  }

  return false
}

interface EditScoreBandProps {
  handleEditScore: (low: number, high: number) => void
  handleCloseEditScore: (event: any) => void
  lowScore: number
  highScore: number
  minScore: number
  maxScore: number
  minRange: number
  step: number
  exam: string
}

const MISSING_REQUIRED_SCORES = 'Please enter a valid Score Band'
const INVALID_SCORES = 'The score must be a whole number between <X> and <Y>'
const INVALID_SCORES_DIFFERENCE = 'High score value must be at least <X> point(s) greater than low score value'

export const EditScoreBand = ({
  handleEditScore,
  handleCloseEditScore,
  lowScore,
  highScore,
  minScore,
  maxScore,
  minRange,
  step,
  exam
}: EditScoreBandProps) => {
  const [values, setValues] = useState({
    low: lowScore,
    high: highScore,
    minScore: minScore,
    maxScore: maxScore,
    minRange: minRange,
    step: step,
    exam: exam
  })
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('sfsf')
  const errorMsgId = uniqueId('score-band')

  useEffect(() => {}, [values])

  const set = (event: ChangeEvent<HTMLInputElement>, input: string) => {
    const value = event.target.value
    setValues(oldValues => ({ ...oldValues, [input]: value }))
  }

  const validate = () => {
    let errorMessage

    if (isBlank(values.low) || isBlank(values.high) || isNaN(values.low) || isNaN(values.high)) {
      errorMessage = 'Please enter a valid Score Band'
    } else if (values.low - Math.floor(values.low) !== 0 || values.high - Math.floor(values.high) !== 0) {
      errorMessage = `The score must be a whole number between ${minScore} and ${maxScore}`
    } else if (values.high - values.low <= minRange - 1) {
      errorMessage = `High score value must be at least ${minRange} point greater than low score value`
    } else if (values.low < minScore || values.low >= maxScore || values.high <= minScore || values.high > maxScore) {
      errorMessage = `The score must be a whole number between ${minScore} and ${maxScore}`
    } else if (step && step! > 0 && (values.low % step !== 0 || values.high % step !== 0)) {
      errorMessage = `The score must be a multiple of ${step}`
    }
    //console.log(`step: ${step}  step % 10 ${step! % 10}  values.low % 10 ${values.low % 10}`)
    return errorMessage
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const message = validate()
    const isUpdateValid = message === undefined

    setValid(isUpdateValid)
    setErrorMessage(message)

    if (isUpdateValid) {
      handleEditScore(Number(values.low), Number(values.high))
    }
  }

  const scoreBandLabel = 'Score band '
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='display-flex align-items-center cb-margin-top-8 cb-margin-bottom-8'>
          <p className='cb-font-weight-bold'>{scoreBandLabel}</p>
          <Input
            className='cb-margin-left-16'
            clearable={false}
            floating
            id='set-score-band-low-value'
            onChange={e => set(e, 'low')}
            style={{ width: '60px', marginLeft: '8px', backgroundColor: '#fff', padding: '11px' }}
            validation={!valid ? 'error' : 'general'}
            validationMessageId={errorMsgId}
            value={values.low}
          />
          <p className='cb-font-weight-bold cb-margin-left-8 cb-margin-right-4'> - </p>
          <Input
            className='cb-margin-left-16'
            clearable={false}
            floating
            id='set-score-band-high-value'
            onChange={e => set(e, 'high')}
            style={{ width: '60px', marginLeft: '8px', backgroundColor: '#fff', padding: '11px' }}
            validationMessageId={errorMsgId}
            validation={!valid ? 'error' : 'general'}
            value={values.high}
          />
          <SquareButton
            className='cb-margin-left-16'
            icon='check'
            id='update-score-band'
            label='Update Score Band'
            type='submit'
          />
          <SquareButton
            className='cb-margin-left-16'
            icon='x-mark'
            id='cancel-score-band-update'
            label='Cancel'
            onClick={handleCloseEditScore}
          />
        </div>
        {!valid ? (
          <p
            className='cb-input-helper cb-validation-error cb-font-size-regular cb-margin-bottom-8 cb-margin-left-8'
            id={errorMsgId}
          >
            {errorMessage}
          </p>
        ) : null}
      </form>
    </>
  )
}
