import React, { useCallback } from 'react'
import { Column } from 'react-table'
import { Subtitle } from './Subtitle'
import { IFolder } from '../common/folders/IFolder'
import FilterOptions from './filters/FilterOptions'
import ViewSavedSearchDetails from './order-details/ViewSavedSearchDetails'
import DownloadAvailableIcon from '../common/DownloadAvailableIcon'
import ListFolderView from '../common/folders/ListFolderView'
import { IPortfolioItem } from '../common/IPortfolioItem'
import { IPortfolioFileType } from '../../../interfaces/reference/IReference'

interface ListFolderSearchesProps {
  allFolders: IFolder[]
  assignFolderSuccess: IFolder | undefined
  columns: Array<Column<object>>
  data: Array<object>
  folder: IFolder
  foldersData: IPortfolioItem[] | undefined
  hiddenColumns: string[]
  setAssignFolderSuccess: (value: IFolder | undefined) => void
  setFolder: (folder: IFolder | undefined) => void
  setView: (view: string) => void
  category: IPortfolioFileType
}

const ListFolderSearches = ({
  allFolders,
  assignFolderSuccess,
  columns,
  data,
  folder,
  foldersData,
  hiddenColumns,
  setAssignFolderSuccess,
  setFolder,
  setView,
  category
}: ListFolderSearchesProps) => {
  const renderHeader = useCallback(
    ({ row }) => (
      <div id={`so-${row.values.savedSearchId}-header`} style={{ width: '100%' }}>
        <div className='row display-flex justify-content-between'>
          <h3 className='cb-h6'>{row.values.name?.replace(/&amp;/g, '&') || 'Untitled Search'}</h3>
          {row.values.downloadsPending && <DownloadAvailableIcon />}
        </div>
        <p className='cb-accordion-heading-content' id={`so-${row.values.savedSearchId}-header-sub-title`}>
          <Subtitle row={row} />
        </p>
      </div>
    ),
    []
  )

  const renderPanel = useCallback(
    ({ row }) => (
      <ViewSavedSearchDetails
        savedSearchId={row.values.savedSearchId}
        accordionSavedSearchId={row.values.savedSearchId}
        orderId={row.values.orderId}
        status={row.values.orderStatus}
        ownerUserId={row.values.ownerUserId}
        startDate={row.values.startDate}
        namesDesired={row.values.namesDesired}
        namesDelivered={row.values.namesDelivered}
        folderId={row.values.folderId}
      />
    ),
    []
  )

  return (
    <ListFolderView
      allFolders={allFolders}
      assignFolderSuccess={assignFolderSuccess}
      columns={columns}
      data={data}
      filterOptions={FilterOptions}
      folder={folder}
      foldersData={foldersData}
      hiddenColumns={hiddenColumns}
      id='searches-orders-folders'
      renderHeader={renderHeader}
      renderPanel={renderPanel}
      setAssignFolderSuccess={setAssignFolderSuccess}
      setFolder={setFolder}
      setView={setView}
      category={category}
    />
  )
}

export default ListFolderSearches
