import '../../geography/geography.scss'
import { useEffect, useState } from 'react'
import { apOptions } from './CriteriaSelectionOptions'
import APExamsList from './APExamsList'
import NoAPExams from './NoAPExams'
import { IApExamCriteria } from './IApExamCriteria'
import { Select, Slider } from '@cb/apricot-react'

type APProps = {
  apExamsCriteria: IApExamCriteria[]
  addApExamCriteria: (apScoreLow: number, apScoreHigh: number, apExams: string[]) => void
  apOption: string
  handleApOption: (value: string) => void
}

const AP = (props: APProps): JSX.Element => {
  const { apExamsCriteria, addApExamCriteria, apOption, handleApOption } = props

  const [apScoreLow, setApScoreLow] = useState<number>(1)
  const [apScoreHigh, setApScoreHigh] = useState<number>(5)
  const [apExams, setApExams] = useState<Array<string>>([])
  const [applyDisabled, setApplyDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (apExams && apExams.length > 0) {
      setApplyDisabled(false)
    } else {
      setApplyDisabled(true)
    }
  }, [apExams])

  const handleScoreRange = (value: string[]) => {
    setApScoreLow(parseInt(value[0]))
    setApScoreHigh(parseInt(value[1]))
  }

  // function to handle selection of ap exams
  const handleApExams = (event: boolean, value: string) => {
    if (event) {
      setApExams(prevApExams => [...prevApExams, value])
    } else {
      setApExams(prevApExams => prevApExams.filter(item => item !== value))
    }
  }

  const selectedApExams = () => {
    const newApExamsCriteria = [...apExamsCriteria]
    return newApExamsCriteria.reduce((acc: string[], current) => acc.concat(current.apExams), [])
  }

  const reset = () => {
    setApExams([])
    setApScoreLow(1)
    setApScoreHigh(5)
  }

  const add = () => {
    addApExamCriteria(apScoreLow, apScoreHigh, apExams)
    // once the ap exam is added reset the ui options
    reset()
  }

  return (
    <>
      <div className='cb-margin-left-8'>
        <div className='row'>
          <div className='col-xs-4'>
            <Select
              ariaLabel='Select an AP exam'
              css=''
              name='apOption'
              onChange={handleApOption}
              selectId='cb-exams-ap-options'
              value={apOption}
              values={apOptions}
            />
          </div>
          {apOption == 'none' || apOption == 'AP' ? (
            <NoAPExams />
          ) : (
            <div className='col-xs-8'>
              <APExamsList handleApExams={handleApExams} selectedApExams={selectedApExams} apExams={apExams} />

              <div className='row cb-margin-top-16'>
                <div className='offset-sm-1 col-sm-6'>
                  <div className='cb-margin-top-48 cb-margin-right-8 cb-margin-bottom-32 cb-margin-left-8'>
                    <Slider
                      disabled={applyDisabled}
                      format={{ to: (value: number) => value.toFixed(0), from: (value: string) => parseInt(value) }}
                      label={['lowest AP score', 'highest AP score']}
                      margin={1}
                      onChange={handleScoreRange}
                      pips={{
                        mode: 'positions',
                        values: [0, 100],
                        density: 25
                      }}
                      range={{ max: 5, min: 1 }}
                      start={[apScoreLow, apScoreHigh]}
                      step={1}
                      tooltips={{ sticky: true }}
                    />
                  </div>
                </div>
                <div className='offset-sm-1 col-sm-3 cb-align-right'>
                  <button
                    className='cb-btn cb-btn-primary ms-applynow'
                    disabled={applyDisabled}
                    id='apply-ap-btn'
                    onClick={add}
                    type='button'
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AP
