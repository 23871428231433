import { Glyph, Input } from '@cb/apricot-react'
import cn from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { TagSet } from '../../../../purecomponents'
import { KEY_EVENTS, KEYS, useKeyEvent } from '../../../hooks/useKeyEvent'
import './styles/MultiSelectInput.scss'

interface MultiSelectInputProps {
  id: string
  inputGroupAriaLabel: string
  maxSelectionCount: number
  placeholder?: string
  tags: any[]
  value: string
  // actions
  onBlur: (e: any) => void
  onChange: (e: any) => void
  onFocus: (e: any) => void
  onRemoveTag: (removedTagInfo: any) => void
  // a11y
  ariaActiveDescendant: string
  ariaAutoComplete: string
  ariaControls: string
  ariaDescribedBy?: string
  ariaExpanded: string
  ariaHasPopup?: string
  ariaLabel: string
  ariaOwns: string
  autoComplete?: boolean
  role: string
}

const MultiSelectInput = ({
  id,
  inputGroupAriaLabel,
  maxSelectionCount,
  placeholder = '',
  tags = [],
  value,

  // actions
  onBlur,
  onChange,
  onFocus,
  onRemoveTag,

  // a11y
  ariaActiveDescendant,
  ariaAutoComplete,
  ariaControls,
  ariaDescribedBy,
  ariaExpanded,
  ariaHasPopup,
  ariaLabel,
  ariaOwns,
  autoComplete,
  role
}: MultiSelectInputProps) => {
  const inputContainerRef = useRef<any>(null)
  const clearButtonRef = useRef<any>(null)
  const initialRender = useRef<any>(true)

  const [inputValue, setInputValue] = useState(value)
  const [selections, setSelections] = useState(tags)

  useEffect(() => {
    if (!initialRender.current) {
      setSelections(tags)
      if (document.activeElement?.id !== id) {
        inputContainerRef.current?.querySelector('input').focus()
      }
    }
  }, [id, tags])

  useEffect(() => {
    if (!initialRender.current) {
      setInputValue(value)
    }
  }, [value])

  useEffect(() => {
    // mark initial render as false after other useEffect's have run
    if (initialRender.current) {
      initialRender.current = false
    }
  }, [])

  useEffect(() => {
    if (maxSelectionCount && selections?.length >= maxSelectionCount) {
      inputContainerRef.current?.classList.add('inactive')
    } else {
      inputContainerRef.current?.classList.remove('inactive')
      inputContainerRef.current?.querySelector('input').focus()
    }
  }, [maxSelectionCount, selections?.length])

  // useKeyEvent({
  //   keyEvent: KEY_EVENTS.KEYDOWN,
  //   targetKey: KEYS.BACKSPACE,
  //   handler: e => {
  //     if (document.activeElement.id === id) {
  //       if (!inputValue && selections?.length) {
  //         const removedTag = selections.pop()
  //         setSelections([...selections])
  //         onRemoveSelection(e, removedTag)
  //       }
  //     }
  //   }
  // })

  const onBlurInput = useCallback(
    (e: any) => {
      inputContainerRef.current.classList.remove('active')
      onBlur?.(e, { value: inputValue, currentSelections: selections })
    },
    [inputValue, selections, onBlur]
  )

  const onChangeInput = useCallback(
    (e: any) => {
      const newValue = e.target.value
      setInputValue(newValue)
      onChange?.(e, { value: newValue, currentSelections: selections })
    },
    [selections, onChange]
  )

  const onClickInputSection = useCallback((e: any) => {
    e.preventDefault()
    inputContainerRef.current.querySelector('input').focus()
  }, [])

  const onFocusInput = useCallback(
    (e: any) => {
      inputContainerRef.current.classList.add('active')
      onFocus?.(e)
    },
    [onFocus]
  )

  const onRemoveSelection = useCallback(
    (e: any, removedTag: any) => {
      const currentTags = selections.filter(selection => selection.orgId !== removedTag.orgId)
      setSelections(currentTags)
      onRemoveTag({ value: removedTag, currentSelections: currentTags })
      inputContainerRef.current.querySelector('input').focus()
    },
    [selections, onRemoveTag]
  )

  if (!ariaLabel) {
    return null
  }

  const containerClasses = cn(
    'multi-select-input-container',
    selections.length > 0 && 'msi-tags-selected',
    Boolean(inputValue || selections.length) && 'msi-value'
  )

  const inputProps = {
    'aria-activedescendant': ariaActiveDescendant,
    'aria-autocomplete': 'list',
    'aria-controls': ariaControls,
    'aria-describedby': ariaDescribedBy,
    'aria-expanded': ariaExpanded,
    'aria-haspopup': ariaHasPopup,
    'aria-label': ariaLabel,
    'aria-owns': ariaOwns,
    autoComplete: 'off',
    'data-is-type-ahead': 'true',
    'data-selected': selections.map(selection => selection.orgId).join('; '),
    onBlur: onBlurInput,
    onFocus: onFocusInput,
    placeholder,
    role: 'combobox'
  }

  return (
    <>
      <div className={containerClasses}>
        <div
          aria-label={inputGroupAriaLabel}
          className='msi-input-container'
          data-testid={`${id}-msi-input-container`}
          id={`${id}-msi-input-container`}
          onMouseDown={onClickInputSection}
          ref={inputContainerRef}
          role='region'
        >
          {selections.length > 0 && (
            <TagSet
              label='Selected institutions'
              offScreen='Remove'
              tags={selections.map(tag => tag.orgName)}
              onClick={(v, e) => {
                e.preventDefault()
                const removedTag = selections.find(tag => tag.orgName === v)
                onRemoveSelection(e, removedTag)
              }}
            />
          )}
          <Input {...inputProps} clearable icon='search' id={id} onChange={onChangeInput} value={inputValue} />
        </div>
      </div>
    </>
  )
}

export default MultiSelectInput
