import { useQuery } from '@apollo/client'
import { Spacer, Spinner, SuccessNotification } from '@cb/apricot-react'
import { dateFormatter } from '@msss/ui-common'
import { Table, useSearchUserContext } from '@msss/ui-components'
import { useEffect, useMemo, useState } from 'react'
import { GET_CRITERIA_PREFS } from '../../../services/graphql/queries'
import { SectionProps } from '../../search/search-form/Search'
import { AvailableTypeValues } from './options'
import SavedCriteriaTable from './SavedCriteriaTable'

export interface ICriteriaPref {
  categoryGroupCd: number
  categoryGroup: string
  createdBy: number
  createdDate: string
  criteriaId: number
  lastModifiedBy: number
  lastModifiedDate: string
  name: string
  uploadFileCount?: number
  uploadFileName?: string
}

const SavedCriteria = () => {
  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Criteria Type', accessor: 'categoryGroup' },
      { Header: 'Modified', accessor: 'lastModifiedDate', sortType: 'date' },
      { Header: 'Criteria Type Code', accessor: 'categoryGroupCd', filter: 'multiSelect' },
      { Header: 'Action', accessor: 'action', disableSort: true }
    ],
    []
  )

  const userContext = useSearchUserContext()

  const [allPrefNames, setAllPrefNames] = useState<Array<string>>([])
  const [success, setSuccess] = useState<boolean>(false)

  const { loading, error, data } = useQuery(GET_CRITERIA_PREFS, {
    variables: { orgId: userContext.user.orgId }
  })

  useEffect(() => {
    if (data) {
      const names = data.getCriteriaPreferences.map(d => d.name)
      setAllPrefNames(names)
    }
  }, [data])

  // if we are loading the list of prefs, we should remove the previous success message
  useEffect(() => {
    if (loading) setSuccess(false)
  }, [loading])

  const checkDupPrefName = (name: string): boolean => {
    return Boolean(allPrefNames.find(n => n.trim().toLocaleLowerCase() === name.trim().toLocaleLowerCase()))
  }

  const hiddenColumns = useMemo(
    () => ['categoryGroupCd', 'uploadFileCount', 'uploadFileName', 'createdDate', 'createdBy', 'lastModifiedBy'],
    []
  )

  const initialTableState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'name'
        }
      ]
    }),
    []
  )

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <p title='My Preferences Error'>There was an error working with My Preferences.</p>
  }

  return (
    <>
      <p>Edit, delete or add saved search criteria sets.</p>
      <Spacer size='32' />
      {success && (
        <>
          <SuccessNotification
            onDismiss={() => {
              setSuccess(false)
            }}
            title='Success!'
            dismissible={true}
          >
            Your criteria set has been created.
          </SuccessNotification>
          <Spacer />
        </>
      )}
      <Table
        columns={columns}
        data={data.getCriteriaPreferences.map(d => ({
          ...d,
          categoryGroupCd: d.categoryGroupCd.toString(),
          categoryGroup: AvailableTypeValues.get(parseInt(d.categoryGroupCd))?.label,
          lastModifiedDate: dateFormatter.format(new Date(d.lastModifiedDate || d.createdDate))
        }))}
        hiddenColumns={hiddenColumns}
        initialTableState={initialTableState}
      >
        <SavedCriteriaTable checkDupPrefName={checkDupPrefName} setSuccess={setSuccess} />
      </Table>
    </>
  )
}

// default component for not implemented saved criteria
export const NotSupported = (props: SectionProps): JSX.Element => {
  return <p>Not supported</p>
}

export default SavedCriteria
