import { TagSet } from '@cb/apricot-react'
import { ScoreBand } from './cb-exams/ScoreBand'
import { ICbExamsRemovable } from './IRemovable'

export const CbExamsRemovable = ({ scoreBands }: ICbExamsRemovable): JSX.Element => {
  return (
    <>
      {scoreBands.map((scoreBand, i) => {
        return (
          <div key={i} className='cb-padding-top-8 cb-padding-bottom-8'>
            <ScoreBand
              lowScore={scoreBand.lowScore}
              highScore={scoreBand.highScore}
              editScore={scoreBand.editScore}
              minScore={scoreBand.minScore}
              maxScore={scoreBand.maxScore}
              minRange={scoreBand.minRange}
              step={scoreBand.step}
              exam={scoreBand.exam}
            />
            <TagSet
              // key={(i + 1) * 100}
              label={`Selected`}
              offScreen='Remove'
              tags={scoreBand.values}
              onClick={(v, e): void => {
                e.preventDefault()
                scoreBand.remove(v)
              }}
            />
          </div>
        )
      })}
    </>
  )
}
