import { ILabeledValue } from '@cb/apricot-react'
import { useEffect, useState } from 'react'
import { ReferenceService } from '../../../../services/rest/ReferenceService'
import {
  pathFileFormat,
  pathPrintFormat,
  pathSortSequence
} from '../../../search/search-form/criteria/geography/Options'
import OrderRunDetails from '../order-details/OrderRunDetails'
import { OrderOverView } from '../order-details/SearchOrderDetails'
import OrderRecipient from '../order-details/view-order-detail/OrderRecipient'

export interface OrderOverViewProps {
  searchOrder: OrderOverView
  savedSearchId: number
}

const NOT_AVAILABLE = 'N/A'

const DownloadsTab = ({ searchOrder, savedSearchId }: OrderOverViewProps) => {
  const [printFormatList, setPrintFormatList] = useState<ILabeledValue[]>([])
  const [fileFormatList, setFileFormatList] = useState<ILabeledValue[]>([])
  const [sortSequenceList, setSortSequenceList] = useState<ILabeledValue[]>([])

  useEffect(() => {
    const setup = async () => {
      const pFormatlist = await ReferenceService.getReferenceData(pathPrintFormat)
      setPrintFormatList(pFormatlist.map((i: any): ILabeledValue => ({ label: i.description, value: i.code })))
      const fFormatlist = await ReferenceService.getReferenceData(pathFileFormat)
      setFileFormatList(fFormatlist.map((i: any): ILabeledValue => ({ label: i.description, value: i.code })))
      const sortingList = await ReferenceService.getReferenceData(pathSortSequence)
      setSortSequenceList(sortingList.map((i: any): ILabeledValue => ({ label: i.description, value: i.code })))
    }
    setup()
  }, [])

  return (
    <>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Runs to Date </div>
        <div className='col-sm-4 cb-margin-top-16'>{searchOrder.totalRuns ? searchOrder.totalRuns : NOT_AVAILABLE}</div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Print Format </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.printFormat && printFormatList.length > 0
            ? printFormatList.filter(nf => nf.value === searchOrder.printFormat)[0].label
            : NOT_AVAILABLE}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>File Recipients</div>
        <div className='col-sm-4 cb-margin-top-16'>{<OrderRecipient orderId={searchOrder.orderId} />}</div>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>File Format </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.fileFormat && fileFormatList.length > 0
            ? fileFormatList.filter(nf => nf.value === searchOrder.fileFormat)[0].label
            : NOT_AVAILABLE}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-2 cb-font-weight-bold cb-margin-top-16'>Sorting Sequence </div>
        <div className='col-sm-4 cb-margin-top-16'>
          {searchOrder.sortSequence && sortSequenceList.length > 0
            ? sortSequenceList.filter(nf => nf.value === searchOrder.sortSequence)[0].label
            : NOT_AVAILABLE}
        </div>
      </div>
      <div className='row'>
        <OrderRunDetails savedSearchId={savedSearchId} accordionSavedSearchId={savedSearchId} />
      </div>
    </>
  )
}

export default DownloadsTab
