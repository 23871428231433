import { useLazyQuery } from '@apollo/client'
import { ILabeledValue, Select, Spinner } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import { useContext, useEffect, useState } from 'react'
import { FeatureAccessContext } from '../../../context/FeatureAccessProvider'
import { GET_CRITERIA_PREFS } from '../../../services/graphql/queries'
import ApplySavedCriteriaModal from '../../search/modals/ApplySavedCriteriaModal'
import { ISavedCriteriaData } from '../../search/search-form/Search'
import { AvailableMySavedCriteriaTypes } from './options'

interface IMyCriteriaListProps {
  type: AvailableMySavedCriteriaTypes
  setSavedCriteriaData: (value: ISavedCriteriaData) => void
  myaccount: boolean
  changed: boolean
}

export interface IMySavedCriteriaModalControl {
  criteriaId?: number
  createdBy?: number
  open: boolean
}

const MyCriteriaList = ({ type, setSavedCriteriaData, myaccount, changed }: IMyCriteriaListProps) => {
  const { user } = useSearchUserContext()
  const { checkAccess } = useContext(FeatureAccessContext)

  const [criteriaId, setCriteriaId] = useState<number>(-1)
  const [openApplySavedCriteriaModal, setOpenApplySavedCriteriaModal] = useState<IMySavedCriteriaModalControl>({ open: false })

  const [getPrefs, prefListResult] = useLazyQuery(GET_CRITERIA_PREFS, {})

  useEffect(() => {
    if (type || type !== -1) {
      getPrefs({
        variables: { orgId: user.orgId }
      })
    }
  }, [type])

  // i need to maintain previous selected in saved critereia drop down until actual apply.  I could have called setsavedcriteriadata
  // direct in the modal since that's where data is being fetched, if I did, i would need to set criteriea id prior
  // calling modal, if user cancel, i would need to remember previous criteria id and set it back.
  // by calling setsavedcriteriadata here, i know that user applied.  I can safely set the criteria id here
  // so dropdown is in sync
  const setApply = (value: ISavedCriteriaData) => {
    setCriteriaId(value.criteriaId)
    setSavedCriteriaData({ ...value, type })
    setOpenApplySavedCriteriaModal({ open: false })
  }

  const onChange = (id: string) => {
    if (id && id.length > 0) {
      const p = prefListResult.data.getCriteriaPreferences.find(r => r.criteriaId === parseInt(id))
      setOpenApplySavedCriteriaModal({ criteriaId: parseInt(id), open: true, createdBy: p.createdBy })
    } else {
      // this allows user to select 'add criteria' again
      setCriteriaId(-1)
    }
  }

  if (prefListResult.loading) {
    return <Spinner />
  }

  if (prefListResult.error) {
    return <p title='Preferences Error'>{`There was an error working with your preferences. ${prefListResult.error}`}</p>
  }

  if (prefListResult.data) {
    const isZipUpload = 'zip_code_upload'
    // filter only for this criteria type
    // set it to the values
    const prefs: Array<ILabeledValue> = prefListResult.data.getCriteriaPreferences
      .filter(p => p.categoryGroupCd === type)
      .map(p => ({
        label: p.name,
        value: p.criteriaId,
        disabled: Boolean(type === AvailableMySavedCriteriaTypes.Geography && !checkAccess(isZipUpload) && p.uploadFileName)
      }))

    return (
      <>
        {openApplySavedCriteriaModal.open && (
          <ApplySavedCriteriaModal
            openModal={openApplySavedCriteriaModal}
            handleApply={setApply}
            handleCancel={setOpenApplySavedCriteriaModal}
            type={type}
            changed={changed}
          />
        )}
        <Select
          ariaLabel='Select my saved criteria from list'
          css=''
          disabled={myaccount || prefs.length === 0}
          labelNoValue={prefs.length > 0 ? 'Add Criteria Set' : 'No saved preferences'}
          name='addCriteriaType'
          onChange={onChange}
          selectId={`${criteriaId}-saved-criteria-options`}
          value={criteriaId.toString()}
          values={prefs}
        />
      </>
    )
  }

  return null
}

export default MyCriteriaList
