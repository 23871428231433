import { Glyph, Input, List, Modal, NakedButton, PrimaryButton, Spacer } from '@cb/apricot-react'
import { useCallback, useEffect, useState } from 'react'
import { IPeerGroup, IPeerInfo, MAX_PEERS_PER_GROUP } from './PeerGroups'
import TypeAhead from './typeahead/Typeahead'
import './NewPGModal.scss'
import { ReferenceService } from '../../../services/rest/ReferenceService'
import refPath from '../../../assets/ref_path.json'
import PeerVolume from './PeerVolume'

enum ModalViews {
  REVIEW,
  CREATE_NAME,
  ORG_SEARCH,
  CONFIRM
}

interface NewPGModalProps {
  groups: IPeerGroup[]
  onSave: (name: string, peers: IPeerInfo[]) => void
  open: boolean
  parentOrgId: number
  setOpen: (isOpen: boolean) => void
  sequence?: number
}

interface ILocalState {
  hasSearched: boolean
  searchAdd: boolean
  searchInit: boolean
  validationMsg: string
  validationType: 'success' | 'error' | 'general' | undefined
}

const NewPGModal = ({ groups, onSave, open, parentOrgId, setOpen, sequence }: NewPGModalProps) => {
  const [allOptions, setAllOptions] = useState<any>([])
  const [modalView, setModalView] = useState<ModalViews>(ModalViews.CREATE_NAME)
  const [name, setName] = useState<string>('')
  const [options, setOptions] = useState<any>(allOptions)
  const [state, setState] = useState<ILocalState>({
    hasSearched: false,
    searchAdd: false,
    searchInit: true,
    validationMsg: '',
    validationType: undefined
  })
  const [tags, setTags] = useState<Array<IPeerInfo>>([])
  const [, setSelectionChange] = useState({}) // to re-render component to update the typeahead menu height

  useEffect(() => {
    if (sequence) {
      const group = groups[sequence - 1]
      setModalView(ModalViews.REVIEW)
      setName(group.name ? group.name : '')
      setTags(group.peers ? group.peers : [])
    }
  }, [groups, sequence, allOptions])

  useEffect(() => {
    const retrieveOptions = async () => {
      type refpathtype = typeof refPath
      const orgInterest = 'orgInterest'
      const path = refPath[orgInterest].xref
      const ref: { diCode: string; orgId: string; orgName: string; city: string; state: string }[] =
        await ReferenceService.getReferenceData(path)
      if (ref.length) {
        const curOptions = ref.map((r, i) => ({
          diCode: r.diCode,
          orgId: r.orgId,
          orgName: r.orgName,
          city: r.city,
          state: r.state,
          uid: `${i}-${r.orgId}`
        }))
        setAllOptions(curOptions)
        setOptions(curOptions)
      }
    }
    retrieveOptions()
  }, [])

  const onTypeAheadOptionClick = useCallback(
    (option: any, options: any[]) => {
      setState(previous => ({ ...previous, searchAdd: options.length < 5 }))
      setTags(options)
      setSelectionChange({})
    },
    [setTags]
  )

  const onTypeAheadOptionRemove = useCallback(
    ({ currentSelections }: any) => {
      setState(previous => ({ ...previous, searchAdd: currentSelections.length < 5 }))
      setTags(currentSelections)
      setSelectionChange({})
    },
    [setTags]
  )

  const onTypeAheadSearch = useCallback(
    (value: string) => {
      const filteredOptions = allOptions.filter(
        (option: any) =>
          (option.orgId !== `${parentOrgId}` && // can't search own institution
            option.orgName.toLowerCase().includes(value.toLowerCase())) ||
          option.diCode.includes(value)
      )
      setState(previous => ({
        ...previous,
        hasSearched: true,
        searchInit: false,
        searchAdd: (previous.hasSearched || Boolean(value)) && tags.length < 5
      }))
      setOptions(filteredOptions)
    },
    [allOptions, tags, parentOrgId]
  )

  const validateName = useCallback(
    (name: string) => {
      setName(name)
      if (name.length > 50) {
        setState(previous => ({
          ...previous,
          validationMsg: 'You reached the maximum number of 50 characters.',
          validationType: 'error'
        }))
      } else if (isDuplicateName(name)) {
        setState(previous => ({
          ...previous,
          validationMsg: 'You must provide a unique name for your peer group.',
          validationType: 'error'
        }))
      } else {
        setState(previous => ({
          ...previous,
          validationMsg: '',
          validationType: 'general'
        }))
      }
    },
    [groups]
  )

  const nextSequence = groups.find((group: IPeerGroup) => !group.name)?.sequence

  const isNameTooLong = name && name.length > 50

  const isDuplicateName = (lname: string) =>
    groups.some(
      // don't flag dups if we are in review and the name that is dupped is the current sequence ie it self
      (group: IPeerGroup) => {
        if (lname.trim() && group.name) {
          if (group.name.toLowerCase() === lname.trim().toLowerCase() && modalView === ModalViews.CREATE_NAME) {
            return true
          }

          if (
            group.name.toLowerCase() === lname.trim().toLowerCase() &&
            modalView === ModalViews.REVIEW &&
            group.sequence !== sequence
          ) {
            return true
          }
        }
        return false
      }
    )

  return (
    <Modal
      actions={
        <div
          className={`display-flex justify-content-${modalView === ModalViews.ORG_SEARCH ? 'between' : 'end'} align-items-center`}
        >
          {modalView === ModalViews.ORG_SEARCH && (
            <p className='cb-gray2-color cb-margin-top-16'>
              {tags.length} of {MAX_PEERS_PER_GROUP} Orgs Added
            </p>
          )}
          <div>
            <NakedButton
              id={modalView === ModalViews.CREATE_NAME ? 'cancel-new-pg' : 'new-pg-go-back'}
              onClick={() => {
                switch (modalView) {
                  case ModalViews.REVIEW:
                  case ModalViews.CREATE_NAME: {
                    setOpen(false)
                    break
                  }
                  case ModalViews.ORG_SEARCH: {
                    setModalView(ModalViews.CREATE_NAME)
                    break
                  }
                  case ModalViews.CONFIRM: {
                    setModalView(ModalViews.ORG_SEARCH)
                    break
                  }
                }
              }}
            >
              {modalView === ModalViews.CREATE_NAME ? 'Cancel' : 'Back'}
            </NakedButton>
            <PrimaryButton
              disabled={
                (modalView === ModalViews.CREATE_NAME && (!name || isDuplicateName(name) || isNameTooLong)) ||
                (modalView === ModalViews.ORG_SEARCH && tags.length < 5)
              }
              id={
                modalView === ModalViews.CREATE_NAME
                  ? 'save-new-pg-name'
                  : modalView === ModalViews.ORG_SEARCH
                  ? 'add-new-pg-peers'
                  : 'confirm-new-pg'
              }
              onClick={() => {
                switch (modalView) {
                  case ModalViews.REVIEW:
                  case ModalViews.CREATE_NAME: {
                    setModalView(ModalViews.ORG_SEARCH)
                    break
                  }
                  case ModalViews.ORG_SEARCH: {
                    setModalView(ModalViews.CONFIRM)
                    break
                  }
                  case ModalViews.CONFIRM: {
                    onSave(name.trim(), tags)
                    setOpen(false)
                    break
                  }
                }
              }}
            >
              {modalView === ModalViews.CREATE_NAME ? 'Save' : modalView === ModalViews.ORG_SEARCH ? 'Review List' : 'Confirm'}
            </PrimaryButton>
          </div>
        </div>
      }
      analytics
      analyticsTitle='new-peer-group'
      className='new-peer-group'
      closeButton={false}
      title={
        modalView === ModalViews.CREATE_NAME
          ? 'Create Name for Peer Group'
          : modalView === ModalViews.REVIEW
          ? 'Update Name for Peer Group'
          : modalView === ModalViews.ORG_SEARCH
          ? 'Organization Selection'
          : 'Final Confirmation'
      }
      withFooter
      withHeader
      open={open}
      onClose={() => {
        setOpen(false)
        setTimeout(() => {
          setModalView(ModalViews.CREATE_NAME)
          setName('')
          setOptions(allOptions)
          setState({
            hasSearched: false,
            searchAdd: false,
            searchInit: true,
            validationMsg: '',
            validationType: undefined
          })
          setTags([])
        }, 350) // delay changes to avoid changing modal apperance while modal is actively closing and allow changes to save
      }}
      disableHeightAdjustment={modalView !== ModalViews.ORG_SEARCH}
    >
      {(modalView === ModalViews.CREATE_NAME || modalView === ModalViews.REVIEW) && (
        <div className='cb-margin-left-24 cb-margin-right-24 cb-margin-bottom-24'>
          <p className='cb-roboto-bold'>Please name your peer group and click Save.</p>
          <Spacer size='24' />
          <Input
            required
            floating={false}
            label='Name'
            labelClassName='cb-roboto-bold'
            onChange={(e: any) => validateName(e.target.value)}
            placeholder={`Enter Peer Group ${modalView === ModalViews.REVIEW ? sequence : nextSequence} Name`}
            validation={state.validationType}
            validationMsg={state.validationMsg}
            value={name}
          />
          <Spacer size='32' />
        </div>
      )}
      {modalView === ModalViews.ORG_SEARCH && (
        <>
          {tags.length >= 5 && (
            <div className='cb-border-bottom cb-padding-left-24 cb-padding-right-24 cb-padding-bottom-24'>
              <PeerVolume orgs={tags.map(o => o.orgId)} />
            </div>
          )}
          <div className='cb-margin-24'>
            {state.searchInit && (
              <p className='cb-roboto-bold'>Search and add institutions to create a peer group for your department.</p>
            )}
            {state.searchAdd && (
              <p className='cb-roboto-bold'>Add 5-20 institutions to create a peer group for your department.</p>
            )}
            <Spacer size='16' />
            <TypeAhead
              id='new-peer-group'
              initializeOnFocus
              initializeOnRender
              inputGroupAriaLabel='type or select an institution'
              label='I want to add...'
              maxOptionsCount={20}
              maxSelectionCount={MAX_PEERS_PER_GROUP}
              minValueLength={3}
              onChange={onTypeAheadSearch}
              onClick={onTypeAheadOptionClick}
              onRemoveTag={onTypeAheadOptionRemove}
              options={options}
              tags={tags}
            />
          </div>
        </>
      )}
      {modalView === ModalViews.CONFIRM && (
        <div className='cb-margin-left-24 cb-margin-right-24 cb-margin-bottom-24'>
          <p className='cb-roboto-bold'>You cannot make any changes to any of your selections once you click Confirm.</p>
          <Spacer size='24' />
          <p>
            You have selected {tags.length} of {MAX_PEERS_PER_GROUP} institutions.
          </p>
          <Spacer size='8' />
          <List flush marker='reset'>
            {tags.map(({ orgId, orgName }: IPeerInfo) => (
              <li className='cb-font-size-small cb-margin-top-16' key={`confirm-${orgId}`}>
                {orgName}
              </li>
            ))}
          </List>
          <Spacer size='24' />
        </div>
      )}
    </Modal>
  )
}

export default NewPGModal
