import { useMutation } from '@apollo/client'
import { NakedButton, Modal, Input, PrimaryButton } from '@cb/apricot-react'
import { useSearchUserContext } from '@msss/ui-components'
import { useState } from 'react'
import { SAVE_UPDATE_SEARCH } from '../../../../services/graphql/mutations'
import { ISavedSearchInput } from '../../../search/search-form/ISearchFaces'
import { SaveSearchValidation } from '../../../search/search-form/saveSearchValidation'
import { ACTIVE_STATUS } from '../../../search/search-form/SearchFormConstants'

interface EditSearchNameModalProps {
  savedSearchId: number
  searchName: string
  namesAvailable: number
  description: string
  uploadFileCount: number
  uploadFileName: string
  updateAfterNameChange?: (savedSearchId: number, searchName: string) => void
  buttonDescribedBy: string
  buttonLabel?: string
}

const EditSaveSearchName = (props: EditSearchNameModalProps) => {
  const {
    buttonDescribedBy,
    savedSearchId,
    searchName,
    uploadFileCount,
    uploadFileName,
    description,
    namesAvailable,
    updateAfterNameChange
  } = props
  const theButtonLabel = props.buttonLabel ? props.buttonLabel : 'Edit Search Name'

  interface ILocalState {
    name: string
    validationMsg: string
    validationType: 'success' | 'error' | 'general' | undefined
  }

  const [state, setState] = useState<ILocalState>({ name: searchName, validationMsg: '', validationType: undefined })
  const { user } = useSearchUserContext()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const validateSearchName = (name: string) => {
    const { errorMessage } = SaveSearchValidation(name)
    errorMessage
      ? setState({ name, validationMsg: errorMessage, validationType: 'error' })
      : setState({ name, validationMsg: '', validationType: 'general' })
  }

  const afterSave = (data: any) => {
    const error = data.saveSavedSearch.error
    if (error) {
      let validationMsg
      switch (error) {
        case 'DuplicateSearchName':
          validationMsg = 'The Search name you entered already exists'
          break
        // TODO: What other validation errors would we have?  Add handlers for future
        // error codes as needed....
        default:
          validationMsg = 'There was an error. Please try again later'
      }
      // we only have duplicate search name for now...
      setState({ ...state, validationMsg, validationType: 'error' })
    } else {
      if (updateAfterNameChange) {
        updateAfterNameChange(data.saveSavedSearch.savedSearchId, state.name)
      }
      reset()
      setOpenModal(false)
    }
  }

  const [saveSearch, { loading, error, data, reset }] = useMutation(SAVE_UPDATE_SEARCH, {
    onCompleted: data => afterSave(data)
  })

  const handleSaveSearch = () => {
    const { errorMessage } = SaveSearchValidation(state.name.trim())
    if (errorMessage) {
      setState({ ...state, validationMsg: errorMessage, validationType: 'error' })
    } else {
      const input: ISavedSearchInput = {
        savedSearchId: savedSearchId,
        name: state.name.trim(),
        portfolioStatus: ACTIVE_STATUS,
        orgId: user.orgId,
        namesAvailable: namesAvailable,
        description: description,
        uploadFileName: uploadFileName ? uploadFileName : '',
        uploadFileCount: uploadFileCount ? uploadFileCount : 0,
        lastModifiedBy: user.userId
      }

      saveSearch({ variables: { savedSearch: input } })
    }
  }

  if (error) {
    setState({ ...state, validationMsg: 'System has encountered an error', validationType: 'error' })
    reset()
  }

  return (
    <>
      {openModal && <Modal
        actions={
          <div>
            <NakedButton
              disabled={state.validationType === 'error'}
              loading={loading}
              id='ms-order-detail-editname-cancel-btn'
              onClick={() => {
                setState({ ...state, validationType: undefined })
                setOpenModal(false)
              }}
            >
              Cancel
            </NakedButton>
            <PrimaryButton
              disabled={state.validationType === 'error'}
              loading={loading}
              id='ms-order-detail-editname-btn'
              onClick={() => {
                handleSaveSearch()
              }}
            >
              Apply
            </PrimaryButton>
          </div>
        }
        analytics
        analyticsTitle='ms-order-detail-editname'
        closeButton={false}
        closeButtonLabel={'Cancel'}
        customAttributes={{}}
        disableHeightAdjustment={true}
        headerId={`ms-order-detail-edit-name-modal-header-${savedSearchId}`}
        modalId={`ms-order-detail-edit-name-modal-${savedSearchId}`}
        onClose={() => {
          setState({ ...state, validationType: undefined })
          setOpenModal(false)
        }}
        onShow={() => {
          //setSaveSearchModal(0)
          setState({ ...state, validationType: undefined })
        }}
        open={openModal}
        title={<span className='cb-font-weight-bold ms-modal-header-fontsize'>Edit Search Name</span>}
        withFooter
        withHeader
      >
        <div />
        <Input
          clearable
          value={state.name}
          floating={false}
          id='ms-order-detail-editname-input'
          label='Enter New Search Name'
          labelClassName='cb-margin-top-12'
          onChange={e => {
            validateSearchName(e.target.value)
          }}
          validation={state.validationType}
          validationMsg={state.validationMsg}
        />
      </Modal>}
      <NakedButton
        aria-describedby={`${buttonDescribedBy}`}
        id={`ms-order-detail-editname-${savedSearchId}`}
        onClick={function noRefCheck() {
          setOpenModal(true)
        }}
      >
        {theButtonLabel}
      </NakedButton>
    </>
  )
}

export default EditSaveSearchName
