import { SecondaryButton, Spacer, Spinner } from '@cb/apricot-react'
import { dateFormatter, ensure, numberFormatter, Roles } from '@msss/ui-common'
import { TooltipHeader, useSearchUserContext } from '@msss/ui-components'
import React, { useContext, useEffect, useState } from 'react'
import { ConfigService } from '../../../services/rest/ConfigService'
import { ServiceUtils } from '../../../services/ServiceUtils'
import { ReferenceService } from '../../../services/rest/ReferenceService'
import { IFeatureInfo } from '../../../interfaces/reference/IReference'
import { FeatureAccessContext } from '../../../context/FeatureAccessProvider'
import SubscriptionTableALaCartes from './SubscriptionTableALaCartes'
import { FlagpolePublic } from '../../../services/rest/FlagpolePublic'
import Quotes from './quotes/Quotes'
import SubscriptionTableTiers from './SubscriptionTableTiers'
import { useNavigate } from 'react-router-dom'
import { TABS } from '../MyAccount'

const SEARCH_UI_CONFIG = 'msss.cbsearch.ui-config'

const PlansFeatuesTransactional = () => {
  const { user } = useSearchUserContext()
  const { getFeatureInfo, loadingFeatures } = useContext(FeatureAccessContext)

  const [subscriptionPricingLink, setSubscriptionPricingLink] = useState<string | undefined>()
  const [subscriptionEmail, setSubscriptionEmail] = useState<string | undefined>()
  const [subscriptionPhone, setSubscriptionPhone] = useState<string | undefined>()
  const [featureInfo, setFeatureInfo] = useState<IFeatureInfo | undefined>()
  const [roleList, setRoleList] = useState<string | undefined>()
  const [roleMap, setRoleMap] = useState<Map<number, string>>()
  const [subscriptionNamesUsed, setSubscriptionNamesUsed] = useState<number>(-1)
  const navigate = useNavigate()

  const setup = async () => {
    const headers = await ServiceUtils.getHeaders()
    const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
    const subscriptionVolume = await FlagpolePublic.getSubscriptionVolume(authOrgId!, user.orgId!)
    if (subscriptionVolume) {
      setSubscriptionNamesUsed(subscriptionVolume.licensedVolume ?? -1)
    }
    try {
      const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
      const { error, configValue } = await ConfigService.getConfig(authOrgId!, headers, SEARCH_UI_CONFIG)
      if (configValue) {
        setSubscriptionPricingLink(
          configValue.subscriptionPricingLink || 'https://cbsearch.collegeboard.org/media/pdf/2023-24-Access-Plan-Guide.pdf'
        )
        if (configValue.subscriptionEmail) {
          setSubscriptionEmail(configValue.subscriptionEmail)
        }
        if (configValue.subscriptionPhone) {
          setSubscriptionPhone(configValue.subscriptionPhone)
        }
      }
    } catch (e) {
      console.log(`failed to load search config ${SEARCH_UI_CONFIG}`)
    }
    try {
      const roleRef = await ReferenceService.getReferenceData('Role')
      if (roleRef) {
        if (user.roles) {
          const roleCodes: number[] = user.roles.map(r => r.role)
          const roleCodeDescriptionMap: Map<number, string> = new Map()

          const roleNames: string = roleRef
            .filter(item => roleCodes.includes(item.code))
            .map(item => item.description)
            .join(', ')
          roleRef
            .filter(item => roleCodes.includes(item.code))
            .map(item => {
              roleCodeDescriptionMap.set(item.code, item.description)
            })

          setRoleList(roleNames)
          setRoleMap(roleCodeDescriptionMap)
        }
      }
    } catch (e) {
      console.log('exception while getting roles', e)
    }
  }

  const isEpsAvailable = (): boolean => {
    let userHasEPSRole = false
    Roles.EPS.map(item => {
      if (user.roles?.map(r => r.role).includes(item)) {
        userHasEPSRole = true
      }
    })
    return userHasEPSRole && featureInfo?.isEnrollmentPlanningFeatureActive
  }

  const isSASAvailable = (): boolean => {
    let userHasSASRole = false
    Roles.SAS.map(item => {
      if (user.roles?.map(r => r.role).includes(item)) {
        userHasSASRole = true
      }
    })
    return userHasSASRole && featureInfo?.isSegmentAnalysisFeatureActive
  }

  useEffect(() => {
    if (loadingFeatures === false) {
      setup()
      const fi = getFeatureInfo(false)
      setFeatureInfo(fi)
    }
  }, [loadingFeatures])

  return (
    <>
      <div className='cb-box-shadow-bottom cb-margin-bottom-16 cb-blue5-bg cb-padding-32 cb-border-radius-8'>
        <div className='container cb-white-color'>
          <div className='row'>
            <div className='col-xs-10'>
              <h2 className='cb-h5'>DISCOVER A MORE FLEXIBLE, MORE POWERFUL SEARCH</h2>
              <Spacer size='8' />
              <p>
                Our customizable subscription plans allow you to create a Search solution that’s tailored to your needs and your
                budget. It's not too late to get started—explore now and start making the most of Search.
              </p>
            </div>
            <div className='col-xs-2 cb-align-right'>
              <SecondaryButton
                className='cb-margin-right-16'
                light
                onClick={() => navigate(`/my-account/${TABS.SelfService}`)}
                id='view-plans-btn'
              >
                View Plans
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
      <Spacer size='32' />
      <div className='cb-border-radius-8'>
        <div className='cb-padding-32' style={{ backgroundColor: '#f5f7fc' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-9'>
                <h2 className='cb-h5'>OVERVIEW OF YOUR SUBSCRIPTIONS</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <OverviewStudentServiceCountsSection namesOrdered={subscriptionNamesUsed} />
        </div>
        {featureInfo && featureInfo.alacarteFeatureTiers && featureInfo.alacarteFeatureTiers.length > 0 && (
          <div className='cb-padding-32 cb-margin-bottom-16'>
            <SubscriptionTableALaCartes data={featureInfo.alacarteFeatureTiers} />
          </div>
        )}
      </div>

      <div className='cb-padding-top-24 cb-padding-left-16 cb-padding-right-32'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-9'>
              <h2 className='cb-h5'>FUTURE SUBSCRIPTIONS</h2>
            </div>
          </div>
        </div>
      </div>
      {(featureInfo?.futureFeatureTiers == undefined || featureInfo?.futureFeatureTiers.length == 0) &&
        (featureInfo?.futureAlacarteFeatureTiers == undefined || featureInfo.futureAlacarteFeatureTiers.length == 0) && (
          <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
            <div className='row'>
              <div className='col-xs-12'>You have no future subscriptions or a la carte features created</div>
            </div>
          </div>
        )}

      {featureInfo && featureInfo.futureFeatureTiers && featureInfo.futureFeatureTiers.length > 0 && (
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <SubscriptionTableTiers data={featureInfo.futureFeatureTiers} />
        </div>
      )}

      {featureInfo && featureInfo.futureAlacarteFeatureTiers && featureInfo.futureAlacarteFeatureTiers.length > 0 && (
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <SubscriptionTableALaCartes data={featureInfo.futureAlacarteFeatureTiers} />
        </div>
      )}
      {/* Quotes Section */}
      <div className='cb-padding-top-24 cb-padding-left-16 cb-padding-right-32'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-9'>
              <h2 className='cb-h5'>SAVED PRICE QUOTES</h2>
            </div>
          </div>
        </div>
      </div>
      <Quotes />

      <div className='cb-padding-top-24 cb-padding-left-16 cb-padding-right-32'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-9'>
              <h2 className='cb-h5'>PREVIOUS YEAR'S PLANS</h2>
            </div>
          </div>
        </div>
      </div>
      {(featureInfo?.priorFeatureTiers == undefined || featureInfo?.priorFeatureTiers.length == 0) &&
        (featureInfo?.priorAlacarteFeatureTiers == undefined || featureInfo.priorAlacarteFeatureTiers.length == 0) && (
          <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
            <div className='row'>
              <div className='col-xs-12'>You have no previous year subscriptions or a la carte features created</div>
            </div>
          </div>
        )}

      {featureInfo && featureInfo.priorFeatureTiers && featureInfo.priorFeatureTiers.length > 0 && (
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <SubscriptionTableTiers data={featureInfo.priorFeatureTiers} />
        </div>
      )}

      {featureInfo && featureInfo.priorAlacarteFeatureTiers && featureInfo.priorAlacarteFeatureTiers.length > 0 && (
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <SubscriptionTableALaCartes data={featureInfo.priorAlacarteFeatureTiers} />
        </div>
      )}
      <div className='cb-border-radius-8'>
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16' style={{ backgroundColor: '#f5f7fc' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-9'>
                <h2 className='cb-h5'>CONTACT US</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-9'>
                <span>
                  For questions about subscriptions and how to upgrade please contact{' '}
                  <a
                    className='cb-blue1-color'
                    href={`mailto:${subscriptionEmail || 'CollegeBoardSearch@collegeboard.org'}`}
                    target='_top'
                  >
                    {subscriptionEmail || 'CollegeBoardSearch@collegeboard.org'}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface OverviewStudentServiceCountsSectionProps {
  namesOrdered: number
}
const OverviewStudentServiceCountsSection = ({ namesOrdered }: OverviewStudentServiceCountsSectionProps): JSX.Element => {
  return (
    <React.Fragment>
      <>
        {/* <div className='cb-box-shadow-bottom cb-padding-left-16 cb-padding-bottom-16 cb-margin-bottom-16'> */}
        <div className='cb-box-shadow-bottom cb-padding-bottom-16 cb-margin-bottom-16'>
          {/* <div className='container'> */}
          <div className='row'>
            <div className='col-xs-4'>
              <div className='container'>
                <div className='row'>
                  <TooltipHeader
                    header='Total Number of Records Remaining'
                    id='total-names-remaining-tip'
                    tooltipMsg='This count represents the number of records you have available to license, excluding records you have already licensed under your current subscription(s). This count also includes any adjustments that have been applied to your active subscription(s) for the current Search year.'
                  />
                </div>
                <div className='row'>
                  <span>0</span>
                </div>
              </div>
            </div>
            <div className='col-xs-4'>
              <div className='container'>
                <div className='row'>
                  <TooltipHeader
                    header='Total Number of Records Included'
                    id='total-names-include-tip'
                    tooltipMsg='This count represents the total number of records included in your current subscription(s).'
                  />
                </div>
                <div className='row'>
                  <span>N/A</span>
                </div>
                <Spacer size='16' />
                <div className='row'>
                  <TooltipHeader
                    header='Total Number of Records Licensed'
                    id='licensed-count-tip'
                    tooltipMsg='This count represents the number of records you have already licensed under your current subscription(s).'
                  />
                </div>
                <div className='row'>
                  <span>N/A</span>
                </div>
              </div>
            </div>
            <div className='col-xs-4'>
              <div className='container'>
                <div className='row'>
                  <TooltipHeader
                    header='Account Adjustment'
                    id='account-adjustment-tip'
                    tooltipMsg='Account Adjustments are the total number of records that have been credited towards your current subscription(s).'
                  />
                </div>
                <div className='row'>
                  <span>N/A</span>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className='container'>
          <div className='row'>
            <TooltipHeader
              header='Overview of all Featues Included in Your Account'
              id='overview-feature-tip'
              tooltipMsg='This is a list of features that are included in your current subscription(s) plus any features you have purchased a la carte.'
            />
          </div>
          <div className='row'>
            <span>N/A</span>
          </div>
        </div>
      </>
    </React.Fragment>
  )
}

export default PlansFeatuesTransactional
