import { getCMSPanelByFieldId, sanitizeHtml } from '@msss/ui-common'
import cn from 'classnames'

const { DRUPAL_API_ENDPOINT } = process.env

interface ResourcesProps {
  content: Record<string, any>
}

const Resources = ({ content }: ResourcesProps) => {
  const resources = getCMSPanelByFieldId(content.field_panel, 'MarketingResourcePanel')
  const resource1 = resources?.field_resource_component[0]
  const resource2 = resources?.field_resource_component[1]

  return (
    <>
      <div className='col-xs-12 cb-align-center cb-padding-bottom-32'>
        <h2 className='cb-h4 cb-margin-bottom-8'>{sanitizeHtml(resources.title)}</h2>
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(resources.field_overview?.processed) }} />
      </div>
      <div className='col-xs-12'>
        <div className='cb-card-list-horizontal-32 justify-content-around'>
          <MediaCard content={resource1} id='lilp-resource-1' isVideo={sanitizeHtml(resource1.type).includes('video')} />
          <MediaCard content={resource2} id='lilp-resource-2' isVideo={sanitizeHtml(resource2.type).includes('video')} />
        </div>
      </div>
    </>
  )
}

export default Resources

interface MediaCardProps {
  content: Record<string, any>
  id: string
  isVideo: boolean
}

const MediaCard = ({ content, id, isVideo }: MediaCardProps) => {
  const { title, field_external_description, field_external_link, field_video_link, field_thumbnail_media } = content
  const { field_media_image } = field_thumbnail_media || {}

  return (
    <div className='cb-card cb-card-title-link cb-card-media cb-no-padding-top'>
      <div className='cb-card-media-content'>
        <div
          className={cn(
            'cb-card-media-img',
            isVideo && 'cb-video-block',
            !isVideo && 'cb-border-radius-top-left-16 cb-border-radius-top-right-16'
          )}
          style={
            field_media_image?.uri?.url
              ? { backgroundImage: `url(${DRUPAL_API_ENDPOINT}${sanitizeHtml(field_media_image.uri.url)})` }
              : undefined
          }
        >
          {isVideo && (
            <div className='cb-video-effect cb-black1-bg cb-opacity-7 cb-border-radius-top-left-16 cb-border-radius-top-right-16'>
              <span className='cb-icon cb-play-video cb-white-color' />
            </div>
          )}
        </div>
      </div>
      <div className='cb-card-content'>
        <a
          aria-describedby={`media-card-desc-${id}`}
          data-cbtrack-promo
          href={sanitizeHtml(isVideo ? field_video_link : field_external_link?.url)}
          rel='noopener noreferrer'
          target='_blank'
        >
          <h2 className='cb-card-title' id={`media-card-title-${id}`}>
            {sanitizeHtml(title)}
          </h2>
        </a>
        <p className='cb-card-desc' id={`media-card-desc-${id}`}>
          {sanitizeHtml(field_external_description)}
        </p>
        <div className='cb-card-action'>
          <span aria-hidden className='cb-icon cb-east' />
        </div>
      </div>
    </div>
  )
}
