import gql from 'graphql-tag'

export const QUERY = gql`
  query ($orgId: Int!) {
    getRecommendations(orgId: $orgId) {
      savedSearch {
        name
        savedSearchId
        order {
          orderRuns {
            namesReported
            processDate
            orderRun
            orderRunType
          }
        }
      }
      campaignId
      campaignDate
      newAvailable
      orderId
      cohortYears
    }
  }
`
