import React from 'react'
import { MsssInfo } from './MsssDialog'

function NoAssociation() {
  return (
    <MsssInfo
      title='It looks like you may be in the wrong place.'
      message='You have a College Board professional account but you have not been granted access to College Board Search. If you
              believe you should have access, please reach out to the College Board Search representative at your institution to
              request access.'
    />
  )
}

export default NoAssociation
