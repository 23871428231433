import { useQuery } from '@apollo/client'
import { Button, ButtonRow, NakedButton, PrimaryButton, Spacer, Spinner } from '@cb/apricot-react'
import { UNLIMITED_NAMES, dateFormatter, numberFormatter, scrollFocus } from '@msss/ui-common'
import { ExpandableTableData, Loader, Table, useSearchUserContext, useTableContext } from '@msss/ui-components'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { QUOTES_OF_ORG } from '../../../../services/graphql/queries'
import { useReactToPrint } from 'react-to-print'
import { IQuote } from './Interfaces'
import QuoteBasicInfo from './QuoteBasicInfo'
import QuoteFeatures from './QuoteFeatures'
import CBLogo from '../../../msof/common/CBLogo'
import { FeatureAccessContext } from '../../../../context/FeatureAccessProvider'

const Quotes = () => {
  const { user } = useSearchUserContext()
  const { searchYear } = useContext(FeatureAccessContext)
  const [quotes, setQuotes] = useState<Array<IQuote>>([])

  const columns = useMemo(
    () => [
      { Header: 'Quote ID', accessor: 'quoteId' },
      { Header: 'Quote', accessor: 'name' },
      {
        Header: 'Date',
        accessor: 'startDate',
        sortType: 'dateRange',
        dateRangeEndAccessor: 'endDate',
        Cell: ({ row }: { row: { original: IQuote } }) => (
          <>
            {dateFormatter.format(new Date(row.original.startDate))} - {dateFormatter.format(new Date(row.original.endDate))}
          </>
        )
      },
      {
        Header: 'Total Number of Records Included',
        accessor: 'totalRecords',
        Cell: ({ row }: { row: { original: IQuote } }) => (
          <>{row.original.totalRecords >= UNLIMITED_NAMES ? 'Unlimited' : numberFormatter(row.original.totalRecords ?? 0)}</>
        )
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }: { row: { original: IQuote } }) => <>{numberFormatter(row.original.price ?? 0, { isCurrency: true })}</>
      },
      { Header: 'Search Year', accessor: 'searchYear' }
    ],
    []
  )

  const quotesQuery = useQuery(QUOTES_OF_ORG, {
    variables: { orgId: user.orgId },
    fetchPolicy: 'network-only' // Doesn't check cache before making a network request
  })

  // once quotes query runs set the IQuote array
  useEffect(() => {
    if (!quotesQuery.loading && !quotesQuery.error) {
      console.log('..quotesQuery.data?.getQuotes..', quotesQuery.data?.getQuotes)
      if (quotesQuery.data?.getQuotes && searchYear > 0) {
        const quotesData =
          quotesQuery.data?.getQuotes
            .filter(item => item?.subscriptionPurchaseId === null && item.searchYear >= (searchYear ?? new Date().getFullYear))
            .map(item => {
              return {
                quoteId: item.quoteId,
                name: item.name,
                price: item.price,
                taxAmount: item.taxAmount,
                totalRecords: item.totalRecords,
                startDate: item.startDate,
                endDate: item.endDate,
                searchYear: item.searchYear
              }
            }) || []
        setQuotes(quotesData)
      } else {
        setQuotes([])
      }
    } else {
      console.log(quotesQuery.error)
    }
  }, [quotesQuery.data, quotesQuery.loading, quotesQuery.error, searchYear])

  // <Table /> must be in a parent to setup the Provider

  if (quotesQuery.loading) {
    return <Loader label='loading saved quotes...' />
  }

  return (
    <>
      {quotes && quotes.length > 0 ? (
        <Table columns={columns} data={quotes} hiddenColumns={['quoteId', 'searchYear']}>
          <QuotesTable />
        </Table>
      ) : (
        <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
          <div className='row'>
            <div className='col-xs-12'>No saved price quotes found.</div>
          </div>
        </div>
      )}
    </>
  )
}

export default Quotes

const QuotesTable = () => {
  const { handleExpandedRow } = useTableContext()
  const navigate = useNavigate()
  const getBtnAriaLabel = useCallback(({ row }) => row.original.name, [])

  const componentRef = React.useRef(null)

  const handleDownloadPdf = useReactToPrint({
    content: () => componentRef.current
  })

  const handleOpenQuote = quoteId => {
    scrollFocus(null, 'main-content')
    navigate(`/my-account/self-service/quote?quoteId=${quoteId}`)
  }

  const renderExpandedRow = useCallback(({ row, rowIndex }) => {
    return (
      // colspan should be 1 more than the number of cells/columns to accomodate the expand icon
      <td colSpan={5}>
        <div className='container'>
          <div aria-label='Quote details' role='region'>
            <div ref={componentRef} id='my-account-saved-quote-download-component'>
              <div className='container'>
                <div id='ms-content'>
                  <CBLogo />
                  <div className='row cb-border-bottom cb-margin-bottom-16'>
                    <Spacer size='48' />
                  </div>
                </div>
                {/* quote name and dates */}
                <QuoteBasicInfo quoteInfo={row.original} />

                <Spacer size='32' />

                {/* quote features  */}
                <QuoteFeatures quoteInfo={row.original} />

                <Spacer size='32' />
              </div>
            </div>

            <ButtonRow align='end'>
              <NakedButton onClick={() => handleExpandedRow(rowIndex)}>Close</NakedButton>
              <Button className='cb-margin-left-16' onClick={handleDownloadPdf}>
                Download
              </Button>
              <PrimaryButton className='cb-margin-left-16' onClick={() => handleOpenQuote(row.original.quoteId)}>
                Open Quote
              </PrimaryButton>
            </ButtonRow>
          </div>
        </div>
      </td>
    )
  }, [])

  return (
    <div className='cb-box-shadow-bottom cb-padding-32 cb-margin-bottom-16'>
      <div className='row'>
        <div className='col-xs-12'>
          <ExpandableTableData getBtnAriaLabel={getBtnAriaLabel} id='quotes-table' renderExpandedRow={renderExpandedRow} />
        </div>
      </div>
    </div>
  )
}
