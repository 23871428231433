import { FeatureKeys } from '@msss/ui-common'
import { useSearchUserContext } from '@msss/ui-components'
import { isAfter, isBefore } from 'date-fns'
import { createContext, FC, useCallback, useEffect, useState } from 'react'
import { getHeaders, FlagpoleApi } from './api'
import {
  IFeatureAccessContext,
  IFeatureState,
  IFeature,
  IFeatureInfo,
  IFeatureTier,
  IAccountSubscriptionType,
  IFeatureTierFeature
} from '../interfaces/reference/IReference'
import { ReferenceService } from '../services/rest/ReferenceService'
import { pathFeatureTiersAllRef, pathFeatureTiersRef } from '../components/search/search-form/criteria/geography/Options'

export const FeatureAccessContext = createContext<IFeatureAccessContext>({} as IFeatureAccessContext)

const FeatureAccessProvider: FC = ({ children }) => {
  const { user } = useSearchUserContext()
  const [featureAccess, setFeatureAccess] = useState<IFeatureState>()
  const [featureUsage] = useState<Set<string>>(new Set())
  const [flagpoleError, setFlagpoleError] = useState<boolean>(false)
  const [loadingFeatures, setLoadingFeatures] = useState<boolean>(false)
  const [prevOrgId, setPrevOrgId] = useState<number>()
  const [transactional, setTransactional] = useState<boolean>(true)
  const [featureInfo, setFeatureInfo] = useState<IFeatureInfo>()
  const [searchYearStartDate, setSearchYearStartDate] = useState<string>('')
  const [searchYearEndDate, setSearchYearEndDate] = useState<string>('')
  const [searchYear, setSearchYear] = useState<number>(new Date().getFullYear())

  const headers = getHeaders()

  const LOGIN_AS_BLOCKED_FEATURES = ['living_record']
  const CONNECTION_AUDIENCE_KEY_SUFFIX = 'Connections_Audiences'
  const CONNECTION_AUDIENCE_NAME_SUFFIX = 'Connections Audiences'

  const getFeatureTierFeatureNames = (featureTierName: string, featureTierRef?: any) => {
    const includedFeatureNames: string[] = []
    try {
      type featureTierType = typeof featureTierRef
      if (featureTierRef && featureTierRef[featureTierName as keyof featureTierType]) {
        const theFeatureTierRef = featureTierRef[featureTierName as keyof featureTierType]
        type theFeatureTierType = typeof theFeatureTierRef
        const featureList: IFeatureTierFeature[] = theFeatureTierRef['features' as keyof featureTierType]
        featureList.map(feature => {
          includedFeatureNames.push(feature.name)
        })
      }
    } catch (e) {
      console.log('problem deriving feature in feature tier')
    }
    return includedFeatureNames
  }

  const getFeatureTierConnectionsCount = (featureTierName: string, featureTierRef: any) => {
    const counts: number[] = [0]
    try {
      type featureTierType = typeof featureTierRef
      if (featureTierRef && featureTierRef[featureTierName as keyof featureTierType]) {
        const theFeatureTierRef = featureTierRef[featureTierName as keyof featureTierType]
        type theFeatureTierType = typeof theFeatureTierRef
        const featureList: IFeatureTierFeature[] = theFeatureTierRef['features' as keyof featureTierType]
        featureList
          .filter(feature => feature.name.indexOf(CONNECTION_AUDIENCE_NAME_SUFFIX) != -1)
          .map(feature => {
            counts.push(Number(feature.name.split(' ')[0]))
          })
      }
    } catch (e) {
      console.log('problem deriving feature in feature tier')
    }
    return counts.reduce((total, item) => total + item)
  }

  const getFutureFeatureTierFeatureNames = (featureTierName: string, year: string, futureFeatureTierRef?: any) => {
    type futureFeatureTierType = typeof futureFeatureTierRef
    const featureTierRef = futureFeatureTierRef[year as keyof futureFeatureTierType]
    const includedFeatureNames: string[] = []
    try {
      type featureTierType = typeof featureTierRef
      if (featureTierRef && featureTierRef[featureTierName as keyof featureTierType]) {
        const theFeatureTierRef = featureTierRef[featureTierName as keyof featureTierType]
        type theFeatureTierType = typeof theFeatureTierRef
        const featureList: IFeatureTierFeature[] = theFeatureTierRef['features' as keyof featureTierType]
        featureList.map(feature => {
          includedFeatureNames.push(feature.name)
        })
      }
    } catch (e) {
      console.log('problem deriving feature in feature tier')
    }
    return includedFeatureNames
  }

  const getPriorFeatureTierFeatureNames = (featureTierName: string, year, priorFeatureTierRef?: any) => {
    type priorFeatureTierType = typeof priorFeatureTierRef
    const featureTierRef = priorFeatureTierRef[year as keyof priorFeatureTierType]
    const includedFeatureNames: string[] = []
    try {
      type featureTierType = typeof priorFeatureTierRef
      if (featureTierRef && featureTierRef[featureTierName as keyof featureTierType]) {
        const theFeatureTierRef = featureTierRef[featureTierName as keyof featureTierType]
        const featureList: IFeatureTierFeature[] = theFeatureTierRef['features' as keyof featureTierType]
        featureList.map(feature => {
          includedFeatureNames.push(feature.name)
        })
      }
    } catch (e) {
      console.log('problem deriving feature in feature tier')
    }
    return includedFeatureNames
  }

  const getFeatureAccess = useCallback(async () => {
    setLoadingFeatures(true)

    const userContext = { orgId: user.parentOrgId }
    if (user.orgId !== user.parentOrgId) {
      userContext['deptId'] = user.orgId
    }

    let response
    try {
      const authOrgId = user.loginAsOrgId ? user.loginAsOrgId : user.orgId
      response = await FlagpoleApi.post(`/features?orgId=${authOrgId}`, { userContext }, { headers })
      setFlagpoleError(false)
    } catch (e) {
      response = { data: undefined }
      setFlagpoleError(true)
    }
    const featureTiersRef = await ReferenceService.getReferenceData(pathFeatureTiersRef)
    const featureTiersAllRef = await ReferenceService.getReferenceData(pathFeatureTiersAllRef)
    const accessDoc = response.data
    console.log('..accessDoc..', accessDoc)

    if (accessDoc) {
      const {
        startDate,
        endDate,
        searchYearStartDate,
        searchYearEndDate,
        features,
        featureTiers,
        futureAccess,
        priorAccess,
        transactional,
        searchYear
      } = accessDoc
      const featureMap = new Map<string, IFeature>()
      const featureInfoTemp: IFeatureInfo = {
        subscriptionType: IAccountSubscriptionType.UNKNOWN,
        startDate: startDate,
        endDate: endDate,
        featureNames: [],
        featureKeys: [],
        isEnrollmentPlanningFeatureActive: false,
        enrollmentPlanningFeatureEndDate: undefined,
        isSegmentAnalysisFeatureActive: false,
        segmentAnalysisFeatureEndDate: undefined,
        alacarteFeatureNames: [],
        alacarteFeatureKeys: [],
        featureTiers: [],
        featureTierNames: [],
        futureFeatureTierNames: [],
        alacarteFeatureTiers: [],
        futureFeatureTiers: [],
        futureAlacarteFeatureTiers: [],
        futureAlacarteFeatureNames: [],
        futureAlacarteFeatureKeys: [],
        futureFeatureNames: [],
        futureFeatureKeys: [],
        priorFeatureTiers: [],
        priorFeatureTierNames: [],
        priorAlacarteFeatureTiers: [],
        priorAlacarteFeatureNames: [],
        priorAlacarteFeatureKeys: [],
        priorFeatureNames: [],
        priorFeatureKeys: [],
        totalConnectionsAudiences: 0,
        featuresTotalConnectionAudience: 0
      }
      const connectionCounts: number[] = [0]
      const featureConnectionCounts: number[] = [0]
      features.forEach((feature: IFeature) => {
        featureMap.set(feature.key, feature)

        if (FeatureKeys.EPS.key === feature.key) {
          featureInfoTemp.isEnrollmentPlanningFeatureActive = true
        }
        if (FeatureKeys.SAS.key === feature.key) {
          featureInfoTemp.isSegmentAnalysisFeatureActive = true
        }
        if (feature.alacarte === true) {
          featureInfoTemp.alacarteFeatureNames.push(feature.name)
          featureInfoTemp.alacarteFeatureKeys.push(feature.key)
        } else {
          if (feature.key.indexOf(CONNECTION_AUDIENCE_KEY_SUFFIX) != -1) {
            if (!featureInfoTemp.featureKeys.includes(CONNECTION_AUDIENCE_KEY_SUFFIX)) {
              featureInfoTemp.featureNames.push(CONNECTION_AUDIENCE_NAME_SUFFIX)
              featureInfoTemp.featureKeys.push(CONNECTION_AUDIENCE_KEY_SUFFIX)
            }
          } else {
            featureInfoTemp.featureNames.push(feature.name)
            featureInfoTemp.featureKeys.push(feature.key)
          }
        }
      })
      featureTiers.forEach((featureTier: any | IFeatureTier) => {
        if (typeof featureTier === 'string') {
          return
        }
        if (featureTier.feature?.key === FeatureKeys.EPS.key) {
          featureInfoTemp.isEnrollmentPlanningFeatureActive = true
          featureInfoTemp.enrollmentPlanningFeatureEndDate = featureTier.endDate
        }
        if (featureTier.feature?.key === FeatureKeys.SAS.key) {
          featureInfoTemp.isSegmentAnalysisFeatureActive = true
          featureInfoTemp.segmentAnalysisFeatureEndDate = featureTier.endDate
        }
        if (featureTier.alacarte === true) {
          if (featureTier.quantity === undefined) {
            featureTier.quantity = '1'
          } else if (featureTier.name == CONNECTION_AUDIENCE_NAME_SUFFIX) {
            connectionCounts.push(Number(featureTier.quantity))
          }
          featureInfoTemp.alacarteFeatureTiers.push(featureTier)
        } else {
          featureInfoTemp.featureTierNames.push(featureTier.name)
          featureInfoTemp.featureTiers.push(featureTier)
          featureTier.includedFeatureNames = getFeatureTierFeatureNames(featureTier.name, featureTiersRef)
          //Get connections from the tier
          const count = getFeatureTierConnectionsCount(featureTier.name, featureTiersRef)
          connectionCounts.push(count)
          featureConnectionCounts.push(count)
        }
      })

      if (futureAccess && futureAccess[0].features) {
        futureAccess[0].features.forEach((feature: IFeature) => {
          if (feature.alacarte === true) {
            featureInfoTemp.futureAlacarteFeatureNames.push(feature.name)
            featureInfoTemp.futureAlacarteFeatureKeys.push(feature.key)
          } else {
            featureInfoTemp.futureFeatureNames.push(feature.name)
            featureInfoTemp.futureFeatureKeys.push(feature.key)
          }
        })
      }
      if (futureAccess && futureAccess[0].featureTiers && futureAccess[0].searchYear) {
        futureAccess[0].featureTiers.forEach((featureTier: IFeatureTier) => {
          if (featureTier.alacarte === true) {
            featureInfoTemp.futureAlacarteFeatureTiers.push(featureTier)
          } else {
            featureInfoTemp.futureFeatureTierNames.push(featureTier.name)
            featureInfoTemp.futureFeatureTiers.push(featureTier)
            featureTier.includedFeatureNames = getFutureFeatureTierFeatureNames(
              featureTier.name,
              futureAccess[0].searchYear,
              featureTiersAllRef
            )
          }
        })
      }

      // load prior year features
      if (priorAccess && priorAccess.features) {
        priorAccess.features.forEach((feature: IFeature) => {
          if (feature.alacarte === true) {
            featureInfoTemp.priorAlacarteFeatureNames.push(feature.name)
            featureInfoTemp.priorAlacarteFeatureKeys.push(feature.key)
          } else {
            featureInfoTemp.priorFeatureNames.push(feature.name)
            featureInfoTemp.priorFeatureKeys.push(feature.key)
          }
        })
      }
      if (priorAccess && priorAccess.featureTiers) {
        priorAccess.featureTiers.forEach((featureTier: IFeatureTier) => {
          if (featureTier.alacarte === true) {
            featureInfoTemp.priorAlacarteFeatureTiers.push(featureTier)
          } else {
            featureInfoTemp.priorFeatureTierNames.push(featureTier.name)
            featureInfoTemp.priorFeatureTiers.push(featureTier)
            featureTier.includedFeatureNames = getPriorFeatureTierFeatureNames(
              featureTier.name,
              searchYear ? searchYear - 1 : new Date().getFullYear(),
              featureTiersAllRef
            )
          }
        })
      }
      featureInfoTemp.subscriptionType =
        transactional && transactional === true
          ? IAccountSubscriptionType.TRANSACTIONAL
          : IAccountSubscriptionType.ACTIVE_SUBSCRIPTION
      featureInfoTemp.totalConnectionsAudiences = connectionCounts.reduce((total, item) => total + item)
      featureInfoTemp.featuresTotalConnectionAudience = featureConnectionCounts.reduce((total, item) => total + item)
      if (featureInfoTemp.featureNames.indexOf(CONNECTION_AUDIENCE_NAME_SUFFIX) != -1) {
        const connectionLabel = CONNECTION_AUDIENCE_NAME_SUFFIX + ` (${featureInfoTemp.featuresTotalConnectionAudience})`
        featureInfoTemp.featureNames[featureInfoTemp.featureNames.indexOf(CONNECTION_AUDIENCE_NAME_SUFFIX)] = connectionLabel
      }
      setFeatureAccess({ features: featureMap, startDate, endDate })
      setFeatureInfo(featureInfoTemp)
      setTransactional(transactional)
      setSearchYearStartDate(searchYearStartDate)
      setSearchYearEndDate(searchYearEndDate)
      setSearchYear(searchYear)
    }

    setLoadingFeatures(false)
    setPrevOrgId(user.orgId)
  }, [headers, user])

  // LIFECYCLES
  useEffect(() => {
    if (((!featureAccess && !flagpoleError) || user.orgId !== prevOrgId) && !loadingFeatures) {
      getFeatureAccess()
    }
  }, [featureAccess, prevOrgId, user.orgId])

  /**---ACTION CREATORS---- */
  const addUsage = (featureKey: string) => {
    featureUsage.add(featureKey)
  }

  const checkAccess = (key: string): boolean => {
    if (user.loginAsOrgId !== undefined) {
      if (LOGIN_AS_BLOCKED_FEATURES.includes(key)) {
        return false
      } else {
        return true
      }
    }

    if (featureAccess) {
      const { startDate, endDate, features } = featureAccess
      if ((startDate && isBefore(startDate)) || (endDate && isAfter(endDate))) {
        return false
      } else {
        const feature = features.get(key)
        if (feature !== undefined) {
          return feature.active || false
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  const clearUsage = () => featureUsage.clear()
  const isLoaded = () => {
    if (user.loginAsOrgId !== undefined) {
      return true
    }

    return !loadingFeatures && (featureAccess !== undefined || flagpoleError)
  }
  const isTransactional = (): boolean => {
    return transactional
  }

  const getFeatureInfo = (refresh: boolean | undefined): IFeatureInfo => {
    if (refresh) {
      getFeatureAccess()
    }

    return featureInfo!
  }

  return (
    <FeatureAccessContext.Provider
      value={{
        loadingFeatures,
        addUsage,
        checkAccess,
        clearUsage,
        isLoaded,
        isTransactional,
        getFeatureInfo,
        searchYear,
        searchYearEndDate,
        searchYearStartDate
      }}
    >
      {children}
    </FeatureAccessContext.Provider>
  )
}

export default FeatureAccessProvider
