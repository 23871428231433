import { useQuery } from '@apollo/client'
import { dateTimeFormatter, ensure } from '@msss/ui-common'
import { Loader, useSearchUserContext } from '@msss/ui-components'
import { useEffect, useMemo } from 'react'
import { GET_PORTFOLIO_BY_CATEGORY_QUERY } from '../../../services/graphql/queries'
import SearchesOrdersContainer from './SearchesOrdersContainer'
import { ErrorNotification, Notification } from '@cb/apricot-react'
import { filterOrderStatusOptions, filterTypeOptions } from './options'
import { ICheck } from '../../search/search-form/ICriteria'
import { IPortfolioItem } from '../common/IPortfolioItem'
import { IPortfolioFileType } from '../../../interfaces/reference/IReference'

interface SearchOrdersHomeProps {
  setDownloadsPendingCount: (count: number) => void
}

export const getPortfolioData = (data: IPortfolioItem[]) => {
  const updatedData = data.map(item => {
    return {
      ...item,
      ...{ name: item.name ? item.name.trim() : item.name },
      ...{
        itemTypeDescr: item.itemType
          ? ensure(filterTypeOptions.find((option: ICheck) => option.value === item.itemType.toString())).label
          : null
      },
      ...{ lastModifiedDateFormatted: item.lastModifiedDate ? dateTimeFormatter.format(new Date(item.lastModifiedDate)) : null },
      ...{
        orderStatusDescr: item.orderStatus
          ? ensure(filterOrderStatusOptions.find((option: ICheck) => option.value === item.orderStatus.toString())).label
          : null
      },
      ...{ orderStatus: item.orderStatus ? item.orderStatus.toString() : null }
    }
  })
  return updatedData
}

export const SearchesOrdersHome = ({ setDownloadsPendingCount }: SearchOrdersHomeProps) => {
  const userContext = useSearchUserContext()

  const { loading, error, data } = useQuery(GET_PORTFOLIO_BY_CATEGORY_QUERY, {
    variables: { orgId: userContext.user.orgId, category: IPortfolioFileType.Search }
  })

  const columns = useMemo(
    () => [
      { Header: 'savedSearchId', accessor: 'savedSearchId' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Order ID', accessor: 'orderId' },
      { Header: 'Type', accessor: 'itemType', filter: 'multiSelect' },
      { Header: 'Type', accessor: 'itemTypeDescr' },
      {
        Header: 'Modified Date',
        accessor: 'lastModifiedDate',
        filter: 'dateRange'
      },
      { Header: 'Modified Date', accessor: 'lastModifiedDateFormatted' },
      { Header: 'Submit Date', accessor: 'submitDate' },
      { Header: 'Start Date', accessor: 'startDate' },
      { Header: 'Names Available', accessor: 'namesAvailable' },
      { Header: 'Names Delivered', accessor: 'namesDelivered' },
      { Header: 'Names Desired', accessor: 'namesDesired' },
      { Header: 'Owner', accessor: 'owner' },
      { Header: 'Owner User ID', accessor: 'ownerUserId' },
      { Header: 'Status', accessor: 'orderStatus', filter: 'multiSelect' },
      { Header: 'Status', accessor: 'orderStatusDescr' },
      { Header: 'Downloads Pending', accessor: 'downloadsPending' },
      { Header: 'Top Search', accessor: 'topSearch' },
      { Header: 'Total Amount', accessor: 'totalAmount' },
      { Header: 'Folder', accessor: 'folderName', disableGlobalFilter: true },
      { Header: 'Folder ID', accessor: 'folderId', disableGlobalFilter: true }
    ],
    []
  )

  const hiddenColumns = useMemo(
    () => [
      'savedSearchId',
      'orderId',
      'itemType',
      'itemTypeDescr',
      'submitDate',
      'startDate',
      'namesDelivered',
      'namesAvailable',
      'namesDesired',
      'owner',
      'lastModifiedDateFormatted',
      'orderStatus',
      'orderStatusDescr',
      'ownerUserId',
      'topSearch',
      'downloadsPending',
      'totalAmount',
      'folderName',
      'folderId'
    ],
    []
  )

  const hasTableData: boolean = data && data.getPortfolio && data.getPortfolio.items && data.getPortfolio.items.length > 0
  const tableData: any = hasTableData && getPortfolioData(data.getPortfolio.items)

  useEffect(() => {
    if (hasTableData) {
      let downloadsPendingCount: number = 0
      tableData.forEach(({ downloadsPending }: { downloadsPending: boolean }) => {
        if (downloadsPending) {
          downloadsPendingCount += 1
        }
      })
      setDownloadsPendingCount(downloadsPendingCount)
    } else {
      setDownloadsPendingCount(0)
    }
  }, [hasTableData, tableData, setDownloadsPendingCount, data])

  if (loading) {
    return <Loader />
  }
  if (error) {
    return <ErrorNotification title='Error'>There was an error fetching Saved Searches & Orders.</ErrorNotification>
  }

  if (hasTableData) {
    return <SearchesOrdersContainer columns={columns} data={tableData} hiddenColumns={hiddenColumns} />
  } else {
    return (
      <Notification color={undefined} icon='exclamation' title='No Records Found'>
        <p>No searches or orders found.</p>
      </Notification>
    )
  }

  return null
}

export default SearchesOrdersHome
