import CBInputMethod from '../../../../../../utils/CBInputMethode'
import { Checkbox, CheckboxGroup } from '@cb/apricot-react-forms'
import { useEffect } from 'react'
import { ICheck } from '../../../ICriteria'
import {
  allApOptions,
  apArtOptions,
  apCapstoneOptions,
  apEnglishOptions,
  apHistoryOptions,
  apLanguageOptions,
  apMathOptions,
  apScienceOptions
} from './CriteriaSelectionOptions'

type APExamsListProps = {
  handleApExams: (event: boolean, value: string) => void
  selectedApExams: () => string[]
  apExams: string[]
}
const APExamsList = (props: APExamsListProps): JSX.Element => {
  useEffect(() => {
    CBInputMethod()
  }, [])
  const { handleApExams, selectedApExams, apExams } = props

  const handleSelectAll = (event: boolean, options: ICheck[]) => {
    const selected = selectedApExams()
    if (event) {
      const availableOptions = options.filter(elem => !selected.find(value => elem.value === value))
      // we want to make sure already selected ap exams are picked again
      availableOptions.map(item => {
        handleApExams(true, item.value)
      })
    } else {
      const availableOptions = options.filter(elem => !selected.find(value => elem.value === value))
      // we want to make sure already selected ap exams are picked again
      availableOptions.map(item => {
        handleApExams(false, item.value)
      })
    }
  }

  const isAllSelected = (options: ICheck[]) => {
    return options.every(item => {
      return apExams.includes(item.value) || selectedApExams().includes(item.value)
    })
  }

  const isAllDisabled = (options: ICheck[]) => {
    return options.every(item => {
      return selectedApExams().includes(item.value)
    })
  }

  return (
    <>
      <div className='cb-border cb-border-1 cb-border-radius'>
        <div className='cb-padding-16 display-flex'>
          <div className='cb-margin-right-24'>
            {/* Select All Checkbox */}
            <Checkbox
              checked={isAllSelected(allApOptions)}
              className='cb-margin-top-24 cb-font-weight-bold'
              disabled={isAllDisabled(allApOptions)}
              id='cb-exams-ap-select-all-ap-option'
              label='Select All AP Exams'
              onChange={(checked: boolean) => handleSelectAll(checked, allApOptions)}
            />
            {/* adding select all checkboxes into array with remaining checkboxes to bypass apricot bug */}
            {/* Art */}
            <CheckboxGroup fieldsetId='cb-exams-ap-art-options' legend='Art' legendClassName='sr-only' vertical>
              {[
                <Checkbox
                  checked={isAllSelected(apArtOptions)}
                  className='cb-margin-top-16 cb-font-weight-bold'
                  disabled={isAllDisabled(apArtOptions)}
                  id='cb-exams-ap-select-all-art-option'
                  key='all'
                  label='Art (Select All)'
                  onChange={(checked: boolean) => handleSelectAll(checked, apArtOptions)}
                />,
                ...apArtOptions.map((option, i) => {
                  const checked = apExams.includes(option.value)
                  const disabled = selectedApExams().includes(option.value)
                  return (
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      id={`cb-exams-ap-art-option-${i}`}
                      key={i}
                      label={option.label}
                      onChange={(checked: boolean) => handleApExams(checked, option.value)}
                    />
                  )
                })
              ]}
            </CheckboxGroup>

            {/* English */}
            <CheckboxGroup fieldsetId='cb-exams-ap-english-options' legend='English' legendClassName='sr-only' vertical>
              {[
                <Checkbox
                  checked={isAllSelected(apEnglishOptions)}
                  className='cb-margin-top-16 cb-font-weight-bold'
                  disabled={isAllDisabled(apEnglishOptions)}
                  id='cb-exams-ap-select-all-english-option'
                  key='all'
                  label='English (Select All)'
                  onChange={(checked: boolean) => handleSelectAll(checked, apEnglishOptions)}
                />,
                ...apEnglishOptions.map((option, i) => {
                  const checked = apExams.includes(option.value)
                  const disabled = selectedApExams().includes(option.value)
                  return (
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      id={`cb-exams-ap-english-option-${i}`}
                      key={i}
                      label={option.label}
                      onChange={(checked: boolean) => handleApExams(checked, option.value)}
                    />
                  )
                })
              ]}
            </CheckboxGroup>

            {/* History & Social Science exams */}
            <CheckboxGroup
              fieldsetId='cb-exams-ap-history-social-sci-options'
              legend='History &amp; Social Science'
              legendClassName='sr-only'
              vertical
            >
              {[
                <Checkbox
                  checked={isAllSelected(apHistoryOptions)}
                  className='cb-margin-top-16 cb-font-weight-bold'
                  disabled={isAllDisabled(apHistoryOptions)}
                  id='cb-exams-ap-select-all-history-social-sci-option'
                  key='all'
                  label='History &amp; Social Science (Select All)'
                  onChange={(checked: boolean) => handleSelectAll(checked, apHistoryOptions)}
                />,
                ...apHistoryOptions.map((option, i) => {
                  const checked = apExams.includes(option.value)
                  const disabled = selectedApExams().includes(option.value)
                  return (
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      id={`cb-exams-ap-history-social-sci-option-${i}`}
                      key={i}
                      label={option.label}
                      onChange={(checked: boolean) => handleApExams(checked, option.value)}
                    />
                  )
                })
              ]}
            </CheckboxGroup>
          </div>
          <div className='cb-margin-right-24'>
            {/* Sciences */}
            <CheckboxGroup fieldsetId='cb-exams-ap-sciences-options' legend='Sciences' legendClassName='sr-only' vertical>
              {[
                <Checkbox
                  checked={isAllSelected(apScienceOptions)}
                  className='cb-margin-top-16 cb-font-weight-bold'
                  disabled={isAllDisabled(apScienceOptions)}
                  id='cb-exams-ap-select-all-sciences-option'
                  key='all'
                  label='Sciences (Select All)'
                  onChange={(checked: boolean) => handleSelectAll(checked, apScienceOptions)}
                />,
                ...apScienceOptions.map((option, i) => {
                  const checked = apExams.includes(option.value)
                  const disabled = selectedApExams().includes(option.value)
                  return (
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      id={`cb-exams-ap-sciences-option-${i}`}
                      key={i}
                      label={option.label}
                      onChange={(checked: boolean) => handleApExams(checked, option.value)}
                    />
                  )
                })
              ]}
            </CheckboxGroup>

            {/* Mathematics & Computer Science */}
            <CheckboxGroup
              fieldsetId='cb-exams-ap-math-comp-sci-options'
              legend='Mathematics &amp; Computer Science'
              legendClassName='sr-only'
              vertical
            >
              {[
                <Checkbox
                  checked={isAllSelected(apMathOptions)}
                  className='cb-margin-top-16 cb-font-weight-bold'
                  disabled={isAllDisabled(apMathOptions)}
                  id='cb-exams-ap-select-all-math-comp-sci-option'
                  key='all'
                  label='Mathematics &amp; Computer Science (Select All)'
                  onChange={(checked: boolean) => handleSelectAll(checked, apMathOptions)}
                />,
                ...apMathOptions.map((option, i) => {
                  const checked = apExams.includes(option.value)
                  const disabled = selectedApExams().includes(option.value)
                  return (
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      id={`cb-exams-ap-math-comp-sci-option-${i}`}
                      key={i}
                      label={option.label}
                      onChange={(checked: boolean) => handleApExams(checked, option.value)}
                    />
                  )
                })
              ]}
            </CheckboxGroup>

            {/* AP Capstone */}
            <CheckboxGroup
              fieldsetId='cb-exams-ap-ap-capstone-options'
              legend='AP Capstone'
              legendClassName='sr-only'
              vertical
            >
              {[
                <Checkbox
                  checked={isAllSelected(apCapstoneOptions)}
                  className='cb-margin-top-16 cb-font-weight-bold'
                  disabled={isAllDisabled(apCapstoneOptions)}
                  id='cb-exams-ap-select-all-ap-capstone-option'
                  key='all'
                  label='AP Capstone (Select All)'
                  onChange={(checked: boolean) => handleSelectAll(checked, apCapstoneOptions)}
                />,
                ...apCapstoneOptions.map((option, i) => {
                  const checked = apExams.includes(option.value)
                  const disabled = selectedApExams().includes(option.value)
                  return (
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      id={`cb-exams-ap-ap-capstone-option-${i}`}
                      key={i}
                      label={option.label}
                      onChange={(checked: boolean) => handleApExams(checked, option.value)}
                    />
                  )
                })
              ]}
            </CheckboxGroup>
          </div>
          <div>
            {/* World Languages &amp; Culture */}
            <CheckboxGroup
              fieldsetId='cb-exams-ap-world-lang-culture-options'
              legend='World Languages &amp; Culture'
              legendClassName='sr-only'
              vertical
            >
              {[
                <Checkbox
                  checked={isAllSelected(apLanguageOptions)}
                  className='cb-margin-top-16 cb-font-weight-bold'
                  disabled={isAllDisabled(apLanguageOptions)}
                  id='cb-exams-ap-select-all-world-lang-culture'
                  key='all'
                  label='World Languages &amp; Culture (Select All)'
                  onChange={(checked: boolean) => handleSelectAll(checked, apLanguageOptions)}
                />,
                ...apLanguageOptions.map((option, i) => {
                  const checked = apExams.includes(option.value)
                  const disabled = selectedApExams().includes(option.value)
                  return (
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      id={`cb-exams-ap-world-lang-culture-option-${i}`}
                      key={i}
                      label={option.label}
                      onChange={(checked: boolean) => handleApExams(checked, option.value)}
                    />
                  )
                })
              ]}
            </CheckboxGroup>
          </div>
        </div>
      </div>
    </>
  )
}

export default APExamsList
