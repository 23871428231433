import { ILabeledValue } from '@cb/apricot-react'
import { INCLUDE_ALL_STUDENTS } from '../../SearchFormConstants'

export enum INCLUDE_STUDENTS {
  all = 'all',
  clusters = 'clusters'
}

export enum ALSO_INCLUDE_STUDENTS {
  noHSC = 'no-hsc',
  noNHC = 'no-nhc'
}

export const includeStudentsMap = new Map<String, ILabeledValue>([
  [
    INCLUDE_STUDENTS.all,
    {
      label: INCLUDE_ALL_STUDENTS,
      value: INCLUDE_STUDENTS.all
    }
  ],
  [
    INCLUDE_STUDENTS.clusters,
    {
      label: 'Include only students within the selected clusters',
      value: INCLUDE_STUDENTS.clusters
    }
  ]
])

export const alsoIncludeStudentsMap = new Map<String, ILabeledValue>([
  [
    ALSO_INCLUDE_STUDENTS.noNHC,
    {
      label: 'Include students with no Educational Neighborhood Clusters',
      value: ALSO_INCLUDE_STUDENTS.noNHC
    }
  ],
  [
    ALSO_INCLUDE_STUDENTS.noHSC,
    {
      label: 'Include students with no High School Clusters',
      value: ALSO_INCLUDE_STUDENTS.noHSC
    }
  ]
])
