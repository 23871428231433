import React from 'react'

type NoExamsRangeProps = {}
const NoExamsRange = (props: NoExamsRangeProps): JSX.Element => {
  return (
    <>
      <div className='col-xs-8 cb-border cb-border-1 cb-border-radius '>
        <div className='cb-padding-16 display-flex' />
      </div>
    </>
  )
}

export default NoExamsRange
