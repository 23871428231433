import { Notification, Spacer } from '@cb/apricot-react'
import { AccordionTableData, GlobalFilter, PageSize, Pagination, Table, useTableContext } from '@msss/ui-components'
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { MSOF_VIEW } from './ViewToggle'
import FolderActions from './FolderActions'
import { IFolder } from './IFolder'
import RemoveModal from './RemoveModal'
import { IPortfolioItem } from '../IPortfolioItem'
import { IFilterOptions } from '../IFilterOptions'
import { IPortfolioFileType } from '../../../../interfaces/reference/IReference'

interface ListFolderViewProps {
  allFolders: IFolder[]
  assignFolderSuccess: IFolder | undefined
  columns: Array<Column<object>>
  data: Array<object>
  filterOptions: FC<IFilterOptions>
  folder: IFolder
  foldersData: IPortfolioItem[] | undefined
  hiddenColumns: string[]
  id: string
  renderHeader: ({ row }: { row: any }) => ReactNode
  renderPanel: ({ row }: { row: any }) => ReactNode
  setAssignFolderSuccess: (value: IFolder | undefined) => void
  setFolder: (folder: IFolder | undefined) => void
  setView: (view: string) => void
  category: IPortfolioFileType
}

const ListFolderView = ({
  allFolders,
  assignFolderSuccess,
  columns,
  data,
  filterOptions,
  folder,
  foldersData,
  hiddenColumns,
  id,
  renderHeader,
  renderPanel,
  setAssignFolderSuccess,
  setFolder,
  setView,
  category
}: ListFolderViewProps) => {
  const initialTableState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'lastModifiedDate',
          desc: true
        }
      ]
    }),
    []
  )

  return (
    <Table columns={columns} data={data} hiddenColumns={hiddenColumns} initialTableState={initialTableState}>
      <ListFolderViewTable
        allFolders={allFolders}
        assignFolderSuccess={assignFolderSuccess}
        filterOptions={filterOptions}
        folder={folder}
        foldersData={foldersData}
        id={id}
        renderHeader={renderHeader}
        renderPanel={renderPanel}
        setAssignFolderSuccess={setAssignFolderSuccess}
        setFolder={setFolder}
        setView={setView}
        category={category}
      />
    </Table>
  )
}

export default ListFolderView

interface ListFolderViewTableProps {
  allFolders: IFolder[]
  assignFolderSuccess: IFolder | undefined
  filterOptions: FC<IFilterOptions>
  folder: IFolder
  foldersData: IPortfolioItem[] | undefined
  id: string
  renderHeader: ({ row }: { row: any }) => ReactNode
  renderPanel: ({ row }: { row: any }) => ReactNode
  setAssignFolderSuccess: (value: IFolder | undefined) => void
  setFolder: (folder: IFolder | undefined) => void
  setView: (view: string) => void
  category: IPortfolioFileType
}

const ListFolderViewTable = ({
  allFolders,
  assignFolderSuccess,
  filterOptions: FilterOptions,
  folder,
  foldersData,
  id,
  renderHeader,
  renderPanel,
  setAssignFolderSuccess,
  setFolder,
  setView,
  category
}: ListFolderViewTableProps) => {
  // selected savedSearch ids that will be used to assign to a folder or remove from folder
  const [selectedSearches, setSelectedSearches] = useState<string[]>([])
  // flag to open/close remove searches confirmation modal
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  // state to show/hide remove searches success msg
  const [removeSuccess, setRemoveSuccess] = useState<number | undefined>(undefined)

  const {
    data,
    globalFilter,
    gotoPage,
    page,
    pageCount,
    pageIndex,
    pageSize,
    rows,
    selectedFlatRows,
    selectedRowIds,
    setFilter,
    setGlobalFilter,
    setPageSize
  } = useTableContext()

  // this useEffect gets just the savedSearchIds from the selected rows
  useEffect(() => {
    setSelectedSearches(selectedFlatRows.map(item => item.values.savedSearchId))
  }, [selectedRowIds])

  const handleFoldersViewClick = useCallback((e: any) => {
    e.preventDefault()
    setView(MSOF_VIEW.FOLDERS)
    setFolder(undefined)
    setRemoveSuccess(undefined)
  }, [])

  const removeFromFolder = useCallback(() => {
    setRemoveModal(true)
  }, [])

  return (
    <>
      <Spacer size='8' />
      <div aria-label='filters' className='row' role='region'>
        <div className='col-xs-12 col-sm-3 cb-margin-bottom-48'>
          <GlobalFilter
            globalFilter={globalFilter}
            id='searches-orders-folders-global-filter'
            label='Search'
            placeholder='Search'
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <div className='col-xs-12 col-sm-9 offset-lg-1 col-lg-8 offset-xl-2 col-xl-7 cb-margin-bottom-48 display-flex justify-content-between'>
          <FilterOptions setFilter={setFilter} setMsofGlobalFilter={setGlobalFilter} />
        </div>
      </div>
      {/* Folder actions menu */}
      <FolderActions
        foldersData={foldersData}
        selectedSearches={selectedSearches}
        allFolders={allFolders}
        setAssignFolderSuccess={setAssignFolderSuccess}
        category={category}
      />
      {/* Success message when searches are removed from a folder */}
      {removeSuccess && removeSuccess > 0 && (
        <div className='col-sm-12 display-flex justify-content-end cb-border-top'>
          <div role='alert' className='cb-toast cb-toast-success' aria-hidden='false'>
            <span className='cb-icon cb-check cb-margin-right-8'>
              <span className='sr-only'>success</span>
            </span>
            <span className='cb-toast-msg'>
              ({removeSuccess}) Searches have been removed from folder '{folder.folderName}'
            </span>
            <button
              type='button'
              className='cb-btn cb-btn-square cb-btn-greyscale cb-btn-close cb-margin-left-8'
              onClick={() => setRemoveSuccess(undefined)}
            >
              <span className='cb-icon cb-x-mark' aria-hidden='false' />
              <span className='sr-only'>Close alert message</span>
            </button>
          </div>
        </div>
      )}
      {/* Success message when searches are assigned to a folder  */}
      {assignFolderSuccess && (
        <div className='col-sm-12 display-flex justify-content-end cb-border-top'>
          <div role='alert' className='cb-toast cb-toast-success' aria-hidden='false'>
            <span className='cb-icon cb-check cb-margin-right-8'>
              <span className='sr-only'>success</span>
            </span>
            <span className='cb-toast-msg'>
              ({assignFolderSuccess.count}) Searches have been assigned to folder '{assignFolderSuccess.folderName}'
            </span>
            <button
              type='button'
              className='cb-btn cb-btn-square cb-btn-greyscale cb-btn-close cb-margin-left-8'
              onClick={() => setAssignFolderSuccess(undefined)}
            >
              <span className='cb-icon cb-x-mark' aria-hidden='false' />
              <span className='sr-only'>Close alert message</span>
            </button>
          </div>
        </div>
      )}
      <div className='row'>
        {/* Folder breadcrumb */}
        <div className='col-sm-12 cb-font-weight-bold cb-margin-bottom-8'>
          <a href='#' className='cb-padding-8' onClick={handleFoldersViewClick}>
            Folders
          </a>{' '}
          / {folder.folderName}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-4' role='status'>
          {rows.length < data.length && (
            <>
              <span className='cb-font-weight-bold'>{rows.length}</span> {rows.length === 1 ? 'Result' : 'Results'}
            </>
          )}
        </div>
        <div className='col-sm-8 cb-align-right'>
          <PageSize pageSize={pageSize} handlePageSize={setPageSize} />
          <span className='cb-padding-8'>
            ({data.length} {data.length === 1 ? 'Record' : 'Records'})
          </span>
        </div>
      </div>

      <AccordionTableData
        headerBtnDisabled={selectedSearches.length === 0}
        headerBtnLabel='Remove from Folder'
        headerBtnOnClick={removeFromFolder}
        id={id}
        renderHeader={renderHeader}
        renderPanel={renderPanel}
        showSelectAll
      />

      {rows.length === 0 && (
        <Notification className='cb-gray5-bg' color={undefined} icon='exclamation' title='No Results Found'>
          <p>To try again, remove any of the selected filters and choose from the others shown.</p>
        </Notification>
      )}

      {page.length < data.length && (
        <div className='row'>
          <div className='col-xs-12 cb-align-center cb-margin-top-24'>
            <PageSize pageSize={pageSize} handlePageSize={setPageSize} />
            <Spacer size='16' />
            <Pagination gotoPage={gotoPage} id='searches-orders-folders' pageCount={pageCount} pageIndex={pageIndex} />
          </div>
        </div>
      )}

      {removeModal ? (
        <RemoveModal
          selectedSearches={selectedSearches.map(item => parseInt(item))}
          folderName={folder.folderName}
          folderId={folder.folderId}
          open={removeModal}
          setOpen={setRemoveModal}
          setRemoveSuccess={setRemoveSuccess}
          category={category}
        />
      ) : null}
    </>
  )
}
