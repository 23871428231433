// this defines the accordion index
export enum Section {
  GRADYEAR = 1,
  IMC,
  IMPEERS,
  EXAMS,
  // EA, may not need
  GEOGRAPHY,
  DEMOGRAPHICS,
  HSACADPERF,
  IM,
  EMAIL,
  PARENTEMAIL,
  SA,
  COLLEGEPREF,
  HS_COURSES_ACTIVITIES,
  NRP,
  LANDSCAPE
}

// this is used to map the getsavedsearch returned criteria by accoridon section
export enum CategoryGroupCode {
  GRADYEAR = 1,
  EXAMS = 2,
  GEOGRAPHY = 3,
  EMAIL = 4,
  DEMOGRAPHICS = 5,
  HSACADPERF = 6,
  IM = 7,
  IMPEERS = 20,
  HS_COURSES_ACTIVITIES = 8,
  COLLEGEPREF = 9,
  SA = 10,
  HSPROFILE = 11,
  HSACTIVITY = 12,
  COMPETITOR = 13,
  IMC = 14,
  ICM = 15,
  NRP = 16,
  EA = 17,
  LANDSCAPE = 18,
  PARENTEMAIL = 19
}
