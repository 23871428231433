import './summary.scss'
import { Column, NakedButton, Table } from '@cb/apricot-react'
import { ensure } from '@msss/ui-common'
import { useEffect, useState } from 'react'
import { CategorizedRemovable } from './CategorizedRemovable'
import { CbExamsRemovable } from './CbExamsRemovable'
import { ISectionSummary, ISummaryTable } from './ISectionSummary'
import { Removable } from './Removable'
import { Section } from './Section'
import SFSSavedCriteria from '../../modals/SFSSavedCriteria'

export const Summary = (props: ISectionSummary): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const { summaryId, section, savedCriteria } = props

  // convert to jsx.element of the string lables so we can click on the x to remove each item
  const [summary, setSummary] = useState<{ name: string; values: JSX.Element }[]>([])

  const summaryEmpty = [
    {
      name: 'No Selections',
      values: <></>
    }
  ]

  useEffect(() => {
    const setup = async () => {
      setLoading(true)
      // default way summary name and values are show for most accordion criteria
      let defaultSummary: ISummaryTable[] = []
      let examSummary: ISummaryTable[] = []

      if (props.data && props.data.length > 0) {
        defaultSummary = props.data.reduce((acc: ISummaryTable[], bits) => {
          if (bits.values && bits.values.length > 0) {
            acc.push({
              name: bits.name,
              values: bits.scroll ? (
                <div className='ms-table-scrollable'>
                  <Removable remove={ensure(bits.remove)} valueType={bits.valueType} values={bits.values} />
                </div>
              ) : (
                <Removable remove={ensure(bits.remove)} valueType={bits.valueType} values={bits.values} />
              )
            })
          } else if (bits.categories && bits.categories.length > 0) {
            acc.push({
              name: bits.name,
              values: (
                <div className='ms-table-scrollable'>
                  <CategorizedRemovable categories={bits.categories} />
                </div>
              )
            })
          }
          return acc
        }, [])
      }

      // now if you have cb exams summary then use the custom component for cb exams
      // convert cbExamsData(ICbExamsBits[]) to examSummary(ISummaryTable[]). also filter out any empty ap exam ones
      if (props.cbExamsData && props.cbExamsData.length > 0) {
        examSummary = props.cbExamsData.reduce((acc: ISummaryTable[], cbExamBits) => {
          if (cbExamBits.scoreBands && cbExamBits.scoreBands.length > 0) {
            acc.push({
              name: cbExamBits.name,
              values: <CbExamsRemovable scoreBands={cbExamBits.scoreBands} />
            })
          }
          return acc
        }, [])
      }

      // combine the arrays
      const summary = [...defaultSummary, ...examSummary]

      setSummary(summary)

      setLoading(false)
    }
    setup()
  }, [props.data, props.cbExamsData])

  // section and preference need to match before the save liink to show
  const additionalFunctions = () => {
    return (
      <div className='ms-clear-all'>
        <div>Selections</div>
        <div>
          <NakedButton noPadding style={{ color: 'white' }} className='cb-pallette-blue3 cb-margin-right-8' onClick={props.clear}>
            Clear All
          </NakedButton>
          {/* this needs to be conditional only for supported sections */}
          {section &&
          savedCriteria &&
          (section === Section.GEOGRAPHY || section === Section.EXAMS || section === Section.SA || section === Section.IM) ? (
            <SFSSavedCriteria savedCriteria={savedCriteria} />
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <div className='ms-table-container'>
      <Table
        className={`ms-table${!summary.length ? ' ms-table-empty' : ''}`}
        data={summary.length ? summary : summaryEmpty}
        tableId={summaryId}
      >
        <Column field='name' rowHeader title='Criteria' />
        <Column field='values' title='Selections' headerComponent={() => additionalFunctions()} />
      </Table>
    </div>
  )
}
